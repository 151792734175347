import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';

import { Button } from '@abyss/web/ui/Button';

import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Text } from '@abyss/web/ui/Text';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { getEnvironment } from '@src/utils/environmentUtil';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Flex } from '@abyss/web/ui/Flex';
import useAuth from '@src/utils/useAuth';
import env from '../../../env.json';
import withAuth from '@src/auth/withAuth';

const UcasFMSITable = () => {
  const { navigate, getLocation } = useRouter();
  const { userRolesGroup } = useAuth();
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [records, setRecords] = useState('0 records');
  const location = getLocation();
  const [isEditFMSI, setEditFMSI] = useState(false);
  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'Edit',
        accessor: 'errSusptblClm',
        dataType: 'text',
        enableEditAction: true,
        sortable: false,
        enableFiltering: false,
      },
      {
        Header: 'FMSI_CD',
        accessor: 'fmsiCd',
      },
      {
        Header: 'FMSI_TXT',
        accessor: 'fmsiTxt',
      },
      {
        Header: 'FMSI_GRP_CD',
        accessor: 'fmsiGrpCd',
      },
      {
        Header: 'FMSI_GRP_TXT',
        accessor: 'fmsiGrpTxt',
      },
      {
        Header: 'LAST_UPDT_DT',
        accessor: 'lastUpdtDt',
      },
      {
        Header: 'LAST_UPDATED_BY',
        accessor: 'lastUpdatedBy',
      },
    ],
    []
  );

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    let hours = today.getHours() % 12 || 12;
    const formattedHours = String(hours).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${formattedHours}${minutes}${seconds}`;
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      // updateRows(row['original']);
      setEditFMSI(true);
    },
    label: <IconSymbol icon="edit" />,
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
      workbook,
      'FMSI AHP INDICATOR' + getFormattedDate() + '.xlsx'
    );
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
      workbook,
      'FMSI AHP INDICATOR' + getFormattedDate() + '.xlsx'
    );
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,

    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions: hasRWaccess && [updateLinkButton],
  });

  const handleClick = () => {
    navigate('/ftmf/ucas');
  };

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);

  const cancelEdit = () => {
    setEditFMSI(false);
  };

  useEffect(() => {
    const hasTableUCAS3ReadWriteAccess =
      env.Table_UCAS_3_Read_Write_Access.some((role) => {
        return userRolesGroup?.includes(role);
      });
    setHasRWaccess(hasTableUCAS3ReadWriteAccess);
    if (
      userRolesGroup.length > 0 &&
      !(
        hasTableUCAS3ReadWriteAccess ||
        env.Table_UCAS_Read_Only_Access.some((role) => {
          return userRolesGroup?.includes(role);
        })
      )
    ) {
      navigate('/ftmf/ucas');
    }
  }, [userRolesGroup]);

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{ marginTop: '135px', marginLeft: '12px' }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Database:
                  </Text>
                  <span>
                    {' '}
                    {getEnvironment()} - {location?.state?.database}
                  </span>
                  {/* <span>DEV - {location?.state?.database}</span> */}
                  <Text
                    css={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: '20px',
                    }}
                  >
                    Table:
                  </Text>
                  <span>{location?.state?.systemSelectedValue}</span>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={300} css={{ fontSize: '14px' }}>
                      {records}
                    </Text>
                  </Grid.Col>
                  {isEditFMSI ? (
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                        marginTop: '20px',
                      }}
                    >
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FMSI_CD"
                          value=""
                          onChange={(e) => {}}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FMSI_TXT"
                          value=""
                          onChange={(e) => {}}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label=" FMSI_GRP_CD"
                          value=""
                          onChange={(e) => {}}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FMSI_GRP_TXT"
                          value=""
                          onChange={(e) => {}}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="LAST_UPDT_DT"
                          value=""
                          onChange={(e) => {}}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="LAST_UPDATED_BY"
                          value=""
                          onChange={(e) => {}}
                        />
                      </Grid.Col>
                      <Flex
                        direction="row"
                        justify="center"
                        style={{ margin: '20px' }}
                      >
                        <Button>Save</Button>

                        <Button
                          onClick={cancelEdit}
                          style={{ marginLeft: '20px' }}
                        >
                          Cancel
                        </Button>
                      </Flex>
                    </Grid>
                  ) : null}

                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title=""
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'left',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(UcasFMSITable, 'table_ucas_ro_rw');
