import React, { useEffect, useState } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import { Link } from '@abyss/web/ui/Link';
import { useDispatch, useSelector } from 'react-redux';
import { lookUpPolicyApi } from '@src/redux/api/FADB/policy/policyApi';
import useAuth from '@src/utils/useAuth';
import {
  lookUpPolicyError,
  lookUpPolicySuccess,
} from '@src/redux/action/FADB/policy/policyActions';
import withAuth from '@src/auth/withAuth';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Heading } from '@abyss/web/ui/Heading';
import { Flex } from '@abyss/web/ui/Flex';

const PolicyLevelSearch = () => {
  const { token } = useAuth();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const dispatch = useDispatch();
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const [recordsCount, setRecordsCount] = useState(
    location?.state?.data?.length
  );
  // get case structure search api data
  const columnsData = location?.state?.searchScreen;

  const getPolicyDetails = async (row, policyNumber) => {
    try {
      const response = await lookUpPolicyApi(policyNumber, config);
      if (response.status === 200) {
        dispatch(lookUpPolicySuccess(response.data));
        const policyDetails = location?.state?.data[row];
        navigate(
          '/fadb/inquiry/case-structure/case-structure-search/policy-details',
          {
            state: {
              columns: location?.state?.columns,
              data: location?.state?.data,
              policyData: policyDetails,
              searchScreen: columnsData,
              include: location?.state?.include,
              policyNumber: location?.state?.policyNumber,
              status: location?.state?.status,
              suffixCode: location?.state?.suffixCode,
              accountCode: location?.state?.accountCode,
              policyTypeCode: location?.state?.policyTypeCode,
              name: location?.state?.name,
              suffixType: location?.state?.suffixType,
              caseInstallationSource: location?.state?.caseInstallationSource,
              originalEffectiveDateFrom:
                location?.state?.originalEffectiveDateFrom,
              originalEffectiveDateTo: location?.state?.originalEffectiveDateTo,
              cancellationDateFrom: location?.state?.cancellationDateFrom,
              cancellationDateTo: location?.state?.cancellationDateTo,
              changeDateFrom: location?.state?.changeDateFrom,
              changeDateTo: location?.state?.changeDateTo,
              authorOfChanges: location?.state?.authorOfChanges,
              legalEntity: location?.state?.legalEntity,
              glReinsurance: location?.state?.glReinsurance,
              outOfNetworkLegalEntity: location?.state?.outOfNetworkLegalEntity,
              fundingArrangment: location?.state?.fundingArrangment,
              legalEntityEffectiveDateFrom:
                location?.state?.legalEntityEffectiveDateFrom,
              legalEntityEffectiveDateTo:
                location?.state?.legalEntityEffectiveDateTo,
              fundingEffectiveDateFrom:
                location?.state?.fundingEffectiveDateFrom,
              fundingEffectiveDateTo: location?.state?.fundingEffectiveDateTo,
              response: response.data,
            },
          }
        );
      }
    } catch (error) {
      dispatch(lookUpPolicyError(error));
    }
  };

  const handleClick = () => {
    window.location.href = '/fadb/inquiry/case-structure/';
  };

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleClick,
    },
  ];

  const getHeadersForEmptyData = () => {
    let columnsHeadings = [];
    columnsHeadings = dataTablePropsPagination.columns[0].headers.reduce(
      (acc, obj) => {
        acc[obj.Header] = null;
        return acc;
      },
      {}
    );
    return columnsHeadings;
  };

  const downloadExcel = () => {
    let fullData = [];
    dataTablePropsPagination?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    if (columnsData === 'P') {
      XLSX.writeFile(workbook, 'Policy_Level_Results(Change_Date).xlsx');
    } else if (columnsData === 'S') {
      XLSX.writeFile(workbook, 'Suffix_Level_Results(Change_Date).xlsx');
    } else if (columnsData === 'A') {
      XLSX.writeFile(workbook, 'Account_Level_Results(Change_Date).xlsx');
    } else if (columnsData === 'F') {
      XLSX.writeFile(workbook, 'Funding_Level_Results(Change_Date).xlsx');
    }
  };

  const downloadFilterExcel = () => {
    let filteredData = [];
    dataTablePropsPagination.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    if (columnsData === 'P') {
      XLSX.writeFile(workbook, 'Policy_Level_Results(Change_Date).xlsx');
    } else if (columnsData === 'S') {
      XLSX.writeFile(workbook, 'Suffix_Level_Results(Change_Date).xlsx');
    } else if (columnsData === 'A') {
      XLSX.writeFile(workbook, 'Account_Level_Results(Change_Date).xlsx');
    } else if (columnsData === 'F') {
      XLSX.writeFile(workbook, 'Funding_Level_Results(Change_Date).xlsx');
    }
  };

  const columnNameMapping = {
    POLICY_NUMBER: 'POLICY NUMBER',
    SUFFIX_CODE: 'SUFFIX CODE',
    SUFFX_ORIG_EFF_DT: 'SUFFX ORIG EFF DT',
    ACCOUNT_CODE: 'ACCOUNT CODE',
    ORIG_EFFECT_DATE: 'ORIG EFFECT DATE',
    ATTR_EFF_DATE: 'ATTR EFF DATE',
    HMO_ACCT_DIVISION: 'HMO ACCT DIVISION',
    SUB_STAT_VALID: 'SUB STAT VALID',
    CANCELATION_DATE: 'CANCELATION DATE',
    CHANGE_DATE: 'CHANGE DATE',
    POL_NBR: 'POLICY NUMBER',
    SRVC_ISS_DT_RUL_CD: 'EFF DT TYP',
    POL_EFF_DT: 'ORG EFF DT',
    ADMIN_SYS_SRC_CD: 'CASE INST',
    POL_FNDNG_EFF_DT: 'FUND EFF DT',
    FUND_ARNG_CD: 'FUND ARRG',
    LEG_ENTY_CD: 'LEG ENT',
    GL_REINS_CD: 'REINS',
    CO_CD: 'CO CD',
    LEG_ENTY_OFN_CD: 'HMO',
    BNK_CD: 'BANK CD',
    POL_CANC_DT: 'CANC DT',
    CHNGDATE: 'CHG DT',
    ORIGINAL_DATE: 'ORG DT',
    ORIGINL_DATE: 'ORG DT',
    EF_DT_TYPE: 'EF DT TYPE',
    ATTRBTS_EFF_DATE: 'ATTRBTS EFF DATE',
    LEGAL_ENTITY: 'LEG ENT',
    GL_REINS: 'GL REINS',
    HMO_L_E: 'HMO LE',
    CNCLTN_DT: 'CNCLTN DT',
    EXT_COV: 'EXT COV',
    EFFECTIVE_DATE: 'EFFECTIVE DATE',
    FUNDING_ARRANGMNT: 'FUNDING ARRANGMNT',
    BANK_CODE: 'BANK CODE',
    LST_CHNG_DATE: 'LST CHNG DATE',
    CHNG_BY: 'CHNG BY',
    TYPE: 'TYPE',
  };

  const getColumnsToShow = (columnsData) => {
    switch (columnsData) {
      case 'F':
        return [
          'POLICY_NUMBER',
          'SUFFIX_CODE',
          'EFFECTIVE_DATE',
          'FUNDING_ARRANGMNT',
          'BANK_CODE',
          'LST_CHNG_DATE',
          'CHNG_BY',
        ];
      case 'S':
        return [
          'POLICY_NUMBER',
          'SUFFIX_CODE',
          'ORIGINL_DATE',
          'TYPE',
          'EF_DT_TYPE',
          'ATTRBTS_EFF_DATE',
          'LEGAL_ENTITY',
          'GL_REINS',
          'HMO_L_E',
          'CNCLTN_DT',
          'EXT_COV',
          'LST_CHNG_DATE',
          'CHNG_BY',
        ];
      case 'P':
        return [
          'POL_NBR',
          'SRVC_ISS_DT_RUL_CD',
          'POL_EFF_DT',
          'ADMIN_SYS_SRC_CD',
          'POL_FNDNG_EFF_DT',
          'FUND_ARNG_CD',
          'LEG_ENTY_CD',
          'GL_REINS_CD',
          'CO_CD',
          'LEG_ENTY_OFN_CD',
          'BNK_CD',
          'POL_CANC_DT',
          'CHNGDATE',
        ];
      case 'A':
        return [
          'POLICY_NUMBER',
          'SUFFIX_CODE',
          'SUFFX_ORIG_EFF_DT',
          'ACCOUNT_CODE',
          'ORIG_EFFECT_DATE',
          'ATTR_EFF_DATE',
          'HMO_ACCT_DIVISION',
          'SUB_STAT_VALID',
          'CANCELATION_DATE',
          'CHANGE_DATE',
        ];
      default:
        return;
    }
  };

  const getTableTitle = {
    P: 'Policy Level Results by Change Date',
    F: 'Suffix Funding and Banking Level Results by Change Date',
    A: 'Account Level Results by Change Date',
    S: 'Suffix General Information Level Results by Change Date',
  };

  const tableTitle = getTableTitle[columnsData];
  const columnsToShow = getColumnsToShow(columnsData);

  const initialColumnsForEmptyData = () => {
    const headerNamesArray = columnsToShow.map((col) => {
      const headerName = columnNameMapping[col] || col;
      console.log(col);
      return {
        Header: headerName,
      };
    });
    return headerNamesArray;
  };
  const trimValues = (obj) => {
    const trimmedObj = {};
    for (let key in obj) {
      if (typeof obj[key] === 'string') {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  };
  const dataTablePropsPagination = useDataTable({
    showPagination: true,
    showFilterDataset: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data.map((item) => trimValues(item))
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
          .filter((col) => columnsToShow?.includes(col.accessor))
          .map((col) => {
            const headerName = columnNameMapping[col.accessor] || col.header;
            return {
              ...col,
              Header: headerName,
              width:
                col.accessor === 'CHNGDATE' || col.accessor === 'LST_CHNG_DATE'
                  ? 200
                  : undefined,
              Cell: ({ value, row }) => {
                if (col.accessor === 'FUNDING_ARRANGMNT') {
                  return value === '1'
                    ? 'Fully Insured'
                    : value === '2'
                    ? 'MP-Minimum Premium'
                    : value === '3'
                    ? 'MMP-Member Minimum Premium'
                    : value === '4'
                    ? 'ASO'
                    : value === '5'
                    ? 'SC/SL 100'
                    : value === '7'
                    ? 'PRIME ASO'
                    : null;
                }
                if (col.accessor === 'BANK_CODE') {
                  return value === 'F'
                    ? 'Fleet'
                    : value === 'C'
                    ? 'Chase'
                    : value === 'W'
                    ? 'Wells Fargo'
                    : null;
                }
                if (
                  col.accessor === 'POL_NBR' ||
                  col.accessor === 'POLICY_NUMBER'
                )
                  return (
                    <a
                      onClick={() => getPolicyDetails(row.index, value)}
                      style={{ cursor: 'pointer', color: 'blue' }}
                    >
                      {value}
                    </a>
                  );
                return value;
              },
            };
          })
      : initialColumnsForEmptyData(),

    customHeaderButtons: [
      {
        label: `${recordsCount} records`,
      },
      {
        label: 'Modify Search',
        onClick: () => {
          navigate('/fadb/inquiry/case-structure/', {
            state: {
              searchScreen: location?.state?.searchScreen,
              include: location?.state?.include,
              policyNumber: location?.state?.policyNumber,
              status: location?.state?.status,
              suffixCode: location?.state?.suffixCode,
              accountCode: location?.state?.accountCode,
              policyTypeCode: location?.state?.policyTypeCode,
              name: location?.state?.name,
              suffixType: location?.state?.suffixType,
              caseInstallationSource: location?.state?.caseInstallationSource,
              originalEffectiveDateFrom:
                location?.state?.originalEffectiveDateFrom,
              originalEffectiveDateTo: location?.state?.originalEffectiveDateTo,
              cancellationDateFrom: location?.state?.cancellationDateFrom,
              cancellationDateTo: location?.state?.cancellationDateTo,
              changeDateFrom: location?.state?.changeDateFrom,
              changeDateTo: location?.state?.changeDateTo,
              authorOfChanges: location?.state?.authorOfChanges,
              legalEntity: location?.state?.legalEntity,
              glReinsurance: location?.state?.glReinsurance,
              outOfNetworkLegalEntity: location?.state?.outOfNetworkLegalEntity,
              fundingArrangment: location?.state?.fundingArrangment,
              legalEntityEffectiveDateFrom:
                location?.state?.legalEntityEffectiveDateFrom,
              legalEntityEffectiveDateTo:
                location?.state?.legalEntityEffectiveDateTo,
              fundingEffectiveDateFrom:
                location?.state?.fundingEffectiveDateFrom,
              fundingEffectiveDateTo: location?.state?.fundingEffectiveDateTo,
            },
          });
        },
      },
    ],
    showDownloadButton: true,
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    pageSizeOptions: [10, 25, 50, 75, 100],
    noDataMessage: `No records found`,
  });

  useEffect(() => {
    setRecordsCount(dataTablePropsPagination.filter?.filteredRows?.length);
  }, [dataTablePropsPagination?.filter?.filteredRows]);

  // const drawer = useOverlay('cellOverflow-drawer');
  // const { data: drawerData } = drawer.getState();
  const date = new Date();
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '220px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Flex justify="space-between">
                <Heading offset={5} textAlign="center">
                  {tableTitle}
                </Heading>
                <b>{formattedDate}</b>
              </Flex>
              <Grid css={{ margin: '0px !important' }}>
                <div
                  style={{
                    height:
                      dataTablePropsPagination?.data?.length > 6
                        ? '550px'
                        : 'auto',
                  }}
                >
                  <DataTable
                    title={tableTitle}
                    tableState={dataTablePropsPagination}
                    hideTitleHeader
                  />
                </div>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(PolicyLevelSearch, 'fadb_update_inquiry');
