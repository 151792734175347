import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SubNavigationMenu } from '@abyss/web/ui/SubNavigationMenu';
import useAuth from '@src/utils/useAuth';
import env from '../env.json';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

const SidebarContainer = styled.div`
  display: flex;
  flexdirection: column;
  width: 210px;
  height: 83vh;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  boxsizing: border-box;
  backgroundcolor: '#f8f8f8';
  padding: 16px 0;
  margin-top: 30px;
  // box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28) !important;
  font-size: 12px;
`;

const CustomSubNavigationMenu = styled(SubNavigationMenu)`
.abyss-sub-navigation-dropdown-title{
  font-size: 16px;
}
.abyss-sub-navigation-header-container {
  position: relative;
}
.abyss-sub-navigation-header-text {
  '&:after': {
    content: '';
    position: absolute;
    borderBottom: '1px solid $gray5';
    width: '100%';
    left: 0;
    bottom: '-8px';
  }
`;

export const Sidebar = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isFADBUpdateTest, setIsFADBUpdateTest] = useState(false);
  const [isFADBInquiryTest, setIsFADBInquiryTest] = useState(false);
  const [isCMDBUpdateTest, setIsCMDBUpdateTest] = useState(false);
  const [isCMDBInquiryTest, setIsCMDBInquiryTest] = useState(false);
  const [isTableCTSReadOnly, setIsTableCTSReadOnly] = useState(false);
  const [isTableCTSReadWrite, setIsTableCTSReadWrite] = useState(false);
  const [isTableUCASReadOnly, setIsTableUCASReadOnly] = useState(false);
  const [isTableUCASReadWrite, setIsTableUCASReadWrite] = useState(false);
  const [isTableFADBReadOnly, setIsTableFADBReadOnly] = useState(false);
  const [isTableFADBReadWrite, setIsTableFADBReadWrite] = useState(false);
  const { userRolesGroup } = useAuth();

  useEffect(() => {
    // ---- FADB Access checks ----
    const hasFADBUpdateTestAccess = env.FADB_Update_Test_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsFADBUpdateTest(hasFADBUpdateTestAccess);

    const hasFADBInquiryTestAccess = env.FADB_Inqury_Test_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsFADBInquiryTest(hasFADBInquiryTestAccess);

    // ---- CMDB Access checks ----
    const hasCMDBUpdateTestAccess = env.CMDB_Update_Test_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsCMDBUpdateTest(hasCMDBUpdateTestAccess);

    const hasCMDBInquiryTestAccess = env.CMDB_Inqury_Test_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsCMDBInquiryTest(hasCMDBInquiryTestAccess);

    // ---- Table Maintenance Access checks ----
    const hasTableCTSReadOnlyAccess = env.Table_CTS_Read_Only_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsTableCTSReadOnly(hasTableCTSReadOnlyAccess);

    const hasTableCTSReadWriteAccess = env.Table_CTS_Read_Write_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsTableCTSReadWrite(hasTableCTSReadWriteAccess);

    const hasTableFADBReadOnlyAccess = env.Table_FADB_Read_Only_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsTableFADBReadOnly(hasTableFADBReadOnlyAccess);

    const hasTableFADBReadWriteAccess = env.Table_FADB_Read_Write_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsTableFADBReadWrite(hasTableFADBReadWriteAccess);

    const hasTableUCASReadOnlyAccess = env.Table_UCAS_Read_Only_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsTableUCASReadOnly(hasTableUCASReadOnlyAccess);

    const hasTableUCASReadWriteAccess = env.Table_UCAS_2_Read_Write_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsTableUCASReadWrite(hasTableUCASReadWriteAccess);

  }, [userRolesGroup]);

  const sideBarMenuItems = [
    {
      title: 'FADB',
      position: 'right',
      isDisabled: !isFADBUpdateTest && !isFADBInquiryTest,
      css: {
        paddingLeft: '0px !important',
      },

      subItems: [
        {
          title: 'Policy',
          href: !isFADBUpdateTest ? "javascript:void(0)" : '/fadb/policy',
          isDisabled: !isFADBUpdateTest,
        },
        {
          title: 'Suffix',
          href: !isFADBUpdateTest ? 'javascript:void(0)' : '/fadb/suffix',
          isDisabled: !isFADBUpdateTest
        },
        {
          title: 'Account',
          href: !isFADBUpdateTest ? 'javascript:void(0)' : '/fadb/account',
          isDisabled: !isFADBUpdateTest
        },
        {
          title: 'Inquiry',
          position: 'right',
          isDisabled: !isFADBUpdateTest && !isFADBInquiryTest,
          css: {
            paddingRight: '0px !important',
          },
          subItems: [
            {
              title: 'Case Structure',
              href: (!isFADBUpdateTest && !isFADBInquiryTest) ? 'javascript:void(0)' : '/fadb/inquiry/case-structure',
              isDisabled: !isFADBUpdateTest && !isFADBInquiryTest,
            },
            {
              title: 'Automated Feeds',
              href: (!isFADBUpdateTest && !isFADBInquiryTest) ? 'javascript:void(0)' : '/fadb/inquiry/automated-feeds',
              isDisabled: !isFADBUpdateTest && !isFADBInquiryTest,
            },
          ],
        },
      ],
    },

    {
      title: 'CMDB',
      isDisabled: !isCMDBUpdateTest && !isCMDBInquiryTest,
      subItems: [
        {
          title: 'Correction Management',
          href: !isCMDBUpdateTest ? "javascript:void(0)" : '/cmdb/correction-management',
          isDisabled: !isCMDBUpdateTest
        },
        {
          title: 'Suspect Errors',
          isDisabled: !isCMDBUpdateTest && !isCMDBInquiryTest,
          subItems: [
            {
              title: 'Errors Download',
              href: (!isCMDBUpdateTest && !isCMDBInquiryTest) ? "javascript:void(0)" : '/cmdb/suspect-errors/errors-download',
              isDisabled: !isCMDBUpdateTest && !isCMDBInquiryTest,
            },
            {
              title: 'Inquiry',
              href: (!isCMDBUpdateTest && !isCMDBInquiryTest) ? "javascript:void(0)" : '/cmdb/suspect-errors/inquiry',
              isDisabled: !isCMDBUpdateTest && !isCMDBInquiryTest,
            },
          ],
        },
      ],
    },
    {
      title: 'Table Maintenance',
      isDisabled: !(isTableCTSReadOnly || isTableCTSReadWrite || isTableFADBReadOnly || isTableFADBReadWrite || isTableUCASReadOnly || isTableUCASReadWrite),
      subItems: [
        {
          title: 'CTS',
          href: (!isTableCTSReadOnly && !isTableCTSReadWrite) ? "javascript:void(0)" : '/ftmf/cts',
          isDisabled: !isTableCTSReadOnly && !isTableCTSReadWrite,
        },
        {
          title: 'FADB',
          href: (!isTableFADBReadOnly && !isTableFADBReadWrite) ? "javascript:void(0)" : '/ftmf/fadb',
          isDisabled: !isTableFADBReadOnly && !isTableFADBReadWrite,
        },
        {
          title: 'UCAS',
          href: (!isTableUCASReadOnly && !isTableUCASReadWrite) ? "javascript:void(0)" : '/ftmf/ucas',
          isDisabled: !isTableUCASReadOnly && !isTableUCASReadWrite,
        },
      ],
    },
  ];

  return (
    <>
      {isAuthenticated && (
        <SidebarContainer>
          <CustomSubNavigationMenu
            navItems={sideBarMenuItems}
            position="left"
            css={{
              'abyss-sub-navigation-menu-root': {
                paddingTop: '2rem !important',
                backgroundColor: '#2d3436',
                backgroundImage:
                  'linear-gradient(315deg, #BFBFBF 0%, #d3d3d3 50%)',
              },
              'abyss-sub-navigation-dropdown-title': {
                color: '#fff !important',
              },
              'abyss-sub-navigation-menu-link': { color: '#ff4e00 !important' },
            }}
          />
        </SidebarContainer>
      )}
    </>
  );
};
