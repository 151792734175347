import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

const SuffixLevel = ({ policyDetails }) => {
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5}>Suffix Level</Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 9}}>
          <Text>{policyDetails?.sufxCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Type:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 9 }}>
          <Text>{policyDetails?.sufxTypCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Legal Entity :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxLegEntyCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Legal Enitiry OFN :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxLegOfnCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Eff. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxEffDt  === "9999-12-31" ? ""  : policyDetails?.sufxEffDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Type of Eff. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srvcIssDtCd === "9999-12-31" ? ""  : policyDetails?.srvcIssDtCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Funding Eff. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxFndEffDt === "9999-12-31" ? ""  : policyDetails?.sufxFndEffDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Funding Arng:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxFundArng}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxCancDt === "9999-12-31" ? "" : policyDetails?.sufxCancDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Attribute Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxAttDt  === "9999-12-31" ? "" : policyDetails?.sufxAttDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Bank Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxBnkCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix GL Reinsurance Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxGlReinsCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Reinst indicator:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.openAccessInd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Ext. Ben. Month Number:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.extBenMoNbr}</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default SuffixLevel;
