import React, { useEffect, useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Flex } from '@abyss/web/ui/Flex';
import { Label } from '@abyss/web/ui/Label';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { BASE_URL } from '@src/utils/endpoints';
import useAuth from '@src/utils/useAuth';
import { Divider } from '@abyss/web/ui/Divider';
import withAuth from '@src/auth/withAuth';

const SubTypeCodeDetails = () => {
  const [data, setData] = useState([]);
  const { getLocation } = useRouter();
  const { state } = getLocation();
  const { navigate } = useRouter();
  const polNumber = state?.rowValues?.POLICY_NBR;
  const transID = state?.transacInfo;
  const sublineSeqNo = state?.sublineSeqNo;
  const { readOnly, token } = useAuth();

  const claimSourceCode =
    state?.data?.clickCell?.sourceSystemList?.find(
      (item) => item.value === data[0]?.trans_src_cd
    )?.label || data[0]?.trans_src_cd;

  const fundingArgCode =
    data[0]?.fund_arng_cd === '1'
      ? 'Fully Insured'
      : data[0]?.fund_arng_cd === '2'
      ? 'MP-Minimum Premium'
      : data[0]?.fund_arng_cd === '3'
      ? 'MMP-Member Minimum Premium'
      : data[0]?.fund_arng_cd === '4'
      ? 'ASO'
      : data[0]?.fund_arng_cd === '5'
      ? 'SC/SL 100'
      : data[0]?.fund_arng_cd === '7'
      ? 'PRIME ASO'
      : '';

  const suspectStatus =
    data[0]?.corr_sts_cd !== 'P'
      ? 'NotFound'
      : data[0]?.corr_sts_cd === 'P'
      ? data[0]?.corr_sts_cd
      : '';

  const tableData = [
    {
      label1: 'SD_Check Number_CPS#:',
      value1: `${data[0]?.srs_desg_cd} -${data[0]?.chk_nbr} -${data[0]?.pay_seq_nbr}`,
      label2: 'Suspect Status:',
      value2: suspectStatus,
    },
    {
      label1: 'Subline Type Code:',
      value1: data[0]?.subl_typ_cd,
      label2: 'Subscriber ID:',
      value2: data[0]?.sbscr_id,
    },
    {
      label1: 'Policy Number:',
      value1: data[0]?.pol_nbr,
      label2: 'Suffix Code:',
      value2: data[0]?.sufx_cd,
    },
    {
      label1: 'Account Code:',
      value1: data[0]?.pol_acct_nbr,
      label2: 'Charge Date:',
      value2: data[0]?.chrg_dt,
    },
    {
      label1: 'Subline Dollar Amount:',
      value1: '$ ' + data[0]?.subl_amt,
      label2: 'Transaction Dollar Amount:',
      value2: '$ ' + data[0]?.trans_amt,
    },
    {
      label1: 'Claim Source System:',
      value1: claimSourceCode,
      label2: 'Issue Date:',
      value2: data[0]?.iss_dt,
    },
    {
      label1: 'Schedule Book Date:',
      value1: data[0]?.sched_bk_dt,
      label2: 'Source Batch Number:',
      value2: data[0]?.src_batch_nbr,
    },
    {
      label1: 'Source Batch Date:',
      value1: data[0]?.src_batch_dt,
      label2: 'Legal Entity Code:',
      value2: data[0]?.leg_enty_cd,
    },
    {
      label1: 'Service From Date:',
      value1: data[0]?.srvc_from_dt,
      label2: 'Legal Entity Group Code:',
      value2: data[0]?.leg_enty_grp_cd,
    },
    {
      label1: 'Company Code:',
      value1: data[0]?.co_cd,
      label2: 'GL Reinsurance Code:',
      value2: data[0]?.gl_reins_cd,
    },
    {
      label1: 'Funding Arrangement Code:',
      value1: fundingArgCode,
      label2: 'HMO Account Division Admin Code:',
      value2: data[0]?.hmo_acctdiv_adm_cd,
    },
    {
      label1: 'HMO Account Division Code:',
      value1: data[0]?.hmo_acct_div_cd,
      label2: 'Case Funding Arrang Group Code:',
      value2: data[0]?.case_fnd_arngrp_cd,
    },
    {
      label1: 'Case Funding Arrang Code:',
      value1: data[0]?.case_fund_arng_cd,
      label2: 'Speciality Code:',
      value2: data[0]?.splcl_cd,
    },
    {
      label1: 'Product Code:',
      value1: data[0]?.prdct_cd,
      label2: 'Medicare Eligibility Indicator:',
      value2: data[0]?.medcr_elig_ind,
    },
    {
      label1: 'Working Status:',
      value1: data[0]?.actv_ret_cd,
    },
  ];
  const trans_id = transID;
  const subl_seq_nbr = sublineSeqNo;
  const pol_nbr = state?.rowValues?.POLICY_NBR;
  const sufx_cd = state?.rowValues?.SFFX_CODE; // SUF CD
  const chrg_dt = state?.rowValues?.CHARGE_DATE; // Charge Date
  const srs_desg_cd = state?.rowValues?.SD; //SD
  const Check_nbr = state?.rowValues?.CHECK; // Check Number
  const pay_seq_nbr = state?.rowValues?.CPS; // CPS
  const subl_typ_cd = state?.rowValues?.SUBLINE_TYPE_CODE; // sub type code
  const sbscr_id = state?.rowValues?.SUBSCR_ID; // SUBSCR ID

  const params = `${trans_id},${subl_seq_nbr},${pol_nbr},${sufx_cd},${chrg_dt},${srs_desg_cd},${Check_nbr},${pay_seq_nbr},${subl_typ_cd},${sbscr_id}`;

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}cmdb-service/suspect-error-sub-details/${params}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        // add-alert-popup
      });
  }, []);

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 11 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '70px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Box color="$white">
                <Flex direction="column">
                  <Flex justify="center" style={{ marginTop: '0px' }}>
                    <Label size="lg" color="#e45f07">
                      Suspect Errors Subline Detail
                    </Label>
                  </Flex>
                  <Flex
                    justify="center"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                  >
                    <Label size="md" color="#e45f07">
                      Transaction Information
                    </Label>
                  </Flex>
                  {tableData.map((item) => (
                    <Flex
                      justify="center"
                      css={{ marginLeft: '25px', maxHeight: '45px' }}
                    >
                      <Label size="em" style={{ width: '25%' }}>
                        {item.label1}
                      </Label>
                      <Label size="em" style={{ width: '25%' }}>
                        {item.value1}
                      </Label>
                      <Label size="em" style={{ width: '25%' }}>
                        {item.label2}
                      </Label>
                      <Label size="em" style={{ width: '25%' }}>
                        {item.value2}
                      </Label>
                    </Flex>
                  ))}
                </Flex>
                <Divider orientation="horizontal" />
                <Flex justify="center">
                  <Label size="em" style={{ width: '10%' }}>
                    System Info:
                  </Label>
                  <Label size="em" style={{ width: '10%' }}>
                    Transaction ID:
                  </Label>
                  <Label size="em" style={{ width: '20%' }}>
                    {data[0]?.trans_id}
                  </Label>
                  <Label size="em" style={{ width: '20%' }}>
                    Subline Sequence Number:
                  </Label>
                  <Label size="em" style={{ width: '20%' }}>
                    {data[0]?.subl_seq_nbr}
                  </Label>
                </Flex>
                <Flex style={{ marginTop: '20px' }}>
                  <Label size="md" style={{ width: '10%' }}></Label>
                  <Label size="em" style={{ width: '20%' }}>
                    Author Of Changes:
                  </Label>
                  <Label size="em" style={{ width: '20%' }}>
                    {data[0]?.user_short_nm}
                  </Label>
                </Flex>
                <Flex justify="center" style={{ marginTop: '20px' }}>
                  <Label size="md" color="#e45f07">
                    Error Codes For The Subline
                  </Label>
                </Flex>
                <Flex justify="center" style={{ marginTop: '20px' }}>
                  <Label size="em" style={{ width: '20%' }}>
                    Error Code
                  </Label>
                  <Label size="em" style={{ width: '20%' }}>
                    Priority Level
                  </Label>
                  <Label size="em" style={{ width: '20%' }}>
                    Description
                  </Label>
                </Flex>
                <Flex justify="center" style={{ marginTop: '20px' }}>
                  <Label size="em" style={{ width: '20%' }}>
                    {data[0]?.spct_err_typ_cd}
                  </Label>
                  <Label size="em" style={{ width: '20%' }}>
                    {data[0]?.prr_typ_cd}
                  </Label>
                  <Label size="em" style={{ width: '20%' }}>
                    {data[0]?.spct_err_desc_txt}
                  </Label>
                </Flex>

                <Flex
                  justify="center"
                  style={{ margin: '10px', marginBottom: '10px' }}
                >
                  <Button
                    onClick={() =>
                      navigate(
                        '/cmdb/suspect-errors/inquiry-search/inquiry-search-subline',
                        { state: state?.data }
                      )
                    }
                  >
                    Return To List
                  </Button>
                </Flex>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};
export default withAuth(SubTypeCodeDetails, 'cmdb_update_inquiry');
