// withAuth.js
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import useAuth from '@src/utils/useAuth';
import env from '../env.json';
import FourOFour from '@src/pages/404';

const withAuth = (WrappedComponent, checkAccessFor) => {
  const AuthenticatedComponent = () => {
    const [validAccess, setValidAccess] = useState(true);
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { navigate } = useRouter();
    const { userRolesGroup } = useAuth();

    useEffect(() => {
      switch (checkAccessFor) {
        case 'fadb_update':
          const hasFADBUpdateAccess = env.FADB_Update_Test_Access.some((role) => {
            return userRolesGroup?.includes(role);
          });
          setValidAccess(hasFADBUpdateAccess);
          break;

        case 'fadb_update_inquiry':
          const hasFADBUpdateInquiryAccess = env.FADB_Update_Test_Access.some((role) => {
            return userRolesGroup?.includes(role);
          }) || env.FADB_Inqury_Test_Access.some((role) => {
            return userRolesGroup?.includes(role);
          });
          setValidAccess(hasFADBUpdateInquiryAccess);
          break;

        case 'cmdb_update':
          const hasCMDBUpdateAccess = env.CMDB_Update_Test_Access.some((role) => {
            return userRolesGroup?.includes(role);
          });
          setValidAccess(hasCMDBUpdateAccess);
          break;

        case 'cmdb_update_inquiry':
          const hasCMDBUpdateInquiryAccess = env.CMDB_Update_Test_Access.some((role) => {
            return userRolesGroup?.includes(role);
          }) || env.CMDB_Inqury_Test_Access.some((role) => {
            return userRolesGroup?.includes(role);
          });
          setValidAccess(hasCMDBUpdateInquiryAccess);
          break;

        case 'table_cts_ro_rw':
          const hasTableCTSRoRwAccess = env.Table_CTS_Read_Only_Access.some((role) => {
            return userRolesGroup?.includes(role);
          }) || env.Table_CTS_Read_Write_Access.some((role) => {
            return userRolesGroup?.includes(role);
          });
          setValidAccess(hasTableCTSRoRwAccess);
          break;

        case 'table_fadb_ro_rw':
          const hasTableFADBRoRwAccess = env.Table_FADB_Read_Only_Access.some((role) => {
            return userRolesGroup?.includes(role);
          }) || env.Table_FADB_Read_Write_Access.some((role) => {
            return userRolesGroup?.includes(role);
          });
          setValidAccess(hasTableFADBRoRwAccess);
          break;

        case 'table_ucas_ro_rw':
          const hasTableUCASRoRwAccess = env.Table_UCAS_Read_Only_Access.some((role) => {
            return userRolesGroup?.includes(role);
          }) || env.Table_UCAS_2_Read_Write_Access.some((role) => {
            return userRolesGroup?.includes(role);
          });
          setValidAccess(hasTableUCASRoRwAccess);
          break;

        case 'allowed':
          setValidAccess(true);
          break;

        default:
          setValidAccess(false);
      };

    }, [checkAccessFor, userRolesGroup]);

    if (!isAuthenticated) {
      navigate('/');
      return null;
    }

    if (validAccess) {
      return <WrappedComponent />;
    } else {
      return <FourOFour />
    }

  };

  return AuthenticatedComponent;
};

export default withAuth;
