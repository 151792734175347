import React, { useEffect, useState } from 'react';

import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { getEnvironment } from '@src/utils/environmentUtil';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import { TextInput } from '@abyss/web/ui/TextInput';
import { constantColumns } from './column-constants';
import { Modal } from '@abyss/web/ui/Modal';
import { Alert } from '@abyss/web/ui/Alert';
import env from '../../../env.json';
import { formateDate } from '@src/components/DateUtils';
import { trimValues } from '../util';

const FinancialMarket = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, msid, userRolesGroup } = useAuth();
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [updateModal, setUpdateModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [jsonData, setJsonData] = useState(location?.state?.data);
  const [updateRow, setUpdateRow] = useState({});
  const [marketSegInd, setmarketSegInd] = useState('');
  const [marketSegTxt, setmarketSegTxt] = useState('');
  const [FmsiTypeCd, setFmsiTypeCd] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [marketSegIndErr, setMarkeySegIndErr] = useState('');
  const [marketSegTextErr, setMarketSegTextErr] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const [disableMrkSegIndUpdt, setDisableMrkSegIndUpdt] = useState(false);
  const [stateValue, setStateValue] = useState(location?.state);
  const [records, setRecords] = useState('0 records');

  useEffect(() => {
    if (location?.state?.data) {
      setStateValue(location?.state);
    }
  }, [location?.state]);

  const columns = React.useMemo(
    () => constantColumns[stateValue.systemSelectedValue],
    []
  );

  const updateRows = (row) => {
    clearFields();
    setUpdateFlag(true);
    setHideAdd(true);
    setDisableMrkSegIndUpdt(true);
    setFlag(true);
    setUpdateRow(row);
    setmarketSegInd(row?.['FINL_MKT_SEG_IND']);
    setFmsiTypeCd(row?.['FMSI_TYPE_CD']);
    setmarketSegTxt(row?.['FINL_MKT_SEG_TXT']);
  };
  const getTableData1 = async () => {
    let payload = [stateValue?.database, stateValue?.systemSelectedValue];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${stateValue?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: <IconSymbol icon="edit" />,
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor.trim()] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Financial Market Segment Indicator.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Financial Market Segment Indicator.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data.map((item) => {
        const trimmedValue = trimValues(item);
        return {
     ...trimmedValue, 
     LST_CHANGE_DATE: formateDate(trimmedValue.LST_CHANGE_DATE),
        };
        })
      : [],
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions: hasRWaccess && (hideUpdate ? [] : [updateLinkButton]),
  });
  const clearFields = () => {
    setErrorMessage('');
    setErrorAlert(false);
    setErrorMessage('');
    setMarketSegTextErr('');
    setMarkeySegIndErr('');
  };
  const addRow = () => {
    clearFields();
    setFlag(true);
    setUpdateFlag(false);
    setHideUpdate(true);
    setHideAdd(true);
    setDisableMrkSegIndUpdt(false);

    //setHideAddRowBtn(true)
  };
  const handleClick = () => {
    navigate(-1);
  };
  const validateSave = () => {
    let valid = true;
    if (marketSegInd === '') {
      setMarkeySegIndErr('Market Segment Ind is required.');
      valid = false;
    } else {
      setmarketSegInd(marketSegInd.toLocaleUpperCase().trim());
      if (!isNaN(marketSegInd)) {
        setMarkeySegIndErr('Market Segment Ind should be character');
        valid = false;
      } else {
        setMarkeySegIndErr('');
      }
    }
    if (marketSegTxt === '' || marketSegTxt.trim() === '') {
      setMarketSegTextErr('Market Segment Text is required.');
      valid = false;
    } else {
      setMarketSegTextErr('');
    }

    if (marketSegInd && marketSegTxt) {
      if (
        jsonData.some(
          (item) =>
            item.FINL_MKT_SEG_IND.trim() ===
            marketSegInd.toLocaleUpperCase().trim()
        )
      ) {
        setErrorAlert(true);
        setErrorMessage('Row found on table, can not Add row.');
        valid = false;
      } else {
        setErrorAlert(false);
        setMarketSegTextErr('');
      }
    }

    if (FmsiTypeCd) {
      setFmsiTypeCd(FmsiTypeCd.toLocaleUpperCase().trim());
    } else {
      setFmsiTypeCd('');
    }

    if (valid) {
      setSaveModal(true);
      setMarkeySegIndErr('');
      setMarketSegTextErr('');
    }
  };

  const validateUpdate = () => {
    let valid = true;
    if (marketSegTxt === '' || marketSegTxt.trim() === '') {
      setMarketSegTextErr('Market Segment Text is required.');
      valid = false;
    } else {
      setMarketSegTextErr('');
    }
    if (marketSegTxt && FmsiTypeCd) {
      if (
        updateRow['FINL_MKT_SEG_TXT'].trim().toUpperCase() ===
          marketSegTxt.trim().toUpperCase() &&
        updateRow['FMSI_TYPE_CD'].trim().toUpperCase() ===
          FmsiTypeCd.trim().toUpperCase()
      ) {
        setErrorAlert(true);
        setErrorMessage('No changes made to update the row.');
        valid = false;
      }
    }

    if (
      updateRow['FINL_MKT_SEG_TXT'].trim().toUpperCase() !==
      marketSegTxt.trim().toUpperCase()
    ) {
      if (
        jsonData.some(
          (item) =>
            item.FINL_MKT_SEG_TXT.trim().toLocaleUpperCase() ===
            marketSegTxt.trim().toLocaleUpperCase()
        ) &&
        jsonData.some(
          (item) =>
            item.FINL_MKT_SEG_IND.trim().toLocaleUpperCase() ===
            marketSegInd.trim().toLocaleUpperCase()
        )
      ) {
        setErrorAlert(true);
        setErrorMessage('Row found on table, can not update row.');
        valid = false;
      } else {
        setErrorAlert(false);
        setErrorMessage('');
      }
    }
    if (valid) {
      setUpdateModal(true);
      setErrorAlert(false);
      setErrorMessage('');
    }
  };
  const save = async () => {
    setSaveModal(false);
    setHideUpdate(false);
    setHideAdd(false);
    const now = new Date();
    setFlag(false);
    let payload = {
      finlMktSegInd: marketSegInd.toLocaleUpperCase(),
      finlMktSegTxt: marketSegTxt.toLocaleUpperCase(),
      fmsiTypeCd: FmsiTypeCd.toLocaleUpperCase(),
      lstChangeId: msid.substr(0, 7).toUpperCase(),
    };
    setmarketSegInd('');
    setFmsiTypeCd('');
    setmarketSegTxt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/fin-mar-seg-ind/${stateValue?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully added record to table');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setJsonData(tableData);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const update = async () => {
    setHideAdd(false);
    setUpdateModal(false);
    setUpdateConfirmModal(false);
    setUpdateFlag(false);
    clearFields();
    const now = new Date();
    setFlag(false);
    let payload = {
      newRecord: {
        finlMktSegInd: marketSegInd,
        finlMktSegTxt: marketSegTxt.toLocaleUpperCase(),
        fmsiTypeCd: FmsiTypeCd.toLocaleUpperCase(),
        lstChangeId: msid.substr(0, 7).toUpperCase(),
      },
      oldRecord: {
        finlMktSegInd: updateRow['FINL_MKT_SEG_IND'],
        finlMktSegTxt: updateRow['FINL_MKT_SEG_TXT'],
        fmsiTypeCd: updateRow['FMSI_TYPE_CD'],
      },
    };
    setmarketSegInd('');
    setFmsiTypeCd('');
    setmarketSegTxt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/fin-mar-seg-ind/${stateValue?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully updated the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setJsonData(tableData);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };

  const updateClose = () => {
    if (updateFlag) {
      setmarketSegInd('');
      setFmsiTypeCd('');
      setmarketSegTxt('');
    }
    setHideUpdate(false);
    setHideAdd(false);
    setFlag(false);
  };
  const close = () => {
    setHideUpdate(false);
    setHideAdd(false);
    setFlag(false);
  };

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);

  useEffect(() => {
    if (isVisible || errorAlert) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        setErrorAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isVisible, errorAlert]);

  useEffect(() => {
    const hasTableCTSReadWriteAccess = env.Table_CTS_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    setHasRWaccess(hasTableCTSReadWriteAccess);
  }, [userRolesGroup]);

  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateConfirmModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update"
          isOpen={updateModal}
          onClose={() => setUpdateModal(false)}
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market Seg Ind: </b>
                {updateRow['FINL_MKT_SEG_IND']}
              </span>
              <span>
                <b>Market Seg Ind: </b>
                {marketSegInd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market Seg Txt: </b>
                {updateRow['FINL_MKT_SEG_TXT']}
              </span>
              <span>
                <b>Market Seg Txt: </b>
                {marketSegTxt.toLocaleUpperCase()}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>FMSI Type Cd: </b>
                {updateRow['FMSI_TYPE_CD']}
              </span>
              <span>
                <b>FMSI Type Cd: </b>
                {FmsiTypeCd.toLocaleUpperCase()}
              </span>
            </div>
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                title={alertMsg}
                variant="success"
                isVisible={isVisible}
                onClose={() => {
                  setIsVisible(false);
                }}
              />

              <Alert
                title={errorMessage}
                variant="error"
                isVisible={errorAlert}
                onClose={() => {
                  setErrorAlert(false);
                }}
              />

              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {stateValue?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span> Financial Market Segment Indicator</span>
              <br />
              <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                <Text fontWeight={300} css={{ fontSize: '14px' }}>
                  {records}
                </Text>
              </Grid.Col>
              <br />
              {hasRWaccess && (
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={hideAdd}
                >
                  + Add Row
                </Button>
              )}
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="Market Seg Ind"
                        value={marketSegInd}
                        onChange={(e) => setmarketSegInd(e.target.value)}
                        maxLength={3}
                        isDisabled={disableMrkSegIndUpdt}
                        errorMessage={marketSegIndErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="Market Seg Txt"
                        value={marketSegTxt}
                        onChange={(e) => setmarketSegTxt(e.target.value)}
                        maxLength={100}
                        errorMessage={marketSegTextErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="FMSI Type Cd"
                        value={FmsiTypeCd}
                        onChange={(e) => setFmsiTypeCd(e.target.value)}
                        maxLength={3}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button
                        onClick={() => {
                          clearFields();

                          if (updateFlag) {
                            if (validateUpdate()) {
                              setUpdateModal(true);
                            }
                          } else if (validateSave()) {
                            setSaveModal(true);
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button
                        onClick={() => {
                          if (updateFlag) {
                            updateClose();
                          } else {
                            close();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(FinancialMarket, 'table_cts_ro_rw');
