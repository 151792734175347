import React, { useState, useEffect } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import axios from 'axios';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import { DateInput } from '@abyss/web/ui/DateInput';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { currentDateFormat } from '@src/components/DateUtils';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';
import { set } from 'lodash';

const SuspectError = () => {
  const { readOnly, token, msid } = useAuth();
  const id = 'suspectError';
  const path = '/cmdb/correction-management';
  const { navigate } = useRouter();
  const handleBackButton = () => {
    navigate(path);
  };
  const [seriesDesignator, setSeriesDesignator] = useState(null);
  const [sdError, setSdError] = useState('');
  const [checkNumber, setCheckNumber] = useState(null);
  const [cnError, setCnError] = useState('');
  const [cps, setCps] = useState(null);
  const [cpsError, setCpsError] = useState('');
  const [suffixCode, setSuffixCode] = useState(null);
  const [policyNumber, setPolicyNumber] = useState('');
  const [pnError, setPnError] = useState('');
  const [chargeFromDate, setChargeFromDate] = useState(null);
  const [chargeToDate, setChargeToDate] = useState(null);
  const [suffixError, setSuffixError] = useState('');
  const [cfdError, setCfdError] = useState('');
  const [ctdError, setCtdError] = useState('');
  const [csc, setCsc] = useState(null);
  const [cscError, setCscError] = useState('');
  const [suspectErrorOptions, setSuspectErrorOptions]: any = useState([]);
  const [errorCode, setErrorCode] = useState('');
  const [ec, setEc] = useState('');
  const [funArrGrpCd, setFunArrGrpCd] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const date = new Date();
  const resetErrorFields = () => {
    setSdError('');
    setCnError('');
    setCpsError('');
    setSuffixError('');
    setPnError('');
    setCfdError('');
    setCtdError('');
    setCscError('');
    setEc('');
  };
  const reset = () => {
    setSeriesDesignator(null);
    setSdError('');
    setCheckNumber(null);
    setCnError('');
    setCps(null);
    setCpsError('');
    setSuffixCode(null);
    setSuffixError('');
    setPolicyNumber('');
    setPnError('');
    setChargeFromDate(null);
    setChargeToDate(null);
    setCfdError('');
    setCtdError('');
    setCsc(null);
    setCscError('');
    setErrorCode('');
    setFunArrGrpCd(null);
    setEc('');
  };
  const checkPolicyNumber = (polNumber) => {
    const regEx: RegExp = /^\d{2}[A-Za-z0-9]\d{4}$/;
    if (polNumber.match(regEx)) {
      return true;
    } else {
      return false;
    }
  };

  const checkValidSuffix = (sufixcd) => {
    const letters: RegExp = /^[A-Za-z.]+$/;
    if (sufixcd.match(letters)) {
      return false;
    } else {
      return true;
    }
  };
  const checkNumeric = (chknbr) => {
    const numbers: RegExp = /^[0-9]+$/;
    if (chknbr.match(numbers)) {
      return false;
    } else {
      return true;
    }
  };

  const checkAlphaNumeric = (chknbr) => {
    const numbers: RegExp = /^[A-Za-z0-9]+$/;
    if (chknbr.match(numbers)) {
      return false;
    } else {
      return true;
    }
  };
  const isSrsDesgLocked = (seriesDesignator) => {
    let isLocked = false;
    switch (seriesDesignator) {
      case 'CU':
      case 'CV':
      case 'CW':
      case 'EN':
      case 'FN':
      case 'KN':
      case 'MN':
      case 'N1':
      case 'N2':
      case 'N3':
      case 'N4':
      case 'N5':
      case 'N6':
      case 'NN':
      case 'NO':
      case 'NQ':
      case 'NY':
      case 'NZ':
      case 'OB':
      case 'OC':
      case 'PB':
      case 'PC':
      case 'PD':
      case 'PE':
      case 'PR':
      case 'QN':
      case 'RS':
      case 'UN':
      case 'VZ':
      case 'W1':
      case 'W2':
      case 'M8':
      case 'M9':
      case 'LU':
      case 'LV':
        isLocked = true;
        break;

      default:
        isLocked = false;
        break;
    }
    return isLocked;
  };
  const validate = () => {
    let validFlag = true;
    if (
      null == policyNumber ||
      policyNumber === undefined ||
      policyNumber === ''
    ) {
      setPnError('Policy Number is required.');
      validFlag = false;
    } else if (policyNumber.length !== 7) {
      setPnError('Policy Number must be 7 charecters.');
      validFlag = false;
    } else if (policyNumber.length === 7 && !checkPolicyNumber(policyNumber)) {
      setPnError('Policy Number must be numeric, Except for the 3rd position.');
      validFlag = false;
    }
    if (
      null == chargeFromDate ||
      chargeFromDate === undefined ||
      chargeFromDate === ''
    ) {
      setCfdError('Charge From Date is required.');
      validFlag = false;
    }
    if (
      null == chargeToDate ||
      chargeToDate === undefined ||
      chargeToDate === ''
    ) {
      setCtdError('Charge To Date is required.');
      validFlag = false;
    }
    if (chargeFromDate !== null && chargeToDate !== null) {
      let to = new Date(chargeFromDate);
      let from = new Date(chargeToDate);
      if (to > from) {
        setCfdError('From Date is not before To Date.');
        setCtdError('From Date is not before To Date.');
        validFlag = false;
      }
      const date = new Date(chargeFromDate);
      const year = date.getFullYear();
      const currentYear = new Date().getFullYear();
      if (year + 3 < currentYear) {
        setCfdError(
          'You have requested a date over 3 years old.' +
            'The active Galaxy database may not have the complete history for your request.' +
            'Please change the Charge date.'
        );
        validFlag = false;
      }
    }
    if (
      null == errorCode ||
      errorCode === undefined ||
      errorCode === '0' ||
      errorCode === ''
    ) {
      setEc('Error Code is required.');
      validFlag = false;
    } else if (errorCode === '4') {
      if (null == suffixCode || suffixCode === undefined || suffixCode === '') {
        setEc('Suffix Code with Error CD 4.');
        validFlag = false;
      }
    }
    if (
      !(null == suffixCode || suffixCode === undefined || suffixCode === '')
    ) {
      if (suffixCode.length > 2) {
        setSuffixError('Suffix Code must be 2 charecters.');
        validFlag = false;
      } else if (checkValidSuffix(suffixCode)) {
        setSuffixError(
          'Please enter a valid suffix with only letters or dots.'
        );
        validFlag = false;
      }
    }

    if (
      !(
        null == seriesDesignator ||
        seriesDesignator === undefined ||
        seriesDesignator === ''
      )
    ) {
      if (
        seriesDesignator.trim().length !== 2 ||
        checkAlphaNumeric(seriesDesignator.trim())
      ) {
        setSdError('Please enter a valid value for Series Designator code.');
        validFlag = false;
      } else if (isSrsDesgLocked(seriesDesignator.toUpperCase())) {
        setSdError(
          'This Series Designator Code is locked out, Please try another.'
        );
        validFlag = false;
      }
    }

    if (!(null == csc || csc === undefined || csc === '')) {
      if (csc.length > 2) {
        setCscError('Please enter a valid value for Claim Source Code.');
        validFlag = false;
      }
    }

    if (
      checkNumber !== null &&
      checkNumber !== undefined &&
      checkNumber !== ''
    ) {
      if (checkNumber.length !== 8) {
        setCnError('Check number must be 8 numbers long.');
        validFlag = false;
      } else if (checkNumeric(checkNumber)) {
        setCnError('Please enter a valid Check Number.');
        validFlag = false;
      }
    }

    if (null !== cps && cps !== '' && cps !== undefined) {
      if (
        checkNumber !== null &&
        checkNumber !== undefined &&
        checkNumber !== ''
      ) {
        if (cps.length !== 8) {
          setCpsError('Claim Payment Sequence number must be 8 numbers long.');
          validFlag = false;
        } else if (checkNumeric(cps)) {
          setCpsError('Please enter a valid Claim Payment Sequence Number.');
          validFlag = false;
        }
      } else {
        setCpsError('Check number is required.');
        validFlag = false;
      }
    }
    return validFlag;
  };
  const formatDate = (inputDate) => {
    const [month, day, year] = inputDate.split('/');
    return `${year}-${month}-${day}`;
  };
  const handleClick = async () => {
    resetErrorFields();
    const valid = validate();
    setIsSubmitDisabled(true);
    if (valid) {
      try {
        let data = {
          policyNumber: policyNumber && policyNumber.toUpperCase(),
          suffixCode: suffixCode && suffixCode.replaceAll("."," ").toUpperCase(),
          fromChargeDate: formatDate(chargeFromDate),
          toChargeDate: formatDate(chargeToDate),
          spctErrTypCd: errorCode,
          claimSourceCode: csc,
          seriesDesignator: seriesDesignator && seriesDesignator.toUpperCase(),
          checkNumber: checkNumber,
          fundArrGrpCode: funArrGrpCd,
          cps: cps,
          userShortNm: msid,
        };
        const response = await axios
          .post(`${BASE_URL}cmdb-service/save-suspect-error`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIsSubmitDisabled(false);
            if (response.status === 200) {
              navigate(
                '/cmdb/correction-management/suspect-error/suspect-error-submit',
                {
                  state: {
                    policyNumber: policyNumber && policyNumber.toLocaleUpperCase(),
                    suffixCode: suffixCode && suffixCode.replaceAll("..","__").replaceAll("."," ").toLocaleUpperCase(),
                    fromChargeDate: chargeFromDate,
                    toChargeDate: chargeToDate,
                    spctErrTypCd: errorCode,
                    claimSourceCode: csc,
                    seriesDesignator: seriesDesignator && seriesDesignator.toUpperCase(),
                    checkNumber: checkNumber,
                    fundArrGrpCode: funArrGrpCd,
                    cps: cps,
                    requestId: response.data,
                  },
                }
              );
            }
          })
          .catch((error) => {
            // add-alert-popup
          });
      } catch (error) {
        // add-alert-popup
      }
    }else{
      setIsSubmitDisabled(false);
    }
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cmdb-service/suspectError/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data) {
          setSuspectErrorOptions(response.data);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        // add-alert-popup
      }
    };
    if (token) fetchDropdownData();
  }, [token]);

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '70px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Custom Correction Request
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '10px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Suspect Error
                  </Heading>
                  <Flex justify="end">
                    <Label size="md" id="first name">
                      {currentDateFormat(date)}
                    </Label>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '20px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Required Criteria:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Charge Date:</Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label="From"
                    placeholder="Pick one"
                    isClearable
                    value={chargeFromDate}
                    onChange={(date) => setChargeFromDate(date)}
                    errorMessage={cfdError}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label="To"
                    placeholder="Pick one"
                    isClearable
                    value={chargeToDate}
                    errorMessage={ctdError}
                    onChange={(date) => setChargeToDate(date)}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="Policy Number"
                    isDisabled={readOnly}
                    value={policyNumber}
                    onChange={(e) => setPolicyNumber(e.target.value)}
                    errorMessage={pnError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <SelectInput
                    label="Error Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={suspectErrorOptions?.suspectErrorEntityList?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    errorMessage={ec}
                    value={errorCode}
                    onChange={(val) => {
                      setErrorCode(val);
                    }}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
              </Grid>

              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '20px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Other Criteria:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Suffix Code:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    subText="Enter . (dot) if you want to give a space."
                    value={suffixCode}
                    onChange={(e) => setSuffixCode(e.target.value.replaceAll(" ","."))}
                    errorMessage={suffixError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Series Designator:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    value={seriesDesignator}
                    onChange={(e) => setSeriesDesignator(e.target.value)}
                    errorMessage={sdError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Claim Source Code:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    value={csc}
                    onChange={(e) => setCsc(e.target.value)}
                    errorMessage={cscError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Check Number:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    value={checkNumber}
                    errorMessage={cnError}
                    onChange={(e) => setCheckNumber(e.target.value)}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>
                    Funding Arrangement Group Code:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <SelectInput
                    label=""
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={suspectErrorOptions?.fundArgGrpCode?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    value={funArrGrpCd}
                    onChange={(val) => setFunArrGrpCd(val)}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>CPS#:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    isDisabled={readOnly}
                    value={cps}
                    errorMessage={cpsError}
                    onChange={(e) => setCps(e.target.value)}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '100px',
                      },
                    }}
                    ariaLoadingLabel="Importing data"
                    isLoading={isSubmitDisabled}
                    isDisabled={readOnly}
                  >
                    Submit
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={reset}
                  >
                    Reset
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleBackButton}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(SuspectError, 'cmdb_update');
