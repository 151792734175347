import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { useDispatch } from 'react-redux';

import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Text } from '@abyss/web/ui/Text';
import { Heading } from '@abyss/web/ui/Heading';
import { Link } from '@abyss/web/ui/Link';
import withAuth from '@src/auth/withAuth';
import {
  updateInquiryElementSearch,
  updateInquiryElementSearchError,
  updateInquirySearch,
  updateInquirySearchError,
  updateInquirySearchSuccess,
  updateInquiryeElementSearchSuccess,
} from '@src/redux/action/CMDB/suspectErrror/inquiry/inquiryAPI';
import {
  inquirySearchApi,
  inquirySearchByElementApi,
} from '@src/redux/api/CMDB/suspectError/inquiry/inquiryAPI';
import useAuth from '@src/utils/useAuth';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';

const InquiryDataSearch = () => {
  const { navigate, getLocation } = useRouter();
  const dispatch = useDispatch();
  const { token } = useAuth();
  const searchData = useSelector((state) => state.inquiry.data);
  const [record, setRecord] = useState(searchData?.pageItems?.length);

  let location = getLocation();
  let data = location?.state;

  const payloadData = useSelector((state) => state.inquiry.payloads);
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {
    displayType,
    reportType,
    errorCode,
    policyNumber,
    legalEntity,
    legalEntityGroup,
    claimSourceCode,
    priorityLevel,
    suffixCode,
    accountCode,
  } = payloadData || {};

  const today = new Date();
  const day = today.toLocaleString('default', { weekday: 'long' });
  const month = today.toLocaleString('default', { month: 'long' });
  const date = today.getDate();
  const year = today.getFullYear();
  const currDate = `${day}, ${month} ${date}, ${year}`;
  const apiHandler = async () => {
    const payload = {
      policyNumber: policyNumber,
      suffixCode: '',
      accountNumber: accountCode,
      reportType: reportType,
      prorityLvl: priorityLevel,
      displayType: displayType,
      errorCode: errorCode,
      legalEntity: legalEntity,
      legalEntityGroup: legalEntityGroup,
    };
    dispatch(updateInquirySearch(payload));
    try {
      const response = await inquirySearchApi(payload, config);
      if (response.status === 200) {
        dispatch(updateInquirySearchSuccess(response.data));
      }
    } catch (error) {
      dispatch(updateInquirySearchError(error));
    }
  };

  const policyData = useSelector((state) => state.inquiry.policy);

  const handleClick = () => {
    navigate('/cmdb/suspect-errors/inquiry', {
      state: { data: location?.state?.data },
    });
  };

  // useEffect(() => {
  //   if (token) apiHandler();
  // }, [token]);

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleClick,
    },
  ];

  const onClickHandler = async (row, col) => {
    function camelize(str) {
      return str
        .toLowerCase()
        .split(' ')
        .map((word, index) => {
          if (index === 0) {
            return word;
          }
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join('');
    }
    const querySearch = row.original.ND_D_CONCAT_PK;
    const type = camelize(col.Header);

    const clickCellValue = row?.values[col.key];
    const clickedCellTitle = col.Header.replace(/_/g, '')
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());

    let searchDisplayValues = [];
    if (reportType === 'CS') {
      let fundingArgCode =
        row?.original?.CLAIM_FUNDING === '1'
          ? 'Fully Insured'
          : row?.original?.CLAIM_FUNDING === '2'
          ? 'MP-Minimum Premium'
          : row?.original?.CLAIM_FUNDING === '3'
          ? 'MMP-Member Minimum Premium'
          : row?.original?.CLAIM_FUNDING === '4'
          ? 'ASO'
          : row?.original?.CLAIM_FUNDING === '5'
          ? 'SC/SL 100'
          : row?.original?.CLAIM_FUNDING === '7'
          ? 'PRIME ASO'
          : '';
      searchDisplayValues.push(`Policy Number: ${policyNumber}`);
      if (type.trim() === 'priorityLevel') {
        if (displayType === 'A') {
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
          searchDisplayValues.push(
            `Account Code: ${row?.original?.ACCOUNT_CODE}`
          );
        } else if (displayType === 'S') {
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
        }
        searchDisplayValues.push(
          `Priority Level: ${row?.original?.PRIORITY_LEVEL}`
        );
      } else if (type.trim() === 'errorCode') {
        if (displayType === 'A') {
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
          searchDisplayValues.push(
            `Account Code: ${row?.original?.ACCOUNT_CODE}`
          );
        } else if (displayType === 'S') {
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
        }
        searchDisplayValues.push(`Error Code: ${row?.original?.ERROR_CODE}`);
      } else if (type.trim() === 'suffixCode') {
        searchDisplayValues.push(`Suffix Code: ${row?.original?.SUFFIX_CODE}`);
      } else if (type.trim() === 'claimFunding') {
        if (displayType === 'S') {
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
        }
        searchDisplayValues.push(`Funding Arrangement Code: ${fundingArgCode}`);
      }
    } else if (reportType === 'EC') {
      searchDisplayValues.push(`Error Code: ${row?.original?.ERROR_CODE}`);
      if (type.trim() === 'priorityLevel') {
        searchDisplayValues.push(
          `Priority Level: ${row?.original?.PRIORITY_LEVEL}`
        );
      } else if (type.trim() === 'policyNumber') {
        searchDisplayValues.push(
          `Policy Number: ${row?.original?.POLICY_NUMBER}`
        );
      } else if (type.trim() === 'suffixCode') {
        searchDisplayValues.push(
          `Policy Number: ${row?.original?.POLICY_NUMBER}`
        );
        searchDisplayValues.push(`Suffix Code: ${row?.original?.SUFFIX_CODE}`);
      }
    } else if (reportType === 'CLS') {
      const claimSourceCode =
        data?.sourceSystemList.find(
          (item) => item.value === row?.original?.CLAIM_SOURCE_CODE
        )?.label || row?.original?.CLAIM_SOURCE_CODE;
      searchDisplayValues.push(`Claim Source Code: ${claimSourceCode}`);
      if (type.trim() === 'priorityLevel') {
        if (displayType === 'A') {
          searchDisplayValues.push(
            `Policy Number: ${row?.original?.POLICY_NUMBER}`
          );
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
          searchDisplayValues.push(
            `Account Code: ${row?.original?.ACCOUNT_CODE}`
          );
        } else if (displayType === 'S') {
          searchDisplayValues.push(
            `Policy Number: ${row?.original?.POLICY_NUMBER}`
          );
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
        } else if (displayType === 'P') {
          searchDisplayValues.push(
            `Policy Number: ${row?.original?.POLICY_NUMBER}`
          );
        }
        searchDisplayValues.push(
          `Priority Level: ${row?.original?.PRIORITY_LEVEL}`
        );
      } else if (type.trim() === 'errorCode') {
        if (displayType === 'A') {
          searchDisplayValues.push(
            `Policy Number: ${row?.original?.POLICY_NUMBER}`
          );
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
          searchDisplayValues.push(
            `Account Code: ${row?.original?.ACCOUNT_CODE}`
          );
        } else if (displayType === 'S') {
          searchDisplayValues.push(
            `Policy Number: ${row?.original?.POLICY_NUMBER}`
          );
          searchDisplayValues.push(
            `Suffix Code: ${row?.original?.SUFFIX_CODE}`
          );
        } else if (displayType === 'P') {
          searchDisplayValues.push(
            `Policy Number: ${row?.original?.POLICY_NUMBER}`
          );
        }
        searchDisplayValues.push(`Error Code: ${row?.original?.ERROR_CODE}`);
      } else if (type.trim() === 'suffixCode') {
        searchDisplayValues.push(
          `Policy Number: ${row?.original?.POLICY_NUMBER}`
        );
        searchDisplayValues.push(`Suffix Code: ${row?.original?.SUFFIX_CODE}`);
      } else if (type.trim() === 'policyNumber') {
        searchDisplayValues.push(
          `Policy Number: ${row?.original?.POLICY_NUMBER}`
        );
      }
    }

    let clickCellData = {
      [clickedCellTitle]: clickCellValue,
      payloadData: payloadData,
      sourceSystemList: data?.sourceSystemList,
      searchDisplayValues: searchDisplayValues,
    };
    dispatch(updateInquiryElementSearch());
    try {
      const response = await inquirySearchByElementApi(
        payloadData,
        querySearch,
        type
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .replace(/^./, (char) => char.toUpperCase()),
        config
      )
        .then((response) => {
          if (response.status === 200 && response && response.data) {
            dispatch(updateInquiryeElementSearchSuccess(response.data));
            navigate(
              '/cmdb/suspect-errors/inquiry-search/inquiry-search-subline',
              {
                state: {
                  data: location?.state?.data,
                  clickCell: clickCellData,
                },
              }
            );
          }
        })
        .catch((error) => {
          dispatch(updateInquiryElementSearchError(error));
        });
    } catch (error) {
      // add-alert-popup
    }
  };

  const LinkCellRenderer = ({ value, row, col }) => {
    return <Link onClick={() => onClickHandler(row, col)}>{value}</Link>;
  };

  const columns = React.useMemo(
    () =>
      displayType === 'P' && reportType === 'CS'
        ? [
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              style: { width: '10px !important' },
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },

            {
              Header: 'PRIORITY LEVEL	',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'ERROR CODE',
              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'CLAIM FUNDING',
              accessor: 'CLAIM_FUNDING',
              Cell: ({ value, row, column }) => {
                let claimFunVal =
                  value === '1'
                    ? 'Fully Insured'
                    : value === '2'
                    ? 'MP-Minimum Premium'
                    : value === '3'
                    ? 'MMP-Member Minimum Premium'
                    : value === '4'
                    ? 'ASO'
                    : value === '5'
                    ? 'SC/SL 100'
                    : value === '7'
                    ? 'PRIME ASO'
                    : '';
                return (
                  <LinkCellRenderer
                    value={claimFunVal}
                    row={row}
                    col={column}
                  />
                );
              },
            },
            {
              Header: 'POL DFLT FUNDING',
              accessor: 'POL_DFLT_FUNDING',
              Cell: ({ value }) => {
                return value === null
                  ? 'NotFound'
                  : value === '1'
                  ? 'Fully Insured'
                  : value === '2'
                  ? 'MP-Minimum Premium'
                  : value === '3'
                  ? 'MMP-Member Minimum Premium'
                  : value === '4'
                  ? 'ASO'
                  : value === '5'
                  ? 'SC/SL 100'
                  : value === '7'
                  ? 'PRIME ASO'
                  : '';
              },
            },
            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
            {
              Header: 'CASE INSTALL SOURCE',
              accessor: 'CASE_INSTAL_SOURCE',
              Cell: ({ value }) => {
                return value === null
                  ? 'NotFound'
                  : value === '1'
                  ? 'PRIME'
                  : value === '2'
                  ? 'ACIS'
                  : value === '3'
                  ? 'EBDS'
                  : value === '4'
                  ? 'ARCS'
                  : '';
              },
            },
          ]
        : displayType === 'P' && reportType === 'EC'
        ? [
            {
              Header: 'ERROR CODE',

              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'PRIORITY LEVEL',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
          ]
        : displayType === 'P' && reportType === 'CLS'
        ? [
            {
              Header: 'CLAIM SOURCE CODE',
              width: 320,
              accessor: 'CLAIM_SOURCE_CODE',
              Cell: ({ value, row, column }) => {
                const mappedLabel =
                  data?.sourceSystemList?.find((item) => item.value === value)
                    ?.label || value;
                return (
                  <LinkCellRenderer
                    value={mappedLabel}
                    row={row}
                    col={column}
                  />
                );
              },
            },
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'PRIORITY LEVEL',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'ERROR CODE',
              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
            {
              Header: 'SD - CHECK NUMBER - CPS#',
              accessor: 'SD_CHECKNUMBER_CPS',
              width: 280,
            },
          ]
        : displayType === 'S' && reportType === 'CS'
        ? [
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              style: { width: '10px !important' },
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'SUFFIX CODE',
              accessor: 'SUFFIX_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'SUFFIX TYPE',
              accessor: 'SUFFIX_TYPE',
              Cell: ({ value }) => {
                return value === null ? 'NA' : value;
              },
            },
            {
              Header: 'PRIORITY LEVEL	',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'ERROR CODE',
              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'CLAIM FUNDING',
              accessor: 'CLAIM_FUNDING',
              Cell: ({ value, row, column }) => {
                let claimFunVal =
                  value === '1'
                    ? 'Fully Insured'
                    : value === '2'
                    ? 'MP-Minimum Premium'
                    : value === '3'
                    ? 'MMP-Member Minimum Premium'
                    : value === '4'
                    ? 'ASO'
                    : value === '5'
                    ? 'SC/SL 100'
                    : value === '7'
                    ? 'PRIME ASO'
                    : '';
                return (
                  <LinkCellRenderer
                    value={claimFunVal}
                    row={row}
                    col={column}
                  />
                );
              },
            },
            {
              Header: 'SUFFIX FUNDING',
              accessor: 'SUFFIX_FUNDING',
              Cell: ({ value }) => {
                return value === null
                  ? 'NotFound'
                  : value === '1'
                  ? 'Fully Insured'
                  : value === '2'
                  ? 'MP-Minimum Premium'
                  : value === '3'
                  ? 'MMP-Member Minimum Premium'
                  : value === '4'
                  ? 'ASO'
                  : value === '5'
                  ? 'SC/SL 100'
                  : value === '7'
                  ? 'PRIME ASO'
                  : '';
              },
            },
            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
            {
              Header: 'CASE INSTALL SOURCE',
              accessor: 'CASE_INSTAL_SOURCE',
              Cell: ({ value }) => {
                return value === null
                  ? 'NotFound'
                  : value === '1'
                  ? 'PRIME'
                  : value === '2'
                  ? 'ACIS'
                  : value === '3'
                  ? 'EBDS'
                  : value === '4'
                  ? 'ARCS'
                  : '';
              },
            },
          ]
        : displayType === 'S' && reportType === 'EC'
        ? [
            {
              Header: 'ERROR CODE',
              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },

            {
              Header: 'PRIORITY LEVEL	',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },

            {
              Header: 'SUFFIX CODE',
              accessor: 'SUFFIX_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },

            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
          ]
        : displayType === 'S' && reportType === 'CLS'
        ? [
            {
              Header: 'CLAIM SOURCE CODE',
              accessor: 'CLAIM_SOURCE_CODE',
              width: 320,
              Cell: ({ value, row, column }) => {
                const mappedLabel =
                  data?.sourceSystemList.find((item) => item.value === value)
                    ?.label || value;
                return (
                  <LinkCellRenderer
                    value={mappedLabel}
                    row={row}
                    col={column}
                  />
                );
              },
            },
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'SUFFIX CODE',
              accessor: 'SUFFIX_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'PRIORITY LEVEL',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'ERROR CODE',
              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
            {
              Header: 'SD - CHECK NUMBER - CPS#',
              accessor: 'SD_CHECKNUMBER_CPS',
              width: 280,
            },
          ]
        : displayType === 'A' && reportType === 'CS'
        ? [
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              style: { width: '10px !important' },
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'SUFFIX CODE',
              accessor: 'SUFFIX_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'SUFFIX TYPE',
              accessor: 'SUFFIX_TYPE',
              Cell: ({ value }) => {
                return value === null ? 'NA' : value;
              },
            },
            {
              Header: 'ACCOUNT CODE',
              accessor: 'ACCOUNT_CODE',
            },
            {
              Header: 'PRIORITY LEVEL	',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'ERROR CODE',
              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
            {
              Header: 'CASE INSTALL SOURCE',
              accessor: 'CASE_INSTAL_SOURCE',
              Cell: ({ value }) => {
                return value === null
                  ? 'NotFound'
                  : value === '1'
                  ? 'PRIME'
                  : value === '2'
                  ? 'ACIS'
                  : value === '3'
                  ? 'EBDS'
                  : value === '4'
                  ? 'ARCS'
                  : '';
              },
            },
          ]
        : displayType === 'A' && reportType === 'EC'
        ? [
            {
              Header: 'ERROR CODE',
              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },

            {
              Header: 'PRIORITY LEVEL	',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },

            {
              Header: 'SUFFIX CODE',
              accessor: 'SUFFIX_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'ACCOUNT CODE',
              accessor: 'ACCOUNT_CODE',
            },

            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
          ]
        : displayType === 'A' && reportType === 'CLS'
        ? [
            {
              Header: 'CLAIM SOURCE CODE',
              accessor: 'CLAIM_SOURCE_CODE',
              width: 320,
              Cell: ({ value, row, column }) => {
                const mappedLabel =
                  data?.sourceSystemList.find((item) => item.value === value)
                    ?.label || value;
                return (
                  <LinkCellRenderer
                    value={mappedLabel}
                    row={row}
                    col={column}
                  />
                );
              },
            },
            {
              Header: 'POLICY NUMBER',
              accessor: 'POLICY_NUMBER',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'SUFFIX CODE',
              accessor: 'SUFFIX_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'ACCOUNT CODE',
              accessor: 'ACCOUNT_CODE',
            },
            {
              Header: 'PRIORITY LEVEL',
              accessor: 'PRIORITY_LEVEL',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'ERROR CODE',
              accessor: 'ERROR_CODE',
              Cell: ({ value, row, column }) => {
                return (
                  <LinkCellRenderer value={value} row={row} col={column} />
                );
              },
            },
            {
              Header: 'TOTAL DOLLAR AMT',
              accessor: 'TOTAL_DOLLAR_AMT',
            },
            {
              Header: 'SD - CHECK NUMBER - CPS#',
              accessor: 'SD_CHECKNUMBER_CPS',
              width: 280,
            },
          ]
        : [],
    []
  );

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (displayType === 'P') {
      if (reportType === 'CS') {
        columnNames = {
          'POLICY NUMBER': null,
          'PRIORITY LEVEL': null,
          'ERROR CODE': null,
          'CLAIM FUNDING': null,
          'POL DFLT FUNDIN': null,
          'TOTAL DOLLAR AMT': null,
          'CASE INSTALL SOURCE': null,
        };
      } else if (reportType === 'EC') {
        columnNames = {
          'ERROR CODE': null,
          'PRIORITY LEVEL': null,
          'POLICY NUMBER': null,
          'TOTAL DOLLAR AMT': null,
        };
      } else if (reportType === 'CLS') {
        columnNames = {
          'CLAIM SOURCE CODE': null,
          'POLICY NUMBER': null,
          'PRIORITY LEVEL': null,
          'ERROR CODE': null,
          'TOTAL DOLLAR AMT': null,
          'SD - CHECK NUMBER - CPS#': null,
        };
      }
    } else if (displayType === 'S') {
      if (reportType === 'CS') {
        columnNames = {
          'POLICY NUMBER': null,
          'SUFFIX CODE': null,
          'SUFFIX TYPE': null,
          'PRIORITY LEVEL': null,
          'ERROR CODE': null,
          'CLAIM FUNDING': null,
          'SUFFIX FUNDING': null,
          'TOTAL DOLLAR AMT': null,
          'CASE INSTALL SOURCE': null,
        };
      } else if (reportType === 'EC') {
        columnNames = {
          'ERROR CODE': null,
          'PRIORITY LEVEL': null,
          'POLICY NUMBER': null,
          'SUFFIX CODE': null,
          'TOTAL DOLLAR AMT': null,
        };
      } else if (reportType === 'CLS') {
        columnNames = {
          'CLAIM SOURCE CODE': null,
          'POLICY NUMBER': null,
          'SUFFIX CODE': null,
          'PRIORITY LEVEL': null,
          'ERROR CODE': null,
          'TOTAL DOLLAR AMT': null,
          'SD - CHECK NUMBER - CPS#': null,
        };
      }
    } else if (displayType === 'A') {
      if (reportType === 'CS') {
        columnNames = {
          'POLICY NUMBER': null,
          'SUFFIX CODE': null,
          'SUFFIX TYPE': null,
          'ACCOUNT CODE': null,
          'PRIORITY LEVEL': null,
          'ERROR CODE': null,
          'TOTAL DOLLAR AMT': null,
          'CASE INSTALL SOURCE': null,
        };
      } else if (reportType === 'EC') {
        columnNames = {
          'ERROR CODE': null,
          'PRIORITY LEVEL': null,
          'POLICY NUMBER': null,
          'SUFFIX CODE': null,
          'ACCOUNT CODE': null,
          'TOTAL DOLLAR AMT': null,
        };
      } else if (reportType === 'CLS') {
        columnNames = {
          'CLAIM SOURCE CODE': null,
          'POLICY NUMBER': null,
          'SUFFIX CODE': null,
          'ACCOUNT CODE': null,
          'PRIORITY LEVEL': null,
          'ERROR CODE': null,
          'TOTAL DOLLAR AMT': null,
          'SD - CHECK NUMBER - CPS#': null,
        };
      }
    }
    return columnNames;
  };

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    let hours = today.getHours() % 12 || 12;
    const formattedHours = String(hours).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${formattedHours}${minutes}${seconds}`;
  };

  const fileName = () => {
    let excelFileName = '';
    if (displayType === 'A' && reportType === 'CS') {
      excelFileName =
        'Suffix_Level_Results_Case_Account_' + getFormattedDate() + '.xlsx';
    } else if (displayType === 'A' && reportType === 'EC') {
      excelFileName =
        'Suffix_Level_Results_Error_Code_Account' +
        getFormattedDate() +
        '.xlsx';
    } else if (displayType === 'A' && reportType === 'CLS') {
      excelFileName =
        'Suffix_Level_Results_ClaimSrc_Account_' + getFormattedDate() + '.xlsx';
    } else {
      excelFileName = 'Suffix_Level_Results_' + getFormattedDate() + '.xlsx';
    }
    return excelFileName;
  };

  const fieldLabelMappings = {
    policyNumber: 'Policy Number',
    chargeDateForm: 'Charge Date From',
    POLICY_NUMBER: 'POLICY NUMBER',
    PRIORITY_LEVEL: 'PRIORITY LEVEL',
    ERROR_CODE: 'ERROR CODE',
    CLAIM_FUNDING: 'CLAIM FUNDING',
    POL_DFLT_FUNDING: 'POL DFLT FUNDING',
    TOTAL_DOLLAR_AMT: 'TOTAL DOLLAR AMT',
    CASE_INSTAL_SOURCE: 'CASE INSTALL SOURCE',
    CLAIM_SOURCE_CODE: 'CLAIM SOURCE CODE',
    SD_CHECKNUMBER_CPS: 'SD - CHECK NUMBER - CPS#',
    SUFFIX_CODE: 'SUFFIX CODE',
    SUFFIX_TYPE: 'SUFFIX TYPE',
    SUFFIX_FUNDING: 'SUFFIX FUNDING',
    ACCOUNT_CODE: 'ACCOUNT CODE',
    suffixCode: 'Suffix Code',
    accountNumber: 'Account Number',
    errorCode: 'Error Code',
    prorityLvl: 'Priority Level',
    dollarAmount: 'Dollar Amount',
    claimSrcCodedis: 'Claim Source Code',
    subscriberId: 'Subscriber ID (SSN)',
    sdall: 'Series Designator',
    chkall: 'Check Number',
    cpsall: 'CPS',
    lglEntity: 'Legal Entity',
    glReInsurance: 'GL Reinsurance',
    fndgInsType: 'Funding Arrangement Code',
    lglEntityGrp: 'Legal Entity Group',
  };

  const downloadExcel = () => {
    let fullData = [];
    dataTablePropsPagination?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = fieldLabelMappings[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName());
  };

  const downloadFilterExcel = () => {
    let filteredData = [];
    dataTablePropsPagination.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = fieldLabelMappings[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName());
  };

  const dataTablePropsPagination = useDataTable({
    showPagination: true,
    initialData:
      searchData === 'Not available' || searchData === 'Data not found'
        ? []
        : searchData?.pageItems,
    initialColumns: columns,
    downloadButtonConfig: {
      custom:
        // optional custom action; can also be an array of objects
        [
          {
            title: 'Download filtered dataset (XLSX)', // option item title
            icon: <IconSymbol icon="filter_alt" />, // option item icon
            onClick: downloadFilterExcel,
          },
          {
            title: 'Download full dataset (XLSX)', // option item title
            icon: <IconSymbol icon="download_done" />, // option item icon
            onClick: downloadExcel,
          },
        ],
    },
    customHeaderButtons,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showGlobalFilter: false,
  });

  let getIncludeVal = () => {
    return reportType === 'CS'
      ? 'Case Structure'
      : reportType === 'EC'
      ? 'Error Code'
      : reportType === 'CLS'
      ? 'Claim Source'
      : '';
  };

  let getDisplayLevel = () => {
    return displayType === 'P'
      ? 'Policy'
      : displayType === 'S'
      ? 'Suffix'
      : displayType === 'A'
      ? 'Account'
      : '';
  };

  const displaySelectedFields = () => {
    return Object.entries(payloadData)
      .filter(([field]) => fieldLabelMappings[field])
      .map(([field, value]) => {
        if (value || (field == 'glReInsurance' && value == '')) {
          if (field === 'dollarAmount' && payloadData?.dollarAmount) {
            const comparison =
              payloadData?.dollarInd === 'O'
                ? '>='
                : payloadData?.dollarInd === 'U'
                ? '<='
                : '';
            return (
              <div key={field}>
                <strong>{fieldLabelMappings[field]}:</strong>{' '}
                {!!payloadData?.dollarAmount
                  ? `${comparison} ${payloadData?.dollarAmount}`
                  : ''}
              </div>
            );
          } else if (field === 'chargeDateForm') {
            const dateFormat = `${
              payloadData?.chargeDateForm + ' To: ' + payloadData?.chanrgeDateTo
            }`;
            return (
              <div key={field}>
                <strong>{fieldLabelMappings[field]}:</strong>{' '}
                {dateFormat ? dateFormat : ''}
              </div>
            );
          } else if (field === 'suffixCode') {
            return (
              <div key={field}>
                <strong>{fieldLabelMappings[field]}:</strong>{' '}
                {value.endsWith(',') ? value.slice(0, -1) : value}
              </div>
            );
          } else if (
            field === 'subscriberId' ||
            'sdall' ||
            'chkall' ||
            'cpsall'
          ) {
            return (
              <div key={field}>
                <strong>{fieldLabelMappings[field]}:</strong>{' '}
                {decodeURIComponent(value.replaceAll('perct', '%'))}
              </div>
            );
          } else {
            return (
              <div key={field}>
                <strong>{fieldLabelMappings[field]}:</strong> {value}
              </div>
            );
          }
        }
        return null;
      });
  };

  useEffect(() => {
    setRecord(dataTablePropsPagination.filter?.filteredRows?.length);
  }, [dataTablePropsPagination?.filter?.filteredRows]);

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Box color="$white">
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '10px !important',
                    padding: '0px !important',
                  }}
                >
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Heading
                      offset={5}
                      textAlign="center"
                      css={{ margin: '0 auto', flexGrow: 1 }}
                    >
                      Suspect Errors By {getIncludeVal()}
                    </Heading>
                    {/* </Grid.Col> */}
                    {/* <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}> */}
                    <Text
                      css={{
                        marginLeft: 'auto',
                        textAlign: 'right',
                        alignSelf: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      {currDate}
                    </Text>
                    {/* </Grid.Col> */}
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    padding: '0px !important',
                  }}
                >
                  <Grid.Col span={{ xs: 8, md: 8, lg: 8, xl: 8 }}></Grid.Col>
                  <Grid.Col
                    span={{ xs: 4, md: 4, lg: 4, xl: 4 }}
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      css={{
                        marginLeft: 'auto',
                        textAlign: 'right',
                        alignSelf: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      Display Level: {getDisplayLevel()}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={700}>Search Criteria Used:</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={700}>{displaySelectedFields()}</Text>
                    <br /> {record}
                    <span style={{ marginLeft: '12px' }}>records</span>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    padding: '0px !important',
                    textAlign: 'end',
                  }}
                ></Grid>
                <Grid css={{ margin: '0px !important' }}>
                  {!searchData ? (
                    <div>Loading ....</div>
                  ) : (
                    <div
                      style={{
                        height:
                          dataTablePropsPagination?.data?.length > 6
                            ? '550px'
                            : 'auto',
                      }}
                    >
                      <DataTable
                        title=""
                        hideTitleHeader
                        tableState={dataTablePropsPagination}
                      />
                    </div>
                  )}
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(InquiryDataSearch, 'cmdb_update_inquiry');
