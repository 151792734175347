import React, { useState, useEffect } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Modal } from '@abyss/web/ui/Modal';
import { Heading } from '@abyss/web/ui/Heading';
import { DateInput } from '@abyss/web/ui/DateInput';
import useAuth from '@src/utils/useAuth';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { caseStructureSearchApi } from '@src/redux/api/FADB/inquiry/caseStructure/caseStructureAPI';
import { useDispatch } from 'react-redux';
import {
  updateCaseStructurSearchError,
  updateCaseStructurSearchSuccess,
  updateCaseStructureSearch,
} from '@src/redux/action/FADB/inquiry/caseStructure/caseStructureAPI';
import withAuth from '@src/auth/withAuth';
import { SelectableList } from '../../../CMDB/SuspectErrors/SelectableList';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';

const CaseStructure = () => {
  const dispatch = useDispatch();
  const { token, readOnly } = useAuth();
  const { navigate, getLocation } = useRouter();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let location = getLocation();
  const [levelOfDetailsRadio, setLevelOfDetailRadio] = useState('');
  const [levelOfDetailsRadioAll, setLevelOfDetailRadioAll] = useState('');
  const [statusRadio, setStatusRadio] = useState('');

  const [policyNumber, setPolicyNumber] = useState('');
  const [suffixCode, setSuffixCode] = useState('');
  const [accountCode, setAccountCode] = useState('');

  const [addedPolicyNumber, setAddedPolicyNumber]: any = useState([]);
  const [addedSuffixCode, setAddedSuffixCode]: any = useState([]);
  const [addedAccountCode, setAddedAccountCode]: any = useState([]);

  const [suffixType, setSuffixType] = useState('');
  const [policyTypeCode, setPolicyTypeCode] = useState('');

  const [name, setName] = useState('');
  const [caseInstallationSource, setCaseInstallationSource] = useState('');
  const [originalEffectiveDateFrom, setOriginalEffectiveDateFrom] =
    useState('');
  const [originalEffectiveDateTo, setOriginalEffectiveDateTo] = useState('');
  const [cancellationDateFrom, setCancellationDateFrom] = useState('');
  const [cancellationDateTo, setCancellationDateTo] = useState('');
  const [changeDateFrom, setChangeDateFrom] = useState('');
  const [changeDateTo, setChangeDateTo] = useState('');
  const [authorOfChanges, setAuthorOfChanges] = useState('');
  const [legalEntity, setLegalEntity] = useState('');
  const [glReinsurance, setGlReinsurance] = useState('');
  const [outOfNetworkLegalEntity, setOutOfNetworkLegalEntity] = useState('');
  const [fundingArrangment, setFundingArrangement] = useState('');
  const [legalEntityEffectiveDateFrom, setLegalEntityEffectiveDateFrom] =
    useState('');
  const [legalEntityEffectiveDateTo, setLegalEntityEffectiveDateTo] =
    useState('');
  const [fundingEffectiveDateFrom, setFudingEffectiveDateFrom] = useState('');
  const [fundingEffectiveDateTo, setFudingEffectiveDateTo] = useState('');
  const [errors, setErrors]: any = useState({});

  const [dropdownData1, setDropdownData1]: any = useState({});
  const [dropdownData2, setDropdownData2]: any = useState({});

  const [selectedPolicyNumbers, setSelectedPolicyNumbers] = useState<number[]>(
    []
  );
  const [selectedSuffixCode, setSelectedSuffixCode] = useState<number[]>([]);
  const [selectedAccountCode, setSelectedAccountCode] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [levelOfDetailError, setLevelOfDetailError] = useState(false);
  const [includeError, setIncludeError] = useState(false);
  const [firstLevelError, setFirstLevelError] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const data = location?.state;
  const addedPolNbr =
    data?.policyNumber &&
    data?.policyNumber.split(',').map((item) => item.trim());
  const validPolNbr =
    addedPolNbr &&
    addedPolNbr.filter((item) => item !== 'undefined' && item !== 'null');
  const addedSufxCd =
    data?.suffixCode && data?.suffixCode.split(',').map((item) => item.trim());
  const validSufxCd =
    addedSufxCd &&
    addedSufxCd.filter((item) => item !== 'undefined' && item !== 'null');
  const addedAcntCd =
    data?.accountCode &&
    data?.accountCode.split(',').map((item) => item.trim());
  const validAcntCd =
    addedAcntCd &&
    addedAcntCd.filter((item) => item !== 'undefined' && item !== 'null');
  useEffect(() => {
    setLevelOfDetailRadio(data?.searchScreen);
    setLevelOfDetailRadioAll(data?.include);
    data?.policyNumber && data?.policyNumber.length > 1
      ? setAddedPolicyNumber((prev) => [
          ...prev,
          ...validPolNbr.map((item) => `${item}`),
        ])
      : setAddedPolicyNumber([data?.policyNumber]);
    data?.suffixCode && data?.suffixCode.length > 1
      ? setAddedSuffixCode((prev) => [...prev, ...validSufxCd])
      : setAddedSuffixCode([data?.suffixCode]);
    data?.accountCode && data?.accountCode.length > 1
      ? setAddedAccountCode((prev) => [...prev, ...validAcntCd])
      : setAddedAccountCode([data?.accountCode]);
    setPolicyTypeCode(data?.policyTypeCode);
    setSuffixType(data?.suffixType);
    setName(data?.name);
    setCaseInstallationSource(data?.caseInstallationSource);
    setOriginalEffectiveDateFrom(data?.originalEffectiveDateFrom);
    setOriginalEffectiveDateTo(data?.originalEffectiveDateTo);
    setCancellationDateFrom(data?.cancellationDateFrom);
    setCancellationDateTo(data?.cancellationDateTo);
    setChangeDateFrom(data?.changeDateFrom);
    setChangeDateTo(data?.changeDateTo);
    setAuthorOfChanges(data?.authorOfChanges);
    setLegalEntity(data?.legalEntity);
    setGlReinsurance(data?.glReinsurance);
    setOutOfNetworkLegalEntity(data?.outOfNetworkLegalEntity);
    setFundingArrangement(data?.fundingArrangment);
    setLegalEntityEffectiveDateFrom(data?.legalEntityEffectiveDateFrom);
    setLegalEntityEffectiveDateTo(data?.legalEntityEffectiveDateTo);
    setFudingEffectiveDateFrom(data?.fundingEffectiveDateFrom);
    setFudingEffectiveDateTo(data?.fundingEffectiveDateTo);
  }, [location?.state]);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}static/policyDrpdwnData/policy`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          setDropdownData1(response.data);
        }
      } catch (error) {
        // add-alert-popup
      }
    };
    if (token) fetchDropdownData();
  }, [token]);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}static/suffxDyanamicData/suffixDyanamicData`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          setDropdownData2(response.data);
        }
      } catch (error) {
        // add-alert-popup
      }
    };
    if (token) fetchDropdownData();
  }, [token]);

  const polNbr = addedPolicyNumber.filter(
    (item) => item !== undefined && item !== null && item !== ''
  );

  const sufxCd = addedSuffixCode.filter(
    (item) => item !== undefined && item !== null && item !== ''
  );

  const acntCd = addedAccountCode.filter(
    (item) => item !== undefined && item !== null && item !== ''
  );
  const payload = {
    searchScreen: levelOfDetailsRadio,
    include: levelOfDetailsRadioAll,
    dfltLglIns: '',
    disabledDropdown: '',
    disabledFlag: '',
    sufxTypCd: suffixType,
    status: '' || statusRadio,

    polNumber: polNbr.join(','),
    sufxCd: sufxCd.join(','),
    POL_ACCT_NBR: acntCd.join(','),
    policytype: policyTypeCode,
    polName: name,
    caseinstallationSource: caseInstallationSource,
    origEffectDateFrom: originalEffectiveDateFrom,
    origEffectDateTo: originalEffectiveDateTo,
    cancDateFrom: cancellationDateFrom,
    cancDateTo: cancellationDateTo,
    chgDateFrom: changeDateFrom,
    chgDateTo: changeDateTo,
    authorOfChanges,
    lglEntyCd: legalEntity,
    defaultglreinsurance: glReinsurance,
    deafaultOutOfNetworkLglEnt: outOfNetworkLegalEntity,
    fundArgdCd: fundingArrangment,
    lglEntEfftDateFrom: legalEntityEffectiveDateFrom,
    lglEntEfftDateTo: legalEntityEffectiveDateTo,
    fndgEntEfftDateFrom: fundingEffectiveDateFrom,
    fndgEntEfftDateTo: fundingEffectiveDateTo,
    pageNumber: 1,
    recordsPerPage: 10,
  };

  const handleChange = (name, value) => {
    switch (name) {
      case 'levelOfDetail':
        setLevelOfDetailRadio(value);
        break;
      case 'levelOfDetailsRadioAll':
        setLevelOfDetailRadioAll(value);
        break;
      case 'statusRadio':
        setStatusRadio(value);
        break;
      case 'policyNumber':
        setPolicyNumber(value);
        break;
      case 'suffixCode':
        setSuffixCode(value);
        break;
      case 'accountCode':
        setAccountCode(value);
        break;
      case 'suffixType':
        setSuffixType(value);
        break;
      case 'policyTypeCode':
        setPolicyTypeCode(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'caseInstallationSource':
        setCaseInstallationSource(value);
        break;
      case 'originalEffectiveDateFrom':
        setOriginalEffectiveDateFrom(value);
        break;
      case 'originalEffectiveDateTo':
        setOriginalEffectiveDateTo(value);
        break;
      case 'cancellationDateFrom':
        setCancellationDateFrom(value);
        break;
      case 'cancellationDateTo':
        setCancellationDateTo(value);
        break;
      case 'changeDateFrom':
        setChangeDateFrom(value);
        break;
      case 'changeDateTo':
        setChangeDateTo(value);
        break;
      case 'authorOfChanges':
        setAuthorOfChanges(value);
        break;
      case 'legalEntity':
        setLegalEntity(value);
        break;
      case 'glReinsurance':
        setGlReinsurance(value);
        break;
      case 'outOfNetworkLegalEntity':
        setOutOfNetworkLegalEntity(value);
        break;
      case 'fundingArrangment':
        setFundingArrangement(value);
        break;
      case 'legalEntityEffectiveDateFrom':
        setLegalEntityEffectiveDateFrom(value);
        break;
      case 'legalEntityEffectiveDateTo':
        setLegalEntityEffectiveDateTo(value);
        break;
      case 'fundingEffectiveDateFrom':
        setFudingEffectiveDateFrom(value);
        break;
      case 'fundingEffectiveDateTo':
        setFudingEffectiveDateTo(value);
        break;
      default:
        break;
    }
  };

  const handleValidation = () => {
    let isValid = true;
    if (!levelOfDetailsRadio && levelOfDetailsRadioAll) {
      setLevelOfDetailError(true);
      isValid = false;
    }
    if (!levelOfDetailsRadioAll && levelOfDetailsRadio) {
      setIncludeError(true);
      isValid = false;
    }
    if (!levelOfDetailsRadio && !levelOfDetailsRadioAll) {
      setFirstLevelError(true);
      isValid = false;
    }
    if (
      levelOfDetailsRadioAll &&
      levelOfDetailsRadioAll.length !== 0 &&
      levelOfDetailsRadio &&
      levelOfDetailsRadio.length !== 0
    ) {
      const isAddedPolicyNumberFilled = Array.isArray(addedPolicyNumber)
        ? addedPolicyNumber.some((item) => item !== undefined)
        : addedPolicyNumber;
      const isAddedSuffixCodeFilled = Array.isArray(addedSuffixCode)
        ? addedSuffixCode.some((item) => item !== undefined)
        : addedSuffixCode;
      const isAddedAccountCodeFilled = Array.isArray(addedAccountCode)
        ? addedAccountCode.some((item) => item !== undefined)
        : addedAccountCode;
      const isAnyFieldFilled =
        isAddedPolicyNumberFilled ||
        isAddedSuffixCodeFilled ||
        isAddedAccountCodeFilled ||
        name ||
        authorOfChanges ||
        fundingArrangment ||
        caseInstallationSource;

      if (!isAnyFieldFilled) {
        setIsOpen(true);
        isValid = false;
      }
    }
    if (originalEffectiveDateFrom > originalEffectiveDateTo) {
      let errors: any = {};
      errors.originalEffectiveDateError = `To Date should be greater than From Date`;
      setErrors(errors);
      isValid = false;
    }
    if (cancellationDateFrom > cancellationDateTo) {
      let errors: any = {};
      errors.cancellationDateError = `To Date should be greater than From Date`;
      setErrors(errors);
      isValid = false;
    }
    if (changeDateFrom > changeDateTo) {
      let errors: any = {};
      errors.changeDateError = `To Date should be greater than From Date`;
      setErrors(errors);
      isValid = false;
    }
    if (legalEntityEffectiveDateFrom > legalEntityEffectiveDateTo) {
      let errors: any = {};
      errors.legalEntityEffectiveDateError = `To Date should be greater than From Date`;
      setErrors(errors);
      isValid = false;
    }
    if (fundingEffectiveDateFrom > fundingEffectiveDateTo) {
      let errors: any = {};
      errors.fundingEffectiveDateError = `To Date should be greater than From Date`;
      setErrors(errors);
      isValid = false;
    }
    return isValid;
  };

  const handleAddPolicyNumber = () => {
    let errors: any = {};
    if (policyNumber) {
      const regEx: RegExp = /^\d{2}[A-Za-z0-9]\d{4}$/;

      let isValid = policyNumber.match(regEx);
      const exists = addedPolicyNumber?.some(
        (option) => option === policyNumber
      );

      if (policyNumber === '' || policyNumber === undefined) {
        errors.policyNumber =
          ' You have hit the add button without entering any data ';
        setErrors(errors);
        return;
      }

      if (policyNumber.length == 7) {
        if (!isValid) {
          errors.policyNumber =
            'Policy Number must be numeric, Except for the 3rd position.';
          setErrors(errors);
          return;
        }
      } else {
        errors.policyNumber = 'Policy Number must be 7 digit Number';
        setErrors(errors);
        return;
      }

      if (exists) {
        errors.policyNumber = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedPolicyNumber((prev) => [...prev, policyNumber.toUpperCase()]);
      setPolicyNumber('');
      setErrors('');
    } else {
      errors.policyNumber = 'Please enter the Policy Number';
      setErrors(errors);
    }
  };

  const handleAddSuffixCode = () => {
    let errors: any = {};
    if (suffixCode) {
      const isValid = /^[A-Za-z]{2}$/.test(suffixCode);
      const exists = addedSuffixCode.some((option) => option === suffixCode);

      if (suffixCode === '' || suffixCode === undefined) {
        errors.suffixCode =
          ' You have hit the add button without entering any data ';
        setErrors(errors);
        return;
      }

      if (!isValid) {
        errors.suffixCode = 'Suffix code must be 2 digit alphabet';
        setErrors(errors);
        return;
      }
      if (exists) {
        errors.suffixCode = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedSuffixCode((prev) => [...prev, suffixCode.toUpperCase()]);
      setSuffixCode('');
      setErrors('');
    } else {
      errors.suffixCode = 'Please enter the Suffix Code';
      setErrors(errors);
    }
  };

  const handleAddAccountCode = () => {
    let errors: any = {};
    if (accountCode) {
      const isValid = /^\d{3}$/.test(accountCode);
      const exists = addedAccountCode.some((option) => option === accountCode);
      if (!isValid) {
        errors.accountCode = 'Policy Account number should be numeric';
        setErrors(errors);
        return;
      }
      if (accountCode === '' || accountCode === undefined) {
        errors.accountCode =
          ' You have hit the add button without entering any data ';
        setErrors(errors);
        return;
      }
      if (exists) {
        errors.accountCode = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedAccountCode((prev) => [...prev, accountCode]);
      setAccountCode('');
      setErrors('');
    } else {
      errors.accountCode = 'Please enter the Account Code';
      setErrors(errors);
    }
  };

  const handleSelectItem = (index, selectedItem, setSelectedItem) => {
    if (!selectedItem.includes(index)) {
      setSelectedItem([index]);
    } else {
      setSelectedItem((prev) => prev.filter((item) => item !== index));
    }
  };

  const removeSelectedItem = (
    addedItem,
    setAddedItem,
    selectedItem,
    setSelectedItem
  ) => {
    const updatedItem = addedItem.filter(
      (_, index) => !selectedItem.includes(index)
    );
    setAddedItem(updatedItem);
    setSelectedItem([]);
  };

  // case-structure-search
  const handlePageSubmit = async () => {
    setIsSearchDisabled(true);
    if (handleValidation()) {
      setIsLoading(true);
      try {
        dispatch(updateCaseStructureSearch(payload));
        const response = await caseStructureSearchApi(payload, config);
        if (response.status === 200) {
          setIsSearchDisabled(false);
          dispatch(updateCaseStructurSearchSuccess(response.data));
          let tableData = response.data;
          let columnKeys = [];
          let columns = {};
          if (tableData?.length > 0) {
            columnKeys = Object.keys(tableData?.[0]);
            columns = columnKeys.map((key, index) => ({
              Header: key,
              accessor: key,
            }));
          }
          navigate('/fadb/inquiry/case-structure/case-structure-search', {
            state: {
              columns: columns,
              data: tableData,
              searchScreen: levelOfDetailsRadio,
              include: levelOfDetailsRadioAll,
              status: statusRadio,
              policyNumber: addedPolicyNumber
                .map((item) => `${item}`)
                .join(','),
              suffixCode: addedSuffixCode.map((item) => `${item}`).join(','),
              accountCode: addedAccountCode.map((item) => `${item}`).join(','),
              policyTypeCode: policyTypeCode,
              suffixType: suffixType,
              name: name,
              caseInstallationSource: caseInstallationSource,
              originalEffectiveDateFrom: originalEffectiveDateFrom,
              originalEffectiveDateTo: originalEffectiveDateTo,
              cancellationDateFrom: cancellationDateFrom,
              cancellationDateTo: cancellationDateTo,
              changeDateFrom: changeDateFrom,
              changeDateTo: changeDateTo,
              authorOfChanges: authorOfChanges,
              legalEntity: legalEntity,
              glReinsurance: glReinsurance,
              outOfNetworkLegalEntity: outOfNetworkLegalEntity,
              fundingArrangment: fundingArrangment,
              legalEntityEffectiveDateFrom: legalEntityEffectiveDateFrom,
              legalEntityEffectiveDateTo: legalEntityEffectiveDateTo,
              fundingEffectiveDateFrom: fundingEffectiveDateFrom,
              fundingEffectiveDateTo: fundingEffectiveDateTo,
            },
          });
        }
      } catch (error) {
        dispatch(updateCaseStructurSearchError(error));
        setIsSearchDisabled(false);
        navigate('/fadb/inquiry/case-structure/case-structure-search', {
          state: {
            columns: [],
            data: [],
            searchScreen: levelOfDetailsRadio,
            include: levelOfDetailsRadioAll,
            status: statusRadio,
            policyNumber: addedPolicyNumber.map((item) => `${item}`).join(','),
            suffixCode: addedSuffixCode.map((item) => `${item}`).join(','),
            accountCode: addedAccountCode.map((item) => `${item}`).join(','),
            policyTypeCode: policyTypeCode,
            suffixType: suffixType,
            name: name,
            caseInstallationSource: caseInstallationSource,
            originalEffectiveDateFrom: originalEffectiveDateFrom,
            originalEffectiveDateTo: originalEffectiveDateTo,
            cancellationDateFrom: cancellationDateFrom,
            cancellationDateTo: cancellationDateTo,
            changeDateFrom: changeDateFrom,
            changeDateTo: changeDateTo,
            authorOfChanges: authorOfChanges,
            legalEntity: legalEntity,
            glReinsurance: glReinsurance,
            outOfNetworkLegalEntity: outOfNetworkLegalEntity,
            fundingArrangment: fundingArrangment,
            legalEntityEffectiveDateFrom: legalEntityEffectiveDateFrom,
            legalEntityEffectiveDateTo: legalEntityEffectiveDateTo,
            fundingEffectiveDateFrom: fundingEffectiveDateFrom,
            fundingEffectiveDateTo: fundingEffectiveDateTo,
          },
        });
      }
    }
    setIsSearchDisabled(false);
  };

  const cancel = () => {
    setLevelOfDetailRadio('');
    setLevelOfDetailRadioAll('');
    setStatusRadio('');
    setPolicyNumber('');
    setSuffixCode('');
    setAddedPolicyNumber([]);
    setAddedSuffixCode([]);
    setAddedAccountCode([]);
    setAccountCode('');
    setSuffixType('');
    setPolicyTypeCode('');
    setName('');
    setCaseInstallationSource('');
    setOriginalEffectiveDateFrom('');
    setOriginalEffectiveDateTo('');
    setCancellationDateFrom('');
    setCancellationDateTo('');
    setChangeDateFrom('');
    setChangeDateTo('');
    setAuthorOfChanges('');
    setLegalEntity('');
    setGlReinsurance('');
    setOutOfNetworkLegalEntity('');
    setFundingArrangement('');
    setLegalEntityEffectiveDateFrom('');
    setLegalEntityEffectiveDateTo('');
    setFudingEffectiveDateFrom('');
    setFudingEffectiveDateTo('');
  };
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '220px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{ textAlign: 'center !important' }}
                >
                  <Heading offset={5}>Inquiry Search Criteria</Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <RadioGroup
                    label="Level of Detail:"
                    display="row"
                    onChange={(e) =>
                      handleChange('levelOfDetail', e.target.value)
                    }
                    value={levelOfDetailsRadio}
                    isDisabled={readOnly}
                  >
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Policy" value="P" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Suffix" value="S" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Funding" value="F" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Account" value="A" />
                    </Grid.Col>
                  </RadioGroup>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <RadioGroup
                    label="Include:"
                    display="row"
                    onChange={(e) => {
                      handleChange('levelOfDetailsRadioAll', e.target.value);
                    }}
                    value={levelOfDetailsRadioAll}
                    isDisabled={readOnly}
                  >
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="All" value="A" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Complete" value="C" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="With Warnings" value="W" />
                    </Grid.Col>
                  </RadioGroup>
                </Grid.Col>
              </Grid>
              <Grid css={{ 'abyss-grid': { marginTop: '2rem' } }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>General Information</Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Policy Number"
                    value={policyNumber}
                    onChange={(e) =>
                      handleChange('policyNumber', e.target.value)
                    }
                    errorMessage={errors.policyNumber}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddPolicyNumber}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Suffix Code"
                    value={suffixCode}
                    onChange={(e) => handleChange('suffixCode', e.target.value)}
                    errorMessage={errors.suffixCode}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddSuffixCode}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Account Code"
                    value={accountCode}
                    onChange={(e) =>
                      handleChange('accountCode', e.target.value)
                    }
                    errorMessage={errors.accountCode}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddAccountCode}
                  >
                    Add
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedPolicyNumber}
                    selectedItems={selectedPolicyNumbers}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedPolicyNumbers,
                        setSelectedPolicyNumbers
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{ width: '100px' }}
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedPolicyNumber,
                        setAddedPolicyNumber,
                        selectedPolicyNumbers,
                        setSelectedPolicyNumbers
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedSuffixCode}
                    selectedItems={selectedSuffixCode}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedSuffixCode,
                        setSelectedSuffixCode
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{ width: '100px' }}
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedSuffixCode,
                        setAddedSuffixCode,
                        selectedSuffixCode,
                        setSelectedSuffixCode
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedAccountCode}
                    selectedItems={selectedAccountCode}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedAccountCode,
                        setSelectedAccountCode
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{ width: '100px' }}
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedAccountCode,
                        setAddedAccountCode,
                        selectedAccountCode,
                        setSelectedAccountCode
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid css={{ 'abyss-grid': { marginTop: '2rem' } }}>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <TextInput
                    label="Name"
                    value={name}
                    isDisabled={readOnly}
                    onChange={(e) => handleChange('name', e.target.value)}
                    css={{
                      'abyss-text-input:focus': {
                        boxShadow: '#ff4e00 0px 0px 0px 2px !important ',
                      },
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInput
                    label="Case Installation Source"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    isDisabled={readOnly}
                    value={caseInstallationSource}
                    onChange={(value) => {
                      handleChange('caseInstallationSource', value);
                    }}
                    options={dropdownData1?.insTyp?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInput
                    label="Policy Type Code"
                    placeholder="Pick one"
                    isClearable
                    isDisabled={readOnly}
                    isSearchable
                    value={policyTypeCode}
                    onChange={(value) => {
                      handleChange('policyTypeCode', value);
                    }}
                    options={dropdownData1?.polTyp?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Suffix Type "
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    isDisabled={readOnly}
                    value={suffixType}
                    onChange={(value) => {
                      handleChange('suffixType', value);
                    }}
                    options={dropdownData2?.sufxTyp?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <RadioGroup
                    onChange={(e) =>
                      handleChange('statusRadio', e.target.value)
                    }
                    value={statusRadio}
                    isDisabled={readOnly}
                    label={''}
                  >
                    <RadioGroup.Radio label="active" value="A" />
                    <RadioGroup.Radio label="cancelled" value="C" />
                    <RadioGroup.Radio label="extended" value="E" />
                  </RadioGroup>
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>
                    Period (to be applied to selected level)
                  </Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>
                    Original Effective Date:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={originalEffectiveDateFrom}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('originalEffectiveDateFrom', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    onClear={() => setOriginalEffectiveDateFrom('')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={originalEffectiveDateTo}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('originalEffectiveDateTo', date);
                    }}
                    isDisabled={readOnly}
                    placeholder="Pick one"
                    isClearable
                    errorMessage={errors.originalEffectiveDateError}
                    onClear={() => setOriginalEffectiveDateTo('')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Cancellation Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={cancellationDateFrom}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('cancellationDateFrom', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    onClear={() => setCancellationDateFrom('')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={cancellationDateTo}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('cancellationDateTo', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    errorMessage={errors.cancellationDateError}
                    onClear={() => setCancellationDateTo('')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Change Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={changeDateFrom}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('changeDateFrom', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    onClear={() => setChangeDateFrom('')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={changeDateTo}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('changeDateTo', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    errorMessage={errors.changeDateError}
                    onClear={() => setChangeDateTo('')}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <TextInput
                    label="Author of Changes"
                    value={authorOfChanges}
                    onChange={(e) =>
                      handleChange('authorOfChanges', e.target.value)
                    }
                    isDisabled={readOnly}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>Advanced Financial Tags</Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Legal Entity"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={legalEntity}
                    onChange={(value) => handleChange('legalEntity', value)}
                    options={dropdownData2?.legalEntity?.map((col) => ({
                      value: col,
                      label: col,
                    }))}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="GL Reinsurance"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={glReinsurance}
                    onChange={(value) => handleChange('glReinsurance', value)}
                    options={dropdownData1?.reInsTyp?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Out of Network Legal Entity"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={outOfNetworkLegalEntity}
                    isDisabled={readOnly}
                    onChange={(value) =>
                      handleChange('outOfNetworkLegalEntity', value)
                    }
                    options={dropdownData2?.legalEntity?.map((col) => ({
                      value: col,
                      label: col,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Funding Arrangement"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={fundingArrangment}
                    isDisabled={readOnly}
                    onChange={(value) =>
                      handleChange('fundingArrangment', value)
                    }
                    options={dropdownData1?.fndCd?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>
                    Legal Entities Effective Date:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={legalEntityEffectiveDateFrom}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('legalEntityEffectiveDateFrom', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    onClear={() => setLegalEntityEffectiveDateFrom('')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={legalEntityEffectiveDateTo}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('legalEntityEffectiveDateTo', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    errorMessage={errors.legalEntityEffectiveDateError}
                    onClear={() => setLegalEntityEffectiveDateTo('')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Funding Effective Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={fundingEffectiveDateFrom}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('fundingEffectiveDateFrom', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    onClear={() => setFudingEffectiveDateFrom('')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    value={fundingEffectiveDateTo}
                    label=""
                    hideLabel
                    onChange={(date) => {
                      handleChange('fundingEffectiveDateTo', date);
                    }}
                    isDisabled={readOnly}
                    isClearable
                    placeholder="Pick one"
                    css={{ float: 'left' }}
                    errorMessage={errors.fundingEffectiveDateError}
                    onClear={() => setFudingEffectiveDateTo('')}
                  />
                </Grid.Col>
              </Grid>
              {isLoading && <WrapperLoader />}

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly || isLoading}
                    onClick={handlePageSubmit}
                    ariaLoadingLabel="Importing data"
                  >
                    Search
                  </Button>
                  {firstLevelError && (
                    <Modal
                      isOpen={firstLevelError}
                      size={'md'}
                      onClose={() => setFirstLevelError(false)}
                    >
                      <Modal.Section>
                        Level of Detail is a required field
                        <br />
                        Include is a required field
                        <br />
                      </Modal.Section>
                    </Modal>
                  )}
                  {levelOfDetailError && (
                    <Modal
                      isOpen={levelOfDetailError}
                      size={'md'}
                      onClose={() => setLevelOfDetailError(false)}
                    >
                      <Modal.Section>
                        Level of Detail is a required field
                        <br />
                      </Modal.Section>
                    </Modal>
                  )}
                  {includeError && (
                    <Modal
                      isOpen={includeError}
                      size={'md'}
                      onClose={() => setIncludeError(false)}
                    >
                      <Modal.Section>
                        Include is a required field
                        <br />
                      </Modal.Section>
                    </Modal>
                  )}

                  {isOpen && (
                    <Modal
                      title="Please correct the following errors"
                      isOpen={isOpen}
                      size={'md'}
                      onClose={() => setIsOpen(false)}
                    >
                      <Modal.Section>
                        Please enter at least one of the following criteria:
                        <br />
                        Policy number, Name,
                        <br />
                        Author of change, Funding arrangement,
                        <br />
                        Case installation Source
                        <br />
                      </Modal.Section>
                    </Modal>
                  )}
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(CaseStructure, 'fadb_update_inquiry');
