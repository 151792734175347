import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';

import { Button } from '@abyss/web/ui/Button';

import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Text } from '@abyss/web/ui/Text';
import { Grid } from '@abyss/web/ui/Grid';
import { Sidebar } from '@src/components/Sidebar';
import { getEnvironment } from '@src/utils/environmentUtil';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Modal } from '@abyss/web/ui/Modal';
import { set } from 'lodash';
import { BASE_URL } from '@src/utils/endpoints';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { Alert } from '@abyss/web/ui/Alert';
import withAuth from '@src/auth/withAuth';

const UcasSuspErrorTable = () => {
  const { token } = useAuth();
  const { navigate, getLocation } = useRouter();
  const [tableCount, setTableCount] = useState(0);
  //Input State
  const [ORIG_SCHED_BK_DT, setORIG_SCHED_BK_DT] = useState('');
  const [SRS_DESG_CD, setSRS_DESG_CD] = useState('');
  const [CLM_PAY_SEQ_NBRD, setCLM_PAY_SEQ_NBRD] = useState('');
  const [FUND_ARNG_CD, setFUND_ARNG_CD] = useState('');
  const [TRANS_SRC_CD, setTRANS_SRC_CD] = useState('');
  const [ERR_PAY_SEQ_NBR, setERR_PAY_SEQ_NBR] = useState('');
  const [ERR_TRANS_ID, setERR_TRANS_ID] = useState('');
  const [LEG_ENT_CD, setLEG_ENT_CD] = useState('');
  const [OBLIGOR_ID, setOBLIGOR_ID] = useState('');
  const [HMO_ACCTDIV_ADM_CD, setHMO_ACCTDIV_ADM_CD] = useState('');
  const [RR_CD, setRR_CD] = useState('');
  const [POL_NBR, setPOL_NBR] = useState('');
  const [PRDCT_CLSS_ID, setPRDCT_CLSS_ID] = useState('');
  const [SUFX_CD, setSUFX_CD] = useState('');
  const [HMO_ACCT_DIV_CD, setHMO_ACCT_DIV_CD] = useState('');
  const [CO_CD, setCO_CD] = useState('');
  const [RPT_CD, setRPT_CD] = useState('');
  const [SRVC_DT, setSRVC_DT] = useState('');
  const [LS_DT, setLS_DT] = useState('');
  const [FUND_ARNG_GRP_CD, setFUND_ARNG_GRP_CD] = useState('');
  const [LEG_ENTY_GRP_CD, setLEG_ENTY_GRP_CD] = useState('');
  const [PRDCT_CD, setPRDCT_CD] = useState('');
  const [MAJ_PRDCT_CD, setMAJ_PRDCT_CD] = useState('');

  const [errFlag, setErrFlag] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateRow, setUpdateRow] = useState({});
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [errorAlert, setErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  const location = getLocation();
  const clearFields = () => {
    setORIG_SCHED_BK_DT('');
    setSRS_DESG_CD('');
    setCLM_PAY_SEQ_NBRD('');
    setFUND_ARNG_CD('');
    setTRANS_SRC_CD('');
    setERR_PAY_SEQ_NBR('');
    setERR_TRANS_ID('');
    setLEG_ENT_CD('');
    setOBLIGOR_ID('');
    setHMO_ACCTDIV_ADM_CD('');
    setRR_CD('');
    setPOL_NBR('');
    setPRDCT_CLSS_ID('');
    setSUFX_CD('');
    setHMO_ACCT_DIV_CD('');
    setCO_CD('');
    setRPT_CD('');
    setSRVC_DT('');
    setLS_DT('');
    setFUND_ARNG_GRP_CD('');
    setLEG_ENTY_GRP_CD('');
    setPRDCT_CD('');
    setMAJ_PRDCT_CD('');
  };
  const close = () => {
    setFlag(false);
    // setmarketSegInd('');
    // setFmsiTypeCd('');
    // setmarketSegTxt('');
  };

  //get column definition
  const headerInfo = [
    {
      Header: 'ERR_TRANS_ID',
      accessor: 'errTransId',
    },
    {
      Header: 'SCHED_BK_DT',
      accessor: 'schedBkDt',
    },
    {
      Header: 'ORIG_SCHED_BK_DT',
      accessor: 'origSchedBkDt',
    },
    {
      Header: 'SRS_DESG_CD',
      accessor: 'srsDesgCd',
    },
    {
      Header: 'SRS_DESG_GRP_CD',
      accessor: 'srsDesgGrpCd',
    },
    {
      Header: 'TRANS_TYP_CD',
      accessor: 'transTypCd',
    },
    {
      Header: 'TRANS_ACTV_TYP_CD',
      accessor: 'transActvTypCd',
    },
    {
      Header: 'TRANS_ACTV_GRP_CD',
      accessor: 'transActvGrpCd',
    },
    {
      Header: 'TRANS_APPL_TYP_CD',
      accessor: 'transApplTypCd',
    },
    {
      Header: 'ABA_NBR',
      accessor: 'abaNbr',
    },
    {
      Header: 'BNK_ACCT_NBR',
      accessor: 'bnkAcctNbr',
    },
    {
      Header: 'BNK_ACCT_TYP_CD',
      accessor: 'bnkAcctTypCd',
    },
    {
      Header: 'BANK_ID',
      accessor: 'bankId',
    },
    {
      Header: 'SUM_DET_IND',
      accessor: 'sumDetInd',
    },
    {
      Header: 'SUM_TYP_CD',
      accessor: 'sumTypCd',
    },
    {
      Header: 'PAY_ACTV_TYP_CD',
      accessor: 'payActvTypCd',
    },
    {
      Header: 'ERR_PAY_SEQ_NBR',
      accessor: 'errPaySeqNbr',
    },
    {
      Header: 'CLM_PAY_SEQ_NBRD',
      accessor: 'clmPaySeqNbrd',
    },
    {
      Header: 'TRANS_SRC_CD',
      accessor: 'transSrcCd',
    },
    {
      Header: 'SRC_BATCH_DT',
      accessor: 'srcBatchDt',
    },
    {
      Header: 'SRC_BATCH_NBR',
      accessor: 'srcBatchNbr',
    },
    {
      Header: 'FM_GRP_CD',
      accessor: 'fmGrpCd',
    },
    {
      Header: 'ISS_DT',
      accessor: 'issDt',
    },
    {
      Header: 'CHRG_DT',
      accessor: 'chrgDt',
    },
    {
      Header: 'SRC_SYS_PROC_DT',
      accessor: 'srcSysProcDt',
    },
    {
      Header: 'SRC_SYS_PROC_TM',
      accessor: 'srcSysProcTm',
    },
    {
      Header: 'PROC_CMPL_DT',
      accessor: 'procCmplDt',
    },
    {
      Header: 'LS_DT',
      accessor: 'lsDt',
    },
    {
      Header: 'RPT_GRP_NBR',
      accessor: 'rptGrpNbr',
    },
    {
      Header: 'POL_NBR',
      accessor: 'polNbr',
    },
    {
      Header: 'SUFX_CD',
      accessor: 'sufxCd',
    },
    {
      Header: 'POL_ACCT_NBR',
      accessor: 'polAcctNbr',
    },
    {
      Header: 'HMO_ACCT_DIV_CD',
      accessor: 'hmoAcctDivCd',
    },
    {
      Header: 'CO_CD',
      accessor: 'coCd',
    },
    {
      Header: 'FUND_ARNG_CD',
      accessor: 'fundArngCd',
    },
    {
      Header: 'FUND_ARNG_GRP_CD',
      accessor: 'fundArngGrpCd',
    },
    {
      Header: 'LEG_ENTY_GRP_CD',
      accessor: 'legEntyGrpCd',
    },
    {
      Header: 'PRDCT_CD',
      accessor: 'prdctCd',
    },
    {
      Header: 'MAJ_PRDCT_CD',
      accessor: 'majPrdctCd',
    },
    {
      Header: 'MKT_SEG_CD',
      accessor: 'mktSegCd',
    },
    {
      Header: 'SRVC_TYP_CD',
      accessor: 'srvcTypCd',
    },
    {
      Header: 'ADJ_OFC_CD',
      accessor: 'adjOfcCd',
    },
    {
      Header: 'SALES_OFC_CD',
      accessor: 'salesOfcCd',
    },
    {
      Header: 'CLM_TYP_CD',
      accessor: 'clmTypCd',
    },
    {
      Header: 'NTC_NBR',
      accessor: 'ntcNbr',
    },
    {
      Header: 'SPLCL_CD',
      accessor: 'splclCd',
    },
    {
      Header: 'TAX_1099_IND',
      accessor: 'tax1099Ind',
    },
    {
      Header: 'SUBL_OCCUR_NBR',
      accessor: 'sublOccurNbr',
    },
    {
      Header: 'SUBL_TYP_CD',
      accessor: 'sublTypCd',
    },
    {
      Header: 'SUBL_AMT',
      accessor: 'sublAmt',
    },
    {
      Header: 'COV_TYP_CD',
      accessor: 'covTypCd',
    },
    {
      Header: 'TRANS_FROM_DT',
      accessor: 'transFromDt',
    },
    {
      Header: 'TRANS_TO_DT',
      accessor: 'transToDt',
    },
    {
      Header: 'SUBL_NY_RPTCTGY_CD',
      accessor: 'sublNyRptctgyCd',
    },
    {
      Header: 'SUBL_PPD_CD',
      accessor: 'sublPpdCd',
    },
    {
      Header: 'SUBL_SEQ_NBR',
      accessor: 'sublSeqNbr',
    },
    {
      Header: 'MBR_FST_NM',
      accessor: 'mbrFstNm',
    },
    {
      Header: 'MBR_MKT_NBR',
      accessor: 'mbrMktNbr',
    },
    {
      Header: 'MBR_MKT_TYP_CD',
      accessor: 'mbrMktTypCd',
    },
    {
      Header: 'CUST_MKT_NBR',
      accessor: 'custMktNbr',
    },
    {
      Header: 'SBSCR_CTY_NM',
      accessor: 'sbscrCtyNm',
    },
    {
      Header: 'SBSCR_ID',
      accessor: 'sbscrId',
    },
    {
      Header: 'SBSCR_NM',
      accessor: 'sbscrNm',
    },
    {
      Header: 'SBSCR_ST_CD',
      accessor: 'sbscrStCd',
    },
    {
      Header: 'SBSCR_STR_LN1_ADR',
      accessor: 'sbscrStrLn1Adr',
    },
    {
      Header: 'SBSCR_ZIP_CD',
      accessor: 'sbscrZipCd',
    },
    {
      Header: 'SBSCR_ZIP_EXT_CD',
      accessor: 'sbscrZipExtCd',
    },
    {
      Header: 'PAYE_NM',
      accessor: 'payeNm',
    },
    {
      Header: 'PAYE_CTY_NM',
      accessor: 'payeCtyNm',
    },
    {
      Header: 'PAYE_ST_CD',
      accessor: 'payeStCd',
    },
    {
      Header: 'PAYE_STR_LN1_ADR',
      accessor: 'payeStrLn1Adr',
    },
    {
      Header: 'PAYE_STR_LN2_ADR',
      accessor: 'payeStrLn2Adr',
    },
    {
      Header: 'PAYE_TYP_CD',
      accessor: 'payeTypCd',
    },
    {
      Header: 'PAYE_ZIP_CD',
      accessor: 'payeZipCd',
    },
    {
      Header: 'PAYE_ZIP_EXT_CD',
      accessor: 'payeZipExtCd',
    },
    {
      Header: 'PAY_TYP_CD',
      accessor: 'payTypCd',
    },
    {
      Header: 'FILLER_04',
      accessor: 'fillerFour',
    },
    {
      Header: 'PROV_MKT_SITE_NBR',
      accessor: 'provMktSiteNbr',
    },
    {
      Header: 'PROV_NTWK_ACSS_CD',
      accessor: 'provNtwkAcssCd',
    },
    {
      Header: 'PROV_PRDCT_CD',
      accessor: 'provPrdctCd',
    },
    {
      Header: 'PROV_ST_CD',
      accessor: 'provStCd',
    },
    {
      Header: 'PROV_TIN_NBR',
      accessor: 'provTinNbr',
    },
    {
      Header: 'PROV_TIN_TYP_CD',
      accessor: 'provTinTypCd',
    },
    {
      Header: 'PROV_ZIP_CD',
      accessor: 'provZipCd',
    },
    {
      Header: 'PROV_ZIP_EXT_CD',
      accessor: 'provZipExtCd',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_1',
      accessor: 'errSuspCdNbr1',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_2',
      accessor: 'errSuspCdNbr2',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_3',
      accessor: 'errSuspCdNbr3',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_4',
      accessor: 'errSuspCdNbr4',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_5',
      accessor: 'errSuspCdNbr5',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_6',
      accessor: 'errSuspCdNbr6',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_7',
      accessor: 'errSuspCdNbr7',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_8',
      accessor: 'errSuspCdNbr8',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_9',
      accessor: 'errSuspCdNbr9',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_10',
      accessor: 'errSuspCdNbr10',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_11',
      accessor: 'errSuspCdNbr11',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_12',
      accessor: 'errSuspCdNbr12',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_13',
      accessor: 'errSuspCdNbr13',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_14',
      accessor: 'errSuspCdNbr14',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_15',
      accessor: 'errSuspCdNbr15',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_16',
      accessor: 'errSuspCdNbr16',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_17',
      accessor: 'errSuspCdNbr17',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_18',
      accessor: 'errSuspCdNbr18',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_19',
      accessor: 'errSuspCdNbr19',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_20',
      accessor: 'errSuspCdNbr20',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_21',
      accessor: 'errSuspCdNbr21',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_22',
      accessor: 'errSuspCdNbr22',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_23',
      accessor: 'errSuspCdNbr23',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_24',
      accessor: 'errSuspCdNbr24',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_25',
      accessor: 'errSuspCdNbr25',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_26',
      accessor: 'errSuspCdNbr26',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_27',
      accessor: 'errSuspCdNbr27',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_28',
      accessor: 'errSuspCdNbr28',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_29',
      accessor: 'errSuspCdNbr29',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_30',
      accessor: 'errSuspCdNbr30',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_31',
      accessor: 'errSuspCdNbr31',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_32',
      accessor: 'errSuspCdNbr32',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_33',
      accessor: 'errSuspCdNbr33',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_34',
      accessor: 'errSuspCdNbr34',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_35',
      accessor: 'errSuspCdNbr35',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_36',
      accessor: 'errSuspCdNbr36',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_37',
      accessor: 'errSuspCdNbr37',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_38',
      accessor: 'errSuspCdNbr38',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_39',
      accessor: 'errSuspCdNbr39',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_40',
      accessor: 'errSuspCdNbr40',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_41',
      accessor: 'errSuspCdNbr41',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_42',
      accessor: 'errSuspCdNbr42',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_43',
      accessor: 'errSuspCdNbr43',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_44',
      accessor: 'errSuspCdNbr44',
    },
    {
      Header: 'ERR_SUSP_CD_NBR_45',
      accessor: 'errSuspCdNbr45',
    },
    {
      Header: 'PRI_CD',
      accessor: 'priCd',
    },
    {
      Header: 'ACTV_RET_CD',
      accessor: 'actvRetCd',
    },
    {
      Header: 'AREA_NTWK_CD',
      accessor: 'areaNtwkCd',
    },
    {
      Header: 'BULKACCT_SRSDSG_CD',
      accessor: 'bulkacctSrsdsgCd',
    },
    {
      Header: 'CAUS_CD',
      accessor: 'causCd',
    },
    {
      Header: 'CLM_OPT_OUT_IND',
      accessor: 'clmOptOutInd',
    },
    {
      Header: 'CLSS_CD',
      accessor: 'clssCd',
    },
    {
      Header: 'COV_PHYSN_CD',
      accessor: 'covPhysnCd',
    },
    {
      Header: 'DATA_TYP_CD',
      accessor: 'dataTypCd',
    },
    {
      Header: 'DIAG_CD',
      accessor: 'diagCd',
    },
    {
      Header: 'POL_HLD_NM',
      accessor: 'polHldNm',
    },
    {
      Header: 'DTL_CHK_NBR',
      accessor: 'dtlChkNbr',
    },
    {
      Header: 'EE_ST_CD',
      accessor: 'eeStCd',
    },
    {
      Header: 'ERISA_CD',
      accessor: 'erisaCd',
    },
    {
      Header: 'FM_CD',
      accessor: 'fmCd',
    },
    {
      Header: 'ERR_CD',
      accessor: 'errCd',
    },
    {
      Header: 'FEE_CD',
      accessor: 'feeCd',
    },
    {
      Header: 'ACT_CD',
      accessor: 'actCd',
    },
    {
      Header: 'NTWK_FUND_CD',
      accessor: 'ntwkFundCd',
    },
    {
      Header: 'GTKPR_IND',
      accessor: 'gtkprInd',
    },
    {
      Header: 'ICD_VER_ID',
      accessor: 'icdVerId',
    },
    {
      Header: 'FILLER_01',
      accessor: 'filler01',
    },
    {
      Header: 'MED_SURCHRG_IND',
      accessor: 'medSurchrgInd',
    },
    {
      Header: 'MEDCR_ELIG_IND',
      accessor: 'medcrEligInd',
    },
    {
      Header: 'NY_ELIG_CD',
      accessor: 'nyEligCd',
    },
    {
      Header: 'OUT_OF_PKT_CD',
      accessor: 'outOfPktCd',
    },
    {
      Header: 'MBR_AGE_NBR',
      accessor: 'mbrAgeNbr',
    },
    {
      Header: 'PTNT_REL_CD',
      accessor: 'ptntRelCd',
    },
    {
      Header: 'RET_CD',
      accessor: 'retCd',
    },
    {
      Header: 'RETRO_CD',
      accessor: 'retroCd',
    },
    {
      Header: 'RR_BEN_PLN_CD',
      accessor: 'rrBenPlnCd',
    },
    {
      Header: 'RR_CD',
      accessor: 'rrCd',
    },
    {
      Header: 'RR_OCP_CD',
      accessor: 'rrOcpCd',
    },
    {
      Header: 'RR_PHRM_CLSS_CD',
      accessor: 'rrPhrmClssCd',
    },
    {
      Header: 'TOPS_PROC_OFC_CD',
      accessor: 'topsProcOfcCd',
    },
    {
      Header: 'RR_UNION_CD',
      accessor: 'rrUnionCd',
    },
    {
      Header: 'RSRV_IND',
      accessor: 'rsrvInd',
    },
    {
      Header: 'RVRS_CORR_CD',
      accessor: 'rvrsCorrCd',
    },
    {
      Header: 'SUMCHK_ORIG_SRC_CD',
      accessor: 'sumchkOrigSrcCd',
    },
    {
      Header: 'CHK_REC_SYS_CD',
      accessor: 'chkRecSysCd',
    },
    {
      Header: 'DRFT_NBR',
      accessor: 'drftNbr',
    },
    {
      Header: 'TOPS_BNK_ID',
      accessor: 'topsBnkId',
    },
    {
      Header: 'TOPS_CAUS_CD',
      accessor: 'topsCausCd',
    },
    {
      Header: 'TOPS_TRANS_FLD_CD',
      accessor: 'topsTransFldCd',
    },
    {
      Header: 'TRACR_CTL_NBR',
      accessor: 'tracrCtlNbr',
    },
    {
      Header: 'TRACR_RSN_CD',
      accessor: 'tracrRsnCd',
    },
    {
      Header: 'TRACR_VEND_CD',
      accessor: 'tracrVendCd',
    },
    {
      Header: 'TRANS_AMT',
      accessor: 'transAmt',
    },
    {
      Header: 'FILLER_14',
      accessor: 'filler14',
    },
    {
      Header: 'FILLER_15',
      accessor: 'filler15',
    },
    {
      Header: 'FILLER_16',
      accessor: 'filler16',
    },
    {
      Header: 'FILLER_17',
      accessor: 'filler17',
    },
    {
      Header: 'TEMP_REINS_CD',
      accessor: 'tempReinsCd',
    },
    {
      Header: 'GL_CUST_NBR',
      accessor: 'glCustNbr',
    },
    {
      Header: 'GL_FINC_ARNG_CD',
      accessor: 'glFincArngCd',
    },
    {
      Header: 'GL_FUNC_CD',
      accessor: 'glFuncCd',
    },
    {
      Header: 'SRVC_DT',
      accessor: 'srvcDt',
    },
    {
      Header: 'GL_LOC_CD',
      accessor: 'glLocCd',
    },
    {
      Header: 'GL_PRDCT_CD',
      accessor: 'glPrdctCd',
    },
    {
      Header: 'TRAV_PRDCT_CD',
      accessor: 'travPrdctCd',
    },
    {
      Header: 'DSTRB_PRI_STS_CD',
      accessor: 'dstrbPriStsCd',
    },
    {
      Header: 'DSTRB_SEC_STS_CD',
      accessor: 'dstrbSecStsCd',
    },
    {
      Header: 'ACCT_TYP_AP_CD',
      accessor: 'acctTypApCd',
    },
    {
      Header: 'ACCT_TYP_BP_CD',
      accessor: 'acctTypBpCd',
    },
    {
      Header: 'GL_ACCT_AP_NBR',
      accessor: 'glAcctApNbr',
    },
    {
      Header: 'GL_ACCT_BP_NBR',
      accessor: 'glAcctBpNbr',
    },
    {
      Header: 'GL_ACT_AP_CD',
      accessor: 'glActApCd',
    },
    {
      Header: 'GL_ACT_BP_CD',
      accessor: 'glActBpCd',
    },
    {
      Header: 'GL_MNTY_AP_AMT',
      accessor: 'glMntyApAmt',
    },
    {
      Header: 'GL_MNTY_BP_AMT',
      accessor: 'glMntyBpAmt',
    },
    {
      Header: 'GL_MNTY_AS_AMT',
      accessor: 'glMntyAsAmt',
    },
    {
      Header: 'GL_MNTY_BS_AMT',
      accessor: 'glMntyBsAmt',
    },
    {
      Header: 'GL_BUS_UNIT_AP_CD',
      accessor: 'glBusUnitApCd',
    },
    {
      Header: 'GL_BUS_UNIT_BP_CD',
      accessor: 'glBusUnitBpCd',
    },
    {
      Header: 'GL_REINS_AP_CD',
      accessor: 'glReinsApCd',
    },
    {
      Header: 'GL_REINS_BP_CD',
      accessor: 'glReinsBpCd',
    },
    {
      Header: 'GL_SRS_DESG_AP_CD',
      accessor: 'glSrsDesgApCd',
    },
    {
      Header: 'GL_SRS_DESG_BP_CD',
      accessor: 'glSrsDesgBpCd',
    },
    {
      Header: 'GL_ZBA_AP_NBR',
      accessor: 'glZbaApNbr',
    },
    {
      Header: 'GL_ZBA_BP_NBR',
      accessor: 'glZbaBpNbr',
    },
    {
      Header: 'TRAV_ACCT_AP_NBR',
      accessor: 'travAcctApNbr',
    },
    {
      Header: 'TRAV_ACCT_BP_NBR',
      accessor: 'travAcctBpNbr',
    },
    {
      Header: 'TRAV_PORT_AP_CD',
      accessor: 'travPortApCd',
    },
    {
      Header: 'TRAV_PORT_BP_CD',
      accessor: 'travPortBpCd',
    },
    {
      Header: 'ACCT_TYP_AS_CD',
      accessor: 'acctTypAsCd',
    },
    {
      Header: 'ACCT_TYP_BS_CD',
      accessor: 'acctTypBsCd',
    },
    {
      Header: 'GL_ACCT_AS_NBR',
      accessor: 'glAcctAsNbr',
    },
    {
      Header: 'GL_ACCT_BS_NBR',
      accessor: 'glAcctBsNbr',
    },
    {
      Header: 'GL_ACT_AS_CD',
      accessor: 'glActAsCd',
    },
    {
      Header: 'GL_ACT_BS_CD',
      accessor: 'glActBsCd',
    },
    {
      Header: 'GL_BUS_UNIT_AS_CD',
      accessor: 'glBusUnitAsCd',
    },
    {
      Header: 'GL_BUS_UNIT_BS_CD',
      accessor: 'glBusUnitBsCd',
    },
    {
      Header: 'GL_REINS_AS_CD',
      accessor: 'glReinsAsCd',
    },
    {
      Header: 'GL_REINS_BS_CD',
      accessor: 'glReinsBsCd',
    },
    {
      Header: 'GL_SRS_DESG_AS_CD',
      accessor: 'glSrsDesgAsCd',
    },
    {
      Header: 'GL_SRS_DESG_BS_CD',
      accessor: 'glSrsDesgBsCd',
    },
    {
      Header: 'GL_ZBA_AS_NBR',
      accessor: 'glZbaAsNbr',
    },
    {
      Header: 'GL_ZBA_BS_NBR',
      accessor: 'glZbaBsNbr',
    },
    {
      Header: 'TRAV_ACCT_AS_NBR',
      accessor: 'travAcctAsNbr',
    },
    {
      Header: 'TRAV_ACCT_BS_NBR',
      accessor: 'travAcctBsNbr',
    },
    {
      Header: 'TRAV_PORT_AS_CD',
      accessor: 'travPortAsCd',
    },
    {
      Header: 'TRAV_PORT_BS_CD',
      accessor: 'travPortBsCd',
    },
    {
      Header: 'BLK_ACCT_ABA_NBR',
      accessor: 'blkAcctAbaNbr',
    },
    {
      Header: 'BLK_ACCT_BNK_ACCT',
      accessor: 'blkAcctBnkAcct',
    },
    {
      Header: 'FTS_ACCT_NBR',
      accessor: 'ftsAcctNbr',
    },
    {
      Header: 'FTS_LE_CD',
      accessor: 'ftsLeCd',
    },
    {
      Header: 'FTS_PAYOR_LE_CD',
      accessor: 'ftsPayorLeCd',
    },
    {
      Header: 'RVLV_FL_CD',
      accessor: 'rvlvFlCd',
    },
    {
      Header: 'ST_ABBR_CD',
      accessor: 'stAbbrCd',
    },
    {
      Header: 'RPT_CD',
      accessor: 'rptCd',
    },
    {
      Header: 'TYP_CD',
      accessor: 'typCd',
    },
    {
      Header: 'MEM_IPA',
      accessor: 'memIpa',
    },
    {
      Header: 'PRV_IPA',
      accessor: 'prvIpa',
    },
    {
      Header: 'FILLER_07',
      accessor: 'filler07',
    },
    {
      Header: 'FILLER_08',
      accessor: 'filler08',
    },
    {
      Header: 'FILLER_09',
      accessor: 'filler09',
    },
    {
      Header: 'FILLER_10',
      accessor: 'filler10',
    },
    {
      Header: 'FILLER_11',
      accessor: 'filler11',
    },
    {
      Header: 'PBM_NAPB_NBR',
      accessor: 'pbmNapbNbr',
    },
    {
      Header: 'FILLER_06',
      accessor: 'fillerSix',
    },
    {
      Header: 'PBM_BTCH_SEQ_NBR',
      accessor: 'pbmBtchSeqNbr',
    },
    {
      Header: 'PBM_RBT_IND',
      accessor: 'pbmRbtInd',
    },
    {
      Header: 'PBM_UNIQUE_QUAL_CD',
      accessor: 'pbmUniqueQualCd',
    },
    {
      Header: 'TOPFLN_OFF_NBR',
      accessor: 'topflnOffNbr',
    },
    {
      Header: 'TOPFLN_DT',
      accessor: 'topflnDt',
    },
    {
      Header: 'TOPFLN_SRL_NBR',
      accessor: 'topflnSrlNbr',
    },
    {
      Header: 'CSE_FNDARNG_GRP_CD',
      accessor: 'cseFndarngGrpCd',
    },
    {
      Header: 'LEG_ENT_CD',
      accessor: 'legEntCd',
    },
    {
      Header: 'CSE_FND_ARNG_CD',
      accessor: 'cseFndArngCd',
    },
    {
      Header: 'HMO_ACCTDIV_ADM_CD',
      accessor: 'hmoAcctdivAdmCd',
    },
    {
      Header: 'RR_TYP_CD',
      accessor: 'rrTypCd',
    },
    {
      Header: 'ADMIN_SYS_SRC_CD',
      accessor: 'adminSysSrcCd',
    },
    {
      Header: 'CUST_ID',
      accessor: 'custId',
    },
    {
      Header: 'CUST_PLAN_ID',
      accessor: 'custPlanId',
    },
    {
      Header: 'NTWK_ORG_ID',
      accessor: 'ntwkOrgId',
    },
    {
      Header: 'BLK_ACCT_PAY_CD',
      accessor: 'blkAcctPayCd',
    },
    {
      Header: 'BLKACT_BNKACTYP_CD',
      accessor: 'blkactBnkactypCd',
    },
    {
      Header: 'FRDRCV_CASE_NBR',
      accessor: 'frdrcvCaseNbr',
    },
    {
      Header: 'FRDRCV_IMCSPOL_NBR',
      accessor: 'frdrcvImcspolNbr',
    },
    {
      Header: 'FRDRCV_SEQ_NBR',
      accessor: 'frdrcvSeqNbr',
    },
    {
      Header: 'DRUG_IND',
      accessor: 'drugInd',
    },
    {
      Header: 'ORIG_TRANS_ID',
      accessor: 'origTransId',
    },
    {
      Header: 'ORIG_CHRG_DT',
      accessor: 'origChrgDt',
    },
    {
      Header: 'ADJD_DT',
      accessor: 'adjdDt',
    },
    {
      Header: 'ORIG_IND',
      accessor: 'origInd',
    },
    {
      Header: 'RCD_TYP_CD',
      accessor: 'rcdTypCd',
    },
    {
      Header: 'SUBL_EXP_TYP_CD',
      accessor: 'sublExpTypCd',
    },
    {
      Header: 'RR_IND',
      accessor: 'rrInd',
    },
    {
      Header: 'TOPS_TRANS_IND',
      accessor: 'topsTransInd',
    },
    {
      Header: 'CMDB_BASE_TRANS_ID',
      accessor: 'cmdbBaseTransId',
    },
    {
      Header: 'CMDB_BASE_CHRG_DT',
      accessor: 'cmdbBaseChrgDt',
    },
    {
      Header: 'CMDB_BASE_SUBL_NBR',
      accessor: 'cmdbBaseSublNbr',
    },
    {
      Header: 'ACIS_CUST_ID',
      accessor: 'acisCustId',
    },
    {
      Header: 'ACIS_GRP_ID',
      accessor: 'acisGrpId',
    },
    {
      Header: 'ACIS_CUST_PLN_ID',
      accessor: 'acisCustPlnId',
    },
    {
      Header: 'ACIS_NTWK_ORG_ID',
      accessor: 'acisNtwkOrgId',
    },
    {
      Header: 'TIN_PREFIX',
      accessor: 'tinPrefix',
    },
    {
      Header: 'FILLER_05',
      accessor: 'filler05',
    },
    {
      Header: 'PROPOSED_ID',
      accessor: 'proposedId',
    },
    {
      Header: 'PBM_DSPNS_STS_CD',
      accessor: 'pbmDspnsStsCd',
    },
    {
      Header: 'ISL_SUFX_CD',
      accessor: 'islSufxCd',
    },
    {
      Header: 'CMDB_RQST_ID',
      accessor: 'cmdbRqstId',
    },
    {
      Header: 'BULK_CHECK_NBR',
      accessor: 'bulkCheckNbr',
    },
    {
      Header: 'SHR_ARNG_CD',
      accessor: 'shrArngCd',
    },
    {
      Header: 'OBLIGOR_ID',
      accessor: 'obligorId',
    },
    {
      Header: 'ICN_NBR',
      accessor: 'icnNbr',
    },
    {
      Header: 'MBR_PCP_TIN_NBR',
      accessor: 'mbrPcpTinNbr',
    },
    {
      Header: 'TIN_SUFFIX',
      accessor: 'tinSuffix',
    },
    {
      Header: 'PRDCT_CLSS_ID',
      accessor: 'prdctClssId',
    },
    {
      Header: 'LD_PRTN_ID',
      accessor: 'ldPrtnId',
    },
    {
      Header: 'PAYR_CD',
      accessor: 'payrCd',
    },
    {
      Header: 'CONSL_PROV_PAY_IND',
      accessor: 'conslProvPayInd',
    },
    {
      Header: 'ACN_AMT',
      accessor: 'acnAmt',
    },
    {
      Header: 'NMF_AMT',
      accessor: 'nmfAmt',
    },
    {
      Header: 'PROV_NPI_ID',
      accessor: 'provNpiId',
    },
    {
      Header: 'PBM_RX_NBR',
      accessor: 'pbmRxNbr',
    },
    {
      Header: 'FILLER_12',
      accessor: 'filler12',
    },
    {
      Header: 'GL_IDB_AP_DT',
      accessor: 'glIdbApDt',
    },
    {
      Header: 'GL_IDB_AS_DT',
      accessor: 'glIdbAsDt',
    },
    {
      Header: 'GL_IDB_BP_DT',
      accessor: 'glIdbBpDt',
    },
    {
      Header: 'GL_IDB_BS_DT',
      accessor: 'glIdbBsDt',
    },
    {
      Header: 'GL_FUNC_AP_CD',
      accessor: 'glFuncApCd',
    },
    {
      Header: 'GL_FUNC_AS_CD',
      accessor: 'glFuncAsCd',
    },
    {
      Header: 'GL_FUNC_BP_CD',
      accessor: 'glFuncBpCd',
    },
    {
      Header: 'GL_FUNC_BS_CD',
      accessor: 'glFuncBsCd',
    },
    {
      Header: 'GL_LOC_AP_NBR',
      accessor: 'glLocApNbr',
    },
    {
      Header: 'GL_LOC_AS_NBR',
      accessor: 'glLocAsNbr',
    },
    {
      Header: 'GL_LOC_BP_NBR',
      accessor: 'glLocBpNbr',
    },
    {
      Header: 'GL_LOC_BS_NBR',
      accessor: 'glLocBsNbr',
    },
    {
      Header: 'GL_CUST_AP_NBR',
      accessor: 'glCustApNbr',
    },
    {
      Header: 'GL_CUST_AS_NBR',
      accessor: 'glCustAsNbr',
    },
    {
      Header: 'GL_CUST_BP_NBR',
      accessor: 'glCustBpNbr',
    },
    {
      Header: 'GL_CUST_BS_NBR',
      accessor: 'glCustBsNbr',
    },
    {
      Header: 'GL_FINC_ARNG_AP_CD',
      accessor: 'glFincArngApCd',
    },
    {
      Header: 'GL_FINC_ARNG_AS_CD',
      accessor: 'glFincArngAsCd',
    },
    {
      Header: 'GL_FINC_ARNG_BP_CD',
      accessor: 'glFincArngBpCd',
    },
    {
      Header: 'GL_FINC_ARNG_BS_CD',
      accessor: 'glFincArngBsCd',
    },
    {
      Header: 'GL_PRDCT_AP_CD',
      accessor: 'glPrdctApCd',
    },
    {
      Header: 'GL_PRDCT_AS_CD',
      accessor: 'glPrdctAsCd',
    },
    {
      Header: 'GL_PRDCT_BP_CD',
      accessor: 'glPrdctBpCd',
    },
    {
      Header: 'GL_PRDCT_BS_CD',
      accessor: 'glPrdctBsCd',
    },
    {
      Header: 'LAG_STAT1_AMT',
      accessor: 'lagStat1Amt',
    },
    {
      Header: 'LAG_STAT2_AMT',
      accessor: 'lagStat2Amt',
    },
    {
      Header: 'REJECT_CD',
      accessor: 'rejectCd',
    },
    {
      Header: 'PRDCT_DUAL_CONTR_IND',
      accessor: 'prdctDualContrInd',
    },
    {
      Header: 'FTS_IN_HMO_SWITCH',
      accessor: 'ftsInHmoSwitch',
    },
    {
      Header: 'FTS_IN_HMO_OPT_OUT_IND',
      accessor: 'ftsInHmoOptOutInd',
    },
    {
      Header: 'FTS_OUT_ORIG_HMO_LE_CD',
      accessor: 'ftsOutOrigHmoLeCd',
    },
    {
      Header: 'FTS_IN_ADC_CD',
      accessor: 'ftsInAdcCd',
    },
    {
      Header: 'FTS_IN_ACCT_TRNS_TYP_CD',
      accessor: 'ftsInAcctTrnsTypCd',
    },
    {
      Header: 'FTS_STATUS_CD',
      accessor: 'ftsStatusCd',
    },
    {
      Header: 'FTS_OUT_REBATE_BU_NBR',
      accessor: 'ftsOutRebateBuNbr',
    },
    {
      Header: 'CONTR_ISS_ST_CD',
      accessor: 'contrIssStCd',
    },
    {
      Header: 'CUST_ADDR_ZIP',
      accessor: 'custAddrZip',
    },
    {
      Header: 'CUST_ADDR_ZIP_EXT',
      accessor: 'custAddrZipExt',
    },
    {
      Header: 'PS9_GL_LE_AP_CD',
      accessor: 'ps9GlLeApCd',
    },
    {
      Header: 'PS9_GL_SEG_AP_CD',
      accessor: 'ps9GlSegApCd',
    },
    {
      Header: 'PS9_GL_LOC_AP_NBR',
      accessor: 'ps9GlLocApNbr',
    },
    {
      Header: 'PS9_GL_ACCT_AP_NBR',
      accessor: 'ps9GlAcctApNbr',
    },
    {
      Header: 'PS9_GL_DEPT_AP_CD',
      accessor: 'ps9GlDeptApCd',
    },
    {
      Header: 'PS9_GL_PRDCT_AP_CD',
      accessor: 'ps9GlPrdctApCd',
    },
    {
      Header: 'PS9_GL_REINS_AP_CD',
      accessor: 'ps9GlReinsApCd',
    },
    {
      Header: 'PS9_GL_CUST_AP_NBR',
      accessor: 'ps9GlCustApNbr',
    },
    {
      Header: 'PS9_PROJECT_AP_ID',
      accessor: 'ps9ProjectApId',
    },
    {
      Header: 'PS9_GL_LE_BP_CD',
      accessor: 'ps9GlLeBpCd',
    },
    {
      Header: 'PS9_GL_SEG_BP_CD',
      accessor: 'ps9GlSegBpCd',
    },
    {
      Header: 'PS9_GL_LOC_BP_NBR',
      accessor: 'ps9GlLocBpNbr',
    },
    {
      Header: 'PS9_GL_ACCT_BP_NBR',
      accessor: 'ps9GlAcctBpNbr',
    },
    {
      Header: 'PS9_GL_DEPT_BP_CD',
      accessor: 'ps9GlDeptBpCd',
    },
    {
      Header: 'PS9_GL_PRDCT_BP_CD',
      accessor: 'ps9GlPrdctBpCd',
    },
    {
      Header: 'PS9_GL_REINS_BP_CD',
      accessor: 'ps9GlReinsBpCd',
    },
    {
      Header: 'PS9_GL_CUST_BP_NBR',
      accessor: 'ps9GlCustBpNbr',
    },
    {
      Header: 'PS9_PROJECT_BP_ID',
      accessor: 'ps9ProjectBpId',
    },
    {
      Header: 'PS9_GL_LE_AS_CD',
      accessor: 'ps9GlLeAsCd',
    },
    {
      Header: 'PS9_GL_SEG_AS_CD',
      accessor: 'ps9GlSegAsCd',
    },
    {
      Header: 'PS9_GL_LOC_AS_NBR',
      accessor: 'ps9GlLocAsNbr',
    },
    {
      Header: 'PS9_GL_ACCT_AS_NBR',
      accessor: 'ps9GlAcctAsNbr',
    },
    {
      Header: 'PS9_GL_DEPT_AS_CD',
      accessor: 'ps9GlDeptAsCd',
    },
    {
      Header: 'PS9_GL_PRDCT_AS_CD',
      accessor: 'ps9GlPrdctAsCd',
    },
    {
      Header: 'PS9_GL_REINS_AS_CD',
      accessor: 'ps9GlReinsAsCd',
    },
    {
      Header: 'PS9_GL_CUST_AS_NBR',
      accessor: 'ps9GlCustAsNbr',
    },
    {
      Header: 'PS9_PROJECT_AS_ID',
      accessor: 'ps9ProjectAsId',
    },
    {
      Header: 'PS9_GL_LE_BS_CD',
      accessor: 'ps9GlLeBsCd',
    },
    {
      Header: 'PS9_GL_SEG_BS_CD',
      accessor: 'ps9GlSegBsCd',
    },
    {
      Header: 'PS9_GL_LOC_BS_NBR',
      accessor: 'ps9GlLocBsNbr',
    },
    {
      Header: 'PS9_GL_ACCT_BS_NBR',
      accessor: 'ps9GlAcctBsNbr',
    },
    {
      Header: 'PS9_GL_DEPT_BS_CD',
      accessor: 'ps9GlDeptBsCd',
    },
    {
      Header: 'PS9_GL_PRDCT_BS_CD',
      accessor: 'ps9GlPrdctBsCd',
    },
    {
      Header: 'PS9_GL_REINS_BS_CD',
      accessor: 'ps9GlReinsBsCd',
    },
    {
      Header: 'PS9_GL_CUST_BS_NBR',
      accessor: 'ps9GlCustBsNbr',
    },
    {
      Header: 'PS9_PROJECT_BS_ID',
      accessor: 'ps9ProjectBsId',
    },
    {
      Header: 'PS9_GL_CUST_NBR',
      accessor: 'ps9GlCustNbr',
    },
    {
      Header: 'PS9_GL_PRDCT_CD',
      accessor: 'ps9GlPrdctCd',
    },
    {
      Header: 'PS9_GL_LOC_NBR',
      accessor: 'ps9GlLocNbr',
    },
    {
      Header: 'GL_IDB_DT',
      accessor: 'glIdbDt',
    },
    {
      Header: 'PHRM_FEE_CD',
      accessor: 'phrmFeeCd',
    },
    {
      Header: 'REV_ORIG_BOOK_DT',
      accessor: 'revOrigBookDt',
    },
    {
      Header: 'SRC_GROUP_CD',
      accessor: 'srcGroupCd',
    },
    {
      Header: 'FTS_IN_REBATE_CD',
      accessor: 'ftsInRebateCd',
    },
    {
      Header: 'RECORD_TRANS_ID',
      accessor: 'recordTransId',
    },
    {
      Header: 'REV_REC_TRANS_ID',
      accessor: 'revRecTransId',
    },
    {
      Header: 'FTS_OUT_REBATE_PS9_GL_LE',
      accessor: 'ftsOutRebatePs9GlLe',
    },
    {
      Header: 'FTS_OUT_REBATE_PS9_GL_SEG',
      accessor: 'ftsOutRebatePs9GlSeg',
    },
    {
      Header: 'SEC_ACCT_RQR_IND',
      accessor: 'secAcctRqrInd',
    },
    {
      Header: 'NORMAL_SIFS_PROCESS_IND',
      accessor: 'normalSifsProcessInd',
    },
    {
      Header: 'RPS_FISCAL_DT',
      accessor: 'rpsFiscalDt',
    },
    {
      Header: 'GL_JNL_ID',
      accessor: 'glJnlId',
    },
    {
      Header: 'FILLER_13',
      accessor: 'filler13',
    },
  ];
  const columns = React.useMemo(() => headerInfo, []);

  const updateRows = (row) => {
    clearFields();
    setFlag(true);
    setUpdateRow(row);
    setORIG_SCHED_BK_DT(row['origSchedBkDt']);
    setSRS_DESG_CD(row['srsDesgCd']);
    setCLM_PAY_SEQ_NBRD(row['clmPaySeqNbrd']);
    setFUND_ARNG_CD(row['fundArngCd']);
    setTRANS_SRC_CD(row['transSrcCd']);
    setERR_PAY_SEQ_NBR(row['errPaySeqNbr']);
    setERR_TRANS_ID(row['errTransId']);
    setLEG_ENT_CD(row['legEntCd']);
    setOBLIGOR_ID(row['obligorId']);
    setHMO_ACCTDIV_ADM_CD(row['hmoAcctdivAdmCd']);
    setRR_CD(row['rrCd']);
    setPOL_NBR(row['polNbr']);
    setPRDCT_CLSS_ID(row['prdctClssId']);
    setSUFX_CD(row['sufxCd']);
    setHMO_ACCT_DIV_CD(row['hmoAcctDivCd']);
    setCO_CD(row['coCd']);
    setRPT_CD(row['rptCd']);
    setSRVC_DT(row['srvcDt']);
    setLS_DT(row['lsDt']);
    setFUND_ARNG_GRP_CD(row['fundArngGrpCd']);
    setLEG_ENTY_GRP_CD(row['legEntyGrpCd']);
    setPRDCT_CD(row['prdctCd']);
    setMAJ_PRDCT_CD(row['majPrdctCd']);
  };
  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: <IconSymbol icon="edit" />,
  };
  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Error Suspense Table.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          title: 'Download dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showGlobalFilter: false,
    showFilterDataset: false,
    individualActions: location?.state?.flag === 'N' ? [] : [updateLinkButton],
  });

  useEffect(() => {
    if (getTableState) {
      setTableCount(getTableState.filter.filteredRows.length);
    }
  }, [getTableState]);
  const isPolEntIdAvbl = async (polNbr) => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}tablemaintenance/ucas/isPolEntIdAvbl/${polNbr}`,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  // http://localhost:5000/tablemaintenance/ucas/isPolEntIdAvbl/:polNbr
  // http://localhost:5000/tablemaintenance/ucas/validPrdctCd/:prdctCd
  const validPrdctCd = async (prdctCd) => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}tablemaintenance/ucas/validPrdctCd/${prdctCd}`,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  // http://localhost:5000/tablemaintenance/ucas/validLegEntyCd/:paramCd/:queryType
  const validLegEntyCd = async (paramCd, queryType) => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}tablemaintenance/ucas/validLegEntyCd/${paramCd}/${queryType}`,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  // http://localhost:5000/tablemaintenance/ucas/validLegEntyCd3/:paramCd
  const validLegEntyCd3 = async (paramCd) => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}tablemaintenance/ucas/validLegEntyCd3/${paramCd}`,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  // http://localhost:5000/tablemaintenance/ucas/validSufxCd/:polEntId/:sufxCd
  const validSufxCd = async (polEntId, sufxCd) => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}tablemaintenance/ucas/validSufxCd/${polEntId}/${sufxCd}`,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };

  // http://localhost:5000/tablemaintenance/ucas/validCoCd/:coCd/:fndArngCd
  const validCoCd = async (coCd, fndArngCd) => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}tablemaintenance/ucas/validCoCd/${coCd}/${fndArngCd}`,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const validateSave = () => {
    let valid = true;
    //API call to validate the data.
    let polEntId: String = '';
    let mbrMajPrdctCd: string = '';
    let legEntCd: string = '';

    // HMO ACCT DIV CD code
    if (HMO_ACCT_DIV_CD.trim().toLocaleUpperCase().length === 0) {
      setHMO_ACCT_DIV_CD('    ');
    }
    setHMO_ACCTDIV_ADM_CD(HMO_ACCT_DIV_CD);
    // CO CD code.
    setCO_CD(CO_CD.toLocaleUpperCase());
    // Suffix Code.
    setSUFX_CD(SUFX_CD.toLocaleUpperCase());
    // Policy code.
    setPOL_NBR(POL_NBR.toLocaleUpperCase());
    if (POL_NBR && POL_NBR.length === 7 && POL_NBR.charAt(0) === '0') {
      isPolEntIdAvbl(POL_NBR).then((response) => {
        polEntId = response;
      });
    }
    // Product code.
    setPRDCT_CD(PRDCT_CD.toLocaleUpperCase());
    if (PRDCT_CD && PRDCT_CD.length === 3) {
      validPrdctCd(PRDCT_CD).then((response) => {
        if (response && response.length !== 0) {
          mbrMajPrdctCd = response[0].mbrMajPrdctCd;
        }
      });
    }
    if (mbrMajPrdctCd !== '' && mbrMajPrdctCd !== null) {
      setMAJ_PRDCT_CD(mbrMajPrdctCd);
    }
    // Legal Entity Code.
    if (mbrMajPrdctCd.trim() === 'HMO' || mbrMajPrdctCd.trim() === 'HMP') {
      validLegEntyCd(LEG_ENT_CD, '2').then((response) => {
        if (response && response.length !== 0) {
          legEntCd = response[0].legEntCd;
        }
        if (legEntCd !== '' && legEntCd !== null) {
          setLEG_ENT_CD(legEntCd);
        }
      });
    } else {
      validLegEntyCd(LEG_ENT_CD, '1').then((response) => {
        if (response && response.length !== 0) {
          legEntCd = response[0].legEntCd;
        }
        if (legEntCd !== '' && legEntCd !== null) {
          setLEG_ENT_CD(legEntCd);
        }
      });
    }
    // Legal Entity Code 3.
    if (legEntCd !== '' && legEntCd !== null) {
      validLegEntyCd3(legEntCd).then((response) => {
        if (response && response.length !== 0) {
          setOBLIGOR_ID(response[0].obligorId);
          setLEG_ENTY_GRP_CD(response[0].legEntyGrpCd);
        }
      });
    }
    // Start validation.
    if (TRANS_SRC_CD === '80') {
      setErrorAlert(true);
      setErrorAlertMessage(
        'You are not allowed to Update a row when TRANS_SRC_CD = 80'
      );
      valid = false;
    } else if (updateRow.sumDetInd === 'S ') {
      setErrorAlert(true);
      setErrorAlertMessage(
        'You are not allowed to Update a row when SUM_DET_IND = S'
      );

      valid = false;
    } else if (POL_NBR.length !== 7) {
      setErrorAlert(true);
      setErrorAlertMessage('Please enter a 7 byte value for POL_NBR');
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidPolNbr = true;
    } else if (POL_NBR.charAt(0) !== '0') {
      setErrorAlert(true);
      setErrorAlertMessage('Please enter a POL_NBR that starts 0');
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidPolNbr = true;
    } else if (!polEntId || polEntId === null || polEntId === '') {
      setErrorAlert(true);
      setErrorAlertMessage('POL_NBR not found on D5252FIN.UNET_POL_HIST table');
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidPolNbr = true;
    } else if (!validSufxCd(polEntId, SUFX_CD)) {
      setErrorAlert(true);
      setErrorAlertMessage(
        'SUFX_CD not found on D5252FIN.POL_SUFFX_HIST table'
      );
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidSufxCd = true;
    } else if (PRDCT_CD.length !== 3) {
      setErrorAlert(true);
      setErrorAlertMessage('Please enter a 3 byte value for PRDCT_CD');
      valid = false;

      // event.originalRecord.hideRecord = true;
      // this.invalidPrdctCd = true;
    } else if (mbrMajPrdctCd === null || mbrMajPrdctCd === '') {
      setErrorAlert(true);
      setErrorAlertMessage(
        'PRDCT_CD(UNET_PRDCT_CD) not found on D5065FIN.PRODUCT table'
      );
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidPrdctCd = true;
    } else if (CO_CD.trim().length === 0) {
      setErrorAlert(true);
      setErrorAlertMessage('Please enter a CO_CD that does contain all blanks');
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidcoCd = true;
    } else if (CO_CD.charAt(0) === ' ') {
      setErrorAlert(true);
      setErrorAlertMessage(
        'Please enter a CO_CD that does not start with blank'
      );
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidcoCd = true;
    } else if (!validCoCd(CO_CD, FUND_ARNG_CD)) {
      setErrorAlert(true);
      setErrorAlertMessage(
        'CO_CD not found on D5252FIN.CO_CD_TRANSLATION table'
      );
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidcoCd = true;
    } else if (
      (mbrMajPrdctCd.trim() === 'HMO' || mbrMajPrdctCd.trim() === 'HMP') &&
      !(CO_CD.trim() === '8' || CO_CD.trim() === '9')
    ) {
      setErrorAlert(true);
      setErrorAlertMessage(
        'MBR_MAJ_PRDCT_CD equals HMO or HMP - CO_CD must equal 8 or 9'
      );
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidcoCd = true;
    } else if (
      !(mbrMajPrdctCd.trim() === 'HMO' || mbrMajPrdctCd.trim() === 'HMP') &&
      (CO_CD.trim() === '8' || CO_CD.trim() === '9')
    ) {
      setErrorAlert(true);
      setErrorAlertMessage(
        'MBR_MAJ_PRDCT_CD does not equal HMO or HMP - CO_CD must not equal 8 or 9'
      );
      valid = false;
      // event.originalRecord.hideRecord = true;
      // this.invalidcoCd = true;
    } else if (
      HMO_ACCT_DIV_CD !== '   ' &&
      HMO_ACCT_DIV_CD.trim().length !== 3
    ) {
      setErrorAlert(true);
      setErrorAlertMessage('HMO_ACCT_DIV_CD must be 3 bytes or equal spaces');
      valid = false;

      // event.originalRecord.hideRecord = true;
      // this.invalidhmoAcctDivCd = true;
    } else if (
      (mbrMajPrdctCd.trim() === 'HMO' || mbrMajPrdctCd.trim() === 'HMP') &&
      HMO_ACCT_DIV_CD === '   '
    ) {
      setErrorAlert(true);
      setErrorAlertMessage(
        'HMO_ACCT_DIV_CD cannot be spaces when MAJ_PROD_CD equals HMO/HMP'
      );
      valid = false;

      // event.originalRecord.hideRecord = true;
      // this.invalidhmoAcctDivCd = true;
    }
    // } else if (
    //   (mbrMajPrdctCd.trim() === 'HMO' || mbrMajPrdctCd.trim() === 'HMP') &&
    //   !(await this.ucasService.validateHmoAcctDivCdError(
    //     event.updatedRecord.hmoAcctDivCd
    //   ))
    // ) {
    //   this.errMsg.content =
    //     'HMO_ACCTDIV_ADM_CD(HMO_ACCT_DIV_CD) not found on D5252FIN.HEALTH_PLAN_SITE table';
    //   this.errMsg.visible = true;
    //   event.originalRecord.hideRecord = true;
    //   this.invalidhmoAcctDivCd = true;
    // } else if (
    //   !(mbrMajPrdctCd.trim() === 'HMO' || mbrMajPrdctCd.trim() === 'HMP') &&
    //   event.updatedRecord.hmoAcctDivCd !== '   '
    // ) {
    //   this.errMsg.content =
    //     'HMO_ACCT_DIV_CD must be spaces when MAJ_PROD_CD not equal HMO/HMP';
    //   this.errMsg.visible = true;
    //   event.originalRecord.hideRecord = true;
    //   this.invalidhmoAcctDivCd = true;
    // } else if (
    //   (mbrMajPrdctCd.trim() === 'HMO' || mbrMajPrdctCd.trim() === 'HMP') &&
    //   (null === legEntCd || legEntCd === '')
    // ) {
    //   this.errMsg.content =
    //     'HMO_ACCTDIV_ADM_CD ' +
    //     event.updatedRecord.hmoAcctDivCd +
    //     'not  found on D5252FIN.HEALTH_PLAN_SITE table';
    //   this.errMsg.visible = true;
    //   event.originalRecord.hideRecord = true;
    //   this.invalidhmoAcctDivCd = true;
    // } else if (
    //   !(mbrMajPrdctCd.trim() === 'HMO' || mbrMajPrdctCd.trim() === 'HMP') &&
    //   (null === legEntCd || legEntCd === '')
    // ) {
    //   this.errMsg.content =
    //     'CO_CD ' +
    //     event.updatedRecord.coCd +
    //     ' not  found on D5252FIN.CO_CD_TRANSLATION table';
    //   this.errMsg.visible = true;
    //   event.originalRecord.hideRecord = true;
    //   this.invalidcoCd = true;
    // } else if (arrayLegEntCd.length === 0) {
    //   this.errMsg.content =
    //     'LEG_ENTY_CD ' +
    //     legEntCd +
    //     ' not  found on D5252FIN.LEGAL_ENTITY table';
    //   this.errMsg.visible = true;
    //   event.originalRecord.hideRecord = true;
    //   this.invalidcoCd = true;
    // } else if (
    //   !(
    //     event.updatedRecord.rrCd.length === 4 &&
    //     !isNaN(event.updatedRecord.rrCd)
    //   ) &&
    //   !(event.updatedRecord.rrCd === '    ')
    // ) {
    //   this.errMsg.content = 'RR_CD must be 4 Numeric bytes or equal spaces';
    //   this.errMsg.visible = true;
    //   event.originalRecord.hideRecord = true;
    //   this.invalidrrCd = true;
    // } else if (
    //   !(
    //     event.updatedRecord.rptCd.length === 4 &&
    //     !isNaN(event.updatedRecord.rptCd)
    //   ) &&
    //   !(event.updatedRecord.rptCd === '    ')
    // ) {
    //   this.errMsg.content = 'RPT_CD must be 4 Numeric bytes or equal spaces';
    //   this.errMsg.visible = true;
    //   event.originalRecord.hideRecord = true;
    //   this.invalidrptCd = true;
    // } else if (
    //   !(
    //     event.updatedRecord.prdctClssId.length === 5 &&
    //     !isNaN(event.updatedRecord.prdctClssId)
    //   ) &&
    //   !(event.updatedRecord.prdctClssId === '    ')
    // ) {
    //   this.errMsg.content = 'PRDCT_CLSS_ID must be 5 numeric bytes or spaces';
    //   this.errMsg.visible = true;
    //   event.originalRecord.hideRecord = true;
    //   this.invalidprdctClssId = true;
    // } else {
    //   this.confirmOriginal = event.originalRecord;
    //   this.confirmUpdated = event.updatedRecord;
    //   this.reviewDialogModal.show = true;
    // }
    return valid;
  };
  const update = async () => {
    const now = new Date();
  };
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  const handleClick = () => {
    navigate('/ftmf/ucas');
  };
  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        {/* <Modal
          title="Confirm Update"
          isOpen={updateModal}
          onClose={() => setUpdateModal(false)}
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market Seg Ind:</b>
                {updateRow['FINL_MKT_SEG_IND']}
              </span>
              <span>
                <b>Market Seg Ind: </b>
                {marketSegInd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market Seg Txt:</b>
                {updateRow['FINL_MKT_SEG_TXT']}
              </span>
              <span>
                <b>Market Seg Txt: </b>
                {marketSegTxt.toLocaleUpperCase()}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>FMSI Type Cd:</b>
                {updateRow['FMSI_TYPE_CD']}
              </span>
              <span>
                <b>FMSI Type Cd: </b>
                {FmsiTypeCd.toLocaleUpperCase()}
              </span>
            </div>
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal> */}
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{ marginTop: '135px', marginLeft: '12px' }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Database:
                  </Text>
                  <span>
                    {getEnvironment()} - {location?.state?.database}
                  </span>
                  <Text
                    css={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: '20px',
                    }}
                  >
                    Table:
                  </Text>
                  <span>ERROR SUSPENSE TABLE</span>
                  <br />
                  <span>{tableCount} Records</span>
                </Grid.Col>
                <Alert
                  title={errorAlertMessage}
                  variant="error"
                  isVisible={errorAlert}
                  onClose={() => setErrorAlert(false)}
                />
                {flag && (
                  <>
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                      }}
                    >
                      <Grid>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="ORIG_SCHED_BK_DT"
                            value={ORIG_SCHED_BK_DT}
                            onChange={(e) =>
                              setORIG_SCHED_BK_DT(e.target.value)
                            }
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="SRS_DESG_CD"
                            value={SRS_DESG_CD}
                            onChange={(e) => setSRS_DESG_CD(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="CLM_PAY_SEQ_NBRD"
                            value={CLM_PAY_SEQ_NBRD}
                            onChange={(e) =>
                              setCLM_PAY_SEQ_NBRD(e.target.value)
                            }
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="FUND_ARNG_CD"
                            value={FUND_ARNG_CD}
                            onChange={(e) => setFUND_ARNG_CD(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid
                        css={{
                          margin: '0px !important',
                          marginTop: '20px !important',
                        }}
                      >
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="TRANS_SRC_CD"
                            value={TRANS_SRC_CD}
                            onChange={(e) => setTRANS_SRC_CD(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="ERR_PAY_SEQ_NBR"
                            value={ERR_PAY_SEQ_NBR}
                            onChange={(e) => setERR_PAY_SEQ_NBR(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="FUND_ARNG_GRP_CD"
                            value={FUND_ARNG_GRP_CD}
                            onChange={(e) =>
                              setFUND_ARNG_GRP_CD(e.target.value)
                            }
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="ERR_TRANS_ID"
                            value={ERR_TRANS_ID}
                            onChange={(e) => setERR_TRANS_ID(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid
                        css={{
                          margin: '0px !important',
                          marginTop: '20px !important',
                        }}
                      >
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="LEG_ENT_CD"
                            value={LEG_ENT_CD}
                            onChange={(e) => setLEG_ENT_CD(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="OBLIGOR_ID"
                            value={OBLIGOR_ID}
                            onChange={(e) => setOBLIGOR_ID(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="LEG_ENTY_GRP_CD"
                            value={LEG_ENTY_GRP_CD}
                            onChange={(e) => setLEG_ENTY_GRP_CD(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="MAJ_PRDCT_CD"
                            value={MAJ_PRDCT_CD}
                            onChange={(e) => setMAJ_PRDCT_CD(e.target.value)}
                            isDisabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="HMO_ACCTDIV_ADM_CD"
                            value={HMO_ACCTDIV_ADM_CD}
                            onChange={(e) =>
                              setHMO_ACCTDIV_ADM_CD(e.target.value)
                            }
                            isDisabled={true}
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid
                        css={{
                          margin: '0px !important',
                          marginTop: '20px !important',
                        }}
                      >
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="POL_NBR"
                            value={POL_NBR}
                            onChange={(e) => setPOL_NBR(e.target.value)}
                            maxLength={7}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="PRDCT_CD"
                            value={PRDCT_CD}
                            onChange={(e) => setPRDCT_CD(e.target.value)}
                            maxLength={3}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="RR_CD"
                            value={RR_CD}
                            onChange={(e) => setRR_CD(e.target.value)}
                            maxLength={4}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="PRDCT_CLSS_ID"
                            value={PRDCT_CLSS_ID}
                            onChange={(e) => setPRDCT_CLSS_ID(e.target.value)}
                            maxLength={5}
                          />
                        </Grid.Col>
                      </Grid>

                      <Grid
                        css={{
                          margin: '0px !important',
                          marginTop: '20px !important',
                        }}
                      >
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="SUFX_CD"
                            value={SUFX_CD}
                            onChange={(e) => setSUFX_CD(e.target.value)}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="HMO_ACCT_DIV_CD"
                            value={HMO_ACCT_DIV_CD}
                            onChange={(e) => setHMO_ACCT_DIV_CD(e.target.value)}
                            maxLength={3}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="RPT_CD"
                            value={RPT_CD}
                            onChange={(e) => setRPT_CD(e.target.value)}
                            maxLength={4}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="LS_DT"
                            value={LS_DT}
                            onChange={(e) => setLS_DT(e.target.value)}
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid
                        css={{
                          margin: '0px !important',
                          marginTop: '20px !important',
                        }}
                      >
                        {' '}
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="CO_CD"
                            value={CO_CD}
                            onChange={(e) => setCO_CD(e.target.value)}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 3 }}>
                          <TextInput
                            label="SRVC_DT"
                            value={SRVC_DT}
                            onChange={(e) => setSRVC_DT(e.target.value)}
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid
                        css={{
                          margin: '0px !important',
                          marginTop: '20px !important',
                        }}
                      >
                        <Grid.Col span={{ xs: 12 }}></Grid.Col>

                        <Grid.Col span={{ xs: 4 }}>
                          <Button
                            onClick={
                              updateFlag ? () => setUpdateModal(true) : ''
                            }
                          >
                            Save
                          </Button>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 4 }}>
                          <Button onClick={close}>Cancel</Button>
                        </Grid.Col>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title=""
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'left',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(UcasSuspErrorTable, 'table_ucas_ro_rw');
