import React, { useState, useEffect } from 'react';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import axios from 'axios';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import withAuth from '@src/auth/withAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Alert } from '@abyss/web/ui/Alert';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import env from '../../../env.json';

const UcasEscp = () => {
  const { token, readOnly, userRolesGroup } = useAuth();
  const { navigate, getLocation } = useRouter();
  const [radioValue, setRadioValue] = useState('single');
  const [seresDesg, setSeresDesg] = useState('');
  const [seresDesgErr, setSeresDesgErr] = useState('');
  const [batchNumb, setBatchNumb] = useState('');
  const [batchNumbErr, setBatchNumbErr] = useState('');
  const [checkNbr, setCheckNbr] = useState('');
  const [checkNbrErr, setCheckNbrErr] = useState('');
  const [batchDt, setBatchDt] = useState('');
  const [batchDtErr, setBatchDtErr] = useState('');
  const [cps, setCps] = useState('');
  const [cpsErr, setCpsErr] = useState('');
  const [srcCode, setSrcCode] = useState('');
  const [srcCodeErr, setSrcCodeErr] = useState('');
  const [flag, setFlag] = useState(false);
  const location = getLocation();
  const [stateValue, setStateValue] = useState(location?.state);
  const [trnNonBookCds, setTrnNonBookCds] = useState('');
  const [trnBatchSeqNbr, setTrnBatchSeqNbr] = useState('');
  const [trnBatchNbr, settrnBatchNbr] = useState('');
  const [trnBatchdt, settrnBatchdt] = useState('');
  const [trnPolNbrExp, settrnPolNbrExp] = useState('');
  const [trnSuffix, settrnSuffix] = useState('');
  const [trnAcct, settrnAcct] = useState('');
  const [trnSersDesg2, settrnSersDesg2] = useState('');
  const [trnScndrySd, settrnScndrySd] = useState('');
  const [trnAcctDiv, settrnAcctDiv] = useState('');
  const [trnFundInd, settrnFundInd] = useState('');
  const [trnFormNbr, settrnFormNbr] = useState('');
  const [trnTypeDocInd, settrnTypeDocInd] = useState('');
  const [trnCorrErrCd, settrnCorrErrCd] = useState('');
  const [trnActionCd, settrnActionCd] = useState('');
  const [trnFeeInd, settrnFeeInd] = useState('');
  const [trnSpecCd, settrnSpecCd] = useState('');
  const [trnIssueDt, settrnIssueDt] = useState('');
  const [trnChargeDt, settrnChargeDt] = useState('');
  const [trnCheckAmt9, settrnCheckAmt9] = useState('');
  const [trnOptOutInd, settrnOptOutInd] = useState('');
  const [trnSummDetInd, settrnSummDetInd] = useState('');
  const [trnMtchcntrlNbr, settrnMtchcntrlNbr] = useState('');
  const [trnPolHldrName, settrnPolHldrName] = useState('');
  const [trnPayeeName, settrnPayeeName] = useState('');
  const [trnEmployName, settrnEmployName] = useState('');
  const [trnSourceCd, settrnSourceCd] = useState('');
  const [trnCheckNbr, settrnCheckNbr] = useState('');
  const [clmPaySeqNbr, setclmPaySeqNbr] = useState('');
  const [trnPolProdCd, settrnPolProdCd] = useState('');
  const [trnDtOfServ, settrnDtOfServ] = useState('');
  const [editAction, setEditAction] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [sublineData, setSublineData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [records, setRecords] = useState('0 records');
  const [sublinerecords, setSublineRecords] = useState('0 records');
  const [action, setAction] = useState('R');
  const [sublineFlag, setSublineFlag] = useState(false);
  const [subTypeCD, setSubTypeCD] = useState('');
  const [sublineFromDate, setSublineFromDate] = useState('');
  const [sublineToDate, setSublineToDate] = useState('');
  const [sublineAmount, setSublineAmount] = useState('');
  const [sublineScmpSubInd, setSublineScmpSubInd] = useState('');
  const [sublinePpd, setSublinePpd] = useState('');
  const [sublineTotalAmt, setSublineTotalAmt] = useState('');
  const [sublineChckAmt, setSublineChckAmt] = useState('');
  const [sublineAlert, setSublineAlert] = useState(false);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  useEffect(() => {
    if (location?.state?.data) {
      setStateValue(location?.state);
    }
  }, [location?.state]);

  function formatDate(inputDate) {
    let dateStr = inputDate?.toString();
    const month = dateStr?.substring(0, 2);
    const day = dateStr?.substring(2, 4);
    const year = dateStr?.substring(4, 8);
    return `${month}/${day}/${year}`;
  }

  function formateChargeDate(inputDate) {
    let dateStr = inputDate?.toString();
    const year = dateStr?.substring(0, 4);
    const month = dateStr?.substring(4, 6);
    const day = dateStr?.substring(6, 8);
    return `${month}/${day}/${year}`;
  }

  //get column definition
  const save = async () => {
    const now = new Date();
    setFlag(false);

    setTrnNonBookCds('');
    setTrnBatchSeqNbr('');
    settrnBatchdt('');
    settrnBatchNbr('');
    settrnPolNbrExp('');
    settrnSuffix('');
    settrnAcct('');
    settrnSersDesg2('');
    settrnScndrySd('');
    settrnAcctDiv('');
    settrnFundInd('');
    settrnFormNbr('');
    settrnTypeDocInd('');
    settrnCorrErrCd('');
    settrnActionCd('');
    settrnFeeInd('');
    settrnSpecCd('');
    settrnIssueDt('');
    settrnChargeDt('');
    settrnCheckAmt9('');
    settrnPolProdCd('');
    settrnOptOutInd('');
    settrnDtOfServ('');
    settrnSummDetInd('');
    settrnMtchcntrlNbr('');
    settrnPolHldrName('');
    settrnPayeeName('');
    settrnEmployName('');
    settrnSourceCd('');
    settrnCheckNbr('');
    setclmPaySeqNbr('');
  };
  const close = () => {
    if (sublineFlag) {
      setSublineAlert(true);
    } else {
      setFlag(false);
      setTrnNonBookCds('');
      setTrnBatchSeqNbr('');
      settrnBatchdt('');
      settrnBatchNbr('');
      settrnPolNbrExp('');
      settrnSuffix('');
      settrnAcct('');
      settrnSersDesg2('');
      settrnScndrySd('');
      settrnAcctDiv('');
      settrnFundInd('');
      settrnFormNbr('');
      settrnTypeDocInd('');
      settrnCorrErrCd('');
      settrnActionCd('');
      settrnFeeInd('');
      settrnSpecCd('');
      settrnIssueDt('');
      settrnChargeDt('');
      settrnCheckAmt9('');
      settrnPolProdCd('');
      settrnOptOutInd('');
      settrnDtOfServ('');
      settrnSummDetInd('');
      settrnMtchcntrlNbr('');
      settrnPolHldrName('');
      settrnPayeeName('');
      settrnEmployName('');
      settrnSourceCd('');
      settrnCheckNbr('');
      setclmPaySeqNbr('');
      setEditAction(false);
      getTableStateSubline.setData([]);
    }
  };

  const sublineSave = async () => {
    setSublineFlag(false);
    setSublineFlag(false);
    setSubTypeCD('');
    setSublineFromDate('');
    setSublineToDate('');
    setSublineAmount('');
    setSublineScmpSubInd('');
    setSublinePpd('');
    setSublineTotalAmt('');
    setSublineChckAmt('');
  };

  const sublineClose = () => {
    setSublineFlag(false);
    setSubTypeCD('');
    setSublineFromDate('');
    setSublineToDate('');
    setSublineAmount('');
    setSublineScmpSubInd('');
    setSublinePpd('');
    setSublineTotalAmt('');
    setSublineChckAmt('');
  };

  const columns = React.useMemo(
    () => [
      { Header: 'ERROR CODES', accessor: 'trnNonBookCds' },
      { Header: 'BATCH SEQ #', accessor: 'trnBatchSeqNbr' },
      { Header: 'BATCH DT', accessor: 'trnBatchdt' },
      { Header: 'BATCH NBR', accessor: 'trnBatchNbr' },
      { Header: 'POLICY NBR', accessor: 'trnPolNbrExp' },
      { Header: 'SUFFIX', accessor: 'trnSuffix' },
      { Header: 'ACCOUNT NBR', accessor: 'trnAcct' },
      { Header: 'SER.DES SD', accessor: 'trnSersDesg2' },
      { Header: 'BULK ACCT SD', accessor: 'trnScndrySd' },
      { Header: 'HMO DIV', accessor: 'trnAcctDiv' },
      { Header: 'FUND CODE', accessor: 'trnFundInd' },
      { Header: 'FORM CODE', accessor: 'trnFormNbr' },
      { Header: 'TYPE CODE', accessor: 'trnTypeDocInd' },
      { Header: 'ERR CODE', accessor: 'trnCorrErrCd' },
      { Header: 'ACTION CD', accessor: 'trnActionCd' },
      { Header: 'FEE CODE', accessor: 'trnFeeInd' },
      { Header: 'SPEC CODE', accessor: 'trnSpecCd' },
      { Header: 'ISSUE DATE', accessor: 'trnIssueDt' },
      { Header: 'CHARGE DATE', accessor: 'trnChargeDt' },
      { Header: 'CHECK AMOUNT', accessor: 'trnCheckAmt9' },
      { Header: 'PROD CODE', accessor: 'trnPolProdCd' },
      { Header: 'OPT OUT IND', accessor: 'trnOptOutInd' },
      { Header: 'DATE OF SERVICE', accessor: 'trnDtOfServ' },
      { Header: 'SUM TYPE', accessor: 'trnSummDetInd' },
      { Header: 'MATCH CONTROL', accessor: 'trnMtchcntrlNbr' },
      { Header: 'MEM FIRST NAME', accessor: 'trnPolHldrName' },
      { Header: 'PAYEE NAME', accessor: 'trnPayeeName' },
      { Header: 'EMPLOYEE NAME', accessor: 'trnEmployName' },
      { Header: 'SRC CODE', accessor: 'trnSourceCd' },
      { Header: 'CHECK NBR', accessor: 'trnCheckNbr' },
      { Header: 'CL-PAY-SEQ-NBR', accessor: 'clmPaySeqNbr' },
    ],
    []
  );
  const checkAlphaNumeric = (str) => {
    var letters = /^[0-9a-zA-Z]+$/;
    if (str.match(letters)) return true;
    else return false;
  };
  const getSubLine = async (row) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload = {
      flag: radioValue === 'single' ? 1 : 2,
      serisDesg: seresDesg,
      chkNbr: checkNbr,
      clPaySeqNbr: cps,
      batchDate: batchDt,
      srcCode: srcCode,
      batchNumb: batchNumb,
      selectedAction: action,
      dtSeqNbr: row['trnDtSeqNbr'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}ucas/cfrErrTrans/getSublineData/${stateValue?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        if (response.data !== 'Not available') {
          getTableStateSubline.setData(response.data, true);
          setSublineData(response.data);
        }
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const updateRows = async (row) => {
    setFlag(true);
    await getSubLine(row);

    setTrnNonBookCds(row['trnNonBookCds']);
    setTrnBatchSeqNbr(row['trnBatchSeqNbr']);
    settrnBatchdt(row['trnBatchdt']);
    settrnBatchNbr(row['trnBatchNbr']);
    settrnPolNbrExp(row['trnPolNbrExp']);
    settrnSuffix(row['trnSuffix']);
    settrnAcct(row['trnAcct']);
    settrnSersDesg2(row['trnSersDesg2']);
    settrnScndrySd(row['trnScndrySd']);
    settrnAcctDiv(row['trnAcctDiv']);
    settrnFundInd(row['trnFundInd']);
    settrnFormNbr(row['trnFormNbr']);
    settrnTypeDocInd(row['trnTypeDocInd']);
    settrnCorrErrCd(row['trnCorrErrCd']);
    settrnActionCd(row['trnActionCd']);
    settrnFeeInd(row['trnFeeInd']);
    settrnSpecCd(row['trnSpecCd(']);
    settrnIssueDt(formatDate(row['trnIssueDt']));
    settrnChargeDt(formateChargeDate(row['trnChargeDt']));
    settrnCheckAmt9(row['trnCheckAmt9']);
    settrnPolProdCd(row['trnPolProdCd']);
    settrnOptOutInd(row['trnOptOutInd']);
    settrnDtOfServ(formatDate(row['trnDtOfServ']));
    settrnSummDetInd(row['trnSummDetInd']);
    settrnMtchcntrlNbr(row['trnMtchcntrlNbr']);
    settrnPolHldrName(row['trnPolHldrName']);
    settrnPayeeName(row['trnPayeeName']);
    settrnEmployName(row['trnEmployName']);
    settrnSourceCd(row['trnSourceCd']);
    settrnCheckNbr(row['trnCheckNbr']);
    setclmPaySeqNbr(row['clmPaySeqNbr']);
  };

  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
      setEditAction(true);
    },
    label: <IconSymbol icon="edit" />,
  };

  const updateSublineRows = async (row) => {
    setSublineFlag(true);
    await getSubLine(row);
    setSubTypeCD(row['subSubline']);
    setSublineFromDate(row['subFromDt']);
    setSublineToDate(row['subToDt']);
    setSublineAmount(row['subSublineAmt9']);
    setSublineScmpSubInd(row['subScmpSubInd']);
    setSublinePpd(row['subPpd']);
    setSublineTotalAmt(row['subSublineAmt9']);
    setSublineChckAmt(row['subSublineAmt9']);
  };

  const updateLinkSublineButton = {
    onClick: ({ updateRow, row }) => {
      updateSublineRows(row['original']);
      setEditAction(true);
    },
    label: <IconSymbol icon="edit" />,
  };

  const validate = () => {
    let valid = true;
    if (radioValue === 'single') {
      if (seresDesg == undefined || seresDesg == '') {
        setSeresDesgErr('SERS DESG IS REQUIRED');
        valid = false;
      }
      if (checkNbr == undefined || checkNbr == '') {
        setCheckNbrErr('CHECK NBR IS REQUIRED');
        valid = false;
      }
      if (!checkAlphaNumeric(seresDesg)) {
        setSeresDesgErr('SERIES DESIGNATOR MISSING OR INVALID');
        valid = false;
      }

      if (checkNbr !== undefined) {
        if (isNaN(Number(checkNbr))) {
          setCheckNbrErr('CHECK NBR SHOULD BE NUMERIC');
          valid = false;
        }
      }

      if (cps !== undefined) {
        if (isNaN(Number(cps))) {
          setCpsErr('CL-PAY-SEQ-NBR SHOULD BE NUMERIC');
          valid = false;
        }
      }
    } else {
      if (batchNumb == undefined || batchNumb == '') {
        setBatchNumbErr('BATCH-NUMB IS REQUIRED');
        valid = false;
      }

      if (batchDt == undefined || batchDt == '') {
        setBatchDt('BATCH-DT IS REQUIRED');
        valid = false;
      }
      if (srcCode == undefined || srcCode == '') {
        setSrcCodeErr('SRC-CODE IS REQUIRED');
        valid = false;
      }
      if (isNaN(Number(batchDt))) {
        setBatchDtErr('BATCH-DT IS INVALID');
        valid = false;
      }

      if (!checkAlphaNumeric(batchDt)) {
        setBatchDt('BATCH-DT MISSING OR INVALID');
        valid = false;
      }

      if (batchDt.trim() == '' || batchNumb.trim() == '') {
        setBatchDtErr('SELECTION CRITERIA IS MISSING');
        setBatchNumbErr('SELECTION CRITERIA IS MISSING');
        valid = false;
      }

      if (!checkAlphaNumeric(batchNumb)) {
        setBatchNumbErr('BATCH NUMBER MISSING OR INVALID');
        valid = false;
      }

      if (srcCode !== undefined) {
        if (isNaN(Number(srcCode))) {
          setSrcCodeErr('SOURCE CODE SHOULD BE NUMERIC');
          valid = false;
        }
      }
    }
    return valid;
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'escp.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'escp.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: responseData,
    initialColumns: columns,

    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions: [updateLinkButton],
  });
  const columnsSubline = React.useMemo(
    () => [
      { Header: 'SUB TYPE CD', accessor: 'subSubline' },
      { Header: 'FROM DATE', accessor: 'subFromDt' },
      { Header: 'TO DATE', accessor: 'subToDt' },
      { Header: 'AMOUNT', accessor: 'subSublineAmt9' },
      { Header: 'SCMP SUB IND', accessor: 'subScmpSubInd' },
      { Header: 'SUBLINE PPD', accessor: 'subPpd' },
    ],
    []
  );
  // const downloadSublineExcel = () => {
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.json_to_sheet(sublineData);
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   XLSX.writeFile(workbook, 'escp.xlsx');
  // };

  const getHeadersForEmptyDataSubline = () => {
    let columnNamesSubline = {};
    if (Array.isArray(columnsSubline)) {
      columnsSubline.forEach((column) => {
        columnNamesSubline[column.Header] = null;
      });
    }
    return columnNamesSubline;
  };

  let columnNameMappingSubline = {};
  columnsSubline.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcelSubline = () => {
    let filteredData = [];
    getTableStateSubline.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMappingSubline[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyDataSubline());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'subline.xlsx');
  };

  const downloadExcelSubline = () => {
    let fullData = [];
    getTableStateSubline?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMappingSubline[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyDataSubline());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'subline.xlsx');
  };

  const getTableStateSubline = useDataTable({
    showPagination: true,
    initialData: sublineData,
    initialColumns: columnsSubline,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcelSubline,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcelSubline,
        },
      ],
    },

    showFilterDataset: true,
    individualActions: [updateLinkSublineButton],
  });
  const handleClick = () => {
    navigate('/ftmf/ucas');
  };
  const clearErrors = () => {
    setSeresDesgErr('');
    setBatchNumbErr('');
    setCheckNbrErr('');
    setBatchDtErr('');
    setCpsErr('');
    setSrcCodeErr('');
  };
  function trnErrCdEncode(org) {
    var upd = '';
    var j = 0;
    for (var i = 0; i < org.length; i++) {
      if (org.charAt(i) == '1') {
        j = i + 1;
        upd = upd + j + ' ';
      }
    }
    return upd;
  }
  const lookup = async () => {
    clearErrors();
    if (validate()) {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let payload = {
        flag: radioValue === 'single' ? 1 : 2,
        serisDesg: seresDesg.toUpperCase(),
        chkNbr: checkNbr,
        clPaySeqNbr: cps,
        batchDate: batchDt,
        srcCode: srcCode,
        batchNumb: batchNumb,
      };
      try {
        const response = await axios.post(
          `${BASE_URL}ucas/cfrErrTrans/getTableData/${stateValue?.database}`,
          payload,
          config
        );
        // if (response.status === 200) {
        if (response.data !== 'Not available') {
          const modifiedResponse = response.data.map((item) => {
            return {
              ...item,
              trnNonBookCds: trnErrCdEncode(item.trnNonBookCds),
            };
          });
          getTableState.setData(modifiedResponse, true);
          setResponseData(response.data);
        } else {
          setShowAlert(true);
          getTableState.setData([], false);
        }

        //}
      } catch (error) {
        // add-alert-popup
      }
    }
  };

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);

  useEffect(() => {
    if (getTableStateSubline.filter?.filteredRows?.length == 1) {
      setSublineRecords(
        `${getTableStateSubline.filter?.filteredRows?.length} record`
      );
    } else {
      setSublineRecords(
        `${getTableStateSubline.filter?.filteredRows?.length} records`
      );
    }
  }, [getTableStateSubline?.filter?.filteredRows]);

  useEffect(() => {
    const hasTableCTSReadWriteAccess = env.Table_UCAS_4_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    if (!hasTableCTSReadWriteAccess && userRolesGroup.length > 0) {
      navigate('/ftmf/ucas');
    }
  }, [userRolesGroup]);

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{ marginTop: '135px', marginLeft: '12px' }}
              >
                Transaction Correction Screen
              </Heading>
              <Card
                css={{
                  width: '50%',
                  border: 'none',
                }}
              >
                {showAlert ? (
                  <Alert
                    variant="error"
                    title="NO RECORDS FOUND FOR SELECTION CRITERIA"
                    isVisible={showAlert}
                    onClose={() => {
                      setShowAlert(false);
                    }}
                  />
                ) : null}
              </Card>

              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <RadioGroup
                    label="Selection:"
                    onChange={(e) => setRadioValue(e.target.value)}
                    value={radioValue}
                    isDisabled={editAction}
                  >
                    <RadioGroup.Radio label="Single Selection" value="single" />
                    <RadioGroup.Radio
                      label="Multiple Selection"
                      value="multiple"
                    />
                  </RadioGroup>
                </Grid.Col>
                <Grid>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="SERS DESG"
                      value={seresDesg}
                      onChange={(e) => setSeresDesg(e.target.value)}
                      errorMessage={seresDesgErr}
                      isDisabled={radioValue === 'multiple'}
                      maxLength={2}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          lookup(event);
                        }
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="BATCH-NUMB"
                      value={batchNumb}
                      onChange={(e) => setBatchNumb(e.target.value)}
                      errorMessage={batchNumbErr}
                      isDisabled={radioValue === 'single'}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          lookup(event);
                        }
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="CHECK NBR"
                      value={checkNbr}
                      onChange={(e) => setCheckNbr(e.target.value)}
                      errorMessage={checkNbrErr}
                      isDisabled={radioValue === 'multiple'}
                      maxLength={8}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          lookup(event);
                        }
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="BATCH-DT"
                      value={batchDt}
                      onChange={(e) => setBatchDt(e.target.value)}
                      errorMessage={batchDtErr}
                      isDisabled={radioValue === 'single'}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          lookup(event);
                        }
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="CL-PAY-SEQ-NBR"
                      value={cps}
                      onChange={(e) => setCps(e.target.value)}
                      errorMessage={cpsErr}
                      isDisabled={radioValue === 'multiple'}
                      maxLength={8}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          lookup(event);
                        }
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="SRC-CODE"
                      value={srcCode}
                      onChange={(e) => setSrcCode(e.target.value)}
                      errorMessage={srcCodeErr}
                      isDisabled={radioValue === 'single'}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          lookup(event);
                        }
                      }}
                    />
                  </Grid.Col>

                  <Grid.Col span={{ xs: 4 }}>
                    <Button onClick={lookup} isDisabled={editAction}>
                      Look up
                    </Button>
                  </Grid.Col>
                </Grid>
                {flag && (
                  <>
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                        marginTop: '20px',
                      }}
                    >
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ERROR CODES"
                          value={trnNonBookCds}
                          onChange={(e) => setTrnNonBookCds(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="BATCH SEQ #"
                          value={trnBatchSeqNbr}
                          onChange={(e) => setTrnBatchSeqNbr(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="BATCH DT"
                          value={trnBatchdt}
                          onChange={(e) => settrnBatchdt(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="BATCH NBR"
                          value={trnBatchNbr}
                          onChange={(e) => settrnBatchNbr(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="POLICY NBR"
                          value={trnPolNbrExp}
                          onChange={(e) => settrnPolNbrExp(e.target.value)}
                          maxLength={7}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SUFFIX"
                          value={trnSuffix}
                          onChange={(e) => settrnSuffix(e.target.value)}
                          maxLength={2}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ACCOUNT NBR"
                          value={trnAcct}
                          onChange={(e) => settrnAcct(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SER.DES SD"
                          value={trnSersDesg2}
                          onChange={(e) => settrnSersDesg2(e.target.value)}
                          maxLength={2}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="BULK ACCT SD"
                          value={trnScndrySd}
                          onChange={(e) => settrnScndrySd(e.target.value)}
                          maxLength={2}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HMO DIV"
                          value={trnAcctDiv}
                          onChange={(e) => settrnAcctDiv(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FUND CODE"
                          value={trnFundInd}
                          onChange={(e) => settrnFundInd(e.target.value)}
                          maxLength={0}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FORM CODE"
                          value={trnFormNbr}
                          onChange={(e) => settrnFormNbr(e.target.value)}
                          maxLength={3}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="TYPE CODE"
                          value={trnTypeDocInd}
                          onChange={(e) => settrnTypeDocInd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ERR CODE"
                          value={trnCorrErrCd}
                          onChange={(e) => settrnCorrErrCd(e.target.value)}
                          maxLength={0}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ACTION CD"
                          value={trnActionCd}
                          onChange={(e) => settrnActionCd(e.target.value)}
                          maxLength={3}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FEE CODE"
                          value={trnFeeInd}
                          onChange={(e) => settrnFeeInd(e.target.value)}
                          maxLength={0}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SPEC CODE"
                          value={trnSpecCd}
                          onChange={(e) => settrnSpecCd(e.target.value)}
                          maxLength={4}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        {/* <TextInput
                          label="ISSUE DATE"
                          value={trnIssueDt}
                          onChange={(e) => settrnIssueDt(e.target.value)}
                        /> */}
                        <DateInput
                          label="ISSUE DATE"
                          value={trnIssueDt}
                          onChange={(date) => settrnIssueDt(date)}
                          onClear={() => settrnIssueDt('')}
                          subText=""
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        {/* <TextInput
                          label="CHARGE DATE"
                          value={trnChargeDt}
                          onChange={(e) => settrnChargeDt(e.target.value)}
                        /> */}{' '}
                        <DateInput
                          label="CHARGE DATE"
                          value={trnChargeDt}
                          onChange={settrnChargeDt}
                          subText=""
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="CHECK AMOUNT"
                          value={trnCheckAmt9}
                          onChange={(e) => settrnCheckAmt9(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="PROD CODE"
                          value={trnPolProdCd}
                          onChange={(e) => settrnPolProdCd(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="OPT OUT IND"
                          value={trnOptOutInd}
                          onChange={(e) => settrnOptOutInd(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        {/* <TextInput
                          label="DATE OF SERVICE"
                          value={trnDtOfServ}
                          onChange={(e) => settrnDtOfServ(e.target.value)}
                        /> */}
                        <DateInput
                          label="DATE OF SERVICE"
                          value={trnDtOfServ}
                          onChange={settrnDtOfServ}
                          onClear={() => settrnDtOfServ('')}
                          subText=""
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SUM TYPE"
                          value={trnSummDetInd}
                          onChange={(e) => settrnSummDetInd(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="MATCH CONTROL"
                          value={trnMtchcntrlNbr}
                          onChange={(e) => settrnMtchcntrlNbr(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="MEM FIRST NAME"
                          value={trnPolHldrName}
                          onChange={(e) => settrnPolHldrName(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="PAYEE NAME"
                          value={trnPayeeName}
                          onChange={(e) => settrnPayeeName(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="EMPLOYEE NAME"
                          value={trnEmployName}
                          onChange={(e) => settrnEmployName(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SRC CODE"
                          value={trnSourceCd}
                          onChange={(e) => settrnSourceCd(e.target.value)}
                          maxLength={6}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="CHECK NBR"
                          value={trnCheckNbr}
                          onChange={(e) => settrnCheckNbr(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="'CL-PAY-SEQ-NBR"
                          value={clmPaySeqNbr}
                          onChange={(e) => setclmPaySeqNbr(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                        <SelectInput
                          label="Action"
                          placeholder="Pick one"
                          isClearable
                          isSearchable
                          options={[
                            { id: 1, label: 'R', value: 'R' },
                            { id: 2, label: 'D', value: 'D' },
                            { id: 3, label: 'U', value: 'U' },
                          ]}
                          isDisabled={readOnly}
                          value={action}
                          onChange={(value) => setAction(value)}
                        />
                      </Grid.Col>

                      {/* <Grid.Col span={{ xs: 1 }}>
                        <Button onClick={save}>Save</Button>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }}>
                        <Button onClick={close}>Cancel</Button>
                      </Grid.Col> */}
                    </Grid>
                    <Flex
                      direction="row"
                      justify="center"
                      style={{ margin: '20px' }}
                    >
                      <Button onClick={save}>Save</Button>

                      <Button onClick={close} style={{ marginLeft: '20px' }}>
                        Cancel
                      </Button>
                    </Flex>
                  </>
                )}
                <Card
                  css={{
                    width: '50%',
                    border: 'none',
                  }}
                >
                  {sublineAlert ? (
                    <Alert
                      variant="error"
                      title="Close the subline transaction"
                      isVisible={sublineAlert}
                      onClose={() => {
                        setSublineAlert(false);
                      }}
                    />
                  ) : null}
                </Card>

                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={300} css={{ fontSize: '14px' }}>
                      {records}
                    </Text>
                  </Grid.Col>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title=""
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'left',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
                <Label size="md" style={{ marginLeft: '12px' }}>
                  Subline Records:
                </Label>

                {sublineFlag && (
                  <>
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                        marginTop: '20px',
                      }}
                    >
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SUB-TYPE-CD"
                          value={subTypeCD}
                          onChange={(e) => setSubTypeCD(e.target.value)}
                        />
                      </Grid.Col>

                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FROM DATE"
                          value={sublineFromDate}
                          onChange={(e) => setSublineFromDate(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="TO DATE"
                          value={sublineToDate}
                          onChange={(e) => setSublineToDate(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="AMOUNT"
                          value={sublineAmount}
                          onChange={(e) => setSublineAmount(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SCMP-SUB-IND"
                          value={sublineScmpSubInd}
                          onChange={(e) => setSublineScmpSubInd(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SUBLINE PPD"
                          value={sublinePpd}
                          onChange={(e) => setSublinePpd(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>

                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SUBLINE TOTAL AMOUNT"
                          value={sublineTotalAmt}
                          onChange={(e) => setSublineTotalAmt(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="CHECK AMOUNT"
                          value={sublineChckAmt}
                          onChange={(e) => setSublineChckAmt(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                    </Grid>
                    <Flex
                      direction="row"
                      justify="center"
                      style={{ margin: '20px' }}
                    >
                      <Button onClick={sublineSave}>Save</Button>

                      <Button
                        onClick={sublineClose}
                        style={{ marginLeft: '20px' }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </>
                )}

                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={300} css={{ fontSize: '14px' }}>
                      {sublinerecords}
                    </Text>
                  </Grid.Col>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title=""
                      tableState={getTableStateSubline}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(UcasEscp, 'table_ucas_ro_rw');
