import React, { useState, useEffect } from 'react';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Modal } from '@abyss/web/ui/Modal';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import { getEnvironment } from '@src/utils/environmentUtil';
import { TextInput } from '@abyss/web/ui/TextInput';
import { constantColumns } from './column-constants';
import { Alert } from '@abyss/web/ui/Alert';
import env from '../../../env.json';

const Product = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const [alertText, setAlertText] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [row, setRow] = useState({});
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const { token, msid, userRolesGroup } = useAuth();
  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );

  const deleteRows = async (payload) => {
    setShowModal(false);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload1 = {
      unetPrdctCd: row['UNET_PRDCT_CD'],
      prdctClssId: row['PRDCT_CLSS_ID'],
      covTypCd: row['COV_TYP_CD'],
      glPrdctCd: row['GL_PRDCT_CD'],
      netInd: row['NET_IND'],
      dualContrInd: row['DUAL_CONTR_IND'],
      gateInd: row['GATE_IND'],
      prdctTxt: row['PRDCT_TXT'],
      mbrMajPrdctCd: row['MBR_MAJ_PRDCT_CD'],
      selChoCd: row['SEL_CHO_CD'],
      lstUpdtDt: row['LSTUPDTDT'],
      lstUpdtTm: row['LST_UPDT_TM'],
      lstUpdtId: row['LST_UPDT_ID'],
      updtInd: row['UPDT_IND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/product/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        let tableData = await getTableData1();
        setAlertText('You have successfully deleted the record');
        setAlertType('success');
        setShowAlert(true);
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [unetPrdctCd, setunetPrdctCd] = useState('');
  const [unetPrdctCdErr, setunetPrdctCdErr] = useState('');
  const [prdctClssId, setprdctClssId] = useState('');
  const [covTypCd, setcovTypCd] = useState('');
  const [glPrdctCd, setglPrdctCd] = useState('');
  const [netInd, setnetInd] = useState('');
  const [dualContrInd, setdualContrInd] = useState('');
  const [gateInd, setgateInd] = useState('');
  const [prdctTxt, setprdctTxt] = useState('');
  const [selChoCd, setSelChoCd] = useState('');
  const [mbrMajPrdctCd, setmbrMajPrdctCd] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setUpdateFlag(true);
    setHideAdd(true);
    setFlag(true);
    setUpdateRow(row);
    setunetPrdctCd(row?.['UNET_PRDCT_CD']);
    setprdctClssId(row?.['PRDCT_CLSS_ID']);
    setcovTypCd(row?.['COV_TYP_CD']);
    setglPrdctCd(row?.['GL_PRDCT_CD']);
    setnetInd(row?.['NET_IND']);
    setdualContrInd(row?.['DUAL_CONTR_IND']);
    setgateInd(row?.['GATE_IND']);
    setprdctTxt(row?.['PRDCT_TXT']);
    setSelChoCd(row?.['SEL_CHO_CD']);
    setmbrMajPrdctCd(row?.['MBR_MAJ_PRDCT_CD']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'product.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'product.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions:
      hasRWaccess &&
      (hideUpdate ? [deleteLinkButton] : [updateLinkButton, deleteLinkButton]),
  });
  const addRow = () => {
    setHideUpdate(true);
    setFlag(true);
  };
  const handleClick = () => {
    navigate(-1);
  };
  const validateSave = () => {
    let valid = true;
    if (unetPrdctCd === '') {
      setunetPrdctCdErr('Please enter valid UNET_PRDCT_CD.');
      valid = false;
    }
    return valid;
  };
  const clearErrors = () => {
    setunetPrdctCdErr('');
  };
  const save = async () => {
    setSaveModal(false);
    if (!validateSave()) {
      return;
    }
    clearErrors();
    const tableData = await getTableData1();
    const exists = tableData.some((row) => row.UNET_PRDCT_CD === unetPrdctCd);
    if (exists) {
      setAlertText(unetPrdctCd + ' already exists in table.');
      setAlertType('error');
      setShowAlert(true);
      return;
    }
    setFlag(false);
    setHideUpdate(false);
    const now = new Date();
    let payload = {
      unetPrdctCd: unetPrdctCd,
      prdctClssId: prdctClssId,
      covTypCd: covTypCd,
      glPrdctCd: glPrdctCd,
      netInd: netInd,
      dualContrInd: dualContrInd,
      gateInd: gateInd,
      prdctTxt: prdctTxt,
      mbrMajPrdctCd: mbrMajPrdctCd,
      selChoCd: selChoCd,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: msid.substring(0, 7).toUpperCase(),
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'N',
    };
    setunetPrdctCd('');
    setprdctClssId('');
    setcovTypCd('');
    setglPrdctCd('');
    setnetInd('');
    setdualContrInd('');
    setgateInd('');
    setprdctTxt('');
    setSelChoCd('');
    setmbrMajPrdctCd('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/product/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setAlertText('You have successfully added the record');
        setAlertType('success');
        setShowAlert(true);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const update = async () => {
    setFlag(false);
    setHideAdd(false);
    setUpdateConfirmModal(false);
    setUpdateFlag(false);
    const now = new Date();
    let payload = {
      newRecord: {
        unetPrdctCd: unetPrdctCd,
        prdctClssId: prdctClssId,
        covTypCd: covTypCd,
        glPrdctCd: glPrdctCd,
        netInd: netInd,
        dualContrInd: dualContrInd,
        gateInd: gateInd,
        prdctTxt: prdctTxt,
        mbrMajPrdctCd: mbrMajPrdctCd,
        selChoCd: selChoCd,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: msid.substring(0, 7).toUpperCase(),
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
      oldRecord: {
        unetPrdctCd: updateRow['UNET_PRDCT_CD'],
        prdctClssId: updateRow['PRDCT_CLSS_ID'],
        covTypCd: updateRow['COV_TYP_CD'],
        glPrdctCd: updateRow['GL_PRDCT_CD'],
        netInd: updateRow['NET_IND'],
        dualContrInd: updateRow['DUAL_CONTR_IND'],
        gateInd: updateRow['GATE_IND'],
        prdctTxt: updateRow['PRDCT_TXT'],
        mbrMajPrdctCd: updateRow['MBR_MAJ_PRDCT_CD'],
        selChoCd: updateRow['SEL_CHO_CD'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: updateRow['UPDT_IND'],
      },
    };
    setunetPrdctCd('');
    setprdctClssId('');
    setcovTypCd('');
    setglPrdctCd('');
    setnetInd('');
    setdualContrInd('');
    setgateInd('');
    setprdctTxt('');
    setSelChoCd('');
    setmbrMajPrdctCd('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/product/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setAlertText('You have successfully updated the record');
        setAlertType('success');
        setShowAlert(true);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setUpdateFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setunetPrdctCd('');
    setprdctClssId('');
    setcovTypCd('');
    setglPrdctCd('');
    setnetInd('');
    setdualContrInd('');
    setgateInd('');
    setprdctTxt('');
    setSelChoCd('');
    setmbrMajPrdctCd('');
  };
  const close1 = () => {
    clearErrors();
    setFlag(false);
    setUpdateFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
  };
  const saveModalFunc = () => {
    if (!validateSave()) {
      return;
    }
    setSaveModal(true);
  };
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  useEffect(() => {
    const hasTableCTSReadWriteAccess = env.Table_CTS_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    setHasRWaccess(hasTableCTSReadWriteAccess);
  }, [userRolesGroup]);

  return (
    <>
      <Modal
        title="Confirm Delete Record"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <Modal.Section>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>UNET_PRDCT_CD:</b>
            </span>
            <span>
              <b>{row['UNET_PRDCT_CD']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>PRDCT_CLSS_ID:</b>
            </span>
            <span>
              <b>{row['PRDCT_CLSS_ID']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>COV_TYP_CD:</b>
            </span>
            <span>
              <b>{row['COV_TYP_CD']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>GL_PRDCT_CD:</b>
            </span>
            <span>
              <b>{row['GL_PRDCT_CD']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>NET_IND:</b>
            </span>
            <span>
              <b>{row['NET_IND']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>DUAL_CONTR_IND:</b>
            </span>
            <span>
              <b>{row['DUAL_CONTR_IND']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>GATE_IND:</b>
            </span>
            <span>
              <b>{row['GATE_IND']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>PRDCT_TXT:</b>
            </span>
            <span>
              <b>{row['PRDCT_TXT']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>MBR_MAJ_PRDCT_CD:</b>
            </span>
            <span>
              <b>{row['MBR_MAJ_PRDCT_CD']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>SEL_CHO_CD:</b>
            </span>
            <span>
              <b>{row['SEL_CHO_CD']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>LSTUPDTDT:</b>
            </span>
            <span>
              <b>{row['LSTUPDTDT']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>LST_UPDT_TM:</b>
            </span>
            <span>
              <b>{row['LST_UPDT_TM']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>LST_UPDT_ID:</b>
            </span>
            <span>
              <b>{row['LST_UPDT_ID']}</b>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ width: '50%' }}>
              <b>UPDT_IND:</b>
            </span>
            <span>
              <b>{row['UPDT_IND']}</b>
            </span>
          </div>
          <br />
          Are you sure you want to delete this record?
          <br />
          <Button css={{ margin: '10px' }} onClick={deleteRows}>
            Ok
          </Button>
          <Button css={{ margin: '10px' }} onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Section>
      </Modal>
      <Modal
        title="Confirm Update Record"
        isOpen={updateConfirmModal}
        onClose={() => setUpdateConfirmModal(false)}
      >
        <Modal.Section>
          Are you sure you want to update this record?
          <br />
          <Button css={{ margin: '10px' }} onClick={update}>
            Ok
          </Button>
          <Button
            css={{ margin: '10px' }}
            onClick={() => setUpdateConfirmModal(false)}
          >
            Cancel
          </Button>
        </Modal.Section>
      </Modal>
      <Modal
        title="Confirm Update"
        isOpen={updateModal}
        onClose={() => setUpdateModal(false)}
      >
        <Modal.Section>
          <b>Please review fields to be updated</b>
          <br />
          <b>Original Values:</b>
          <Grid>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput
                label="UNET_PRDCT_CD"
                value={updateRow?.['UNET_PRDCT_CD']}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="NET_IND" value={updateRow?.['NET_IND']} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput
                label="MBR_MAJ_PRDCT_CD"
                value={updateRow?.['MBR_MAJ_PRDCT_CD']}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput
                label="PRDCT_CLSS_ID"
                value={updateRow?.['PRDCT_CLSS_ID']}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput
                label="DUAL_CONTR_IND"
                value={updateRow?.['DUAL_CONTR_IND']}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="SEL_CHO_CD" value={updateRow?.['SEL_CHO_CD']} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="COV_TYP_CD" value={updateRow?.['COV_TYP_CD']} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="GATE_IND" value={updateRow?.['GATE_IND']} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput
                label="GL_PRDCT_CD"
                value={updateRow?.['GL_PRDCT_CD']}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="PRDCT_TXT" value={updateRow?.['PRDCT_TXT']} />
            </Grid.Col>
          </Grid>
          <br />
          <b>Updated values:</b>
          <Grid>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="UNET_PRDCT_CD" value={unetPrdctCd} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="NET_IND" value={netInd} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="MBR_MAJ_PRDCT_CD" value={mbrMajPrdctCd} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="PRDCT_CLSS_ID" value={prdctClssId} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="DUAL_CONTR_IND" value={dualContrInd} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="SEL_CHO_CD" value={selChoCd} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="COV_TYP_CD" value={covTypCd} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="GATE_IND" value={gateInd} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="GL_PRDCT_CD" value={glPrdctCd} />
            </Grid.Col>
            <Grid.Col span={{ xs: 4 }}>
              <TextInput label="PRDCT_TXT" value={prdctTxt} />
            </Grid.Col>
          </Grid>
          <br />
          <Button css={{ margin: '10px' }} onClick={updateClick}>
            Ok
          </Button>
          <Button
            css={{ margin: '10px' }}
            onClick={() => setUpdateModal(false)}
          >
            Cancel
          </Button>
        </Modal.Section>
      </Modal>
      <Modal
        title="Confirm Add Record"
        isOpen={saveModal}
        onClose={() => setSaveModal(false)}
      >
        <Modal.Section>
          Are you sure you want to add this record?
          <br />
          <Button css={{ margin: '10px' }} onClick={save}>
            Ok
          </Button>
          <Button css={{ margin: '10px' }} onClick={() => setSaveModal(false)}>
            Cancel
          </Button>
        </Modal.Section>
      </Modal>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                isVisible={showAlert}
                onClose={() => setShowAlert(false)}
                variant={alertType}
                title={alertText}
              />
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {getTableState?.filter?.filteredRows?.length} Records
              </span>
              <br />
              {hasRWaccess && (
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={flag || hideAdd ? true : false}
                >
                  + Add Row
                </Button>
              )}
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="UNET_PRDCT_CD"
                        value={unetPrdctCd}
                        onChange={(e) => setunetPrdctCd(e.target.value)}
                        isDisabled={updateFlag}
                        errorMessage={unetPrdctCdErr}
                        maxLength={3}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="PRDCT_CLSS_ID"
                        value={prdctClssId}
                        onChange={(e) => setprdctClssId(e.target.value)}
                        isDisabled={updateFlag}
                        maxLength={5}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="COV_TYP_CD"
                        value={covTypCd}
                        onChange={(e) => setcovTypCd(e.target.value)}
                        maxLength={1}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="GL_PRDCT_CD"
                        value={glPrdctCd}
                        onChange={(e) => setglPrdctCd(e.target.value)}
                        maxLength={3}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="NET_IND"
                        value={netInd}
                        onChange={(e) => setnetInd(e.target.value)}
                        maxLength={1}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="DUAL_CONTR_IND"
                        maxLength={1}
                        value={dualContrInd}
                        onChange={(e) => setdualContrInd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="GATE_IND"
                        value={gateInd}
                        maxLength={1}
                        onChange={(e) => setgateInd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="PRDCT_TXT"
                        value={prdctTxt}
                        onChange={(e) => setprdctTxt(e.target.value)}
                        maxLength={25}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="MBR_MAJ_PRDCT_CD"
                        value={mbrMajPrdctCd}
                        onChange={(e) => setmbrMajPrdctCd(e.target.value)}
                        maxLength={4}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="SEL_CHO_CD"
                        value={selChoCd}
                        maxLength={2}
                        onChange={(e) => setSelChoCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button
                        onClick={
                          updateFlag
                            ? () => setUpdateModal(true)
                            : saveModalFunc
                        }
                      >
                        Save
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button onClick={updateFlag ? close : close1}>
                        Cancel
                      </Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(Product, 'table_cts_ro_rw');
