import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

const AccountLevel = ({ policyDetails }) => {

  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5}>Account Level</Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Policy Acct. Number:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.polAcctNbr}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Acct. Attribute Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.acctAttDt === "9999-12-31" ? "" : policyDetails?.acctAttDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Acct. Eff. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.acctEffDt === "9999-12-31" ? "" : policyDetails?.acctEffDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Acct. Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.acctCancDt === "9999-12-31" ? "": policyDetails?.acctCancDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Subscriber Sts. Valid. Ind:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sbscrStsVldInd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>HMO Acct. Div. Admin Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.hmoAdmCd}</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default AccountLevel;
