import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import { DateInput } from '@abyss/web/ui/DateInput';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import { useRouter } from '@abyss/web/hooks/useRouter';
import withAuth from '@src/auth/withAuth';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { convertDateFormat } from '@src/utils/convertDateFormat';
import { currentDateFormat } from '@src/components/DateUtils';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';
import { set } from 'lodash';

const Employee = () => {
  const { readOnly, token, msid } = useAuth();
  const [fromBookMonth, setFromBookMonth] = useState('');
  const [toBookMonth, setToBookMonth] = useState('');
  const [fromBookYear, setFromBookYear] = useState('');
  const [toBookYear, setToBookYear] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [ssnNo, setSsnNo] = useState('');
  const [suffixCode, setSuffixCode] = useState('');
  const [policyAccountNumber, setPolicyAccountNumber] = useState('');
  const [chargeFromDate, setChargeFromDate] = useState(null);
  const [chargeToDate, setChargeToDate] = useState(null);
  const [fbmError, setFbmError] = useState('');
  const [tbmError, setTbmError] = useState('');
  const [fbyError, setFbyError] = useState('');
  const [tbyError, setTbyError] = useState('');
  const [pnError, setPnError] = useState('');
  const [ssnError, setSsnError] = useState('');
  const [suffixError, setSuffixError] = useState('');
  const [panError, setPanError] = useState('');
  const [cfdError, setCfdError] = useState('');
  const [ctdError, setCtdError] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const path = '/cmdb/correction-management';
  const { navigate } = useRouter();
  const [employeeOptions, setEmployeeOptions]: any = useState([]);
  const id = 'employeeDropdownData';
  const years = [];
  const date = new Date();
  for (let year = 1990; year <= 2029; year++) {
    years.push({ id: year, value: year, label: year.toString() });
  }
  const handleBackButton = () => {
    navigate(path);
  };
  const resetErorFields = () => {
    setTbmError('');
    setFbmError('');
    setPnError('');
    setSsnError('');
    setSuffixError('');
    setPanError('');
    setCtdError('');
    setCfdError('');
    setFbyError('');
    setTbyError('');
  };
  const reset = () => {
    setFromBookMonth('');
    setToBookMonth('');
    setFromBookYear('');
    setToBookYear('');
    setPolicyAccountNumber('');
    setPolicyNumber('');
    setSsnNo('');
    setChargeFromDate(null);
    setChargeToDate(null);
    setSuffixCode('');
    resetErorFields();
    setFbyError('');
    setTbyError('');
  };
  const checkPolicyNumber = (polNumber) => {
    const regEx: RegExp = /^\d{2}[A-Za-z0-9]\d{4}$/;
    if (polNumber.match(regEx)) {
      return true;
    } else {
      return false;
    }
  };
  const checkPolicyAccount = (polAcc) => {
    const pol: RegExp = /^[0-9]+$/;
    if (polAcc.match(pol)) {
      return true;
    } else {
      return false;
    }
  };
  const checkNumeric = (chknbr) => {
    const numbers: RegExp = /^[0-9]+$/;
    if (chknbr.match(numbers)) {
      return false;
    } else {
      return true;
    }
  };
  const checkValidSuffix = (sufixcd) => {
    const letters: RegExp = /^[A-Za-z.]+$/;
    if (sufixcd.match(letters)) {
      return false;
    } else {
      return true;
    }
  };
  const validate = () => {
    let validFlag = true;
    resetErorFields();
    if (
      null == fromBookMonth ||
      fromBookMonth === undefined ||
      fromBookMonth === '' ||
      null == fromBookYear ||
      fromBookYear === undefined ||
      fromBookYear === ''
    ) {
      setFbmError('Book Month From is required.');
      validFlag = false;
    }
    if (
      null == toBookMonth ||
      toBookMonth === undefined ||
      toBookMonth === '' ||
      null == toBookYear ||
      toBookYear === undefined ||
      toBookYear === ''
    ) {
      setTbmError('Book Month To is required.');
      validFlag = false;
    }

    if (
      null == fromBookYear ||
      fromBookYear === undefined ||
      fromBookYear === ''
    ) {
      setFbyError('Book Year From is required.');
      validFlag = false;
    }
    if (null == toBookYear || toBookYear === undefined || toBookYear === '') {
      setTbyError('Book Year To is required.');
      validFlag = false;
    }

    if (
      fromBookYear !== null &&
      toBookYear !== null &&
      fromBookMonth !== null &&
      toBookMonth !== null
    ) {
      if (toBookYear < fromBookYear) {
        setTbmError('From Date is not before To Date.');
        setFbmError('From Date is not before To Date.');
        validFlag = false;
      }
      if (toBookYear === fromBookYear) {
        if (toBookMonth < fromBookMonth) {
          setTbmError('From Date is not before To Date.');
          setFbmError('From Date is not before To Date.');
          validFlag = false;
        }
      }
    }
    if (
      null === policyNumber ||
      policyNumber === undefined ||
      policyNumber === '' ||
      policyNumber.trim() === ''
    ) {
      setPnError('Policy Number is required.');
      validFlag = false;
    } else if (policyNumber.length !== 7) {
      setPnError('Policy Number must be 7 characters.');
      validFlag = false;
    } else if (policyNumber.length === 7 && !checkPolicyNumber(policyNumber)) {
      setPnError('Policy Number must be numeric, Except for the 3rd position.');
      validFlag = false;
    }
    if (
      null === ssnNo ||
      ssnNo === undefined ||
      ssnNo === '' ||
      ssnNo.trim() === ''
    ) {
      setSsnError('Subscriber ID (SSN) is required.');
      validFlag = false;
    } else if (ssnNo.trim().length !== 9 || checkNumeric(ssnNo)) {
      setSsnError('Please enter valid Subscriber ID (SSN).');
      validFlag = false;
    }
    if (suffixCode !== null && suffixCode !== undefined && suffixCode !== '') {
      if (checkValidSuffix(suffixCode)) {
        setSuffixError(
          'Please enter a valid suffix with only letters or dots.'
        );
        validFlag = false;
      } else if (suffixCode.length > 2) {
        setSuffixError('Suffix Code can be max of 2 characters long.');
        validFlag = false;
      }
    }
    if (
      policyAccountNumber != null &&
      policyAccountNumber !== undefined &&
      policyAccountNumber != ''
    ) {
      if (policyAccountNumber.length !== 3) {
        setPanError('Policy Account Number must have a length of 3.');
        validFlag = false;
      } else if (!checkPolicyAccount(policyAccountNumber)) {
        setPanError('Policy Account Number must be a whole number.');
        validFlag = false;
      }
    }
    if (chargeFromDate !== null && chargeToDate !== null) {
      let to = new Date(chargeFromDate);
      let from = new Date(chargeToDate);
      if (to > from) {
        setCfdError('From Date is not before To Date.');
        setCtdError('From Date is not before To Date.');
        validFlag = false;
      }
    }
    return validFlag;
  };
  const handleClick = async () => {
    const valid = validate();
    if (valid) {
      setIsSubmitDisabled(true);
      try {
        let data = {
          fromBookMonth: fromBookMonth,
          toBookMonth: toBookMonth,
          fromBookYear: fromBookYear,
          toBookYear: toBookYear,
          policyNumber: policyNumber.toUpperCase(),
          ssnNo: ssnNo,
          suffixCode: suffixCode.replaceAll("."," "),
          polAccNo: policyAccountNumber,
          chargeFromDate:
            chargeFromDate !== null ? convertDateFormat(chargeFromDate) : null,
          chargeToDate:
            chargeToDate !== null ? convertDateFormat(chargeToDate) : null,
          userShortNm: msid,
        };
        const response = await axios
          .post(`${BASE_URL}cmdb-service/employee-saveData `, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIsSubmitDisabled(false);
            if (response.status === 200) {
              navigate('/cmdb/correction-management/employee/employee-submit', {
                state: {
                  fromBookMonth: `${fromBookYear}${fromBookMonth}`,
                  toBookMonth: `${toBookMonth}${toBookYear}`,
                  ssnNo: ssnNo,
                  policyNumber: policyNumber.toLocaleUpperCase(),
                  suffixCode: suffixCode.replaceAll("..","__").replaceAll("."," ").toLocaleUpperCase(),
                  policyAccountNumber: policyAccountNumber,
                  chargeFromDate: chargeFromDate,
                  chargeToDate: chargeToDate,
                  requestId: response.data,
                },
              });
            }
          })
          .catch((error) => {
            // add-alert-popup
          });
      } catch (error) {
        // add-alert-popup
      }
    }
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cmdb-service/employeeDropdownData/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data) {
          setEmployeeOptions(response.data);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        // add-alert-popup
      }
    };
    if (token) fetchDropdownData();
  }, [token]);

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '70px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading textAlign="center" offset={5}>
                    Custom Correction Request
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '10px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading textAlign="center" offset={5}>
                    Employee
                  </Heading>
                  <Flex justify="end">
                    <Label size="md" id="first name">
                      {currentDateFormat(date)}
                    </Label>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '20px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Required Criteria:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Book Month:</Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <SelectInput
                    label="From Month"
                    placeholder="Month"
                    isClearable
                    value={fromBookMonth}
                    options={employeeOptions?.monthList?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    onChange={(date) => setFromBookMonth(date)}
                    isDisabled={readOnly}
                    errorMessage={fbmError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <SelectInput
                    label="From Year"
                    placeholder="Year"
                    isClearable
                    value={fromBookYear}
                    options={years}
                    onChange={(date) => setFromBookYear(date)}
                    isDisabled={readOnly}
                    errorMessage={fbyError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <SelectInput
                    label="To Month"
                    placeholder="Month"
                    isClearable
                    value={toBookMonth}
                    options={employeeOptions?.monthList?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    onChange={(date) => setToBookMonth(date)}
                    isDisabled={readOnly}
                    errorMessage={tbmError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <SelectInput
                    label="To Year"
                    placeholder="Year"
                    isClearable
                    value={toBookYear}
                    options={years}
                    onChange={(date) => setToBookYear(date)}
                    isDisabled={readOnly}
                    errorMessage={tbyError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="Subscriber Id (SSN)"
                    value={ssnNo}
                    onChange={(e) => setSsnNo(e.target.value)}
                    errorMessage={ssnError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="Policy Number"
                    value={policyNumber}
                    onChange={(e) => setPolicyNumber(e.target.value)}
                    errorMessage={pnError}
                  />
                </Grid.Col>
              </Grid>

              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '20px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Other Criteria:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Policy Account Number:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    value={policyAccountNumber}
                    errorMessage={panError}
                    onChange={(e) => setPolicyAccountNumber(e.target.value)}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Suffix Code:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    subText="Enter . (dot) if you want to give a space."
                    value={suffixCode}
                    errorMessage={suffixError}
                    onChange={(e) =>
                      setSuffixCode(e.target.value.replaceAll(" ",".").toUpperCase())
                    }
                    maxLength={2}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Charge Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label=""
                    placeholder="Pick one"
                    isClearable
                    value={chargeFromDate}
                    onChange={(date) => setChargeFromDate(date)}
                    errorMessage={cfdError}
                    css={{ float: 'left' }}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label=""
                    placeholder="Pick one"
                    isClearable
                    value={chargeToDate}
                    onChange={(date) => setChargeToDate(date)}
                    errorMessage={ctdError}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '100px',
                      },
                    }}
                    isLoading={isSubmitDisabled}
                    ariaLoadingLabel="Submitting"
                    isDisabled={readOnly}
                  >
                    Submit
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={reset}
                  >
                    Reset
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleBackButton}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(Employee, 'cmdb_update');
