// Login.js
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useIsAuthenticated } from '@azure/msal-react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { InteractionStatus } from '@azure/msal-browser';

const Login = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { getLocation } = useRouter();
  const location = getLocation();
  const [routeChanged, setRouteChanged] = useState(false);

  useEffect(() => {
    if (location.pathname === '/' && !isAuthenticated) {
      setRouteChanged(true);
    }
  }, [location]);

  useEffect(() => {
    if (routeChanged) {
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        instance
          .loginRedirect(loginRequest)
          .then((response) => {})
          .catch((error) => {});
      }
    }
  }, [instance, isAuthenticated, routeChanged]);

  return <></>;
};

export default Login;
