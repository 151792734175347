import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Heading } from '@abyss/web/ui/Heading';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Box } from '@abyss/web/ui/Box';
import { TextInput } from '@abyss/web/ui/TextInput';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import { Modal } from '@abyss/web/ui/Modal';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Alert } from '@abyss/web/ui/Alert';
import { BASE_URL } from '@src/utils/endpoints';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updatePolicyApi } from '@src/redux/api/FADB/policy/policyApi';
import {
  referencePolicyApi,
  referencesDynamicApi,
} from '@src/redux/api/FADB/policy/policyApi';
import { formateDate } from '@src/components/DateUtils';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';
import {
  fetchLegalEntitiesSuccess,
  fetchLegalEntitiesFailure,
  fetchDynamicDropdownSuccess,
  fetchDynamicDropdownFailure,
} from '../../../redux/action/FADB/policy/policyActions';
import withAuth from '@src/auth/withAuth';
import { handleSubmitClick } from '@src/pages/CMDB/CorrectionManagment/finalizeCorrectionHandler';
const PolicyLookup = () => {
  const { readOnly, token, msid } = useAuth();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { navigate } = useRouter();
  const dispatch = useDispatch();
  const [isLoad, setIsLoad] = useState(false);
  const [newPolicy, setNewPolicy] = useState(false);
  const [pageType, setPageType] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState([]);
  const [alertText, setAlertText] = useState('');
  const [lookupflag, setLookUpFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText2, setAlertText2] = useState('');
  const [showAlert2, setShowAlert2] = useState(false);
  const [alertText1, setAlertText1] = useState('');
  const [showAlert1, setShowAlert1] = useState(false);
  const [policyNumber, setPolicyNumber] = useState('');
  const [policyNumberErr, setPolicyNumberErr] = useState('');
  const [policyData, setPolicyData] = useState();
  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [effectiveDate, setEffectiveDate] = useState('');
  const [effectiveDateErr, setEffectiveDateErr] = useState('');
  const [typeofEffDate, setTypeofEffDate] = useState('S');
  const [policyType, setPolicyType] = useState('O');
  const [caseInstallation, setCaseInstallation] = useState('');
  const [caseInstallationErr, setCaseInstallationErr] = useState('');
  const [erisa, setErisa] = useState('E');
  const [attributeEffDate, setAttributeEffDate] = useState('');
  const [attributeEffDateErr, setAttributeEffDateErr] = useState('');
  const [defaultLegalEntityCode, setDefaultLegalEntityCode] = useState('');
  const [defaultGLReinsurance, setDefaultGLReinsurance] = useState('');
  const [defaultOutOfLegalEntity, setDefaultOutOfLegalEntity] = useState('');
  const [sharedArrangement, setSharedArrangement] = useState('00');
  const [isHighlight, setIsHighlight] = useState(false);
  const [selectedErisa, setSelectedErisa] = useState([
    {
      id: '1',
      label: 'Compliant',
      value: 'E',
    },
    {
      id: '2',
      label: 'Non Compliant',
      value: 'N',
    },
  ]);
  const [selectedTypeOfEffDate, setSelectedTypeOfEffDate] = useState([]);
  const [selectedPolicyType, setSelectedPolicyType] = useState([]);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState([]);
  const [selectedCaseInstallation, setSelectedCaseInstallation] = useState([]);
  const [selectedGLReinsurance, setSelectedGLReinsurance] = useState([]);
  const [selectedSharedArrangement, setselectedSharedArrangement] = useState(
    []
  );
  const [withoutChange, setWithoutChange] = useState(false);
  const [typeOfRenewal, setTypeOfRenewal] = useState('');
  const [effModal, setEffModal] = useState(false);
  const [firstRenewalMonth, setFirstRenewalMonth] = useState('');
  const [day, setDay] = useState('');
  const [cancellationDate, setCancellationDate] = useState('');
  const [lastChangedDate, setLastChangedDate] = useState('');
  const [by, setBy] = useState('');
  const [selectedRenewalType, setSelectedRenewalType] = useState([]);
  const [selectedRenewalMonth, setSelectedRenewalMonth] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [fundingeffectiveDate, setFundingEffectiveDate] = useState('');
  const [fundingeffectiveDateErr, setFundingEffectiveDateErr] = useState('');
  const [fundingCode, setFundingCode] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [bankCode, setBankCode] = useState('F');
  const [selectedFundingCode, setSelectedFundingCode] = useState([]);
  const [selectedBankCode, setSelectedBankCode] = useState([]);
  useEffect(() => {
    if (
      policyData &&
      policyData !== 'Data Not Found' &&
      policyData.length > 0
    ) {
      setName(policyData?.[0]?.POLHLDR_NM || ''),
        setEffectiveDate(policyData?.[0]?.POL_EFF_DT),
        setPolicyType(policyData?.[0]?.RR_TYP_CD),
        setErisa(
          policyData?.[0]?.ERISA_CD === 'E' ? policyData?.[0]?.ERISA_CD : 'N'
        ),
        setAttributeEffDate(policyData?.[0]?.POL_ATT_EFF_DT),
        setDefaultLegalEntityCode(policyData?.[0]?.LEG_ENTY_CD),
        setDefaultOutOfLegalEntity(policyData?.[0]?.LEG_ENTY_OFN_CD),
        setSharedArrangement(policyData?.[0]?.SHR_ARNG_CD),
        setTypeofEffDate(policyData?.[0]?.SRVC_ISS_DT_RUL_CD),
        setCaseInstallation(policyData?.[0]?.ADMIN_SYS_SRC_CD);
      setBy(policyData?.[0]?.USER_SHORT_NM || ''),
        setTypeOfRenewal(policyData?.[0]?.RENEWAL_CD_TYP),
        setFirstRenewalMonth(policyData?.[0]?.REN_MO_NBR?.trim()),
        setDay(policyData?.[0]?.REN_DAY_NBR?.trim()),
        setCancellationDate(policyData?.[0]?.POL_CANC_DT),
        setLastChangedDate(policyData?.[0]?.POL_CHG_DT);
      setFundingEffectiveDate(policyData?.[0]?.POL_FNDNG_EFF_DT),
        setFundingCode(policyData?.[0]?.FUND_ARNG_CD),
        setBankCode(policyData?.[0]?.BNK_CD),
        setCompanyCode(policyData?.[0]?.CO_CD),
        setDefaultGLReinsurance(policyData?.[0]?.GL_REINS_CD.trim());
    } else {
      setName(''),
        setEffectiveDate(''),
        setPolicyType('O'),
        setErisa('E'),
        setAttributeEffDate(''),
        setDefaultLegalEntityCode(''),
        setDefaultGLReinsurance(''),
        setDefaultOutOfLegalEntity(''),
        setSharedArrangement('00'),
        setTypeofEffDate('S'),
        setCaseInstallation('');
      setBy(''),
        setTypeOfRenewal(''),
        setFirstRenewalMonth(''),
        setDay(''),
        setCancellationDate(''),
        setLastChangedDate('');
      setFundingEffectiveDate(''),
        setFundingCode(''),
        setBankCode('F'),
        setCompanyCode('');
    }
  }, [policyData]);
  const handleFetchEntity = () => {
    referencePolicyApi(config)
      .then((response) => {
        setSelectedLegalEntity(response.data.legalEntity);
        setselectedSharedArrangement(response.data.shrdArrng);
        dispatch(fetchLegalEntitiesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchLegalEntitiesFailure(error));
      });
  };
  const convertDate = (dateStrr) => {
    if (!dateStrr.includes('-')) return dateStrr;
    const [year, month, day] = dateStrr.split('-');
    return `${month}/${day}/${year}`;
  };
  const checkPolicyNumber = (polNumber) => {
    const regEx: RegExp = /^\d{2}[A-Za-z0-9]\d{4}$/;
    if (polNumber.match(regEx)) {
      return true;
    } else {
      return false;
    }
  };
  const checkMandatoryField = () => {
    let valid = true;
    // message5.visible = false;
    let fndEftDateFlag = false;
    if (
      null == policyNumber ||
      policyNumber === undefined ||
      policyNumber === ''
    ) {
      setPolicyNumberErr('Policy Number must be 7 charecters.');
      valid = false;
    } else if (policyNumber.length !== 7) {
      setPolicyNumberErr('Policy Number must be 7 charecters.');
      valid = false;
    } else if (policyNumber.length === 7 && !checkPolicyNumber(policyNumber)) {
      setPolicyNumberErr(
        'Policy Number must be numeric, Except for the 3rd position.'
      );
      valid = false;
    }

    if (
      null == name.trim() ||
      name.trim() === undefined ||
      name.trim() === ''
    ) {
      setNameErr('Name is required field.');
      valid = false;
    } else if (name.trim().length > 25) {
      setNameErr('Name should be less than or equal to 25 characters.');
      valid = false;
    }
    if (
      null == caseInstallation ||
      caseInstallation === undefined ||
      caseInstallation === ''
    ) {
      setCaseInstallationErr('Case Installation Source is required field.');
      valid = false;
    }
    if (
      null == effectiveDate ||
      effectiveDate === undefined ||
      effectiveDate === ''
    ) {
      setEffectiveDateErr('Effective Date is required field.');
      valid = false;
    }
    if (
      null == attributeEffDate ||
      attributeEffDate === undefined ||
      attributeEffDate === ''
    ) {
      setAttributeEffDateErr('Attributes Effective Date is required field.');
      valid = false;
    }

    // tslint:disable-next-line:max-line-length
    if (
      undefined === bankCode &&
      null !== bankCode &&
      undefined !== bankCode &&
      bankCode !== '' &&
      undefined !== fundingCode &&
      null != fundingCode &&
      fundingCode !== '' &&
      (null == fundingeffectiveDate ||
        fundingeffectiveDate === undefined ||
        fundingeffectiveDate === '')
    ) {
      fndEftDateFlag = true;
      valid = false;
    }
    // tslint:disable-next-line:max-line-length
    if (
      (undefined === bankCode ||
        null == bankCode ||
        undefined !== bankCode ||
        bankCode !== '') &&
      undefined !== fundingCode &&
      null != fundingCode &&
      undefined !== fundingCode &&
      fundingCode !== '' &&
      (null == fundingeffectiveDate ||
        fundingeffectiveDate === undefined ||
        fundingeffectiveDate === '')
    ) {
      fndEftDateFlag = true;
      valid = false;
    }
    // tslint:disable-next-line:max-line-length
    if (
      undefined !== bankCode &&
      null !== bankCode &&
      undefined !== bankCode &&
      bankCode !== '' &&
      (undefined === fundingCode ||
        null == fundingCode ||
        undefined === fundingCode ||
        fundingCode === '') &&
      (null == fundingeffectiveDate ||
        fundingeffectiveDate === undefined ||
        fundingeffectiveDate === '')
    ) {
      fndEftDateFlag = true;
      valid = false;
    }
    if (fndEftDateFlag === true) {
      setFundingEffectiveDateErr(
        'Funding effective date is required for the requested Funding or Bank code .'
      );
    }
    return valid;
  };
  const effModalClick = (e) => {
    setEffModal(false);
    handleSubmitSuffix(e, pageType, true);
  };
  const handleDynamicDropdown = () => {
    referencesDynamicApi(config)
      .then((response) => {
        setSelectedFundingCode(response.data.fndCd);
        setSelectedBankCode(response.data.bankCd);
        setSelectedTypeOfEffDate(response.data.typEffDt);
        setSelectedPolicyType(response.data.polTyp);
        setSelectedCaseInstallation(response.data.insTyp);
        setSelectedGLReinsurance(response.data.reInsTyp);
        setDefaultGLReinsurance('');
        setSelectedRenewalType(response.data.renewalType);
        setSelectedRenewalMonth(response.data.renewalMnth);
        setSelectedDay(response.data.day);
        dispatch(fetchDynamicDropdownSuccess(response.data));
        dispatch(fetchDynamicDropdownSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDynamicDropdownFailure(error));
      });
  };
  const clearErrorFields = () => {
    setPolicyNumberErr('');
    setNameErr('');
    setEffectiveDateErr('');
    setCaseInstallationErr('');
    setAttributeEffDateErr('');
    setFundingEffectiveDateErr('');
  };
  const cancel = () => {
    window.scrollTo(0, 0);
    setIsHighlight(true);
    clearErrorFields();
    setLookUpFlag(false);
    setPolicyData(null);
    setPolicyNumber('');
    focusTextInput();
  };
  useEffect(() => {
    if (token) {
      handleFetchEntity();
      handleDynamicDropdown();
    }
    focusTextInput();
  }, [token]);
  const inputRef = useRef(null);

  const focusTextInput = () => {
    // Check if the inputRef.current is not null
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const cocdlookup = async () => {
    setCompanyCode('');
    setShowAlert(false);
    try {
      const response = await axios.get(
        `${BASE_URL}policy/cocd-inq/${defaultLegalEntityCode}/${defaultGLReinsurance === '' ? ' ' : defaultGLReinsurance
        }/${fundingCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        if (
          response.data === 'Not available' ||
          response.data === 'Data Not Found'
        ) {
          setAlertText('Company Code not found');
          setShowAlert(true);
        } else {
          setCompanyCode(response?.data?.['CO_CD']);
        }
      }
    } catch (error) {
      setAlertText('Company Code not found');
      setShowAlert(true);
      return {};
    }
  };

  const validatePolicyLookup = () => {
    let valid = true;
    if (
      null == policyNumber ||
      policyNumber === undefined ||
      policyNumber === ''
    ) {
      setPolicyNumberErr('Policy Number must be 7 charecters.');
      valid = false;
    } else if (policyNumber.length !== 7) {
      setPolicyNumberErr('Policy Number must be 7 charecters.');
      valid = false;
    } else if (policyNumber.length === 7 && !checkPolicyNumber(policyNumber)) {
      setPolicyNumberErr(
        'Policy Number must be numeric, Except for the 3rd position.'
      );
      valid = false;
    }
    return valid;
  };

  const handleLookup = async () => {
    clearErrorFields();
    setShowAlert2(false);
    setIsLoading(true);
    if (validatePolicyLookup()) {
      setLookUpFlag(true);
      try {
        const response = await axios.get(`${BASE_URL}policy/${policyNumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data === 'Data Not Found') {
          setIsLoading(false);
          setNewPolicy(true);
        }
        if (response && response.data) {
          setPolicyData(response.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setNewPolicy(true);
        }
      } catch (error) {
        // add-alert-popup
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };
  const noChange = () => {
    let erisa1 = policyData[0].ERISA_CD === 'E' ? 'E' : 'N';
    return (
      policyData &&
      policyData.length > 0 &&
      policyData[0].POLHLDR_NM === name &&
      policyData[0].POL_EFF_DT === effectiveDate &&
      policyData[0].RR_TYP_CD === policyType &&
      erisa1 === erisa &&
      policyData[0].POL_ATT_EFF_DT === attributeEffDate &&
      policyData[0].LEG_ENTY_CD === defaultLegalEntityCode &&
      policyData[0].LEG_ENTY_OFN_CD === defaultOutOfLegalEntity &&
      policyData[0].SHR_ARNG_CD === sharedArrangement &&
      policyData[0].SRVC_ISS_DT_RUL_CD === typeofEffDate &&
      policyData[0].ADMIN_SYS_SRC_CD === caseInstallation &&
      policyData[0].GL_REINS_CD?.trim() === defaultGLReinsurance?.trim() &&
      policyData[0].POL_FNDNG_EFF_DT === fundingeffectiveDate &&
      policyData[0].FUND_ARNG_CD === fundingCode &&
      policyData[0].BNK_CD === bankCode &&
      policyData[0].RENEWAL_CD_TYP === typeOfRenewal &&
      policyData[0].REN_MO_NBR === firstRenewalMonth &&
      policyData[0].REN_DAY_NBR?.trim() === day?.trim() &&
      policyData[0].POL_CANC_DT === cancellationDate
    );
  };
  const handleSubmitSuffix = async (e, page, typeftdtRule) => {
    e.preventDefault();
    setIsLoad(true);
    if (!lookupflag) {
      setIsLoad(false);
      setAlertText2('Press Look up button first');
      setShowAlert2(true);
      window.scrollTo(0, 0);
      return;
    }
    if (
      caseInstallation !== '1' &&
      caseInstallation !== null &&
      caseInstallation !== '' &&
      !/^\d+$/.test(policyNumber)
    ) {
      setIsLoad(false);
      setAlertText2('Policy Number must be numeric.');
      setShowAlert2(true);
      window.scrollTo(0, 0);
      return;
    }
    if (noChange()) {
      setIsLoad(false);
      setWithoutChange(true);
      return;
    }
    setPageType(page);
    let payload = {
      disabledFlag: false,
      disabledDropdown: true,
      actPolNbr: policyNumber,
      policyNumber: policyNumber,
      polName: name,
      typeofeffectiveDate: typeofEffDate,
      effectiveDate: convertDate(effectiveDate),
      caseinstallationSource: caseInstallation,
      policytype: policyType,
      erisacode: erisa === 'E' ? 'E' : ' ',
      attrEffectiveDate: convertDate(attributeEffDate),
      deafultlegalEntityCode: defaultLegalEntityCode,
      defaultglreinsurance: defaultGLReinsurance,
      sharedArrangement: sharedArrangement,
      fundingEffectiveDate: convertDate(fundingeffectiveDate),
      fundingCode: fundingCode,
      bankCode: bankCode,
      companyCode: companyCode,
      typeofRenewal: typeOfRenewal,
      firstRenewalMonth: firstRenewalMonth?.trim(),
      firstRenewalDay: day,
      lastChange: lastChangedDate,
      lastChangeBy: msid,
      typeftdtRule: typeftdtRule,
      userSortName: msid,
      cancelationDate: convertDate(cancellationDate),
    };
    if (defaultOutOfLegalEntity.trim() !== '') {
      payload['deafaultOutOfNetworkLglEnt'] = defaultOutOfLegalEntity.trim();
    }
    clearErrorFields();
    if (checkMandatoryField()) {
      try {
        const response = await axios.post(
          `${BASE_URL}policy/savePolicy`,
          payload,
          config
        );
        if (response.status === 200) {
          if (response?.data?.result === 'success') {
            if (page === 'suffix') {
              setShowAlert1(true);
              setAlertText1(
                newPolicy
                  ? 'Policy Data Saved Successfully'
                  : 'Policy Data Updated Successfully'
              );
              window.scrollTo(0, 0);
              setTimeout(() => {
                navigate('/fadb/suffix/lookup', {
                  state: {
                    data: payload,
                    policyData: policyData,
                    selectedTypeOfEffDate: selectedTypeOfEffDate,
                    selectedPolicyType: selectedPolicyType,
                    selectedCaseInstallation: selectedCaseInstallation,
                    selectedErisa: selectedErisa,
                    selectedLegalEntity: selectedLegalEntity,
                    policyEntId: policyData?.[0]?.['POL_ENT_ID'],
                    selectedGLReinsurance: selectedGLReinsurance,
                    selectedFundingCode: selectedFundingCode,
                    selectedBankCode: selectedBankCode,
                  },
                });
              }, 3000);
              setIsLoad(false);
            } else {
              setShowAlert1(true);
              setAlertText1(
                newPolicy
                  ? 'Policy Data Saved Successfully'
                  : 'Policy Data Updated Successfully'
              );
              window.scrollTo(0, 0);
              cancel();
              setIsLoad(false);
            }
          } else if (
            Object.values(response?.data).length === 1 &&
            Object.values(response?.data)[0] ===
            'Type effective date should be setup as Service'
          ) {
            setEffModal(true);
            setIsLoad(false);
          } else {
            let value = Object.values(response?.data);
            setErrorModalText(value);
            setErrorModal(true);
            setIsLoad(false);
          }
        }
      } catch (error) {
        // add-alert-popup
        setIsLoad(false);
        return {};
      }
    }
    setNewPolicy(false);
    setIsLoad(false);
  };
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '70px',
              marginLeft: '210px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white" css={{ padding: '2rem' }}>
              <Grid>
                <React.Fragment>
                  <Alert
                    title={alertText1}
                    variant="success"
                    isVisible={showAlert1}
                    onClose={() => {
                      setShowAlert1(false);
                    }}
                  />
                  <Alert
                    title={alertText2}
                    variant="error"
                    isVisible={showAlert2}
                    onClose={() => {
                      setShowAlert2(false);
                    }}
                  />
                  <Alert
                    title={
                      'You have hit the Submit button without changing any data.'
                    }
                    variant="error"
                    isVisible={withoutChange}
                    onClose={() => {
                      setWithoutChange(false);
                    }}
                  />
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Policy</Heading>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Policy Number"
                      value={policyNumber}
                      ref={inputRef}
                      onChange={(e) => {
                        setPolicyNumber(e.target.value.toUpperCase());
                        setIsHighlight(false);
                        setLookUpFlag(false);
                      }}
                      errorMessage={policyNumberErr}
                      isDisabled={readOnly}
                      highlighted={showAlert2 || isHighlight}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleLookup(event);
                        }
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                        },
                      }}
                      onClick={handleLookup}
                      isDisabled={readOnly}
                      isLoading={isLoading}
                      type="submit"
                    >
                      Look Up
                    </Button>
                  </Grid.Col>
                  {isLoading && <WrapperLoader />}
                </React.Fragment>
                <React.Fragment>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>General Information</Heading>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="Name"
                      value={name}
                      isDisabled={readOnly}
                      onChange={(e) => setName(e.target.value)}
                      errorMessage={nameErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <DateInput
                      label="Effective Date"
                      value={
                        effectiveDate !== ''
                          ? formateDate(effectiveDate)
                          : effectiveDate
                      }
                      onChange={(date) => setEffectiveDate(date)}
                      subText={' '}
                      isClearable={false}
                      isDisabled={readOnly}
                      errorMessage={effectiveDateErr}
                      enableOutsideScroll
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <SelectInput
                      label="Type Of Effective Date"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      value={typeofEffDate}
                      onChange={(value) => setTypeofEffDate(value)}
                      options={selectedTypeOfEffDate.map(
                        (typeofeffdt: any) => ({
                          value: typeofeffdt.value,
                          label: typeofeffdt.label,
                        })
                      )}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <SelectInput
                      label="Policy Type"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      value={policyType}
                      onChange={(value) => setPolicyType(value)}
                      options={selectedPolicyType.map((polTyp: any) => ({
                        value: polTyp.value,
                        label: polTyp.label,
                      }))}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <SelectInput
                      label="Case Installation Source"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      value={caseInstallation}
                      errorMessage={caseInstallationErr}
                      onChange={(value) => setCaseInstallation(value)}
                      options={selectedCaseInstallation.map(
                        (caseinstype: any) => ({
                          value: caseinstype.value,
                          label: caseinstype.label,
                        })
                      )}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Modal
                    isOpen={errorModal}
                    onClose={() => setErrorModal(false)}
                    title="Please check the below errors"
                  >
                    <div style={{ margin: '15px', color: 'red' }}>
                      {errorModalText.map((item, index) => (
                        <div key={index}>{item}</div>
                      ))}
                    </div>
                  </Modal>
                  <Modal
                    isOpen={effModal}
                    onClose={() => setEffModal(false)}
                  // title=
                  >
                    <div style={{ padding: '15px' }}>
                      The following business rule may be overridden.Do you wish
                      to accept this business rule prime case should be set up
                      with a Type of Effective Date = Service
                    </div>
                    <Button
                      style={{ margin: '5px' }}
                      onClick={(e) => effModalClick(e)}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{ margin: '5px' }}
                      onClick={() => setEffModal(false)}
                    >
                      No
                    </Button>
                  </Modal>
                  <Grid.Col span={{ xs: 4 }}>
                    <SelectInput
                      label="ERISA"
                      placeholder=""
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={erisa}
                      onChange={(value) => setErisa(value)}
                      options={selectedErisa.map((erisa: any) => ({
                        value: erisa.value,
                        label: erisa.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Attribute Effective Date"
                      errorMessage={attributeEffDateErr}
                      value={
                        attributeEffDate !== ''
                          ? formateDate(attributeEffDate)
                          : attributeEffDate
                      }
                      onChange={(value) => setAttributeEffDate(value)}
                      subText={' '}
                      isClearable={true}
                      isDisabled={readOnly}
                      enableOutsideScroll
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Default Legal Entiy Code"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={defaultLegalEntityCode}
                      onChange={(value) => setDefaultLegalEntityCode(value)}
                      options={selectedLegalEntity.map((entity) => ({
                        value: entity,
                        label: entity,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Default GL Reinsurance"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={defaultGLReinsurance}
                      onChange={(value) => setDefaultGLReinsurance(value)}
                      options={selectedGLReinsurance.map((reins: any) => ({
                        value: reins.value,
                        label: reins.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Default Out of Network Legal Entity"
                      placeholder=" "
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={defaultOutOfLegalEntity}
                      onChange={(value) => setDefaultOutOfLegalEntity(value)}
                      options={selectedLegalEntity.map((entity) => ({
                        value: entity,
                        label: entity,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Shared Arrangement"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={sharedArrangement}
                      onChange={(value) => setSharedArrangement(value)}
                      options={selectedSharedArrangement.map(
                        (sharedArr: any) => ({
                          value: sharedArr.shrArngCd,
                          label: sharedArr.shrArngDescTxt,
                        })
                      )}
                    />
                  </Grid.Col>
                </React.Fragment>
                <React.Fragment>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Default Funding and Banking</Heading>
                  </Grid.Col>
                  <Alert
                    title={alertText}
                    variant="error"
                    isVisible={showAlert}
                    onClose={() => {
                      setShowAlert(false);
                    }}
                  />
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Funding Effective Date"
                      errorMessage={fundingeffectiveDateErr}
                      value={
                        fundingeffectiveDate !== ''
                          ? formateDate(fundingeffectiveDate)
                          : fundingeffectiveDate
                      }
                      onChange={(date) => setFundingEffectiveDate(date)}
                      subText={' '}
                      isDisabled={readOnly}
                      enableOutsideScroll
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Funding Code"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      value={fundingCode}
                      isDisabled={readOnly}
                      onChange={(value) => setFundingCode(value)}
                      options={selectedFundingCode.map((fndCode: any) => ({
                        value: fndCode.value,
                        label: fndCode.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Company Code"
                      value={companyCode}
                      isDisabled={true}
                      onChange={(e) => {
                        setCompanyCode(e.target.value);
                      }}
                    // onChange={(e) => handleInputChange('companyCode', e.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Bank Code"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={bankCode}
                      onChange={(value) => setBankCode(value)}
                      options={selectedBankCode.map((bnkCd: any) => ({
                        value: bnkCd.value,
                        label: bnkCd.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <Button
                      css={{
                        'abyss-button-root': { marginTop: '25px !important' },
                      }}
                      isDisabled={readOnly}
                      onClick={cocdlookup}
                    >
                      Look Up
                    </Button>
                  </Grid.Col>
                </React.Fragment>
                <React.Fragment>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Renewal/Cancellation</Heading>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Type Of Renewal"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={typeOfRenewal}
                      onChange={(value) => setTypeOfRenewal(value)}
                      options={selectedRenewalType.map((renewaltype: any) => ({
                        value: renewaltype.value,
                        label: renewaltype.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="First Renewal Month"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      value={firstRenewalMonth}
                      isDisabled={readOnly}
                      onChange={(value) => setFirstRenewalMonth(value)}
                      options={selectedRenewalMonth.map((renewalMon: any) => ({
                        value: renewalMon.value,
                        label: renewalMon.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Day"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={day}
                      onChange={(value) => setDay(value)}
                      options={selectedDay.map((day: any) => ({
                        value: day.value,
                        label: day.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Cancellation Date"
                      value={
                        cancellationDate !== ''
                          ? formateDate(cancellationDate)
                          : cancellationDate
                      }
                      isDisabled={readOnly}
                      onChange={(value) => setCancellationDate(value)}
                      subText={' '}
                      enableOutsideScroll
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Last Changed"
                      value={lastChangedDate}
                      subText={' '}
                      isDisabled={true}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput label="By" value={by} isDisabled={true} />
                  </Grid.Col>
                </React.Fragment>
              </Grid>
              {isLoad && <WrapperLoader />}
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={(e) => handleSubmitSuffix(e, 'suffix', false)}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '220px',
                      },
                    }}
                    isDisabled={readOnly}
                    isLoading={isLoad}
                  >
                    Submit & Suffix Page
                  </Button>
                  <Button
                    onClick={(e) => handleSubmitSuffix(e, 'policy', false)}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '220px',
                      },
                    }}
                    isDisabled={readOnly}
                    isLoading={isLoad}
                  >
                    Submit & Policy Page
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={cancel}
                    isLoading={isLoad}
                  >
                    Cancel
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(PolicyLookup, "fadb_update");
