import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
export default function FourOFour() {
  return (
    <>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
          <Card
            css={{
              marginTop: '70px',
              marginLeft: '200px',
              border: 'none',
              boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <h1>404 Page Not Found</h1>
          </Card>
        </Grid.Col>
      </Grid>
    </>
    // <>
    //   <h1>Page Not Found</h1>
    // </>
  );
}
