import React, { useState, useEffect } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import { Alert } from '@abyss/web/ui/Alert';

export const AccountSection = ({
  handleGeneralData,
  setLoadingStatus,
  getAcctCode,
  setLookupPress,
  polData,
  sufData,
  clearAccCode,
  setClearAccCode,
}) => {
  const { readOnly, token } = useAuth();
  const [accountCode, setAccountCode] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [suffixCode, setsuffixCode] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [alerMsg, setAlertMsg] = useState();
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    if (polData && polData.length > 0) {
      setPolicyNumber(polData?.[0]?.POL_NBR);
    }
    if (sufData && sufData.length > 0) {
      setsuffixCode(sufData[0]?.SUFX_CD);
    }
  }, [polData, sufData]);

  const handleSubmit = (e) => {
    if (accountCode.length > 3) {
      setIsOpen(true);
      setAlertMsg('Please enter a 3 byte value for Account Number.');
    } else {
      setIsOpen(false);
      setLookupPress(true);
      getAcctCode(accountCode);
      e.preventDefault();
      setIsLoad(true);
      axios
        .get(
          `${BASE_URL}account/${polData?.[0]?.['POL_ENT_ID']}/${sufData?.[0]?.['SUFX_ENT_ID']}/${accountCode}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          handleGeneralData(response.data);
          setIsLoad(false);
        })
        .catch((error) => {
          // add-alert-popup
          setIsLoad(false);
        });
    }
  };

  useEffect(() => {
    setAccountCode('');
    setClearAccCode(false);
  }, [clearAccCode, setClearAccCode]);

  useEffect(() => {
    setLoadingStatus(isLoad);
  }, [isLoad]);

  useEffect(() => {
    getAcctCode(accountCode);
  }, [accountCode]);

  return (
    <React.Fragment>
      <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
        <Heading offset={5}>Account</Heading>
      </Grid.Col>
      <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
        <TextInput
          label="Code "
          value={accountCode}
          onChange={(e) => {
            return setAccountCode(e.target.value);
          }}
          isDisabled={readOnly}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSubmit(event);
            }
          }}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
        <Button
          css={{
            'abyss-button-root': { marginTop: '25px !important' },
          }}
          onClick={handleSubmit}
          isDisabled={readOnly}
        >
          Look Up
        </Button>
      </Grid.Col>
      <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
        {isOpen ? (
          <Alert
            isVisible={isOpen}
            css={{
              'abyss-alert-root': {
                alignItems: 'center',
              },
            }}
            onClose={() => setIsOpen(false)}
          >
            {alerMsg}
          </Alert>
        ) : (
          ''
        )}
      </Grid.Col>
      {/* <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>

      </Grid.Col> */}
    </React.Fragment>
  );
};
