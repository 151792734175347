import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

const SourceSystemInfo = ({ policyDetails }) => {
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5} textAlign='center'>Source System Information</Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>File ID:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.fileId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Trans. ID:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.trnId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Medical Com. Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.medCoCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Den. Comp. Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.dentCoCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Bus. Trans. Type Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.busTrnTypCd}</Text>
        </Grid.Col>

        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Health Plan Type Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.hlthPlanCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src. Policy Eff. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcPolEffDt === "9999-12-31" ? "" : policyDetails?.srcPolEffDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src. Policy Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcPolCancDt === "9999-12-31" ? " " : policyDetails?.srcPolCancDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Previous Suffix Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.prevSufxCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Change Eff. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.chgEffDt === "9999-12-31" ? "" : policyDetails?.chgEffDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src. Suffix Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcSufxCancDt === "9999-12-31" ? "" : policyDetails?.srcSufxCancDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src. Acct. Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcAcctCancDt === "9999-12-31" ? "" : policyDetails?.srcAcctCancDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src. ERISA Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcErisaCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Ret Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.retCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Open Access Indicator:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.openAccessInd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Reins. Policy Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.reinsPolCancDt === "9999-12-31" ? "" : policyDetails?.reinsPolCancDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Reins. Suffix Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.reinsSufxCancDt === "9999-12-31" ? "" : policyDetails?.reinsSufxCancDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Reins. Acct. Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.reinsAcctCancDt === "9999-12-31" ? "" : policyDetails?.reinsAcctCancDt}</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default SourceSystemInfo;
