import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import { getEnvironment } from '@src/utils/environmentUtil';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { BASE_URL } from '@src/utils/endpoints';
import { DateInput } from '@abyss/web/ui/DateInput';
import { constantColumns } from './column-constants';
import { Modal } from '@abyss/web/ui/Modal';
import { Alert } from '@abyss/web/ui/Alert';
import { Flex } from '@abyss/web/ui/Flex';
import env from '../../../env.json';

const HmoAcct = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, msid, userRolesGroup } = useAuth();
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [records, setRecords] = useState('0 records');
  const [isDeleteRecordVisible, setIsDeleteRecordVisible] = useState(false);
  const [isHmoFieldVisisble, setIsHmoFieldVisisble] = useState(false);
  const [updateHmoModal, setUpdateHmoModal] = useState(false);
  const [addHmoModal, setAddHmoModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [isUpdateRecordVisible, setIsUpdateRecordVisible] = useState(false);

  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );
  const [row, setRow] = useState({});
  useEffect(() => {
    if (isDeleteRecordVisible) {
      const timer = setTimeout(() => {
        setIsDeleteRecordVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isDeleteRecordVisible]);
  useEffect(() => {
    if (isUpdateRecordVisible) {
      const timer = setTimeout(() => {
        setIsUpdateRecordVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isUpdateRecordVisible]);
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setShowModal(false);

    let payload1 = {
      hmoRowNbr: row['HMO_ROW_NBR'],
      hmoBeginDt: row['HMOBEGINDT'],
      hmoEndDt: row['HMOENDDT'],
      hmoLegalEntNbr: row['HMO_LEGAL_ENT_NBR'],
      hmoLegalEnt: row['hmoLegalEnt'],
      hmoMktName: row['HMO_MKT_NAME'],
      hmoMktNbr: row['HMO_MKT_NBR'],
      hmoProdTyp: row['HMO_PROD_TYP'],
      hmoAcctDiv: row['HMO_ACCT_DIV'],
      lstUpdtDt: row['LSTUPDTDT'],
      lstUpdtTm: row['LST_UPDT_TM'],
      lstUpdtId: row['LST_UPDT_ID'],
      updtInd: row['UPDT_IND'],
      lglEntyGlbuNbr: row['LGL_ENTY_GLBU_NBR'],
      glLocCd: row['GL_LOC_CD'],
      hmoPct: row['HMO_PCT'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/hmoacct/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        setIsDeleteRecordVisible(true);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [hmoRowNbr, setHmoRowNbr] = useState('');
  const [hmoBeginDt, sethmoBeginDt] = useState(null);
  const [hmoEndDt, setHmoEndDt] = useState(null);
  const [hmoLegalEntNbr, setHmoLegalEntNbr] = useState('');
  const [hmoLegalEnt, setHmoLegalEnt] = useState('');
  const [hmoMktName, sethmoMktName] = useState('');
  const [hmoMktNbr, setHmoMktNbr] = useState('');
  const [hmoProdTyp, setHmoProdTyp] = useState('');
  const [hmoAcctDiv, setHmoAcctDiv] = useState('');
  const [lglEntyGlbuNbr, setlglEntyGlbuNbr] = useState('');
  const [glLocCd, setGlLocCd] = useState('');
  const [hmoPct, setHmoPct] = useState('');
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setHideAdd(true);
    setUpdateFlag(true);
    setFlag(true);
    setUpdateRow(row);
    setHmoRowNbr(row?.['HMO_ROW_NBR']);
    sethmoBeginDt(row?.['HMOBEGINDT']);
    setHmoEndDt(row?.['HMOENDDT']);
    setHmoLegalEntNbr(row?.['HMO_LEGAL_ENT_NBR']);
    setHmoLegalEnt(row?.['HMO_LEGAL_ENT']);
    sethmoMktName(row?.['HMO_MKT_NAME']);
    setHmoMktNbr(row?.['HMO_MKT_NBR']);
    setHmoProdTyp(row?.['HMO_PROD_TYP']);
    setHmoAcctDiv(row?.['HMO_ACCT_DIV']);
    setlglEntyGlbuNbr(row?.['LGL_ENTY_GLBU_NBR']);
    setGlLocCd(row?.['GL_LOC_CD']);
    setHmoPct(row?.['HMO_PCT']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
      setIsHmoFieldVisisble(true);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'hmoacct.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'hmoacct.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions:
      hasRWaccess &&
      (hideUpdate ? [deleteLinkButton] : [updateLinkButton, deleteLinkButton]),
  });
  const addRow = () => {
    setHideUpdate(true);
    setFlag(true);
    setIsHmoFieldVisisble(false);
  };
  const handleClick = () => {
    navigate(-1);
  };

  const save = async () => {
    setAddHmoModal(false);
    setHideUpdate(false);
    setFlag(false);
    const now = new Date();
    let payload = {
      hmoRowNbr: hmoRowNbr,
      hmoBeginDt: hmoBeginDt,
      hmoEndDt: hmoEndDt,
      hmoLegalEntNbr: hmoLegalEntNbr,
      hmoLegalEnt: hmoLegalEnt,
      hmoMktName: hmoMktName,
      hmoMktNbr: hmoMktNbr,
      hmoProdTyp: hmoProdTyp,
      hmoAcctDiv: hmoAcctDiv,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: msid.substr(0, 7).toUpperCase(),
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'Y',
      lglEntyGlbuNbr: lglEntyGlbuNbr,
      glLocCd: glLocCd,
      hmoPct: hmoPct,
    };
    setHmoRowNbr('');
    sethmoBeginDt(null);
    setHmoEndDt(null);
    setHmoLegalEntNbr('');
    setHmoLegalEnt('');
    sethmoMktName('');
    setHmoMktNbr('');
    setHmoProdTyp('');
    setHmoAcctDiv('');
    setlglEntyGlbuNbr('');
    setGlLocCd('');
    setHmoPct('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/hmoacct/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };

  const addHmoacct = () => {
    setAddHmoModal(true);
  };
  const update = async () => {
    setUpdateHmoModal(false);
    setHideAdd(false);
    setFlag(false);
    const now = new Date();
    let payload = {
      newRecord: {
        hmoRowNbr: hmoRowNbr,
        hmoBeginDt: hmoBeginDt,
        hmoEndDt: hmoEndDt,
        hmoLegalEntNbr: hmoLegalEntNbr,
        hmoLegalEnt: hmoLegalEnt,
        hmoMktName: hmoMktName,
        hmoMktNbr: hmoMktNbr,
        hmoProdTyp: hmoProdTyp,
        hmoAcctDiv: hmoAcctDiv,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: msid.substr(0, 7).toUpperCase(),
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
        lglEntyGlbuNbr: lglEntyGlbuNbr,
        glLocCd: glLocCd,
        hmoPct: hmoPct,
        // hideRecord: true
      },
      oldRecord: {
        hmoRowNbr: updateRow['HMO_ROW_NBR'],
        hmoBeginDt: updateRow['HMO_BEGIN_DT'],
        hmoEndDt: updateRow['HMO_END_DT'],
        hmoLegalEntNbr: updateRow['HMO_LEGAL_ENT_NBR'],
        hmoLegalEnt: updateRow['HMO_LEGAL_ENT'],
        hmoMktName: updateRow['HMO_MKT_NAME'],
        hmoMktNbr: updateRow['HMO_MKT_NBR'],
        hmoProdTyp: updateRow['HMO_PROD_TYP'],
        hmoAcctDiv: updateRow['HMO_ACCT_DIV'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: 'Y',
        lglEntyGlbuNbr: updateRow['LGL_ENTY_GLBU_NBR'],
        glLocCd: updateRow['GL_LOC_CD'],
        hmoPct: updateRow['HMO_PCT'],
      },
    };
    setHmoRowNbr('');
    sethmoBeginDt(null);
    setHmoEndDt(null);
    setHmoLegalEntNbr('');
    setHmoLegalEnt('');
    sethmoMktName('');
    setHmoMktNbr('');
    setHmoProdTyp('');
    setHmoAcctDiv('');
    setlglEntyGlbuNbr('');
    setGlLocCd('');
    setHmoPct('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/hmoacct/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsUpdateRecordVisible(true);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };

  const updateHmoacct = () => {
    setUpdateHmoModal(true);
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setHmoRowNbr('');
    sethmoBeginDt(null);
    setHmoEndDt(null);
    setHmoLegalEntNbr('');
    setHmoLegalEnt('');
    sethmoMktName('');
    setHmoMktNbr('');
    setHmoProdTyp('');
    setHmoAcctDiv('');
    setlglEntyGlbuNbr('');
    setGlLocCd('');
    setHmoPct('');
  };

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);

  const close1 = () => {
    setFlag(false);
    setUpdateFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
  };

  useEffect(() => {
    const hasTableCTSReadWriteAccess = env.Table_CTS_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    setHasRWaccess(hasTableCTSReadWriteAccess);
  }, [userRolesGroup]);

  const onSubmit = () => {};

  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Add Record"
          isOpen={addHmoModal}
          onClose={() => setAddHmoModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setAddHmoModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Delete Record"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Section>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_ROW_NBR: </b>
              </span>
              <span>
                <b>{row['HMO_ROW_NBR']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_BEGIN_DT: </b>
              </span>
              <span>
                <b>{row['HMOBEGINDT']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_END_DT: </b>
              </span>
              <span>
                <b>{row['HMOENDDT']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_LEGAL_ENT_NBR: </b>
              </span>
              <span>
                <b>{row['HMO_LEGAL_ENT_NBR']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_LEGAL_ENT: </b>
              </span>
              <span>
                <b>{row['HMO_LEGAL_ENT']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_MKT_NAME: </b>
              </span>
              <span>
                <b>{row['HMO_MKT_NAME']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>HMO_MKT_NBR: </b>
              </span>
              <span>
                <b>{row['HMO_MKT_NBR']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>HMO_PROD_TYP: </b>
              </span>
              <span>
                <b>{row['HMO_PROD_TYP']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>HMO_ACCT_DIV: </b>
              </span>
              <span>
                <b>{row['HMO_ACCT_DIV']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_DT: </b>
              </span>
              <span>
                <b>{row['LSTUPDTDT']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_TM: </b>
              </span>
              <span>
                <b>{row['LST_UPDT_TM']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_ID: </b>
              </span>
              <span>
                <b>{row['LST_UPDT_ID']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>UPDT_IND: </b>
              </span>
              <span>
                <b>{row['UPDT_IND']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>LGL_ENTY_GLBU_NBR: </b>
              </span>
              <span>
                <b>{row['LGL_ENTY_GLBU_NBR']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>GL_LOC_CD: </b>
              </span>
              <span>
                <b>{row['GL_LOC_CD']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>HMO_PCT: </b>
              </span>
              <span>
                <b>{row['HMO_PCT']}</b>
              </span>
            </div>
            <br />
            Are you sure you want to delete this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={deleteRows}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>

        <Modal
          title="Confirm Update"
          isOpen={updateHmoModal}
          onClose={() => setUpdateHmoModal(false)}
          size="lg"
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_ROW_NBR:</b>
                {updateRow['HMO_ROW_NBR']}
              </span>
              <span>
                <b>HMO_ROW_NBR:</b>
                {hmoRowNbr}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_BEGIN_DT:</b>
                {row['HMOBEGINDT']}
              </span>
              <span>
                <b>HMO_BEGIN_DT:</b>
                {hmoBeginDt}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_END_DT:</b>
                {row['HMOENDDT']}
              </span>
              <span>
                <b>HMO_END_DT:</b>
                {hmoEndDt}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_LEGAL_ENT_NBR:</b>
                {updateRow['HMO_LEGAL_ENT_NBR']}
              </span>
              <span>
                <b>HMO_LEGAL_ENT_NBR:</b>
                {hmoLegalEntNbr}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_LEGAL_ENT:</b>
                {updateRow['HMO_LEGAL_ENT']}
              </span>
              <span>
                <b>HMO_LEGAL_ENT:</b>
                {hmoLegalEnt}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_MKT_NAME:</b>
                {updateRow['HMO_MKT_NAME']}
              </span>
              <span>
                <b>HMO_MKT_NAME:</b>
                {hmoMktName}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_MKT_NBR:</b>
                {updateRow['HMO_MKT_NBR']}
              </span>
              <span>
                <b>HMO_MKT_NBR:</b>
                {hmoMktNbr}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_PROD_TYP:</b>
                {updateRow['HMO_PROD_TYP']}
              </span>
              <span>
                <b>HMO_PROD_TYP:</b>
                {hmoProdTyp}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_ACCT_DIV:</b>
                {updateRow['HMO_ACCT_DIV']}
              </span>
              <span>
                <b>HMO_ACCT_DIV:</b>
                {hmoAcctDiv}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>LGL_ENTY_GLBU_NBR:</b>
                {updateRow['LGL_ENTY_GLBU_NBR']}
              </span>
              <span>
                <b>LGL_ENTY_GLBU_NBR:</b>
                {lglEntyGlbuNbr}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>GL_LOC_CD:</b>
                {updateRow['GL_LOC_CD']}
              </span>
              <span>
                <b>GL_LOC_CD:</b>
                {glLocCd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_PCT:</b>
                {updateRow['HMO_PCT']}
              </span>
              <span>
                <b>HMO_PCT:</b>
                {hmoPct}
              </span>
            </div>

            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateHmoModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>

        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>

              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                <Text fontWeight={300} css={{ fontSize: '14px' }}>
                  {records}
                </Text>
              </Grid.Col>
              <br />
              {hasRWaccess && (
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={flag || hideAdd ? true : false}
                >
                  + Add Row
                </Button>
              )}
              <Alert
                title="You have successfully deleted record from table"
                variant="success"
                isVisible={isDeleteRecordVisible}
                onClose={() => {
                  setIsDeleteRecordVisible(false);
                }}
              />
              <Alert
                title="You have successfully updated record from table"
                variant="success"
                isVisible={isUpdateRecordVisible}
                onClose={() => {
                  setIsUpdateRecordVisible(false);
                }}
              />

              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    {isHmoFieldVisisble ? (
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HMO_ROW_NBR"
                          value={hmoRowNbr}
                          onChange={(e) => setHmoRowNbr(e.target.value)}
                          isDisabled
                        />
                      </Grid.Col>
                    ) : null}

                    <Grid.Col span={{ xs: 4 }}>
                      <DateInput
                        label="HMO_BEGIN_DT"
                        value={hmoBeginDt}
                        onChange={(e) => sethmoBeginDt(e)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <DateInput
                        label="HMO_END_DT"
                        value={hmoEndDt}
                        onChange={(e) => setHmoEndDt(e)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_LEGAL_ENT_NBR"
                        value={hmoLegalEntNbr}
                        onChange={(e) => setHmoLegalEntNbr(e.target.value)}
                        maxLength={3}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_LEGAL_ENT"
                        value={hmoLegalEnt}
                        onChange={(e) => setHmoLegalEnt(e.target.value)}
                        maxLength={50}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_MKT_NAME"
                        value={hmoMktName}
                        onChange={(e) => sethmoMktName(e.target.value)}
                        maxLength={25}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_MKT_NBR"
                        value={hmoMktNbr}
                        onChange={(e) => setHmoMktNbr(e.target.value)}
                        maxLength={5}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_PROD_TYP"
                        value={hmoProdTyp}
                        onChange={(e) => setHmoProdTyp(e.target.value)}
                        maxLength={25}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_ACCT_DIV"
                        value={hmoAcctDiv}
                        onChange={(e) => setHmoAcctDiv(e.target.value)}
                        maxLength={3}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="LGL_ENTY_GLBU_NBR"
                        value={lglEntyGlbuNbr}
                        onChange={(e) => setlglEntyGlbuNbr(e.target.value)}
                        maxLength={5}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="GL_LOC_CD"
                        value={glLocCd}
                        onChange={(e) => setGlLocCd(e.target.value)}
                        maxLength={4}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_PCT"
                        value={hmoPct}
                        onChange={(e) => setHmoPct(e.target.value)}
                        maxLength={6}
                      />
                    </Grid.Col>
                  </Grid>
                  <Flex direction="row" style={{ margin: '20px' }}>
                    <Button onClick={updateFlag ? updateHmoacct : addHmoacct}>
                      Save
                    </Button>

                    <Button
                      onClick={updateFlag ? close : close1}
                      style={{ marginLeft: '20px' }}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(HmoAcct, 'table_cts_ro_rw');
