import React, { useState, useEffect, useSyncExternalStore } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Heading } from '@abyss/web/ui/Heading';
import { DateInput } from '@abyss/web/ui/DateInput';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import { formateDate } from '@src/utils/dateUtil';
import { convertDateFormat } from '@src/utils/convertDateFormat';

import {
  updateInquirySearch,
  updateInquirySearchError,
  updateInquirySearchSuccess,
  setPayload,
} from '@src/redux/action/CMDB/suspectErrror/inquiry/inquiryAPI';
import { inquirySearchApi } from '@src/redux/api/CMDB/suspectError/inquiry/inquiryAPI';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectableList } from './SelectableList';
import { set } from 'lodash';

const Inquiry = () => {
  const dispatch = useDispatch();
  const { token, readOnly } = useAuth();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { navigate, getLocation } = useRouter();
  const location = getLocation();
  const [isLoad, setIsLoad] = useState(false);
  const [reportType, setReportType] = useState('');
  const [displayLevel, setDisplayLevel] = useState('');
  const [statusRadio, setStatusRadio] = useState('O');
  const [policyNumber, setPolicyNumber] = useState('');
  const [suffixCode, setSuffixCode] = useState('');
  const [accountCode, setAccountCode] = useState('');
  const [addedPolicyNumber, setAddedPolicyNumber]: any = useState([]);
  const [addedSuffixCode, setAddedSuffixCode]: any = useState([]);
  const [addedAccountCode, setAddedAccountCode]: any = useState([]);
  const [claimSourceCode, setClaimSourceCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [priorityLevel, setPriorityLevel] = useState('');
  const [dollarInd, setDollarInd] = useState('O');
  const [dollarAmount, setDollarAmount] = useState('');
  const [chargeDate, setChargeDate] = useState('');
  const [chargeDateFrom, setChargeDateFrom] = useState('');
  const [chargeDateTo, setChargeDateTo] = useState('');
  const [subscriberId, setSubscriberId] = useState('');
  const [addedSubscriberId, setAddedSubscriberId]: any = useState([]);

  const [addedSeriesDesignator, setAddedSeriesDesignator]: any = useState([]);
  const [seriesDesignator, setSeriesDesignator] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [cps, setCps] = useState('');
  // const [tempSD, setTempSD] = useState('');
  const [tempChk, setTempChk] = useState('');
  const [tempCps, setTempCps] = useState('');
  const [sdall, setSdall]: any = useState([]);
  const [chkall, setChkall]: any = useState([]);
  const [cpsall, setCpsall]: any = useState([]);

  const [legalEntity, setLegalEntity] = useState('');
  const [glReinsurance, setGlReinsurance] = useState(null);
  const [legalEntityGroup, setLegalEntityGroup] = useState('');
  const [fundingArrangment, setFundingArrangement] = useState('');
  const [toDateChangeErr, setToDateChangeErr] = useState('');
  const [inquiryDropdownOptions, setInquiryDropDownOptions]: any = useState([]);
  const id = 'suspectErrorDropdown';
  const [errors, setErrors]: any = useState({});
  const [isValid, setIsValid]: any = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isErrorFinTagOpen, setIsErrorFinTagOpen] = useState(false);
  const [dollarModal, setDollarModal] = useState(false);

  const [isOpenSuffix, setIsopenSuffix] = useState(false);
  const [isOpenAccount, setISOpenAccount] = useState(false);
  const [selectedPolicyNumbers, setSelectedPolicyNumbers] = useState<number[]>(
    []
  );
  const [selectedSuffixCode, setSelectedSuffixCode] = useState<number[]>([]);
  const [selectedAccountCode, setSelectedAccountCode] = useState<number[]>([]);
  const [selectedSubscriberId, setSelectedSubscriberId] = useState<number[]>(
    []
  );
  const [selectedSeriesDesignator, setSelectedSeriesDesignator] = useState<
    number[]
  >([]);

  useEffect(() => {
    const data = location?.state?.data;
    setReportType(data?.reportType ? data?.reportType : '');
    setDisplayLevel(data?.displayType ? data?.displayType : '');
    setAddedPolicyNumber(data?.policyNumber ? data?.policyNumber.split(',') : []);
    setAddedSuffixCode(data?.suffixCode ? data?.suffixCode.split(',').filter(item => item !== "") : []);
    setAddedAccountCode(data?.accountNumber ? data?.accountNumber.split(',') : []);
    setClaimSourceCode(data?.claimSrcCode ? data?.claimSrcCode : '');
    setErrorCode(data?.errorCode ? data?.errorCode : '');
    setPriorityLevel(data?.prorityLvl ? data?.prorityLvl : '');
    setDollarInd(data?.dollarInd ? data?.dollarInd : 'O');
    setDollarAmount(data?.dollarAmount ? data?.dollarAmount : '');
    setChargeDateFrom(data?.chargeDateForm ? formateDate(data?.chargeDateForm) : '');
    setChargeDateTo(data?.chanrgeDateTo ? formateDate(data?.chanrgeDateTo) : '');
    setAddedSubscriberId(data?.subscriberId ? data?.subscriberId.split(',') : []);
    setAddedSeriesDesignator(data?.seriesDesg ?
      decodeURIComponent(data?.seriesDesg.replaceAll('perct', '%')).split(',')
      : []);
    setStatusRadio(data?.type ? data?.type : 'O');
    setLegalEntity(data?.lglEntity ? data?.lglEntity : '');
    setGlReinsurance(data?.glReInsurance !== (null || undefined) ? data?.glReInsurance : null);
    setLegalEntityGroup(data?.lglEntityGrp ? data?.lglEntityGrp : '');
    setFundingArrangement(data?.fundingArrangement ? data?.fundingArrangement : '');
  }, [location?.state?.data]);

  const handleChange = (name, value) => {
    switch (name) {
      case 'reportType':
        setReportType(value);
        break;
      case 'displayLevel':
        setDisplayLevel(value);
        break;
      case 'statusRadio':
        setStatusRadio(value);
        break;

      case 'claimSourceCode':
        setClaimSourceCode(value);
        break;

      case 'errorCode':
        setErrorCode(value);
        break;
      case 'priorityLevel':
        setPriorityLevel(value);
        break;
      case 'dollarInd':
        setDollarInd(value);
        break;
      case 'dollarAmount':
        setDollarAmount(value);
        break;

      case 'chargeDateFrom':
        setChargeDateFrom(value);
        break;

      case 'chargeDateTo':
        setChargeDateTo(value);
        break;

      case 'checkNumber':
        setCheckNumber(value);
        setTempChk(value.replaceAll(' ', '_'));
        break;

      case 'cps':
        setCps(value);
        setTempCps(value.replaceAll(' ', '_'));
        break;

      case 'legalEntity':
        setLegalEntity(value);
        break;
      case 'glReinsurance':
        setGlReinsurance(value);
        break;

      case 'legalEntityGroup':
        setLegalEntityGroup(value);
        break;

      case 'fundingArrangment':
        setFundingArrangement(value);
        break;

      default:
        break;
    }
  };

  const chargedDate = { from: chargeDateFrom, to: chargeDateTo };

  const payload = {
    policyNumber: addedPolicyNumber?.join(','),
    suffixCode: addedSuffixCode.includes("__") ? addedSuffixCode?.join(',') + "," : addedSuffixCode?.join(','),
    accountNumber: addedAccountCode?.join(','),
    reportType: reportType,
    displayType: displayLevel,
    errorCode: errorCode,
    prorityLvl: priorityLevel,
    dollarAmount: dollarAmount,
    dollarInd: dollarInd,
    claimSrcCode: claimSourceCode,
    claimSrcCodedis: inquiryDropdownOptions?.sourceSystemList?.find(item => item.value === claimSourceCode)?.label,
    subscriberId: addedSubscriberId.join(','),
    seriesDesg: encodeURIComponent(addedSeriesDesignator.join(',')).replaceAll('%', 'perct'),
    sdall: encodeURIComponent(sdall?.join(',')).replaceAll('%', 'perct'),
    chkall: encodeURIComponent(chkall?.join(',')).replaceAll('%', 'perct'),
    cpsall: encodeURIComponent(cpsall?.join(',')).replaceAll('%', 'perct'),
    chargeDateForm: chargeDateFrom
      ? convertDateFormat(chargeDateFrom)
      : '',
    chanrgeDateTo: chargeDateTo
      ? convertDateFormat(chargeDateTo)
      : '',
    lglEntity: legalEntity,
    glReInsurance: glReinsurance,
    fundingArrangement: fundingArrangment,
    fndgInsType: inquiryDropdownOptions?.fndCd?.find(
      (item) => item.value === fundingArrangment
    )?.label,
    lglEntityGrp: legalEntityGroup,
    type: statusRadio,
  };
  const handleValidation = () => {
    const errors: any = {};
    setToDateChangeErr('');
    let isValid = true;

    if (chargeDateFrom != null && chargeDateTo != null) {
      if (formateDate(chargeDateFrom) > formateDate(chargeDateTo)) {
        setToDateChangeErr('From date is not before To date');
        isValid = false;
        window.scrollTo(0, 0);
      }
    }
    if (!reportType) {
      errors.reportType = 'Report Type is a required field';
      isValid = false;
      window.scrollTo(0, 0);
    }
    if (!displayLevel) {
      errors.displayLevel = 'Display Level is a required field';
      isValid = false;
      window.scrollTo(0, 0);
    }

    if (isValid) {
      if (
        !(
          errorCode ||
          addedPolicyNumber.length !== 0 ||
          addedSuffixCode.length !== 0 ||
          addedAccountCode.length !== 0 ||
          priorityLevel ||
          dollarAmount ||
          claimSourceCode ||
          chargeDate ||
          addedSubscriberId.length !== 0 ||
          checkNumber ||
          legalEntity ||
          glReinsurance !== null ||
          legalEntityGroup ||
          fundingArrangment
        )
      ) {
        setIsOpen(true);
        isValid = false;
        return;
      }
      if (dollarAmount && !(displayLevel == "") && !(reportType == "")) {
        if (!(
          errorCode ||
          addedPolicyNumber.length !== 0 ||
          addedSuffixCode.length !== 0 ||
          addedAccountCode.length !== 0 ||
          priorityLevel ||
          claimSourceCode ||
          chargeDate ||
          addedSubscriberId.length !== 0 ||
          checkNumber ||
          legalEntity ||
          glReinsurance !== null ||
          legalEntityGroup ||
          fundingArrangment
        )) {
          setDollarModal(true);
          isValid = false;
          return;
        }
      }
      if (legalEntity && !legalEntityGroup) {
        errors.legalEntity = 'Legal entity can not be chosen on its own.';
        setIsErrorFinTagOpen(true);
        isValid = false;
      }
      if (glReinsurance !== null && !legalEntityGroup) {
        errors.glReinsurance = 'GL Insurance can not be chosen on its own.';
        setIsErrorFinTagOpen(true);
        isValid = false;
      }
      if (
        (reportType == 'CS' &&
          displayLevel == 'S' &&
          addedSuffixCode.length > 0 &&
          addedAccountCode.length > 0) ||
        (reportType == 'EC' &&
          displayLevel == 'S' &&
          addedSuffixCode.length > 0 &&
          addedAccountCode.length > 0) ||
        (reportType == 'CLS' &&
          displayLevel == 'S' &&
          addedSuffixCode.length > 0 &&
          addedAccountCode.length > 0) ||
        (reportType == 'CS' &&
          displayLevel == 'P' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length > 0) ||
        (reportType == 'CS' &&
          displayLevel == 'A' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length > 0) ||
        (reportType == 'EC' &&
          displayLevel == 'P' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length > 0) ||
        (reportType == 'CLS' &&
          displayLevel == 'P' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length > 0) ||
        (reportType == 'CLS' &&
          displayLevel == 'A' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length > 0) ||
        (reportType === 'CS' &&
          displayLevel === 'S' &&
          addedSuffixCode.length > 0) ||
        (reportType === 'CS' &&
          displayLevel === 'P' &&
          addedSuffixCode.length > 0) ||
        (reportType === 'CS' &&
          displayLevel === 'A' &&
          addedSuffixCode.length > 0) ||
        (reportType === 'EC' &&
          displayLevel === 'P' &&
          addedSuffixCode.length > 0) ||
        (reportType === 'EC' &&
          displayLevel === 'S' &&
          addedSuffixCode.length > 0) ||
        (reportType === 'EC' &&
          displayLevel === 'A' &&
          addedSuffixCode.length > 0) ||
        (reportType === 'CLS' &&
          displayLevel === 'P' &&
          addedSuffixCode.length > 0) ||
        (reportType === 'CLS' &&
          displayLevel === 'S' &&
          addedSuffixCode.length > 0) ||
        (reportType === 'CLS' &&
          displayLevel === 'A' &&
          addedSuffixCode.length > 0)
      ) {
        if (!(addedPolicyNumber.length !== 0 || errorCode)) {
          setIsopenSuffix(true);
          isValid = false;
        }
      }

      if (
        (reportType == 'CS' &&
          displayLevel == 'P' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0) ||
        (reportType == 'EC' &&
          displayLevel == 'A' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0) ||
        (reportType == 'EC' &&
          displayLevel == 'P' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0) ||
        (reportType == 'EC' &&
          displayLevel == 'S' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0) ||
        (reportType == 'CLS' &&
          displayLevel == 'A' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0) ||
        (reportType == 'CLS' &&
          displayLevel == 'P' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0) ||
        (reportType == 'CLS' &&
          displayLevel == 'S' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0) ||
        (reportType == 'CS' &&
          displayLevel == 'A' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0) ||
        (reportType == 'CS' &&
          displayLevel == 'S' &&
          addedAccountCode.length > 0 &&
          addedSuffixCode.length === 0)
      ) {
        if (
          !(
            addedPolicyNumber.length !== 0 ||
            errorCode ||
            addedSubscriberId.length !== 0 ||
            legalEntityGroup ||
            fundingArrangment
          )
        ) {
          setISOpenAccount(true);
          isValid = false;
        }
      }
      if (checkNumber) {
        errors.checkNumber = "Please click on Add button to add the new check number to the list";
        isValid = false;
      };
      if (cps) {
        errors.cps = "Please click on Add button to add the new CPS to the list";
        isValid = false;
      };
    }

    setErrors(errors);
    setIsValid(isValid);
    return isValid;
  };

  useEffect(() => {
    const sdallValues: any = [];
    const chkallValues: any = [];
    const cpsallValues: any = [];
    addedSeriesDesignator.forEach((item) => {
      const [seriesDes, checkPart, cpsPart] = item.split("-");
      sdallValues.push(seriesDes);
      if (checkPart !== "All") {
        if (checkPart.includes("(*)")) {
          chkallValues.push(`Like ${checkPart}`);
        } else {
          chkallValues.push(checkPart);
        };
      };
      if (cpsPart !== "All") {
        if (cpsPart.includes("(*)")) {
          cpsallValues.push(`Like ${cpsPart}`);
        } else {
          cpsallValues.push(cpsPart);
        };
      };
    });
    setSdall(sdallValues);
    setChkall(chkallValues);
    setCpsall(cpsallValues);
  }, [addedSeriesDesignator]);

  const handlePageSubmit = async (e) => {
    setIsLoad(true);
    e.preventDefault();
    if (handleValidation()) {
      try {
        dispatch(setPayload(payload));
        dispatch(updateInquirySearch(payload));
        const response = await inquirySearchApi(payload, config);
        if (response.status === 200) {
          dispatch(updateInquirySearchSuccess(response.data));
          navigate('/cmdb/suspect-errors/inquiry-search', {
            state: {
              sourceSystemList: inquiryDropdownOptions?.sourceSystemList,
              data: payload,
            },
          });
        }
        setIsLoad(false);
      } catch (error) {
        dispatch(updateInquirySearchError(error));
        setIsLoad(false);
      }
      setIsLoad(false);
    }
    setIsLoad(false);
  };

  const handleCancel = () => {
    setReportType('');
    setFundingArrangement('');
    setLegalEntityGroup('');
    setGlReinsurance(null);
    setLegalEntity('');
    setCps('');
    setCheckNumber('');
    setChargeDateTo('');
    setChargeDateFrom('');
    setDollarAmount('');
    setDollarInd('O');
    setPriorityLevel('');
    setErrorCode('');
    setClaimSourceCode('');
    setStatusRadio('O');
    setDisplayLevel('');
    setAddedPolicyNumber([]);
    setAddedSuffixCode([]);
    setAddedSeriesDesignator([]);
    setAddedAccountCode([]);
    setAddedSubscriberId([]);
    setPolicyNumber('');
    setSuffixCode('');
    setAccountCode('');
    setSeriesDesignator('');
    setSubscriberId('');
    setErrors('');
    setToDateChangeErr('');
  };

  const fetchDropdownData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}cmdb-service/suspectErrDropdown/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response && response.data) {
        setInquiryDropDownOptions(response.data);
      } else {
        console.error('No data found');
      }
    } catch (error) {
      // add-alert-popup
    }
  };

  useEffect(() => {
    if (token) fetchDropdownData();
  }, [token]);

  const handlePolicyNumberChange = (e) => {
    setPolicyNumber(e.target.value);
  };

  const handleSuffixCodeChange = (e) => {
    setSuffixCode(e.target.value.replaceAll(" ","."));
  };
  const handleAccountCodeChange = (e) => {
    setAccountCode(e.target.value);
  };
  const handleSubscriberIdChange = (e) => {
    setSubscriberId(e.target.value);
  };
  const handleSeriesDesignatorChange = (e) => {
    setSeriesDesignator(e.target.value);
  };
  const handleAddPolicyNumber = () => {
    let errors: any = {};
    if (policyNumber) {
      const regEx: RegExp = /^\d{2}[A-Za-z0-9]\d{4}$/;

      let isValid = policyNumber.match(regEx);
      const exists = addedPolicyNumber.some(
        (option) => option === policyNumber
      );

      if (policyNumber.length == 7) {
        if (!isValid) {
          errors.policyNumber =
            'Policy Number must be numeric, Except for the 3rd position.';
          setErrors(errors);
          return;
        }
      } else {
        errors.policyNumber = 'Policy Number must be 7 digit Number';
        setErrors(errors);
        return;
      }

      if (exists) {
        errors.policyNumber = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedPolicyNumber((prev) => [
        ...prev,
        policyNumber.toLocaleUpperCase(),
      ]);
      setPolicyNumber('');
      setErrors('');
    } else {
      errors.policyNumber = 'Please enter the Policy Number';
      setErrors(errors);
    }
  };

  const handleAddSuffixCode = () => {
    let errors: any = {};
    if (suffixCode) {
      const isValid = /^([A-Za-z]{2}$|^\.\.|[A-Za-z])$/.test(suffixCode);
      const exists = addedSuffixCode.some((option) => option == suffixCode.replaceAll(".","_").toLocaleUpperCase());
      if (suffixCode.length > 2) {
        errors.suffixCode = 'Suffix code must be 2 digit alphabet';
        setErrors(errors);
        return;
      }
      if (!isValid) {
        errors.suffixCode = 'Suffix code must be 2 dots or Alphabetic';
        setErrors(errors);
        return;
      }
      if (exists) {
        errors.suffixCode = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedSuffixCode((prev) => [...prev, suffixCode.replaceAll(".","_").toUpperCase()]);
      setSuffixCode('');
      setErrors('');
    } else {
      errors.suffixCode = 'Please enter the Suffix Code';
      setErrors(errors);
    }
  };

  const handleAddAccountCode = () => {
    let errors: any = {};
    if (accountCode) {
      const isValid = /^\d{3}$/.test(accountCode);
      const exists = addedAccountCode.some((option) => option === accountCode);
      if (!isValid) {
        errors.accountCode = 'Account code must be 3 digit alphabet';
        setErrors(errors);
        return;
      }
      if (exists) {
        errors.accountCode = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedAccountCode((prev) => [...prev, accountCode]);
      setAccountCode('');
      setErrors('');
    } else {
      errors.accountCode = 'Please enter the Account Code';
      setErrors(errors);
    }
  };

  const handleAddSubId = () => {
    let errors: any = {};
    if (subscriberId) {
      const isValid = /^\d{9}$/.test(subscriberId);
      const exists = addedSubscriberId.some(
        (option) => option === subscriberId
      );

      if (!isValid) {
        errors.subscriberId = 'Subscriber ID number should be 9 digit number';
        setErrors(errors);
        return;
      }
      if (exists) {
        errors.subscriberId = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedSubscriberId((prev) => [...prev, subscriberId]);
      setSubscriberId('');
      setErrors('');
    } else {
      errors.subscriberId = ' Please enter the Subscriber Id';
      setErrors(errors);
    }
  };

  const handleAddSeriesId = () => {
    let errors: any = {};
    if (seriesDesignator) {
      const isValidSeriesDesg = /^[A-Za-z0-9]{2}$|^\s{2}$/.test(seriesDesignator);
      const isValidCheckNum = /^[0-9]+$/.test(checkNumber);
      const isValidCps = /^[0-9]+$/.test(cps);

      if (!isValidSeriesDesg) {
        errors.seriesDesignator = 'Series Designator must be 2 digit';
      };
      if (checkNumber && !isValidCheckNum) {
        errors.checkNumber = 'Check Number must be numeric';
      };
      if (cps && !isValidCps) {
        errors.cps = 'CPS# must be numeric';
      };
      if (Object.keys(errors). length > 0) {
        setErrors(errors);
        return;
      };

      const checkPart = tempChk === "" ? "All" : checkNumber.length < 8 ? `${tempChk}(*)`.toLocaleUpperCase() : tempChk.toLocaleUpperCase();
      const cpsPart = tempCps === "" ? "All" : cps.length < 8 ? `${tempCps}(*)`.toLocaleUpperCase() : tempCps.toLocaleUpperCase();
      const addSdChkCps = `${seriesDesignator.toLocaleUpperCase()}-${checkPart}-${cpsPart}`;
      const exists = addedSeriesDesignator.some(
        (option) => option === addSdChkCps
      );
      if (exists) {
        errors.seriesDesignator = 'You have entered a duplicate value';
        if(checkNumber) errors.checkNumber = 'You have entered a duplicate value';
        if(cps) errors.cps = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }
      setAddedSeriesDesignator((prev) => [...prev, addSdChkCps]);
      setSeriesDesignator('');
      setCheckNumber('');
      setCps('');
      setTempChk('');
      setTempCps('');
      setErrors('');
    } else {
      errors.seriesDesignator = 'Please enter the Series Designator';
      setErrors(errors);
    }
  };

  const handleSelectItem = (index, selectedItem, setSelectedItem) => {
    if (!selectedItem.includes(index)) {
      setSelectedItem([index]);
    } else {
      setSelectedItem((prev) => prev.filter((item) => item !== index));
    }
  };

  const removeSelectedItem = (
    addedItem,
    setAddedItem,
    selectedItem,
    setSelectedItem
  ) => {
    const updatedItem = addedItem.filter(
      (_, index) => !selectedItem.includes(index)
    );
    setAddedItem(updatedItem);
    setSelectedItem([]);
  };

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 11 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '220px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Heading css={{ textAlign: 'center' }} offset={5}>
                Suspect Error Inquiry Search Criteria
              </Heading>
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <RadioGroup
                    label="Report Type:"
                    display="row"
                    onChange={(e) => handleChange('reportType', e.target.value)}
                    errorMessage={errors.reportType}
                    value={reportType}
                    isDisabled={readOnly}
                  >
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Case Structure" value="CS" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Error Code" value="EC" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Claim Source" value="CLS" />
                    </Grid.Col>
                  </RadioGroup>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <RadioGroup
                    label="Display Level:"
                    display="row"
                    onChange={(e) => {
                      handleChange('displayLevel', e.target.value);
                    }}
                    value={displayLevel}
                    errorMessage={errors.displayLevel}
                    isDisabled={readOnly}
                  >
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Policy" value="P" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Suffix" value="S" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Account" value="A" />
                    </Grid.Col>
                  </RadioGroup>
                </Grid.Col>
              </Grid>
              <Grid css={{ 'abyss-grid': { marginTop: '0.5rem' } }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading css={{ textAlign: 'center' }} offset={5}>
                    General Information
                  </Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Policy Number"
                    value={policyNumber}
                    onChange={handlePolicyNumberChange}
                    errorMessage={errors.policyNumber}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddPolicyNumber}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Suffix Code"
                    subText="Enter . (dot) if you want to give a space."
                    value={suffixCode}
                    onChange={handleSuffixCodeChange}
                    errorMessage={errors.suffixCode}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddSuffixCode}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Account Code"
                    value={accountCode}
                    onChange={handleAccountCodeChange}
                    errorMessage={errors.accountCode}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddAccountCode}
                  >
                    Add
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedPolicyNumber}
                    selectedItems={selectedPolicyNumbers}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedPolicyNumbers,
                        setSelectedPolicyNumbers
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{ width: '100px' }}
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedPolicyNumber,
                        setAddedPolicyNumber,
                        selectedPolicyNumbers,
                        setSelectedPolicyNumbers
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedSuffixCode}
                    selectedItems={selectedSuffixCode}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedSuffixCode,
                        setSelectedSuffixCode
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{ width: '100px' }}
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedSuffixCode,
                        setAddedSuffixCode,
                        selectedSuffixCode,
                        setSelectedSuffixCode
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedAccountCode}
                    selectedItems={selectedAccountCode}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedAccountCode,
                        setSelectedAccountCode
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{ width: '100px' }}
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedAccountCode,
                        setAddedAccountCode,
                        selectedAccountCode,
                        setSelectedAccountCode
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid css={{ 'abyss-grid': { marginTop: '2rem' } }}>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Error Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={errorCode}
                    options={inquiryDropdownOptions?.errorCodeList?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    onChange={(value) => {
                      handleChange('errorCode', value);
                    }}
                    errorMessage={errors.errorCode}
                    isDisabled={readOnly}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 4 }}>
                  <SelectInput
                    label="Priority Level"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={[
                      { id: 1, label: '01', value: '1' },
                      { id: 2, label: '02', value: '2' },
                      { id: 3, label: '03', value: '3' },
                      { id: 4, label: '07', value: '7' },
                      { id: 5, label: '08', value: '8' },
                      { id: 6, label: '09', value: '9' },
                    ]}
                    isDisabled={readOnly}
                    value={priorityLevel}
                    onChange={(value) => {
                      handleChange('priorityLevel', value);
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <RadioGroup
                    label="Dollar Amount"
                    display="column"
                    onChange={(e) => handleChange('dollarInd', e.target.value)}
                    value={dollarInd}
                    isDisabled={readOnly}
                  >
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Over" value="O" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Under" value="U" />
                    </Grid.Col>
                  </RadioGroup>
                </Grid.Col>
                <Grid.Col
                  span={{ xs: 2, md: 2, lg: 2, xl: 2 }}
                  css={{ marginTop: '50px', marginLeft: '-45px' }}
                >
                  <Grid>
                    <Grid.Col
                      span={{ xs: 1, md: 1, lg: 1, xl: 1 }}
                      css={{ marginTop: '10px' }}
                    >
                      <Text css={{ fontWeight: 'bold', marginTop: '3px' }}>
                        $
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 10, md: 10, lg: 10, xl: 10 }}>
                      <TextInput
                        onChange={(e) =>
                          handleChange('dollarAmount', e.target.value)
                        }
                        value={dollarAmount}
                      />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Claim Source Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    isDisabled={readOnly}
                    value={claimSourceCode}
                    onChange={(value) => {
                      handleChange('claimSourceCode', value);
                    }}
                    options={inquiryDropdownOptions?.sourceSystemList?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label="Charge Date From"
                    value={chargeDateFrom}
                    subText={' '}
                    onChange={(date) => {
                      handleChange('chargeDateFrom', date);
                    }}
                    isDisabled={readOnly}
                    errorMessage={toDateChangeErr}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label="Charge Date To"
                    value={chargeDateTo}
                    subText={' '}
                    onChange={(date) => {
                      handleChange('chargeDateTo', date);
                    }}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={{ xs: 4, md: 4, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Subscriber ID (SSN)"
                    value={subscriberId}
                    onChange={handleSubscriberIdChange}
                    isDisabled={readOnly}
                    errorMessage={errors.subscriberId}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddSubId}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Series Designator"
                    value={seriesDesignator}
                    onChange={handleSeriesDesignatorChange}
                    isDisabled={readOnly}
                    errorMessage={errors.seriesDesignator}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                        width: '100px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddSeriesId}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="Check Number"
                    value={checkNumber}
                    maxLength={8}
                    onChange={(e) =>
                      handleChange('checkNumber', e.target.value)
                    }
                    errorMessage={errors.checkNumber}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedSubscriberId}
                    selectedItems={selectedSubscriberId}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedSubscriberId,
                        setSelectedSubscriberId
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{ width: '100px' }}
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedSubscriberId,
                        setAddedSubscriberId,
                        selectedSubscriberId,
                        setSelectedSubscriberId
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedSeriesDesignator}
                    selectedItems={selectedSeriesDesignator}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedSeriesDesignator,
                        setSelectedSeriesDesignator
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{ width: '100px' }}
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedSeriesDesignator,
                        setAddedSeriesDesignator,
                        selectedSeriesDesignator,
                        setSelectedSeriesDesignator
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <RadioGroup
                  label=""
                  display="column"
                  onChange={(e) => handleChange('statusRadio', e.target.value)}
                  value={statusRadio}
                  isDisabled={readOnly}
                >
                  <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                    <RadioGroup.Radio label="Complete" value="O" />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                    <RadioGroup.Radio label="Partial" value="P" />
                  </Grid.Col>
                </RadioGroup>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="CPS#"
                    value={cps}
                    maxLength={8}
                    onChange={(e) => handleChange('cps', e.target.value)}
                    errorMessage={errors.cps}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading css={{ textAlign: 'center' }} offset={5}>
                    Financial Tags
                  </Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Legal Entity"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={legalEntity}
                    onChange={(value) => handleChange('legalEntity', value)}
                    options={inquiryDropdownOptions?.legalEntity?.map(
                      (col) => ({
                        value: col,
                        label: col,
                      })
                    )}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="GL Reinsurance"
                    placeholder="Pick one"
                    isClearable
                    onClear={() => setGlReinsurance(null)}
                    isSearchable
                    value={glReinsurance}
                    onChange={(value) => handleChange('glReinsurance', value)}
                    options={inquiryDropdownOptions?.reInsTyp?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Legal Entity Group"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={legalEntityGroup}
                    isDisabled={readOnly}
                    onChange={(value) =>
                      handleChange('legalEntityGroup', value)
                    }
                    // options={[
                    //   { value: 'none', label: 'None' },
                    //   { value: 'Other', label: 'Other' },
                    // ]}
                    options={inquiryDropdownOptions?.legalEntityGrpCd?.map(
                      (col) => ({
                        value: col,
                        label: col,
                      })
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Funding Arrangement"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={fundingArrangment}
                    isDisabled={readOnly}
                    onChange={(value) =>
                      handleChange('fundingArrangment', value)
                    }
                    options={inquiryDropdownOptions?.fndCd?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={(e) => handlePageSubmit(e)}
                    isLoading={isLoad}
                  >
                    Search
                  </Button>

                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
      {isOpen && (
        <Modal
          title="Please correct the following errors"
          isOpen={isOpen}
          size={'md'}
          onClose={() => setIsOpen(false)}
        >
          <Modal.Section>
            Please enter at least one of the following criteria:
            <br />
            Policy Number, Suffix Code, Account Code,
            <br />
            Error Code
            <br />
            Priority Level
            <br />
            Dollar Amount
            <br />
            Claim Source Code
            <br />
            Charge Date <br />
            Subscriber Id
            <br />
            Check Number <br />
            Legal Entity <br />
            GL Reinsurance <br />
            Legal Entity Group <br />
            Funding Arrangement
            <br />
          </Modal.Section>
        </Modal>
      )}

      {isOpenSuffix && (
        <Modal
          title="Suffix Code can not be chosen own its own"
          isOpen={isOpenSuffix}
          size={'md'}
          onClose={() => setIsopenSuffix(false)}
        >
          <Modal.Section>
            Please enter at least one of the following criteria:
            <br />- Policy Number or Error Code
          </Modal.Section>
        </Modal>
      )}
      {isOpenAccount && (
        <Modal
          title="Account Code can not be chosen own its own"
          isOpen={isOpenAccount}
          size={'md'}
          onClose={() => setISOpenAccount(false)}
        >
          <Modal.Section>
            Please enter at least one of the following criteria:
            <br />
            - Policy Number or Error code <br />
            - Subscriber ID(SSN) or Legal Entity Group code
            <br />- Funding Arrangemnet List
          </Modal.Section>
        </Modal>
      )}
      {isErrorFinTagOpen && (
        <Modal
          isOpen={isErrorFinTagOpen}
          size={'md'}
          onClose={() => setIsErrorFinTagOpen(false)}
        >
          {errors.legalEntity ? (
            <Modal.Section>
              {errors.legalEntity} <br />
              Please enter the following criteria:
              <br />- Legal Entity group code
            </Modal.Section>
          ) : null}
          {errors.glReinsurance ? (
            <Modal.Section>
              {errors.glReinsurance} <br />
              Please enter the following criteria:
              <br />- Legal Entity group code
            </Modal.Section>
          ) : null}
        </Modal>
      )}
      {dollarModal && (
        <Modal
          title="Dollar amount cannot be choosen on its own"
          isOpen={dollarModal}
          size={'md'}
          onClose={() => setDollarModal(false)}
        >
          <Modal.Section>
            Please correct the following errors:<br />
            Please enter at least one of the following criteria:<br />
            Policy Number, Suffix Code, Account Code,<br />
            Error Code<br />
            Priority Level<br />
            Dollar Amount<br />
            Claim Source Code<br />
            Charge Date <br />
            Subscriber Id<br />
            Check Number <br />
            Legal Entity <br />
            GL Reinsurance <br />
            Legal Entity Group <br />
            Funding Arrangement
            <br />
          </Modal.Section>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withAuth(Inquiry, "cmdb_update_inquiry");
