import React, { useState, useEffect } from 'react';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import useAuth from '@src/utils/useAuth';

import * as XLSX from 'xlsx';
import axios from 'axios';

import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Modal } from '@abyss/web/ui/Modal';
import { Alert } from '@abyss/web/ui/Alert';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Footer } from '@src/components/Footer';

import { DataTable } from '@abyss/web/ui/DataTable';
import { getEnvironment } from '@src/utils/environmentUtil';
import { constantColumns } from './column-constants';
import { BASE_URL } from '@src/utils/endpoints';
import { get, max, set } from 'lodash';
import env from '../../../env.json';
import withAuth from '@src/auth/withAuth';
import { trimValues } from '../util';

const MarketLocation = () => {
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [updateRow, setUpdateRow] = useState({});
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [row, setRow] = useState({});

  const [marketNbr, setMarketNbr] = useState([]);
  const [marketNbrState, setMarketNbrState] = useState([]);
  const [originCd, setOriginCd] = useState([]);
  const [hmoAcctDiv, setHmoActDiv] = useState([]);
  const [legalEntity, setLegalEntity] = useState([]);
  const [locationCd, setLocationCd] = useState([]);
  const [majorMarket, setMajorMarket] = useState([]);
  const [saleOfcCd, setSaleOfcCd] = useState([]);
  const [marketNbrSelected, setMarketNbrSelected] = useState([]);
  const [marketNbrStateSelected, setMarketNbrStateSelected] = useState([]);
  const [originCdSelected, setOriginCdSelected] = useState([]);
  const [hmoAcctDivSelected, setHmoActDivSelected] = useState([]);
  const [legalEntitySelected, setLegalEntitySelected] = useState([]);
  const [locationCdSelected, setLocationCdSelected] = useState([]);
  const [majorMarketSelected, setMajorMarketSelected] = useState([]);
  const [saleOfcCdSelected, setSaleOfcCdSelected] = useState([]);
  const [payloadText, setPayloadText] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [text, setText] = useState('');
  const [json, setJson] = useState({});
  const [errorMsg, setErrorMsg] = useState('');

  const [marketNbrForm, setMarketNbrForm] = useState('');
  const [marketNbrPrfx, setMarketNbrPrfx] = useState('');
  const [marketNbrStateForm, setMarketNbrStateForm] = useState('');
  const [marketNbrSite, setMarketNbrSite] = useState('');
  const [siteNbr, setSiteNbr] = useState('');
  const [hcn, setHcn] = useState('');
  const [marketNameForm, setMarketNameForm] = useState('');
  const [originCdForm, setOriginCdForm] = useState('');
  const [hmoAcctDivForm, setHmoActDivForm] = useState('');
  const [legalEntityForm, setLegalEntityForm] = useState('');
  const [locationCdForm, setLocationCdForm] = useState('');
  const [majorMarketForm, setMajorMarketForm] = useState('');
  const [hmoPct, setHmoPct] = useState('');
  const [majMarketNm, setMajMarketNm] = useState('');
  const [records, setRecords] = useState('0 records');
  const [salesOfcCdForm, setSalesOfcCdForm] = useState('');
  const [maxLength, setMaxLength] = useState({});

  const [marketNbrErrorMsg, setMarketNbrErrorMsg] = useState('');
  const [hmoAcctErrMsg, setHmoAcctErrMsg] = useState('');
  const [legalEntErr, setLegalEntErr] = useState('');
  const [locationCdErr, setLocationCdErr] = useState('');
  const [hmoPctErr, setHmoPctErr] = useState('');
  const [salesOfcCdFormErr, setSalesOfcCdFormErr] = useState('');

  const [errAlert, setErrAlert] = useState(false);
  const [errAlertMsg, setErrAlertMsg] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, msid, userRolesGroup } = useAuth();
  const data = [];
  const [JSONData, setJSONData] = useState(location?.state?.data);

  const handleDynamicDropdown = async () => {
    try {
      let payload = [location?.state?.database];
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/market-location/getDropDowns`,
        payload,
        config
      );
      if (response.status === 200) {
        setJson(response.data);
      }
    } catch (error) {
      // add-alert-popup
    }
  };
  useEffect(() => {
    if (Object.keys(json).length > 0) {
      let marketNbr1 = json?.['marketNbrList'].map((item) => ({
        value: item,
        label: item,
      }));
      setMarketNbr(marketNbr1);
      let marketNbrState1 = json?.['marketNbrStateList'].map((item) => ({
        value: item,
        label: item,
      }));
      setMarketNbrState(marketNbrState1);
      let originCd1 = json?.['originCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setOriginCd(originCd1);
      let hmoacc = json?.['hmoAcctDivList'].map((item) => ({
        value: item,
        label: item,
      }));
      setHmoActDiv(hmoacc);
      let legalEntity1 = json?.['legalEntityList'].map((item) => ({
        value: item,
        label: item,
      }));
      setLegalEntity(legalEntity1);
      let locationcd1 = json?.['locationCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setLocationCd(locationcd1);
      let majorMarket1 = json?.['majorMarketList'].map((item) => ({
        value: item,
        label: item,
      }));
      setMajorMarket(majorMarket1);
      let sale = json?.['saleOfcCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setSaleOfcCd(sale);
    }
  }, [json]);
  useEffect(() => {
    let text1 =
      (marketNbrSelected?.length > 0
        ? 'MARKET_NBR - ' + marketNbrSelected.toString() + '\n'
        : '') +
      (marketNbrStateSelected?.length > 0
        ? 'MARKET_NBR_STATE - ' + marketNbrStateSelected.toString() + '\n'
        : '') +
      (originCdSelected?.length > 0
        ? 'ORIGIN_CD - ' + originCdSelected.toString() + '\n'
        : '') +
      (hmoAcctDivSelected?.length > 0
        ? 'HMO_ACCT_DIV - ' + hmoAcctDivSelected.toString() + '\n'
        : '') +
      (legalEntitySelected?.length > 0
        ? 'LEGAL_ENTITY - ' + legalEntitySelected.toString() + '\n'
        : '') +
      (locationCdSelected?.length > 0
        ? 'LOCATION_CD - ' + locationCdSelected.toString() + '\n'
        : '') +
      (majorMarketSelected?.length > 0
        ? 'MAJOR_MARKET - ' + majorMarketSelected.toString() + '\n'
        : '') +
      (saleOfcCdSelected?.length > 0
        ? 'SALE_OFC_CD - ' + saleOfcCdSelected.toString() + '\n'
        : '');
    let payload =
      (marketNbrSelected?.length > 0
        ? 'MARKET_NBR IN (' + marketNbrSelected.toString() + ') AND '
        : '') +
      (marketNbrStateSelected?.length > 0
        ? 'MARKET_NBR_STATE IN (' + marketNbrStateSelected.toString() + ') AND '
        : '') +
      (originCdSelected?.length > 0
        ? 'ORIGIN_CD IN (' +
          originCdSelected.map((item) => `'${item}'`).join(',') +
          ') AND '
        : '') +
      (hmoAcctDivSelected?.length > 0
        ? 'HMO_ACCT_DIV IN (' + hmoAcctDivSelected.toString() + ') AND '
        : '') +
      (legalEntitySelected?.length > 0
        ? 'LEGAL_ENTITY IN (' + legalEntitySelected.toString() + ') AND '
        : '') +
      (locationCdSelected?.length > 0
        ? 'LOCATION_CD IN (' + locationCdSelected.toString() + ') AND '
        : '') +
      (majorMarketSelected?.length > 0
        ? 'MAJOR_MARKET IN (' + majorMarketSelected.toString() + ') AND '
        : '') +
      (saleOfcCdSelected?.length > 0
        ? 'SALE_OFC_CD IN (' + saleOfcCdSelected.toString() + ') AND '
        : '');
    setPayloadText(payload);
    setText(text1);
  }, [
    marketNbrSelected,
    marketNbrStateSelected,
    originCdSelected,
    hmoAcctDivSelected,
    legalEntitySelected,
    locationCdSelected,
    majorMarketSelected,
    saleOfcCdSelected,
  ]);
  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);
  const addRow = () => {
    setMaxLength({
      marketNbr: 7,
      marketName: 13,
      originCd: 1,
      hmoAcctDiv: 3,
      legalEntity: 5,
      locationCd: 4,
      majorMarket: 4,
      hmoPct: 4,
      majMarketNm: 30,
      saleOfcCd: 3,
      siteNbr1995: 4,
      marketNbrPrfx: 2,
      hcn1995: 7,
      marketNbrState: 2,
      marketNbrSite: 3,
    });
    setFlag(true);
    setHideUpdate(true);
  };
  //get column definition
  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );

  const updateRows = (row) => {
    setMaxLength({
      marketNbr: 7,
      hmoAcctDiv: 3,
      majorMarket: 1,
      siteNbr1995: 4,
      hmoPct: 4,
      marketNbrPrfx: 2,
      hcn1995: 7,
      legalEntity: 5,
      majMarketNm: 30,
      marketName: 13,
      locationCd: 4,
      saleOfcCd: 3,
      marketNbrState: 2,
      marketNbrSite: 3,
    });
    setUpdateFlag(true);
    setFlag(true);
    setUpdateRow(row);
    setHideAdd(true);
    setMarketNbrForm(row['MARKETNBR']);
    setMarketNbrPrfx(row['MARKETNBRPRFX']);
    setMarketNbrStateForm(row['MARKETNBRSTATE']);
    setMarketNbrSite(row['MARKETNBRSITE']);
    setSiteNbr(row['SITENBR1995']);
    setHcn(row['HCN1995']);
    setMarketNameForm(row['MARKETNAME']);
    setOriginCdForm(row['ORIGINCD']);
    setHmoActDivForm(row['HMOACCTDIV']);
    setLegalEntityForm(row['LEGALENTITY']);
    setLocationCdForm(row['LOCATIONCD']);
    setMajorMarketForm(row['MAJORMARKET']);
    setHmoPct(row['HMOPCT']);
    setMajMarketNm(row['MAJMARKETNM']);
    setSalesOfcCdForm(row['SALEOFCCD']);
  };
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const deleteRows = async (payload) => {
    setShowModal(false);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload1 = {
      marketNbr: row['MARKETNBR'],
      marketNbrPrfx: row['MARKETNBRPRFX'],
      marketNbrState: row['MARKETNBRSTATE'],

      marketNbrSite: row['MARKETNBRSITE'],
      siteNbr1995: row['SITENBR1995'],
      hcn1995: row['HCN1995'],
      marketName: row['MARKETNAME'],
      originCd: row['ORIGINCD'],
      hmoAcctDiv: row['HMOACCTDIV'],
      legalEntity: row['LEGALENTITY'],
      locationCd: row['LOCATIONCD'],
      majorMarket: row['MAJORMARKET'],
      hmoPct: row['HMOPCT'],
      majMarketNm: row['MAJMARKETNM'],
      saleOfcCd: row['SALEOFCCD'],
      lstUpdtDt: row['LSTUPDTDT'],
      lstUpdtTm: row['LSTUPDTTM'],
      lstUpdtId: row['LSTUPDTID'],
      updtInd: row['UPDTIND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/market/${location?.state?.database}`,

        payload1,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully deleted the record');
        let tableData = await getTableData1();

        getTableState.setData(tableData || [], true);
        setJSONData(tableData);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'market-location.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'market-location.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: data.map((item) => trimValues(item)),
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions: hideUpdate
      ? [deleteLinkButton]
      : [updateLinkButton, deleteLinkButton],
  });
  const setData = async (tableData) => {
    let columnKeys = [];
    let columns = {};
    if (tableData?.length > 0) {
      columnKeys = Object.keys(tableData?.[0]);
      columns = columnKeys.map((key, index) => ({
        Header: key,
        accessor: key,
      }));
    }
    if (Array.isArray(tableData)) {
      getTableState.setColumns(columns, true);
      getTableState.setData(tableData, true);
    }
  };
  const onLookup = async () => {
    let payload = {
      selectedValues: payloadText.substring(0, payloadText.length - 4),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let apiUrl =
      payload.selectedValues === ''
        ? `${BASE_URL}tableMaintainance/cts/market/getallMarketList/${location?.state?.database}`
        : `${BASE_URL}tableMaintainance/cts/market/getMarketList/${location?.state?.database}`;
    let payloadValue = payload.selectedValues === '' ? '' : payload;
    try {
      const response = await axios.post(apiUrl, payloadValue, config);
      if (response.status === 200) {
        await setData(response.data.map((item) => trimValues(item)));
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = {
      selectedValues: payloadText.substring(0, payloadText.length - 4),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/market/getallMarketList/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
        handleDynamicDropdown();
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return [];
    }
  };
  const handleClick = () => {
    navigate(-1);
  };
  
  const clear = async () => {
    setMarketNbrSelected([]);
    setMarketNbrStateSelected([]);
    setOriginCdSelected([]);
    setHmoActDivSelected([]);
    setLegalEntitySelected([]);
    setLocationCdSelected([]);
    setMajorMarketSelected([]);
    setSaleOfcCdSelected([]);
    setText('');
    setPayloadText('');

    await getTableData1();
  };
  const clearErrorFields = () => {
    // clear error fields
    setMarketNbrErrorMsg('');
    setHmoAcctErrMsg('');
    setLegalEntErr('');
    setLocationCdErr('');
    setHmoPctErr('');
    setSalesOfcCdFormErr('');
    setErrAlertMsg('');
    setErrAlert(false);
  };

  const isHmoAcctDivExists = async (hmoAcctDiv) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URL}cts/market/validHmoAccDivExists/${hmoAcctDiv}/${location?.state?.database}`,
        config
      );
      if (response.status === 200) {
        return response.data.COUNT;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const isLocationCdExists = async (marketNbr, locationCd) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URL}cts/market/validLocationCdExists/${marketNbr}/${locationCd}/${location?.state?.database}`,
        config
      );
      if (response.status === 200) {
        return response.data.COUNT;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const validateSave = async () => {
    clearErrorFields();
    let valid = true;

    // Market Nbr

    if (
      marketNbrForm !== null &&
      marketNbrForm !== undefined &&
      marketNbrForm !== ""
    ) {
      setMarketNbrPrfx(marketNbrForm.substring(0, 2));
      setMarketNbrStateForm(marketNbrForm.substring(2, 4));
      setMarketNbrSite(marketNbrForm.substring(4,7));
    
      if (marketNbrForm.trim().length !== 7) {
        setMarketNbrErrorMsg('Market Number Length should be equal to 7.');
        valid = false;
      } else if (isNaN(marketNbrForm)) {
        setMarketNbrErrorMsg('Market Number should be numeric.');
        valid = false;
      } else  if (
        !(
          (marketNbrForm.charAt(0) === '0' && marketNbrForm.charAt(1) === '0') ||
          (marketNbrForm.charAt(0) === '9' && marketNbrForm.charAt(1) === '9')
        )
      ) {
        setMarketNbrErrorMsg(
          'First 2 digits of Market Number should be either 00 or 99.'
        );
        valid = false;
      }
     
    } else {
      setMarketNbrErrorMsg('Please enter Market Nbr');
      valid = false;
    }

    // HMO Acct Div
    if (hmoAcctDivForm !== undefined && hmoAcctDivForm !== null) {
      if (hmoAcctDivForm === '') {
        setHmoActDivForm('');
      }
      if (hmoAcctDivForm !== '') {
        if (hmoAcctDivForm.trim().length !== 3) {
          setHmoAcctErrMsg('HmoAcctDiv length should be equal to 3.');
          valid = false;
        } else if (isNaN(hmoAcctDivForm)) {
          setHmoAcctErrMsg('HmoAcctDiv should be numeric.');
          valid = false;
        } else if ((await isHmoAcctDivExists(hmoAcctDivForm)) === 0) {
          setErrAlert(true);
          setErrAlertMsg('Please Enter valid HMO Acct Div.');
          valid = false;
        }
      }
    }

    //Legal Entity
    if (hmoAcctDivForm === '') {
      setLegalEntityForm('29200');
    } else {
      if (legalEntityForm.trim().length !== 5) {
        setLegalEntErr('Legal Entity length should be equal to 5.');
        valid = false;
      }
      if (isNaN(legalEntityForm)) {
        setLegalEntErr('Legal Entity should be numeric.');
        valid = false;
      }
    }

    // location code
    if (
      locationCdForm === undefined &&
      locationCdForm === null &&
      locationCdForm === ''
    ) {
      setLocationCdErr('Please enter Location Code.');
      valid = false;
    } else if (locationCdForm.trim() === '') {
      setLocationCdErr('Please enter Location Code.');
      valid = false;
    } else if (locationCdForm.trim().length !== 4) {
      setLocationCdErr('Location Code should be equal to 4.');
      valid = false;
    } else if (isNaN(locationCdForm)) {
      setLocationCdErr('Location Code should be numeric.');
      valid = false;
    } else if (
      (await isLocationCdExists(marketNbrForm, locationCdForm)!== 0) 
    ) {
      setErrAlert(true);
      setErrAlertMsg(
        'Please enter a valid Location Code - Location values must be same under a Market Number'
      );
      valid = false;
    }
  

    // HMO Pct
    if (hmoAcctDivForm === '') {
      setHmoPct('0.0000');
    } else {
      if (isNaN(hmoPct)) {
        setHmoPctErr('HMO Percent should be numeric.');
        valid = false;
      } else if (!(parseFloat(hmoPct) < 1 && parseFloat(hmoPct) >= 0)) {
        setHmoPctErr('HMO Percent not within valid range.');
        valid = false;
      }
    }

    // Sales Office Code
    if (salesOfcCdForm !== '') {
      if (salesOfcCdForm.length !== 3) {
        setSalesOfcCdFormErr('Sale Ofc Cd length should be equal to 3.');
        valid = false;
      }
      if (isNaN(salesOfcCdForm)) {
        setSalesOfcCdFormErr('Sale Ofc Cd should be numeric.');
        valid = false;
      }
    }

    if (hmoAcctDivForm !== '' && salesOfcCdForm !== '') {
      setErrAlert(true);
      setErrAlertMsg(
        'Please Enter valid Sales Office Code / HMO Account - Sale_Ofc_Cd and Hmo_Acct_Div cannot be populated at the same time.'
      );
      valid = false;
    }
    return valid;
  };
  const validateUpdate = async () => {
    clearErrorFields();
    let valid = true;
    // Market Nbr
    if (
      marketNbrForm !== null &&
      marketNbrForm !== undefined &&
      marketNbrForm !== ''
    ) {
      setMarketNbrPrfx(marketNbrForm.substring(0, 2));
      setMarketNbrStateForm(marketNbrForm.substring(2, 4));
      setMarketNbrSite(marketNbrForm.substring(4,7));
    
      if (marketNbrForm.trim().length > 7) {
        setMarketNbrErrorMsg('Market Number Length should be equal to 7.');
        valid = false;
      } else if (isNaN(marketNbrForm)) {
        setMarketNbrErrorMsg('Market Number should be numeric.');
        valid = false;
      }else if (
        !(
          (marketNbrForm.charAt(0) === '0' && marketNbrForm.charAt(1) === '0') ||
          (marketNbrForm.charAt(0) === '9' && marketNbrForm.charAt(1) === '9')
        )
      ) {
        setMarketNbrErrorMsg(
          'First 2 digits of Market Number should be either 00 or 99.'
        );
        valid = false;
      }
    } else {
      setMarketNbrErrorMsg('Please enter Market Nbr');
      valid = false;
    }

    
    

    // HMO Acct Div
    if (hmoAcctDivForm !== undefined && hmoAcctDivForm !== null) {
      if (hmoAcctDivForm === '') {
        setHmoActDivForm('');
      }
      if (hmoAcctDivForm !== '') {
        if (hmoAcctDivForm.trim().length > 3) {
          setHmoAcctErrMsg(
            'HmoAcctDiv length should  be less than or equal to 3.'
          );
          valid = false;
        } else if (isNaN(hmoAcctDivForm)) {
          setHmoAcctErrMsg('HmoAcctDiv should be numeric.');
          valid = false;
        } else if ((await isHmoAcctDivExists(hmoAcctDivForm)) === 0) {
          setErrAlert(true);
          setErrAlertMsg('Please Enter valid HMO Acct Div.');
          valid = false;
        }
      }
    }
    //Legal Entity
    if (hmoAcctDivForm === '') {
      setLegalEntityForm('29200');
    } else {
      if (legalEntityForm.trim().length > 5) {
        setLegalEntErr(
          'Legal Entity length should  be less than or equal to 5.'
        );
        valid = false;
      }
      if (isNaN(legalEntityForm)) {
        setLegalEntErr('Legal Entity should be numeric.');
        valid = false;
      }
    }
    // location code
    if (
      locationCdForm === undefined &&
      locationCdForm === null &&
      locationCdForm === ''
    ) {
      setLocationCdErr('Please enter Location Code.');
      valid = false;
    } else if (locationCdForm.trim() === '') {
      setLocationCdErr('Please enter Location Code.');
      valid = false;
    } else if (locationCdForm.trim().length > 4) {
      setLocationCdErr('Location Code should be less than or equal to  4.');
      valid = false;
    } else if (isNaN(locationCdForm)) {
      setLocationCdErr('Location Code should be numeric.');
      valid = false;
    } else if (
      (await isLocationCdExists(marketNbrForm, locationCdForm)) !== 0
    ) {
      setErrAlert(true);
      setErrAlertMsg(
        'Please enter a valid HMO Acct Div - Location values must be same under a Market Number'
      );
      valid = false;
    }

    // HMO Pct
    if (hmoAcctDivForm === '') {
      setHmoPct('0.0000');
    } else {
      if (isNaN(hmoPct)) {
        setHmoPctErr('HMO Percent should be numeric.');
        valid = false;
      } else if (!(parseFloat(hmoPct) < 1 && parseFloat(hmoPct) >= 0)) {
        setHmoPctErr('HMO Percent not within valid range.');
        valid = false;
      }
    }

    // Sales Office Code
    if (salesOfcCdForm === '') {
      setSalesOfcCdForm('');
    }
    if (salesOfcCdForm !== '') {
      if (salesOfcCdForm.length > 3) {
        setSalesOfcCdFormErr(
          'Sale Ofc Cd length should be less than or equal to 3.'
        );
        valid = false;
      } else if (isNaN(salesOfcCdForm)) {
        setSalesOfcCdFormErr('Sale Ofc Cd should be numeric.');
        valid = false;
      }
    }

    if (hmoAcctDivForm !== '' && salesOfcCdForm !== '') {
      setErrAlert(true);
      setErrAlertMsg(
        'Please Enter valid Sales Office Code / HMO Account - Sale_Ofc_Cd and Hmo_Acct_Div cannot be populated at the same time.'
      );
      valid = false;
    }

    if (
      (updateRow['MARKETNBR'].trim() === marketNbrForm.trim()) &&
      (updateRow['MARKETNAME'].trim() === marketNameForm.trim()) &&
      (updateRow['ORIGINCD'].trim() === originCdForm.trim()) &&
      (updateRow['HMOACCTDIV'].trim() === hmoAcctDivForm.trim()) &&
      (updateRow['LEGALENTITY'].trim() === legalEntityForm.trim()) &&
      (updateRow['LOCATIONCD'] === locationCdForm) &&
      (updateRow['MAJORMARKET'].trim() === majorMarketForm.trim()) &&
      (updateRow['HMOPCT'] === hmoPct) &&
      (updateRow['MAJMARKETNM'].trim() === majMarketNm.trim()) &&
      (updateRow['SALEOFCCD'].trim() === salesOfcCdForm.trim())
    ) {
      setErrAlert(true);
      setErrAlertMsg('No fields are changed to update');
      valid = false;
    }

    return valid;
  };
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  const update = async () => {
    setUpdateFlag(false);
    setUpdateConfirmModal(false);
    const now = new Date();
    setHideAdd(false);
    setFlag(false);
    let payload = {
      oldRecord: {
        marketNbr: updateRow['MARKETNBR'],
        marketNbrPrfx: updateRow['MARKETNBRPRFX'],
        marketNbrState: updateRow['MARKETNBRSTATE'],
        marketNbrSite: updateRow['MARKETNBRSITE'],
        siteNbr1995: updateRow['SITENBR1995'],
        hcn1995: updateRow['HCN1995'],
        marketName: updateRow['MARKETNAME'],
        originCd: updateRow['ORIGINCD'],
        hmoAcctDiv: updateRow['HMOACCTDIV'],
        legalEntity: updateRow['LEGALENTITY'],
        locationCd: updateRow['LOCATIONCD'],
        majorMarket: updateRow['MAJORMARKET'],
        hmoPct: updateRow['HMOPCT'],
        majMarketNm: updateRow['MAJMARKETNM'],
        saleOfcCd: updateRow['SALEOFCCD'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LSTUPDTTM'],
        lstUpdtId: updateRow['LSTUPDTID'],
        updtInd: updateRow['UPDTIND'],
      },
      newRecord: {
        marketNbr: marketNbrForm,
        marketNbrPrfx: marketNbrPrfx,
        marketNbrState: marketNbrStateForm,
        marketNbrSite: marketNbrSite,
        siteNbr1995: siteNbr,
        saleOfcCd: salesOfcCdForm,
        hcn1995: hcn,
        marketName: marketNameForm,
        originCd: originCdForm,
        hmoAcctDiv: hmoAcctDivForm,
        legalEntity: legalEntityForm,
        locationCd: locationCdForm,
        majorMarket: majorMarketForm,
        hmoPct: hmoPct,
        majMarketNm: majMarketNm,
        lstUpdtDt: '',
        lstUpdtId:  msid.substr(0, 7).toUpperCase(),

        lstUpdtTm: '',
        updtInd: 'Y',
      },
    };
    setMarketNbrForm('');
    setMarketNbrPrfx('');
    setMarketNbrStateForm('');
    setMarketNbrSite('');
    setSiteNbr('');
    setHcn('');
    setMarketNameForm('');
    setOriginCdForm('');
    setHmoActDivForm('');
    setLegalEntityForm('');
    setLocationCdForm('');
    setMajorMarketForm('');
    setHmoPct('');
    setMajMarketNm('');
    setSalesOfcCdForm('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/market/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully updated the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData.map((item) => trimValues(item)) || [], true);
        setJSONData(tableData);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const save = async () => {
    setHideUpdate(false);
    setFlag(false);
    setSaveModal(false);
    const now = new Date();

    let payload = {
      marketNbr: marketNbrForm,
      marketNbrPrfx: marketNbrPrfx,
      marketNbrState: marketNbrStateForm,
      marketNbrSite: marketNbrSite,
      siteNbr1995: siteNbr,
      hcn1995: hcn,
      marketName: marketNameForm,
      originCd: originCdForm,
      hmoAcctDiv: hmoAcctDivForm,
      legalEntity: legalEntityForm,
      locationCd: locationCdForm,
      majorMarket: majorMarketForm,
      hmoPct: hmoPct,
      majMarketNm: majMarketNm,
      lstUpdtDt: '',
      lstUpdtId: msid.substr(0, 7).toUpperCase(),
      saleOfcCd: salesOfcCdForm,
      lstUpdtTm: '',
      updtInd: 'Y',
    };
    setMarketNbrForm('');
    setMarketNbrPrfx('');
    setMarketNbrStateForm('');
    setMarketNbrSite('');
    setSiteNbr('');
    setHcn('');
    setMarketNameForm('');
    setOriginCdForm('');
    setHmoActDivForm('');
    setLegalEntityForm('');
    setLocationCdForm('');
    setMajorMarketForm('');
    setHmoPct('');
    setMajMarketNm('');
    setSalesOfcCdForm('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/market/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully added record to table');

        let tableData = await getTableData1();
        getTableState.setData(tableData.map((item) => trimValues(item)) || [], true);
        setJSONData(tableData);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setMarketNbrForm('');
    setMarketNbrPrfx('');
    setMarketNbrStateForm('');
    setMarketNbrSite('');
    setSiteNbr('');
    setHcn('');
    setMarketNameForm('');
    setOriginCdForm('');
    setHmoActDivForm('');
    setLegalEntityForm('');
    setLocationCdForm('');
    setMajorMarketForm('');
    setHmoPct('');
    setMajMarketNm('');
    setSalesOfcCdForm('');
    clearErrorFields()
  };
  const updateModalFunc = async () => {
    const isValid = await validateUpdate();
    if (isValid) {
      setUpdateModal(true);
    }
  };
  const saveModalFunc = async () => {
    const isValid = await validateSave();
    if (isValid) {
      setSaveModal(true);
    }
  };
  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);
  useEffect(() => {
    if (isVisible || errAlert) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        setErrAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isVisible, errAlert]);
  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => {
                setUpdateConfirmModal(false);
                setFlag(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update"
          isOpen={updateModal}
          onClose={() => setUpdateModal(false)}
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market_Nbr:</b>
                {updateRow['MARKETNBR']}
              </span>
              <span>
                <b>Market_Nbr:</b>
                {marketNbrForm}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Site_Nbr_1995:</b>
                {updateRow['SITENBR1995']}
              </span>
              <span>
                <b>Site_Nbr_1995:</b>
                {siteNbr}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Hmo_Acct_Div:</b>
                {updateRow['HMOACCTDIV']}
              </span>
              <span>
                <b>Hmo_Acct_Div:</b>
                {hmoAcctDivForm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Hmo_Pct:</b>
                {updateRow['HMOPCT']}
              </span>
              <span>
                <b>Hmo_Pct:</b>
                {hmoPct}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market_Nbr_Prfx:</b>
                {updateRow['MARKETNBRPRFX']}
              </span>
              <span>
                <b>Market_Nbr_Prfx:</b>
                {marketNbrPrfx}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Hcn_1995:</b>
                {updateRow['HCN1995']}
              </span>
              <span>
                <b>Hcn_1995:</b>
                {hcn}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Legal_Entity:</b>
                {updateRow['LEGALENTITY']}
              </span>
              <span>
                <b>Legal_Entity:</b>
                {legalEntityForm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Maj_Market_Nm:</b>
                {updateRow['MAJMARKETNM']}
              </span>
              <span>
                <b>Maj_Market_Nm:</b>
                {majMarketNm}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market_Nbr_State:</b>
                {updateRow['MARKETNBRSTATE']}
              </span>
              <span>
                <b>Market_Nbr_State:</b>
                {marketNbrStateForm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market_Name:</b>
                {updateRow['MARKETNAME']}
              </span>
              <span>
                <b>Market_Name:</b>
                {marketNameForm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Location_Cd:</b>
                {updateRow['LOCATIONCD']}
              </span>
              <span>
                <b>Location_Cd:</b>
                {locationCdForm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Market_Nbr_Site:</b>
                {updateRow['MARKETNBRSITE']}
              </span>
              <span>
                <b>Market_Nbr_Site:</b>
                {marketNbrSite}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Origin_Cd:</b>
                {updateRow['ORIGINCD']}
              </span>
              <span>
                <b>Origin_Cd:</b>
                {originCdForm}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Major_Market:</b>
                {updateRow['MAJORMARKET']}
              </span>
              <span>
                <b>Major_Market:</b>
                {majorMarketForm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>Sale_Ofc_Cd:</b>
                {updateRow['SALEOFCCD']}
              </span>
              <span>
                <b>Sale_Ofc_Cd:</b>
                {salesOfcCdForm}
              </span>
            </div>
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => {
                setUpdateModal(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Delete Record"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Section>
            <b>
              Market_Nbr: {row?.['MARKETNBR']}
              <br />
              Site_Nbr_1995: {row?.['SITENBR1995']}
              <br />
              Hmo_Acct_Div : {row?.['HMOACCTDIV']}
              <br />
              Hmo_Pct: {row?.['HMOPCT']}
              <br />
              Market_Nbr_Prfx: {row?.['MARKETNBRPRFX']}
              <br />
              Hcn_1995: {row?.['HCN1995']}
              <br />
              Legal_Entity: {row?.['LEGALENTITY']}
              <br />
              Maj_Market_Nm : {row?.['MAJMARKETNM']}
              <br />
              Market_Nbr_State: {row?.['MARKETNBRSTATE']}
              <br />
              Market_Name: {row?.['MARKETNAME']}
              <br />
              Market_Nbr_Site: {row?.['MARKETNBRSITE']}
              <br />
              Origin_Cd: {row?.['ORIGINCD']}
              <br />
              Major_Market: {row?.['MAJORMARKET']}
              <br />
              Sale_Ofc_Cd: {row?.['SALEOFCCD']}
              <br />
            </b>
            Are you sure you want to delete this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={deleteRows}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '0px',
                  marginBottom: '14px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Grid>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Market_Nbr"
                    value={marketNbrSelected}
                    onChange={(e) => setMarketNbrSelected(e)}
                    options={marketNbr}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Market_Nbr_State"
                    value={marketNbrStateSelected}
                    onChange={(e) => setMarketNbrStateSelected(e)}
                    options={marketNbrState}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Origin_Cd"
                    value={originCdSelected}
                    onChange={(e) => setOriginCdSelected(e)}
                    options={originCd}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Hmo_Acct_Div"
                    value={hmoAcctDivSelected}
                    onChange={(e) => setHmoActDivSelected(e)}
                    options={hmoAcctDiv}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Legal_Entity"
                    value={legalEntitySelected}
                    onChange={(e) => setLegalEntitySelected(e)}
                    options={legalEntity}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Location_Cd"
                    value={locationCdSelected}
                    onChange={(e) => setLocationCdSelected(e)}
                    options={locationCd}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Major_Market"
                    value={majorMarketSelected}
                    onChange={(e) => setMajorMarketSelected(e)}
                    options={majorMarket}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Sale_Ofc_Cd"
                    value={saleOfcCdSelected}
                    onChange={(e) => setSaleOfcCdSelected(e)}
                    options={saleOfcCd}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <Button css={{ marginTop: '25px' }} onClick={onLookup}>
                    Lookup
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 10 }}>
                  <TextInputArea value={text} rows={8} />
                </Grid.Col>
                <Grid.Col span={{ xs: 2 }}>
                  <Button css={{ marginTop: '15px' }} onClick={clear}>
                    Clear
                  </Button>
                </Grid.Col>
              </Grid>
              <Alert
                title={alertMsg}
                variant="success"
                isVisible={isVisible}
                onClose={() => setIsVisible(false)}
              />
              <Alert
                title={errAlertMsg}
                variant="error"
                isVisible={errAlert}
                onClose={() => {
                  setErrAlert(false);
                }}
              />
              <Grid css={{ marginTop: '15px', marginLeft: '0px' }}>
                <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                  Database:
                </Text>
                <span>
                  {getEnvironment()} - {location?.state?.database}
                </span>

                <Text
                  css={{
                    fontWeight: 600,
                    fontSize: '18px',
                    marginLeft: '20px',
                  }}
                >
                  Table:
                </Text>
                <span>{location?.state?.systemSelectedValue}</span>
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={flag || hideAdd ? true : false}
                  css={{ marginTop: '-10px' }}
                >
                  + Add Row
                </Button>

                {flag && (
                  <>
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                      }}
                    >
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Nbr"
                          value={marketNbrForm}
                          onChange={(e) => setMarketNbrForm(e.target.value)}
                          errorMessage={marketNbrErrorMsg}
                          maxLength={maxLength.marketNbr}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Nbr_Prfx"
                          value={marketNbrPrfx}
                          onChange={(e) => setMarketNbrPrfx(e.target.value)}
                          maxLength={maxLength.marketNbrPrfx}
                          isDisabled={true}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Nbr_State"
                          value={marketNbrStateForm}
                          onChange={(e) =>
                            setMarketNbrStateForm(e.target.value)
                          }
                          maxLength={maxLength.marketNbrState}
                          isDisabled={true}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Nbr_Site"
                          value={marketNbrSite}
                          maxLength={maxLength.marketNbrSite}
                          onChange={(e) => setMarketNbrSite(e.target.value)}
                          isDisabled={true}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Site_Nbr_1995"
                          value={siteNbr}
                          onChange={(e) => setSiteNbr(e.target.value)}
                          maxLength={maxLength.siteNbr1995}
                          isDisabled={true}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Hcn_1995"
                          value={hcn}
                          maxLength={maxLength.hcn1995}
                          onChange={(e) => setHcn(e.target.value)}
                          isDisabled={true}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Name"
                          maxLength={maxLength.marketName}
                          value={marketNameForm}
                          onChange={(e) => setMarketNameForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Origin_Cd"
                          maxLength={maxLength.originCd && maxLength.originCd}
                          value={originCdForm}
                          onChange={(e) => setOriginCdForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Hmo_Acct_Div"
                          value={hmoAcctDivForm}
                          onChange={(e) => setHmoActDivForm(e.target.value)}
                          errorMessage={hmoAcctErrMsg}
                          maxLength={maxLength.hmoAcctDiv}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Legal_Entity"
                          value={legalEntityForm}
                          onChange={(e) => setLegalEntityForm(e.target.value)}
                          errorMessage={legalEntErr}
                          maxLength={maxLength.legalEntity}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Location_Cd"
                          value={locationCdForm}
                          onChange={(e) => setLocationCdForm(e.target.value)}
                          errorMessage={locationCdErr}
                          maxLength={maxLength.locationCd}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Major_Market"
                          value={majorMarketForm}
                          onChange={(e) => setMajorMarketForm(e.target.value)}
                          maxLength={maxLength.majorMarket}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Hmo_Pct"
                          value={hmoPct}
                          onChange={(e) => setHmoPct(e.target.value)}
                          errorMessage={hmoPctErr}
                          maxLength={maxLength.hmoPct}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Maj_Market_Nm"
                          value={majMarketNm}
                          onChange={(e) => setMajMarketNm(e.target.value)}
                          maxLength={maxLength.majMarketNm}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Sale_Ofc_Cd"
                          value={salesOfcCdForm}
                          onChange={(e) => setSalesOfcCdForm(e.target.value)}
                          errorMessage={salesOfcCdFormErr}
                          maxLength={maxLength.saleOfcCd}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                        <Button
                          onClick={updateFlag ? updateModalFunc : saveModalFunc}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                        <Button onClick={close}>Cancel</Button>
                      </Grid.Col>
                    </Grid>
                  </>
                )}
              </Grid>
              <br />
              <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                <Text fontWeight={300} css={{ fontSize: '14px' }}>
                  {records}
                </Text>
              </Grid.Col>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(MarketLocation, 'table_cts_ro_rw');
