import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';

export default function CorrectionAplied({ state }) {
  return (
    <React.Fragment>
      <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
        <Heading offset={5} css={{ fontSize: '18px !important' }}>
          Step 3: Select Transactions to be Corrected
        </Heading>
      </Grid.Col>

      <Grid css={{ margin: '0px !important', marginTop: '10px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5} css={{ fontSize: '16px !important' }}>
            Correction To Be Applied:
          </Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Policy Number :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.polNbr1}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.sufxCd1}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Policy Account Number :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.polAcctNbr1}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>HMO Acct Div :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.selectedHMO}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Subline Type Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.selectedSublType}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Active Ret Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.selectedActiveRetCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Fund Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.fundCd1}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Product Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.selectedProductCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Retag Fin Only :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.retag}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Subscriber SSN :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{state?.subscrSSN}</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
}
