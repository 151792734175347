import React, { useEffect, useState } from 'react';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';

import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { DataTable } from '@abyss/web/ui/DataTable';
import withAuth from '@src/auth/withAuth';
import { getEnvironment } from '@src/utils/environmentUtil';

const UcasSelectionTable = () => {
  const { navigate, getLocation } = useRouter();
  const [tableCount, setTableCount] = useState(0);
  const [records, setRecords] = useState('0 records');
  const location = getLocation();
  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'ABA_NBR',
        accessor: 'abaNbr',
      },
      {
        Header: 'BNK_ACCT_NBR',
        accessor: 'bnkAcctNbr',
      },
      {
        Header: 'GL_BUS_UNIT_CD',
        accessor: 'glBusUnitCd',
      },
      {
        Header: 'GL_ACCT_NBR',
        accessor: 'glAcctNbr',
      },
      {
        Header: 'BNK_ID',
        accessor: 'bnkId',
      },
      {
        Header: 'BNK_ACCT_TYP_CD',
        accessor: 'bnkAcctTypCd',
      },
      {
        Header: 'BNK_ACCT_TYP_TXT',
        accessor: 'bnkAcctTypTxt',
      },
      {
        Header: 'RVLV_FL_CD',
        accessor: 'rvlvFlCd',
      },
    ],
    []
  );

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    columnNames = {
      ABA_NBR: null,
      BNK_ACCT_NBR: null,
      GL_BUS_UNIT_CD: null,
      GL_ACCT_NBR: null,
      BNK_ID: null,
      BNK_ACCT_TYP_CD: null,
      BNK_ACCT_TYP_TXT: null,
      RVLV_FL_CD: null,
    };
    return columnNames;
  };

  const columnNameMapping = {
    abaNbr: 'ABA_NBR',
    bnkAcctNbr: 'BNK_ACCT_NBR',
    glBusUnitCd: 'GL_BUS_UNIT_CD',
    glAcctNbr: 'GL_ACCT_NBR',
    bnkId: 'BNK_ID',
    bnkAcctTypCd: 'BNK_ACCT_TYP_CD',
    bnkAcctTypTxt: 'BNK_ACCT_TYP_TXT',
    rvlvFlCd: 'RVLV_FL_CD',
  };

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'bank_account.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'bank_account.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showGlobalFilter: false,
    showFilterDataset: true,
  });

  useEffect(() => {
    if (getTableState) {
      setTableCount(getTableState.filter.filteredRows.length);
    }
  }, [getTableState]);

  const handleClick = () => {
    navigate('/ftmf/ucas');
  };

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{ marginTop: '135px', marginLeft: '12px' }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Database:
                  </Text>
                  <span>
                    {getEnvironment()} - {location?.state?.database}
                  </span>

                  <Text
                    css={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: '20px',
                    }}
                  >
                    Table:
                  </Text>
                  <span>{location?.state?.systemSelectedValue}</span>
                  <br />
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={300} css={{ fontSize: '14px' }}>
                      {records}
                    </Text>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title=""
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'left',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(UcasSelectionTable, 'table_ucas_ro_rw');
