import React, { useState } from 'react';
import useAuth from '@src/utils/useAuth';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import CorrectionAplied from './CorrectionAplied';
import { Text } from '@abyss/web/ui/Text';
import CorrectableTransactionTable from './CorrectableTransactionTable';
import { Alert } from '@abyss/web/ui/Alert';
import { BASE_URL } from '@src/utils/endpoints';
import axios from 'axios';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';

const CorrectableTransaction = () => {
  const { readOnly, token, msid } = useAuth();
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const data = location?.state?.data;

  const [correctableTrans, setTransformedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alerMsg, setAlertMsg] = useState('');
  const [postApiSuccess, setPostApiSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const today = new Date();
  const day = today.toLocaleString('default', { weekday: 'long' });
  const date = today.getDate();
  const month = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  const currentDate = `${day}, ${month} ${date}, ${year}`;

  const handleTransformedData = (data) => {
    setTransformedData(data);
  }

  const handleSubmit = async () => {
    if (correctableTrans.length == 0) {
      setIsOpen(true);
      setAlertMsg('Please select the transactions you wish to correct.');
    }
    else {
      setIsOpen(false);
      setIsLoading(true);
      try {
        let payload = {
          "requestId": data.requestId,
          "step": {
            polNbr: data?.polNbr1,
            sufxCd: data?.sufxCd1.replaceAll("__","  "),
            polAccNbr: data?.polAcctNbr1,
            subscrSsn: data?.subscrSSN,
            sublTypCd: data?.selectedSublType,
            hmoAcctDivNbr: data?.selectedHMO,
            actvRetCd: data?.selectedActiveRetCd,
            fundCd: data?.fundCd1,
            prdctCd: data?.selectedProductCd,
            lastUserShortNm: msid
          },
          correctableTrans
        };

        if (data?.retag) {
          payload.step = {
            ...payload.step,
            ...{ retagFinancialTag: data?.retag },
          }
        }

        const response = await axios
          .post(`${BASE_URL}manageRequest/set-correctable-transactions`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIsLoading(false);
            setTransformedData([]);
            if (response.status === 200) {
              if (response?.data?.recordsSubmittedForCorrection == correctableTrans.length) {
                setPostApiSuccess(true);
              } else {
                setIsOpen(true);
                setAlertMsg('Something went wrong. Please try again.');
              }
            } else {
              setTransformedData([]);
              setIsOpen(true);
              setAlertMsg('Something went wrong. Please try again.');
            }
          });
      } catch (err) {
        setIsLoading(false);
        setTransformedData([]);
        setIsOpen(true);
        setAlertMsg('Something went wrong. Please try again.');
      }
    }
  }

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Heading
                    offset={5}
                    textAlign="center"
                    css={{ margin: '0 auto', flexGrow: 1 }}
                  >
                    Apply Itemized Corrections for Request ID:&nbsp;
                    {location?.state?.data?.requestId}
                  </Heading>
                  <Text
                    css={{
                      marginLeft: 'auto',
                      textAlign: 'right',
                      alignSelf: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {currentDate}
                  </Text>
                </Grid.Col>
              </Grid>

              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                }}
              >
                <CorrectionAplied state={location?.state?.data} />
                <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                  {isOpen ? (
                    <Alert
                      isVisible={isOpen}
                      css={{
                        'abyss-alert-root': {
                          alignItems: 'center',
                        },
                      }}
                      onClose={() => setIsOpen(false)}
                    >
                      {alerMsg}
                    </Alert>
                  ) : (
                    ''
                  )}
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  {isOpen ? (
                    <Alert
                      isVisible={isOpen}
                      css={{
                        'abyss-alert-root': {
                          alignItems: 'center',
                        },
                      }}
                      onClose={() => setIsOpen(false)}
                    >
                      {alerMsg}
                    </Alert>
                  ) : (
                    ''
                  )}
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} css={{ fontSize: '16px !important', marginTop: "10px" }}>
                    Correctable Transactions
                  </Heading>
                </Grid.Col>
                {isLoading && <WrapperLoader />}
                {postApiSuccess ? (
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }} css={{ textAlign: 'center' }}>
                    <Text
                      css={{
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      No more records Found
                    </Text>
                  </Grid.Col>
                ) :
                  !isLoading && (
                    <CorrectableTransactionTable
                      state={location?.state?.response}
                      onTransformedData={handleTransformedData}
                    />
                  )
                }
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  {location?.state?.response === 'No records found' ||
                    location?.state?.response === '' ||
                    location?.state?.response === undefined ||
                    location?.state?.response === null ||
                    postApiSuccess ? (
                    ''
                  ) : (
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={isLoading}
                      onClick={handleSubmit}
                    >
                      Submit Change
                    </Button>
                  )}
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={() => navigate(-1)}
                  >
                    New Correction
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={() => navigate('/cmdb/correction-management')}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment >
  );
};

export default withAuth(CorrectableTransaction, "cmdb_update");
