import React, { useEffect, useState } from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';

import { TextInput } from '@abyss/web/ui/TextInput';
import { DateInput } from '@abyss/web/ui/DateInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Text } from '@abyss/web/ui/Text';
import useAuth from '@src/utils/useAuth';
import axios from 'axios';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { Alert } from '@abyss/web/ui/Alert';
import { Modal } from '@abyss/web/ui/Modal';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Flex } from '@abyss/web/ui/Flex';
import { Label } from '@abyss/web/ui/Label';
import { currentDateFormat } from '@src/components/DateUtils';

const AggragateCorrection = () => {
  const { readOnly, token, msid, userName } = useAuth();
  const { getLocation } = useRouter();
  const { navigate } = useRouter();
  const location = getLocation();

  const requestId = location?.state?.requestId;
  const id = 'aggregationCorrectionDropdown';

  // Start: State Initialization
  const [alert, showAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  //Step 1 : Error Sates
  const [policyError1, setPolicyError1] = useState('');
  const [suffixError1, setSuffixError1] = useState('');
  const [policyAccError1, setPolicyAccNumErr1] = useState('');
  const [subSSNErr1, setSubscSSNErr1] = useState('');
  const [toChangeDateErr1, setToDateChangeErr1] = useState('');
  const [toTransDateErr1, setTransToDateErr1] = useState('');
  const [toCheckDateErr1, setCheckToDateErr1] = useState('');

  //Step 2 : Error Sates
  const [policyError2, setPolicyError2] = useState('');
  const [suffixError2, setSuffixError2] = useState('');
  const [policyAccError2, setPolicyAccNumErr2] = useState('');
  const [form2Error, setForm2Err] = useState('');
  const [form1Error, setForm1Err] = useState('');

  const [aggregateOptions, setAggregateOptions]: any = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formValues, setFormValues] = useState({
    policyNumber1: '',
    suffixCode1: '',
    policyAccountNumber1: '',
    feeCode1: '',
    fundCode1: '',
    productCode1: '',
    sublTypeCode1: '',
    unetSpclCode1: '',
    activeRetCode1: '',
    subscriberSSN1: '',
    hmoAcctDiv1: '',
    chargeDateFrom1: null,
    chargeDateTo1: null,
    transactionFstServiceDateFrom1: null,
    transactionFstServiceDateTo1: null,
    checkIssueDateFrom1: null,
    checkIssueDateTo1: null,
    policyNumber: '',
    suffixCode: '',
    policyAccountNumber: '',
    fundCode: '',
    productCode: '',
    sublTypeCode: '',
    activeRetCode: '',
    subscriberSSN: '',
    hmoAcctDiv: '',
    isChecked: false,
  });

  //Get Dropdown Data
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cmdb-service/aggrCrctn/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data) {
          setAggregateOptions(response.data);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        // add-alert-popup
      }
    };
    if (token) fetchDropdownData();
  }, [token]);

  // Onchange handler
  const handleChange = (value, field) => {
    setFormValues((prevFormValues) => ({ ...prevFormValues, [field]: value }));
  };

  //Start : Validation methods
  const checkPolicyNumber = (polNumber) => {
    const regEx: RegExp = /^\d{2}[A-Za-z0-9]\d{4}$/;
    if (polNumber.match(regEx)) {
      return true;
    } else {
      return false;
    }
  };
  const checkValidSuffix = (sufixcd) => {
    const letters: RegExp = /^[A-Za-z.]+$/;
    if (sufixcd.match(letters)) {
      return false;
    } else {
      return true;
    }
  };
  const checkPolicyAccount = (polAcc) => {
    const pol: RegExp = /^[0-9]+$/;
    if (polAcc.match(pol)) {
      return true;
    } else {
      return false;
    }
  };

  const formatDate = (date) => {
    if (date) {
      const formattedDate = new Date(date).toISOString().split('T')[0];
      return formattedDate;
    }
  };

  const validateForm = () => {
    let isValid = true;

    //Step 1: Policy Number
    if (!formValues.policyNumber1) {
      setPolicyError1('Policy Number is required');
      isValid = false;
    }
    if (formValues.policyNumber1) {
      if (formValues.policyNumber1.length !== 7) {
        setPolicyError1('Policy Number must be 7 characters.');
        isValid = false;
      } else if (
        formValues.policyNumber1.length === 7 &&
        !checkPolicyNumber(formValues.policyNumber1)
      ) {
        setPolicyError1(
          'Policy Number must be numeric, Except for the 3rd position.'
        );
        isValid = false;
      }
    }
    //Step 1: Suffix Code
    if (!formValues.suffixCode1) {
      setSuffixError1('Suffix Code is required.');
      isValid = false;
    }
    if (formValues.suffixCode1) {
      if (checkValidSuffix(formValues.suffixCode1)) {
        setSuffixError1(
          'Please enter a valid suffix with only letters or dots.'
        );
        isValid = false;
      } else if (formValues.suffixCode1.length > 2) {
        setSuffixError1('Suffix Code can be max of 2 characters long.');
        isValid = false;
      }
    }
    // Step 1 Policy Account Number
    if (
      formValues.policyAccountNumber1 !== null &&
      formValues.policyAccountNumber1 !== undefined &&
      formValues.policyAccountNumber1 !== ''
    ) {
      if (formValues.policyAccountNumber1.length !== 3) {
        setPolicyAccNumErr1('Policy Account Number must have a length of 3.');
        isValid = false;
      } else if (!checkPolicyAccount(formValues.policyAccountNumber1)) {
        setPolicyAccNumErr1('Policy Account Number must be a whole number.');
        isValid = false;
      }
    }
    // Step 1 Subscriber SSN
    if (
      formValues.subscriberSSN1 !== null &&
      formValues.subscriberSSN1 !== undefined &&
      formValues.subscriberSSN1 !== ''
    ) {
      if (
        formValues.subscriberSSN1.trim().length !== 9 ||
        isNaN(formValues.subscriberSSN1)
      ) {
        setSubscSSNErr1('Please enter valid Subscriber ID (SSN).');
        isValid = false;
      }
    }

    // Step 1 Charge Date
    if (
      formValues.chargeDateFrom1 != null &&
      formValues.chargeDateTo1 != null
    ) {
      if (
        formatDate(formValues.chargeDateFrom1) >
        formatDate(formValues.chargeDateTo1)
      ) {
        setToDateChangeErr1('From Charge Date not before To Date.');
        isValid = false;
      }
    }
    // Step 1 Trans Date
    if (
      formValues.transactionFstServiceDateFrom1 != null &&
      formValues.transactionFstServiceDateTo1 != null
    ) {
      if (
        formatDate(formValues.transactionFstServiceDateFrom1) >
        formatDate(formValues.transactionFstServiceDateTo1)
      ) {
        setTransToDateErr1('From TransFst Service Date not before To Date.');
        isValid = false;
      }
    }
    // Step 1 Chk Issue Date
    if (
      formValues.checkIssueDateFrom1 != null &&
      formValues.checkIssueDateTo1 != null
    ) {
      if (
        formatDate(formValues.checkIssueDateFrom1) >
        formatDate(formValues.checkIssueDateTo1)
      ) {
        setCheckToDateErr1('From Check Issue Date not before To Date.');
        isValid = false;
      }
    }
    // Step 2 validations
    // Step 2 Policy Number
    if (
      null !== formValues.policyNumber &&
      formValues.policyNumber !== undefined &&
      formValues.policyNumber !== ''
    ) {
      if (formValues.policyNumber.length !== 7) {
        setPolicyError2('Policy Number must be 7 characters.');
        isValid = false;
      } else if (
        formValues.policyNumber.length === 7 &&
        !checkPolicyNumber(formValues.policyNumber)
      ) {
        setPolicyError2(
          'Policy Number must be numeric, Except for the 3rd position.'
        );
        isValid = false;
      }
    }
    // Step 2 Suffix Code
    if (
      formValues.suffixCode !== null &&
      formValues.suffixCode !== undefined &&
      formValues.suffixCode !== ''
    ) {
      if (checkValidSuffix(formValues.suffixCode)) {
        setSuffixError2(
          'Please enter a valid suffix with only letters or dots.'
        );
        isValid = false;
      } else if (formValues.suffixCode.length > 2) {
        setSuffixError2('Suffix Code can be max of 2 characters long.');
        isValid = false;
      }
    }
    // Step 2 Policy Account Number
    if (
      formValues.policyAccountNumber !== null &&
      formValues.policyAccountNumber !== undefined &&
      formValues.policyAccountNumber !== ''
    ) {
      if (formValues.policyAccountNumber.length !== 3) {
        setPolicyAccNumErr2('Policy Account Number must have a length of 3.');
        isValid = false;
      } else if (!checkPolicyAccount(formValues.policyAccountNumber)) {
        setPolicyAccNumErr2('Policy Account Number must be a whole number.');
        isValid = false;
      }
    }

    // Step 2 Retag validations
    // if both the values are not there
    // if (isChecked == true) return valid;
    if (
      formValues.isChecked === null ||
      formValues.isChecked === undefined ||
      formValues.isChecked === false
    ) {
      if (
        (formValues.policyNumber === null ||
          formValues.policyNumber === undefined ||
          formValues.policyNumber === '') &&
        (formValues.suffixCode === null ||
          formValues.suffixCode === undefined ||
          formValues.suffixCode === '') &&
        (formValues.policyAccountNumber === null ||
          formValues.policyAccountNumber === undefined ||
          formValues.policyAccountNumber === '') &&
        (formValues.sublTypeCode === 'Not Used' ||
          formValues.sublTypeCode === '') &&
        (formValues.activeRetCode === 'Not Used' ||
          formValues.activeRetCode === '') &&
        (formValues.hmoAcctDiv === 'Not Used' ||
          formValues.hmoAcctDiv === '') &&
        (formValues.productCode === 'Not Used' ||
          formValues.productCode === '') &&
        (formValues.fundCode === 'Not Used' || formValues.fundCode === '')
      ) {
        setShowAlert2(true);
        setForm2Err(
          'Please either check the Change Financials(LE/Funding) Checkbox \xa0\xa0\xa0\xa0 -OR- \xa0\xa0\xa0\xa0 Enter Atleast one of the following fields:Step 2: Policy NumberStep 2: Suffix Code\nStep 2: Policy Account Number\nStep 2: Subscriber SSN\nStep 2: HMO Acct DivStep 2: SubLine Type CdStep 2: Active Ret Cd\nStep 2: Fund CdStep 2: Product Cd'
        );
        isValid = false;
      }
    }
    // if both the values are there
    if (
      formValues.isChecked !== null &&
      formValues.isChecked !== undefined &&
      formValues.isChecked !== false
    ) {
      if (
        (formValues.policyNumber !== null &&
          formValues.policyNumber !== '' &&
          formValues.policyNumber !== undefined) ||
        (formValues.suffixCode !== null &&
          formValues.suffixCode !== '' &&
          formValues.suffixCode !== undefined) ||
        (formValues.policyAccountNumber !== null &&
          formValues.policyAccountNumber !== '' &&
          formValues.policyAccountNumber !== undefined) ||
        (formValues.sublTypeCode !== 'Not Used' &&
          formValues.sublTypeCode !== '') ||
        (formValues.activeRetCode !== 'Not Used' &&
          formValues.activeRetCode !== '') ||
        (formValues.hmoAcctDiv !== 'Not Used' &&
          formValues.hmoAcctDiv !== '') ||
        (formValues.productCode !== 'Not Used' &&
          formValues.productCode !== '') ||
        (formValues.fundCode !== 'Not Used' && formValues.fundCode !== '')
      ) {
        setShowAlert2(true);
        setForm2Err(
          'Please either check the Change Financials(LE/Funding) Checkbox \xa0\xa0\xa0\xa0 -OR- \xa0\xa0\xa0\xa0 Enter Atleast one of the following fields:Step 2: Policy NumberStep 2: Suffix Code\nStep 2: Policy Account Number\nStep 2: Subscriber SSN\nStep 2: HMO Acct DivStep 2: SubLine Type CdStep 2: Active Ret Cd\nStep 2: Fund CdStep 2: Product Cd'
        );
        isValid = false;
      }
    }

    const step1Errors: string[] = [];
    if (
      formValues.policyNumber !== null &&
      formValues.policyNumber !== '' &&
      formValues.policyNumber !== undefined &&
      (formValues.policyNumber1 === null ||
        formValues.policyNumber1 === '' ||
        formValues.policyNumber1 === undefined)
    ) {
      step1Errors.push('Please enter Policy Number in Step 1. ');
    }
    if (
      formValues.suffixCode !== null &&
      formValues.suffixCode !== '' &&
      formValues.suffixCode !== undefined &&
      (formValues.suffixCode1 === null ||
        formValues.suffixCode1 === '' ||
        formValues.suffixCode1 === undefined)
    ) {
      step1Errors.push('Please enter Suffix Code in Step 1. ');
    }
    if (
      formValues.policyAccountNumber !== null &&
      formValues.policyAccountNumber !== '' &&
      formValues.policyAccountNumber !== undefined &&
      (formValues.policyAccountNumber1 === null ||
        formValues.policyAccountNumber1 === '' ||
        formValues.policyAccountNumber1 === undefined)
    ) {
      step1Errors.push('Please enter Policy Account Number in Step 1. ');
    }
    if (formValues.fundCode !== '' && formValues.fundCode1 === '') {
      step1Errors.push('Please enter Fund Cd in Step 1. ');
    }
    if (formValues.productCode !== '' && formValues.productCode1 === '') {
      step1Errors.push('Please enter Product Cd in Step 1. ');
    }
    if (formValues.sublTypeCode !== '' && formValues.sublTypeCode1 === '') {
      step1Errors.push('Please enter Subl Typ Cd in Step 1. ');
    }
    if (formValues.activeRetCode !== '' && formValues.activeRetCode1 === '') {
      step1Errors.push('Please enter Active Ret Cd in Step 1. ');
    }
    if (formValues.hmoAcctDiv !== '' && formValues.hmoAcctDiv1 === '') {
      step1Errors.push('Please enter HMO Acct Div in Step 1. ');
    }
    if (step1Errors?.length > 0) {
      setForm1Err(step1Errors.join(''));
      setShowAlert1(true);
      isValid = false;
    }

    return isValid;
  };

  // Start Clear Error Feilds
  const clearErrorFeilds = () => {
    setShowAlert2(false);
    setForm2Err('');
    setPolicyError1('');
    setPolicyError2('');
    setSuffixError1('');
    setSuffixError2('');
    setPolicyAccNumErr1('');
    setPolicyAccNumErr2('');
    setToDateChangeErr1('');
    setTransToDateErr1('');
    setCheckToDateErr1('');
    setShowAlert1(false);
    setForm1Err('');
  };
  //Start : Handle Submit

  const handleSubmit = (event) => {
    clearErrorFeilds();
    let valid = validateForm();
    if (valid) {
      setIsOpen(true);
    }
  };

  //Start : Reset method
  const handleReset = () => {
    setFormValues({
      policyNumber1: '',
      suffixCode1: '',
      policyAccountNumber1: '',
      feeCode1: '',
      fundCode1: '',
      productCode1: '',
      sublTypeCode1: '',
      unetSpclCode1: '',
      activeRetCode1: '',
      subscriberSSN1: '',
      hmoAcctDiv1: '',
      chargeDateFrom1: null,
      chargeDateTo1: null,
      transactionFstServiceDateFrom1: null,
      transactionFstServiceDateTo1: null,
      checkIssueDateFrom1: null,
      checkIssueDateTo1: null,
      policyNumber: '',
      suffixCode: '',
      policyAccountNumber: '',
      fundCode: '',
      productCode: '',
      sublTypeCode: '',
      activeRetCode: '',
      subscriberSSN: '',
      hmoAcctDiv: '',
      isChecked: false,
    });
  };

  //Start : Correction home handler
  const backToHome = () => {
    navigate('/cmdb/correction-management');
  };

  //Start : Add bulk correction handler
  const addBulk = async () => {
    setIsSubmitDisabled(true);
    const apiUrl = `${BASE_URL}manageRequest/get-request-bulk-correction`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      let data = {
        requestId: requestId,
        retagFinInd: formValues.isChecked === false ? 'N' : 'Y',
        polNbr2: formValues.policyNumber.toUpperCase(),
        sufxCd2: formValues.suffixCode.replaceAll("."," ").toUpperCase(),
        polAcctNbr2: formValues.policyAccountNumber,
        sublTypCd2: formValues.sublTypeCode,
        actvRetCd2: formValues.activeRetCode,
        feeCd2: formValues.feeCode1,
        fundCd2: formValues.fundCode,
        prdctCd2: formValues.productCode,
        unetSpclCd2: formValues.unetSpclCode1,
        lastUserShortNm: msid,
        polNbr1: formValues.policyNumber1.toUpperCase(),
        sufxCd1: formValues.suffixCode1.replaceAll("."," ").toUpperCase(),
        polAcctNbr1: formValues.policyAccountNumber1,
        sublTypCd1: formValues.sublTypeCode1,
        actvRetCd1: formValues.activeRetCode1,
        feeCd1: formValues.feeCode1,
        fundCd1: formValues.fundCode1,
        prdctCd1: formValues.productCode1,
        unetSpclCd1: formValues.unetSpclCode1,
        fromTrnSvcDt: formValues.transactionFstServiceDateFrom1,
        toTrnSvcDt: formValues.transactionFstServiceDateTo1,
        fromChkIssDt: formValues.checkIssueDateFrom1,
        toChkIssDt: formValues.checkIssueDateTo1,
        fromChrgDt: formValues.chargeDateFrom1,
        toChrgDt: formValues.chargeDateTo1,
      };
      const payload = {
        requestId: requestId,
        polNbr1: formValues.policyNumber1.toUpperCase(),
        sufxCd1: formValues.suffixCode1.replaceAll("."," ").toUpperCase(),
        polAcctNbr1: formValues.policyAccountNumber1,
        sublTypCd1: formValues.sublTypeCode1,
        actvRetCd1: formValues.activeRetCode1,
        hmoAcctDiv1: formValues.hmoAcctDiv1,
        feeCd1: formValues.feeCode1,
        fundCd1: formValues.fundCode1,
        prdctCd1: formValues.productCode1,
        unetSpclCd1: formValues.unetSpclCode1,
        subscrSSN: formValues.subscriberSSN1,
        fromChrgDt: formValues.chargeDateFrom1,
        toChrgDt: formValues.chargeDateTo1,
        fromTrnSvcDt: formValues.transactionFstServiceDateFrom1,
        toTrnSvcDt: formValues.transactionFstServiceDateTo1,
        fromChkIssDt: formValues.checkIssueDateFrom1,
        toChkIssDt: formValues.checkIssueDateTo1,
      };
      const response = await axios
        .post(apiUrl, payload, config)
        .then((response) => {
          setIsSubmitDisabled(false);
          if (response.status === 200) {
            if (response.data === 'No records to bulk correct') {
              setIsOpen(false);
              showAlert(true);
              setAlertText(response.data);
            } else {
              axios
                .post(
                  `${BASE_URL}manageRequest/add-bulk-correction-request `,
                  data,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    if (response.data === 0) {
                      setIsOpen(false);
                      showAlert(true);
                      setAlertText(
                        'Bulk Correction Request Submit Not Successfull.'
                      );
                    } else {
                      setIsOpen(false);
                      setShowSuccess(true);
                    }
                  }
                })
                .catch((error) => {
                  setIsOpen(false);
                  showAlert(true);
                  setAlertText(
                    'Bulk Correction Request Submit Not Successfull.'
                  );
                });
            }
          }
        })
        .catch((error) => {
          // add-alert-popup
        });
    } catch (error) {
      // add-alert-popup
    }
  };
  const date = new Date();
  return (
    <React.Fragment>
      <Modal
        title="Confirm Request"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Modal.Section>
          Are you sure you want to Bulk Correct Request {requestId}
          <br />
          <Button css={{ margin: '10px' }} onClick={addBulk}>
            Yes
          </Button>
          <Button css={{ margin: '10px' }} onClick={() => setIsOpen(false)}>
            No
          </Button>
        </Modal.Section>
      </Modal>
      <Modal
        title="Bulk Correction Request Successfull"
        isOpen={showSuccess}
        onClose={() => setShowSuccess(false)}
      >
        <Modal.Section>
          Bulk Correction Request for Request ID: {requestId} submitted
          successfully.
          <br />
          <Button css={{ margin: '10px' }} onClick={() => navigate(-1)}>
            Ok
          </Button>
        </Modal.Section>
      </Modal>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '70px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid
                css={{
                  margin: '0px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Flex justify="space-between">
                    <Heading offset={5}>
                      Apply Bulk Correction for Request ID: &nbsp; {requestId}
                    </Heading>
                    <Label size="md" id="first name">
                      {currentDateFormat(date)}
                    </Label>
                  </Flex>
                </Grid.Col>
              </Grid>

              <Grid css={{ marginLeft: '20px', marginTop: '20px' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} css={{ fontSize: '18px !important' }}>
                    Step 1 : Select Transactions To Be Corrected
                  </Heading>
                  <Alert
                    variant="error"
                    isVisible={showAlert1}
                    onClose={() => {
                      setShowAlert1(false);
                    }}
                  >
                    {form1Error}
                  </Alert>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <TextInput
                    label="Policy Number"
                    isDisabled={readOnly}
                    value={formValues.policyNumber1}
                    onChange={(event) =>
                      handleChange(event.target.value, 'policyNumber1')
                    }
                    errorMessage={policyError1}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <TextInput
                    label="Suffix Code"
                    subText="Enter . (dot) if you want to give a space."
                    isDisabled={readOnly}
                    value={formValues.suffixCode1}
                    onChange={(event) =>
                      handleChange(event.target.value.replaceAll(" ","."), 'suffixCode1')
                    }
                    errorMessage={suffixError1}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <TextInput
                    label="Policy Account Number"
                    isDisabled={readOnly}
                    value={formValues.policyAccountNumber1}
                    onChange={(event) =>
                      handleChange(event.target.value, 'policyAccountNumber1')
                    }
                    errorMessage={policyAccError1}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Fee Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.feeCdDropDown?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                    value={formValues.feeCode1}
                    onChange={(event) => handleChange(event, 'feeCode1')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Fund Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.fundCdDropDown?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                    value={formValues.fundCode1}
                    onChange={(event) => handleChange(event, 'fundCode1')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Product Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.productCdDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.productCode1}
                    onChange={(event) => handleChange(event, 'productCode1')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Subl Type Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.sublTypCdDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.sublTypeCode1}
                    onChange={(event) => handleChange(event, 'sublTypeCode1')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Unet Spcl Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.unetSpclCdDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.unetSpclCode1}
                    onChange={(event) => handleChange(event, 'unetSpclCode1')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Active Ret Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.activeRetCdDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.activeRetCode1}
                    onChange={(event) => handleChange(event, 'activeRetCode1')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <TextInput
                    label="Subscriber SSN"
                    isDisabled={readOnly}
                    value={formValues.subscriberSSN1}
                    onChange={(event) =>
                      handleChange(event.target.value, 'subscriberSSN1')
                    }
                    errorMessage={subSSNErr1}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="HMO Acct Div"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.hmoAcctDivDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.hmoAcctDiv1}
                    onChange={(event) => handleChange(event, 'hmoAcctDiv1')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}></Grid.Col>
                <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                  <Text css={{ fontWeight: 600 }}>Charge Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                  <Text css={{ fontWeight: 600 }}>
                    Transaction Fst Service Date:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <DateInput
                    label="From"
                    subText={' '}
                    isClearable={true}
                    isDisabled={readOnly}
                    value={formValues.chargeDateFrom1}
                    onChange={(event) => handleChange(event, 'chargeDateFrom1')}
                    errorMessage={toChangeDateErr1}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <DateInput
                    label="To"
                    subText={' '}
                    isClearable={true}
                    isDisabled={readOnly}
                    value={formValues.chargeDateTo1}
                    onChange={(event) => handleChange(event, 'chargeDateTo1')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <DateInput
                    label="From"
                    subText={' '}
                    isClearable={true}
                    isDisabled={readOnly}
                    value={formValues.transactionFstServiceDateFrom1}
                    onChange={(event) =>
                      handleChange(event, 'transactionFstServiceDateFrom1')
                    }
                    errorMessage={toTransDateErr1}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <DateInput
                    label="To"
                    subText={' '}
                    isClearable={true}
                    isDisabled={readOnly}
                    value={formValues.transactionFstServiceDateTo1}
                    onChange={(event) =>
                      handleChange(event, 'transactionFstServiceDateTo1')
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Check Issue Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <DateInput
                    label="From"
                    subText={' '}
                    isClearable={true}
                    isDisabled={readOnly}
                    value={formValues.checkIssueDateFrom1}
                    onChange={(event) =>
                      handleChange(event, 'checkIssueDateFrom1')
                    }
                    errorMessage={toCheckDateErr1}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <DateInput
                    label="To"
                    subText={' '}
                    isClearable={true}
                    isDisabled={readOnly}
                    value={formValues.checkIssueDateTo1}
                    onChange={(event) =>
                      handleChange(event, 'checkIssueDateTo1')
                    }
                  />
                </Grid.Col>
                {/* <SelectTransaction
                  onChange={handleSelectTransactionChange}
                  policyError={policyError}
                  suffixError={suffixError}
                />
                <KeyCorrection
                  onChange={handleKeyCorrectionChange}
                  error={error}
                /> */}
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                }}
              >
                <Alert
                  variant="error"
                  isVisible={alert}
                  onClose={() => {
                    showAlert(false);
                  }}
                >
                  {alertText}
                </Alert>
              </Grid>

              <Grid css={{ marginLeft: '20px', marginTop: '20px' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} css={{ fontSize: '18px !important' }}>
                    Step 2 : Key Correction Values
                  </Heading>
                  <Alert
                    variant="error"
                    isVisible={showAlert2}
                    onClose={() => {
                      setShowAlert2(false);
                    }}
                  >
                    {form2Error}
                  </Alert>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <TextInput
                    label="Policy Number"
                    isDisabled={readOnly}
                    value={formValues.policyNumber}
                    onChange={(event) =>
                      handleChange(event?.target.value, 'policyNumber')
                    }
                    errorMessage={policyError2}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <TextInput
                    label="Suffix Code"
                    subText="Enter . (dot) if you want to give a space."
                    isDisabled={readOnly}
                    value={formValues.suffixCode}
                    onChange={(event) =>
                      handleChange(event?.target.value.replaceAll(" ","."), 'suffixCode')
                    }
                    errorMessage={suffixError2}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <TextInput
                    label="Policy Account Number"
                    isDisabled={readOnly}
                    value={formValues.policyAccountNumber}
                    onChange={(event) =>
                      handleChange(event?.target.value, 'policyAccountNumber')
                    }
                    errorMessage={policyAccError2}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Fund Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.fundCdDropDown?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                    value={formValues.fundCode}
                    onChange={(value) => handleChange(value, 'fundCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Product Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.productCdDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.productCode}
                    onChange={(value) => handleChange(value, 'productCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Subl Type Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.sublTypCdDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.sublTypeCode}
                    onChange={(value) => handleChange(value, 'sublTypeCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="Active Ret Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.activeRetCdDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.activeRetCode}
                    onChange={(value) => handleChange(value, 'activeRetCode')}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 3 }}>
                  <SelectInput
                    label="HMO Acct Div"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={aggregateOptions?.hmoAcctDivDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={formValues.hmoAcctDiv}
                    onChange={(value) => handleChange(value, 'hmoAcctDiv')}
                  />
                </Grid.Col>
                <Grid.Col
                  span={{ xs: 3, md: 3, lg: 3, xl: 3 }}
                  css={{ textAlign: 'center' }}
                >
                  <Text css={{ fontWeight: 600 }}>-OR-</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                  <Checkbox
                    label="Change Financials(LE/Funding)"
                    isChecked={formValues.isChecked}
                    isDisabled={readOnly}
                    onChange={(e) =>
                      handleChange(e.target.checked, 'isChecked')
                    }
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '150px',
                      },
                    }}
                    isDisabled={readOnly}
                    isLoading={isSubmitDisabled}
                    ariaLoadingLabel="Loading"
                    onClick={handleSubmit}
                  >
                    Submit Rqst
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '150px',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={backToHome}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(AggragateCorrection, 'cmdb_update');
