import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { usePrint } from '@abyss/web/hooks/usePrint';
import withAuth from '@src/auth/withAuth';

const PaymentSubmit = () => {
  const { readOnly } = useAuth();
  const { navigate, getLocation } = useRouter();
  const path = '/cmdb/correction-management';
  const handleBackButton = () => {
    navigate(path);
  };
  const print = usePrint();
  let location = getLocation();
  let data = location?.state;
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <style>{`
            @media print{
              .printing {
              margin-top:150px;
              min-width: 800px;}
            }`}</style>
            <Box color="$white" className="printing">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>Correction Request Processor</Heading>
                </Grid.Col>
              </Grid>
              <Grid.Col span={{ xs: 3 }}>
                <Text css={{ fontWeight: 600 }}>Request ID : </Text>
                <Text>{data?.requestId}</Text>
              </Grid.Col>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Heading offset={5}>Request Detail</Heading>
                    <b>
                      {new Date().toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </b>
                  </div>
                </Grid.Col>
              </Grid>
              <Grid
                css={{ margin: '0px !important', marginTop: '20px !important' }}
              >
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Book Month From :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{data?.fromBookMonth ? data?.fromBookMonth : '(blank)'}</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Series Designator :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{data?.seriesDesignator ? data?.seriesDesignator : '(blank)'}</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Book Month To :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{data?.toBookMonth ? data?.toBookMonth : '(blank)'}</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Check Number :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{data?.checkNumber ? data?.checkNumber : '(blank)'}</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Request Type :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>Payment</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>CPS# :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{data?.cps ? data?.cps : '(blank)'}</Text>
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={() => {
                      print.printPage();
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleBackButton}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(PaymentSubmit, "cmdb_update");
