import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { currentDateFormat } from '@src/components/DateUtils';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { Link } from '@abyss/web/ui/Link';
import useAuth from '@src/utils/useAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Button } from '@abyss/web/ui/Button';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { Alert } from '@abyss/web/ui/Alert';
const CorrectionManagement = () => {
  const { readOnly, token } = useAuth();
  const { navigate } = useRouter();
  const { getLocation } = useRouter();
  const location = getLocation();
  const currentDate = new Date().toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });

  const [requestId, setRequestId] = useState(() => {
    const currentPath = location?.pathname;
    return currentPath?.startsWith('/cmdb/correction-management')
      ? sessionStorage.getItem('requestId') || ''
      : '';
  });
  const [rqstTxt, setRqstTxt] = useState('');
  const [error, setError] = useState('');

  const [dailyLogData, setDailyLogData] = useState({ reviewDate: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    if (location?.pathname.startsWith('/cmdb/correction-management')) {
      sessionStorage.setItem('requestId', requestId);
    } else {
      setRequestId('');
      sessionStorage.removeItem('requestId');
    }
  }, [location?.pathname, requestId]);

  const handleRequest = (path) => {
    if (!requestId) {
      setError('Request ID Required');
    } else {
      setError('');
      axios
        .get(`${BASE_URL}manageRequest/validReqID/${requestId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (
              response.data['stsCd'] === 'E' ||
              response.data['stsCd'] === 'I'
            ) {
              axios
                .get(`${BASE_URL}manageRequest/getReqType/${requestId}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  if (response.status === 200) {
                    setRqstTxt(response.data[rqstTxt]);
                    navigate(path, {
                      state: {
                        data: response.data,
                        requestId: requestId,
                        rqstTxt: rqstTxt,
                      },
                    });
                  }
                })
                .catch((error) => {
                  // add-alert-popup
                });
            } else {
              setMsg(
                `Request ID ${requestId} is not available for Correction.`
              );
              setIsOpen(true);
            }
          }
        })
        .catch((error) => {
          // add-alert-popup
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}crtmgt/daily-review/getDailyReview`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setDailyLogData(response.data);
      } catch (error) {
        // add-alert-popup
      }
    };
    if (token) fetchData();
  }, [token]);

  const getFinalizeCorrectionData = async (path, endPoint) => {
    try {
      const response = await axios
        .get(`${BASE_URL}${endPoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            navigate(path, {
              state: {
                data: response.data,
                requestId: requestId,
              },
            });
          }
          if (response.status === 404) {
            setMsg('No record found against the search criteria.');
            setIsOpen(true);
            setError('');
          }
        })
        .catch((error) => {
          // add-alert-popup
        });
    } catch (error) {
      // add-alert-popup
    }
  };
  const getFinSumData = async (path, endPoint) => {
    try {
      const response = await axios
        .get(`${BASE_URL}${endPoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            navigate(path, {
              state: {
                data: response.data,
                requestId: requestId,
              },
            });
          }
          if (response.status === 404) {
            setMsg('No record found against the search criteria.');
            setIsOpen(true);
            setError('');
          }
        })
        .catch((error) => {
          // add-alert-popup
        });
    } catch (error) {
      // add-alert-popup
    }
  };
  const handleApproveFinSum = async (path) => {
    if (!requestId) {
      setError('Request ID Required');
    } else {
      setError('');
      try {
        const response = await axios.get(
          `${BASE_URL}manageRequest/validReqID/${requestId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          if (response.data['stsCd'] === 'W') {
            getFinSumData(
              path,
              `manageRequest/get-approve-fin-summmary/${requestId}`
            );
          } else {
            setMsg(
              `Request ID ${requestId} is not available for Financial Approval.`
            );
            setIsOpen(true);
          }
        }
      } catch (error) {
        // add-alert-popup
      }
    }
  };
  const handleFinalizeCorrection = async (path) => {
    if (!requestId) {
      setError('Request ID Required');
    } else {
      setError('');
      try {
        const response = await axios.get(
          `${BASE_URL}manageRequest/validReqID/${requestId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          if (
            response.data['stsCd'] === 'E' ||
            response.data['stsCd'] === 'I' ||
            response.data['stsCd'] === 'R' ||
            response.data['stsCd'] === 'H' ||
            response.data['stsCd'] === 'B'
          ) {
            getFinalizeCorrectionData(
              path,
              `manageRequest/get-request-detail/${requestId}`
            );
          } else {
            setMsg(`Request ID ${requestId} is not available for Finalize.`);
            setIsOpen(true);
          }
        }
      } catch (error) {
        // add-alert-popup
      }
    }
  };
  const date = new Date();
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={12} css={{ paddingBottom: '2.5rem' }}>
          <Card
            css={{
              marginTop: '70px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Flex justify="space-between" css={{ marginBottom: '10px' }}>
                <span></span>
                <Heading textAlign="center" offset={3}>
                  Correction Request Management
                </Heading>
                <Label size="md" id="first name">
                  {currentDateFormat(date)}
                </Label>
              </Flex>
              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginTop: '10px', paddingRight: '12px' }}>
                        <Text css={{ fontWeight: 'bold' }}>
                          My Request ID:{' '}
                        </Text>
                      </span>
                      <span>
                        <TextInput
                          value={requestId}
                          onChange={(e) => setRequestId(e.target.value)}
                          css={{
                            'abyss-text-input:focus': {
                              boxShadow: '#ff4e00 0px 0px 0px 2px !important ',
                            },
                          }}
                          isDisabled={readOnly}
                          errorMessage={error}
                        />
                      </span>
                    </div>
                    <Button
                      onClick={() =>
                        navigate('/cmdb/correction-management/help')
                      }
                    >
                      Help
                    </Button>
                  </div>
                </Grid.Col>
                <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                  <Alert
                    isVisible={isOpen}
                    css={{
                      'abyss-alert-root': {
                        alignItems: 'center',
                      },
                    }}
                    onClose={() => setIsOpen(!isOpen)}
                  >
                    {msg}
                  </Alert>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}></Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 6 }}>
                  <Text css={{ fontWeight: 600 }}>Create Custom Request</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Text css={{ fontWeight: 600 }}>Inquiry/Excel Download</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/policy-structure"
                    isDisabled={readOnly}
                  >
                    Policy Structure
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/inquiry-search"
                    isDisabled={readOnly}
                  >
                    Inquiry Search
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/financial-structure"
                    isDisabled={readOnly}
                  >
                    Financial Structure
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/requests-available-correction"
                    isDisabled={readOnly}
                  >
                    Requests Available For Correction (Custom)
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/employee"
                    isDisabled={readOnly}
                  >
                    Employee
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/requests-on-hold"
                    isDisabled={readOnly}
                  >
                    Requests on Hold (All)
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/payment"
                    isDisabled={readOnly}
                  >
                    Payment
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/requests-in-progress"
                    isDisabled={readOnly}
                  >
                    Requests in Progress (Custom)
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/suspect-error"
                    isDisabled={readOnly}
                  >
                    Suspect Error
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/requests-requiring-financial-approval"
                    isDisabled={readOnly}
                  >
                    Requests Requiring Financial Appproval (All)
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}></Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/view-bulk-corrections"
                    isDisabled={readOnly}
                  >
                    View Bulk Corrections &nbsp;
                    <Text css={{ color: '$redDvz1' }}>
                      (Request ID Required)
                    </Text>
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}></Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/view-prelim-fin-sum"
                    isDisabled={readOnly}
                  >
                    View Prelim. Fin. Sum &nbsp;
                    <Text css={{ color: '$redDvz1' }}>
                      (Request ID Required)
                    </Text>
                  </Link>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 6 }}>
                  <Text css={{ fontWeight: 600 }}>
                    Manage Request &nbsp;
                    <Text css={{ color: '$redDvz1' }}>
                      (Request ID Required)
                    </Text>
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 6 }}>
                  <Text css={{ fontWeight: 600 }}>Lock Management</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    // href="/cmdb/correction-management/aggregate-correction"
                    isDisabled={readOnly}
                    onClick={(e) =>
                      handleRequest(
                        '/cmdb/correction-management/aggregate-correction'
                      )
                    }
                  >
                    Aggregate Corrections (Bulk)
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/unlock-claim-transactions"
                    isDisabled={readOnly}
                  >
                    Unlock Claim Transactions (All)
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    onClick={(e) =>
                      handleRequest(
                        `/cmdb/correction-management/itemize-correction/${requestId}`
                      )
                    }
                    isDisabled={readOnly}
                  >
                    Itemized Corrections (Non-Bulk)
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Text css={{ fontWeight: 600 }}>Logs </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    onClick={(e) =>
                      handleFinalizeCorrection(
                        '/cmdb/correction-management/finalize-correction'
                      )
                    }
                    isDisabled={readOnly}
                  >
                    Finalize Corrections (Non-Bulk)/Deny Request
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    href="/cmdb/correction-management/daily-auto-review"
                    isDisabled={readOnly}
                  >
                    Daily Auto Review (`{dailyLogData.reviewDate}
                    `)
                  </Link>
                </Grid.Col>
                <Grid.Col span={{ xs: 6 }}>
                  <Link
                    // href="/cmdb/correction-management/approve-financial-summary"
                    onClick={(e) =>
                      handleApproveFinSum(
                        '/cmdb/correction-management/approve-financial-summary-all'
                      )
                    }
                    isDisabled={readOnly}
                  >
                    Approve Financial Summary (All)
                  </Link>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(CorrectionManagement, 'cmdb_update');
