import React, { useState, useEffect } from 'react';

import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import { Alert } from '@abyss/web/ui/Alert';
import * as XLSX from 'xlsx';
import { getEnvironment } from '@src/utils/environmentUtil';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Modal } from '@abyss/web/ui/Modal';
import { BASE_URL } from '@src/utils/endpoints';
import { DateInput } from '@abyss/web/ui/DateInput';
import { set } from 'lodash';
import env from '../../../env.json';

const UserGroupMember = () => {
  const { getLocation } = useRouter();
  const location = getLocation();
  const { token, msid, userRolesGroup } = useAuth();
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [row, setRow] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [records, setRecords] = useState('0 records');
  const [saveModal, setSaveModal] = useState(false);
  const [errIsVisible, setErrIsVisible] = useState(false);
  const [errAlertMsg, setErrAlertMsg] = useState('');
  const [hideUpdate, setHideUpdate] = useState(false);
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const deleteRows = async (payload) => {
    setShowModal(false);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/sec/delete/userGroupMember/${location?.state?.database}`,
        row,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully deleted the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setJSONData(tableData);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [userShortNm, setuserShortNm] = useState('');
  const [userShortNmErr, setuserShortNmErr] = useState('');
  const [appCd, setAppCd] = useState('FADB');
  const [appCdErr, setAppCdErr] = useState('');
  const [userGrpId, setUserGrpId] = useState('');
  const [userGrpIdErr, setUserGrpIdErr] = useState('');
  const [effDt, setEffDt] = useState(null);
  const [effDtErr, setEffDtErr] = useState('');
  const [endDt, setEndDt] = useState('12/31/9999');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [JSONData, setJSONData] = useState(location?.state?.data);

  const getTableData1 = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}fadb/sec/get/userGroupMember/${location?.state?.database}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setUpdateFlag(true);
    setFlag(true);
    setUpdateRow(row);
    setuserShortNm(row?.['userShortNm']);
    setAppCd(row?.['appCd']);
    setUserGrpId(row?.['userGrpId']);
    setEffDt(row?.['effDt']);
    setEndDt(row?.['endDt']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    columnNames = {
      USER_SHORT_NM: null,
      APP_CD: null,
      USER_GRP_ID: null,
      EFF_DT: null,
      END_DT: null,
    };
    return columnNames;
  };

  const columnNameMapping = {
    userShortNm: 'USER_SHORT_NM',
    appCd: 'APP_CD',
    userGrpId: 'USER_GRP_ID',
    effDt: 'EFF_DT',
    endDt: 'END_DT',
  };

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'user_group_member.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'user_group_member.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions:
      hasRWaccess &&
      (hideUpdate ? [deleteLinkButton] : [updateLinkButton, deleteLinkButton]),
  });
  const isUserShortNm = async (payload) => {
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/sec/user-group-member/isUserShortNm/${location?.state?.database}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const addRow = () => {
    setFlag(true);
    setHideUpdate(true);
  };
  const handleClick = () => {
    window.location.href = '/ftmf/fadb';
  };
  const validateSave = async () => {
    clearErrorFields();
    let valid = true;
    if (userShortNm === '') {
      setUserGrpIdErr('Please enter valid User Short Name.');
      valid = false;
    }

    if (appCd === '') {
      setAppCdErr('APP_CD cannot be empty.');
      valid = false;
    }
    if (userGrpId === '' || Number(userGrpId) === 0) {
      setuserShortNmErr('USER_GRP_ID cannot be empty or 0.');
      valid = false;
    } else if (Number(userGrpId) < 1 || Number(userGrpId) > 10) {
      setuserShortNmErr('USER_GRP_ID must between 1 to 10.');
      valid = false;
    }
    if (effDt === null) {
      setEffDtErr('Please enter a valid Effective Date - MM/DD/YYYY.');
      valid = false;
    }

    // Check if combination already exists in the table

    if (
      JSONData?.some(
        (data) =>
          data?.userShortNm.trim() == userShortNm.trim() &&
          data?.appCd.trim() == appCd.trim() &&
          data?.userGrpId == userGrpId
      )
    ) {
      setErrAlertMsg(
        userShortNm +
          ' ' +
          appCd +
          ' ' +
          userGrpId +
          ' already exists in the table'
      );
      setErrIsVisible(true);
      valid = false;
    }
    //validate user short name

    const userGrpIdCheck = Number(userGrpId) >= 1 && Number(userGrpId) <= 10;
    if (
      userShortNm !== '' &&
      userGrpId !== '' &&
      userGrpIdCheck &&
      appCd !== '' &&
      effDt !== null
    ) {
      const response = await isUserShortNm({ userShortNm: userShortNm });
      if (response === false) {
        setErrAlertMsg(userShortNm + ' does not exist in SEC_USER table.');
        setErrIsVisible(true);
        valid = false;
        setSaveModal(false);
      }
    }

    return valid;
  };

  const save = async () => {
    setHideUpdate(false);
    setFlag(false);
    setSaveModal(false);
    let payload = {
      userShortNm: userShortNm,
      appCd: appCd,
      userGrpId: userGrpId,
      effDt: effDt,
      endDt: endDt,
    };
    setuserShortNm('');
    setAppCd('');
    setUserGrpId('');
    setEffDt(null);
    setEndDt(null);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/sec/add/userGroupMember/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully added record to table');

        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setJSONData(tableData);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const clearErrorFields = () => {
    setuserShortNmErr('');
    setEffDtErr('');
    setAppCdErr('');
    setUserGrpIdErr('');
  };
  const validateUpdate = () => {
    clearErrorFields();
    let valid = true;
    if (userGrpId === '') {
      setuserShortNmErr('USER_GRP_ID cannot be empty or 0.');
      valid = false;
    } else if (Number(userGrpId) < 1 || Number(userGrpId) > 10) {
      setuserShortNmErr('USER_GRP_ID must between 1 to 10.');
      valid = false;
    }
    if (effDt === null) {
      setEffDtErr('EFF_DT cannot be empty.');
      valid = false;
    }
    if (
      userShortNm === updateRow['userShortNm'] &&
      appCd === updateRow['appCd'] &&
      userGrpId === updateRow['userGrpId'] &&
      effDt === updateRow['effDt'] &&
      endDt === updateRow['endDt']
    ) {
      setErrAlertMsg('Nothing changed for update');
      setErrIsVisible(true);
      setUpdateModal(false);
      close();
      valid = false;
    }
    return valid;
  };
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  const update = async () => {
    setUpdateFlag(false);
    setFlag(false);
    setUpdateConfirmModal(false);
    let payload = {
      newRecord: {
        userShortNm: userShortNm,
        appCd: appCd,
        userGrpId: userGrpId,
        effDt: effDt,
        lstUpdtId: msid,
        endDt: endDt,
      },
      oldRecord: {
        userShortNm: updateRow['userShortNm'],
        appCd: updateRow['appCd'],
        userGrpId: updateRow['userGrpId'],
        effDt: updateRow['effDt'],
        endDt: updateRow['endDt'],
      },
    };
    setuserShortNm('');
    setAppCd('');
    setUserGrpId('');
    setEffDt(null);
    setEndDt(null);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/sec/update/userGroupMember/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully updated the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setJSONData(tableData);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const close = () => {
    clearErrorFields();
    setHideUpdate(false);
    setFlag(false);
    setuserShortNm('');
    setAppCd('');
    setUserGrpId('');
    setEffDt(null);
    setEndDt(null);
    setUpdateFlag(false);
  };
  const updateModalFunc = async () => {
    const isValid = await validateUpdate();
    if (isValid) {
      setUpdateModal(true);
    }
  };
  const saveModalFunc = async () => {
    const isValid = await validateSave();
    if (isValid) {
      setSaveModal(true);
    }
  };
  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  useEffect(() => {
    const hasTableFADBReadWriteAccess = env.Table_FADB_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    setHasRWaccess(hasTableFADBReadWriteAccess);
  }, [userRolesGroup]);

  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => {
                setUpdateConfirmModal(false);
                setFlag(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update"
          isOpen={updateModal}
          onClose={() => setUpdateModal(false)}
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>USER_SHORT_NM:</b>
                {updateRow['userShortNm']}
              </span>
              <span>
                <b>USER_SHORT_NM:</b>
                {userShortNm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>APP_CD:</b>
                {updateRow['appCd']}
              </span>
              <span>
                <b>APP_CD:</b>
                {appCd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>USER_GRP_ID:</b>
                {updateRow['userGrpId']}
              </span>
              <span>
                <b>USER_GRP_ID:</b>
                {userGrpId}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>EFF_DT:</b>
                {updateRow['effDt']}
              </span>
              <span>
                <b>EFF_DT:</b>
                {effDt}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>END_DT:</b>
                {updateRow['endDt']}
              </span>
              <span>
                <b>END_DT:</b>
                {endDt}
              </span>
            </div>
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => {
                setUpdateModal(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Delete Record"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Section>
            <b>
              USER_SHORT_NM: {row?.['userShortNm']}
              <br />
              APP_CD: {row?.['appCd']}
              <br />
              USER_GRP_ID : {row?.['userGrpId']}
              <br />
              EFF_DT: {row?.['effDt']}
              <br />
              END_DT: {row?.['endDt']}
              <br />
            </b>
            Are you sure you want to delete this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={deleteRows}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                title={alertMsg}
                variant="success"
                isVisible={isVisible}
                onClose={() => setIsVisible(false)}
              />
              <Alert
                title={errAlertMsg}
                variant="error"
                isVisible={errIsVisible}
                onClose={() => {
                  setErrIsVisible(false);
                }}
              />
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                <Text fontWeight={300} css={{ fontSize: '14px' }}>
                  {records}
                </Text>
              </Grid.Col>
              <br />
              {hasRWaccess && (
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={flag ? true : false}
                >
                  + Add Row
                </Button>
              )}
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                    span={{ xs: 8 }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="USER_SHORT_NM"
                        value={userShortNm}
                        onChange={(e) =>
                          setuserShortNm(e.target.value.toUpperCase())
                        }
                        isDisabled={updateFlag}
                        errorMessage={userGrpIdErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="APP_CD"
                        isDisabled={updateFlag}
                        value={appCd || 'FADB'}
                        onChange={(e) => setAppCd(e.target.value)}
                        errorMessage={appCdErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="USER_GRP_ID"
                        value={userGrpId}
                        mask="numeric"
                        onChange={(e) => setUserGrpId(e.target.value)}
                        errorMessage={userShortNmErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <DateInput
                        label="EFF_DT"
                        value={effDt}
                        onChange={(e) => setEffDt(e)}
                        errorMessage={effDtErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <DateInput
                        label="END_DT"
                        value={endDt || '12/31/9999'}
                        onChange={(e) => setEndDt(e)}
                        isDisabled={updateFlag}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button
                        onClick={updateFlag ? updateModalFunc : saveModalFunc}
                      >
                        Save
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(UserGroupMember, 'table_fadb_ro_rw');
