// useAuth.js
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode'; // import dependency
import { loginRequest } from '../auth/authConfig';

const useAuth = () => {
  const { instance, accounts } = useMsal();
  const [roles, setRoles] = useState<string[] | undefined>([]);
  const [token, setToken] = useState<string | null>(localStorage.getItem('jwttoken'));
  const [readOnly, setReadOnly] = useState(false);
  const [noAccess, setNoAccess] = useState(false);
  const [msid, setMsid] = useState('');
  const [userName, setUserName] = useState('');
  const [userRolesGroup, setUserRolesGroup] = useState([]);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isInactive, setIsInactive] = useState(false);

  // Function to get remaining time until token expiry
  const getRemainingTime = (token: string | undefined) => {
    if (!token) return 0;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp - currentTime;
    } catch (error) {
      console.error('Invalid token specified:', error);
      return 0;
    }
  };

  // Function to acquire tokens
  const getToken = async () => {
    if (isLoggedOut || !accounts || accounts.length === 0) {
      return; // Prevent further actions if logged out
    }
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      setToken(response?.idToken);
    } catch (error) {
      console.error('Token acquisition failed', error);
    }
  };

  const handleTokenExpiry = async () => {
    if (isLoggedOut || isInactive) return;
    if (accounts && accounts.length > 0) {
      const account = accounts[0];

      const localToken = localStorage.getItem('jwttoken');
      const idTokenRemainingTime = getRemainingTime(localToken);

      // Refresh ID token if less than 20 minutes remaining
      if (localToken != null && idTokenRemainingTime < 1200) {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read'],
            account,
            forceRefresh: true,
            refreshTokenExpirationOffsetSeconds: 3600,
          });
          setToken(response.idToken);
          // await getToken();
        } catch (error) {
          console.error('ID token refresh failed', error);
        }
      }
      // Log out if access token is expired
      // if (accessToken && accessTokenRemainingTime <= 0) {
      //   handleLogout();
      // }
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    instance.logoutRedirect();
    setIsLoggedOut(true);
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== '') {
      localStorage.setItem('jwttoken', token);
    }
  }, [token]);

  useEffect(() => {
    getToken(); // Attempt to get the token if it exists

    if (accounts && accounts.length > 0) {
      setRoles(accounts[0]?.idTokenClaims?.roles);
      setToken(accounts[0]?.idToken);
      setUserRolesGroup(accounts[0]?.idTokenClaims?.groups);
    }

    if (token) {
      const decodedToken = jwtDecode(token);
      setMsid(decodedToken.MSID);
    }

    if (accounts[0]?.name) {
      const fullName = accounts[0].name;
      const lastName = fullName.split(', ')[1];
      setUserName(lastName);
    }

    const handleActivity = () => {
      sessionStorage.setItem('inactivityStartTime', Date.now().toString());
    };
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Interval to check token expiry and inactivity every minute
    const interval = setInterval(() => {
      const activityTime = sessionStorage.getItem('inactivityStartTime');
      // Check for inactivity
      if (Date.now() - parseInt(activityTime) > 30 * 60 * 1000) {
        setIsInactive(true);
      }
      handleTokenExpiry();
    }, 1 * 60 * 1000);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [instance, accounts]);
  // }, [instance, accounts, token, userName]);

  const resetIdleStatus = (val) => {
    setIsInactive(false);
    if (val === 'userConfirmedLogout') {
      handleLogout();
    } else if (val === 'cancel') {
      sessionStorage.setItem('inactivityStartTime', Date.now().toString());
      handleTokenExpiry();
    }
  };

  return { roles, token, readOnly, msid, userName, noAccess, userRolesGroup, isInactive, resetIdleStatus };
};

export default useAuth;
