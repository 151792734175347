import React, { useState, useEffect } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { usePrint } from '@abyss/web/hooks/usePrint';
import axios from 'axios';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Layout } from '@abyss/web/ui/Layout';
import useAuth from '@src/utils/useAuth';
import withAuth from '@src/auth/withAuth';
import { BASE_URL, API_BASE_URL } from '@src/utils/endpoints';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

const UnlockClaimTransactions = () => {
  const { readOnly, token } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const { navigate } = useRouter();
  const lockedIDs = {
    reqId: '',
    transId: '',
  };
  const [lockedId, setLockedId] = useState(lockedIDs);
  const [errorMsg, setErrorMsg] = useState(lockedIDs);
  const [data, setData] = useState([]);
  const [recordSCount, setRecords] = useState('');

  const print = usePrint();

  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'Request ID',
        accessor: 'rqstId',
      },
      {
        Header: 'Rqst User Name		',
        accessor: 'rqstUserName',
      },
      {
        Header: 'Request Date	',
        accessor: 'rqstDt',
      },
      {
        Header: 'Charge Date	',
        accessor: 'chargeDate',
      },
      {
        Header: 'Transaction ID',
        accessor: 'transId',
      },
      {
        Header: 'Subline Sequence Number',
        accessor: 'sublineSeqNbr',
      },
      {
        Header: 'User Locking Transaction',
        accessor: 'userLockingTransaction',
      },
      {
        Header: 'Locked Request ID',
        accessor: 'lockedReqId',
        width: 100,
      },
    ],
    []
  );

  const handleChange = (type, e) => {
    let value = e.target.value;
    if (type === 'trans') {
      setLockedId({ ...lockedId, transId: value });
    } else if (type === 'req') {
      setLockedId({ ...lockedId, reqId: value });
    }
  };
  //  TODO: After getting testData will implement submit feature
  const validateReqId = () => {
    if (
      lockedId.reqId === null ||
      lockedId.reqId === undefined ||
      lockedId.reqId?.trim() === '' ||
      lockedId.reqId === ''
    ) {
      setErrorMsg((errorMsg) => ({
        ...errorMsg,
        reqId: 'Locked Request ID is required.',
      }));
      return false;
    }
    if (!/^\d+$/.test(lockedId.reqId)) {
      setErrorMsg({ ...errorMsg, reqId: 'Please enter a valid Request ID' });
      return false;
    } else {
      setErrorMsg((errorMsg) => ({ ...errorMsg, reqId: '' }));
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateReqId()) {
      setIsLoading(true);
      try {
        const payload = {
          lockedRequestId: lockedId.reqId,
          lockedTransId: lockedId.transId,
        };

        const response = await axios.post(
          `${BASE_URL}cmdb-service/get-locked-transaction`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200 && response.data) {
          setData(response.data);
          setIsLoading(false);
        }

        setErrorMsg(lockedIDs);
      } catch (error) {
        // add-alert-popup
      }
    }
  };

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    let hours = today.getHours() % 12 || 12;
    const formattedHours = String(hours).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${formattedHours}${minutes}${seconds}`;
  };

  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      data && Array.isArray(data) && data.length > 0
        ? data
        : [
            {
              'Request ID': null,
              'Rqst User Name	': null,
              'Request Date': null,
              'Charge Date': null,
              'Transaction ID': null,
              'Subline Sequence Number': null,
              'User Locking Transaction': null,
              'Locked Request ID': null,
            },
          ]
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
      workbook,
      'CMDB-Locked Transactions_' + getFormattedDate() + '.xlsx'
    );
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: data && Array.isArray(data) ? data : [],
    showSelection: true,
    minColumnWidth: 50,
    downloadButtonConfig: {
      custom: {
        title: 'Download full dataset (XLSX)',
        icon: <IconSymbol icon="download_done" />,
        onClick: downloadExcel,
      },
    },
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showGlobalFilter: false,
  });

  useEffect(() => {
    getTableState.setData(data && Array.isArray(data) ? data : [], true);
  }, [data]);

  useEffect(() => {
    const recordSCount =
      data && Array.isArray(data) && data?.length > 0
        ? `${data.length} records`
        : '0 records';
    setRecords(recordSCount);
  }, []);

  const handleBackButton = () => {
    navigate('/cmdb/correction-management');
  };
  return (
    <Layout.Group>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <style>{`
            @media print{
              .printing {
              margin-top:150px;
              margin-left:100px}
            }`}</style>
          <Grid.Col
            span={10}
            css={{ paddingBottom: '2.5rem' }}
            className="printing"
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Heading offset={5} textAlign="left">
                  Unlock Transaction
                </Heading>
                <b>
                  {new Date().toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </b>
              </div>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderTop: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Search for Locked Transactions:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <TextInput
                    label="Locked Request ID"
                    value={lockedId.reqId}
                    onChange={(e) => {
                      handleChange('req', e);
                    }}
                    errorMessage={errorMsg.reqId}
                    isDisabled={readOnly}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleSubmit(event);
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <TextInput
                    label="Locked Trans ID"
                    value={lockedId.transId}
                    onChange={(e) => {
                      handleChange('trans', e);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleSubmit(event);
                      }
                    }}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col
                  span={{ xs: 4, md: 4, lg: 4, xl: 4 }}
                  css={{ margin: '5px' }}
                >
                  <Button onClick={handleSubmit} isDisabled={readOnly}>
                    Get Locked Transactions
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderTop: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Locked Transaction Information:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 300, fontSize: '14px' }}>
                    {recordSCount}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  {isLoading && <WrapperLoader />}
                  {!isLoading && (
                    <div
                      style={{
                        height:
                          getTableState?.data?.length > 6 ? '550px' : 'auto',
                      }}
                    >
                      <DataTable
                        title="Unlock Claim Transactions"
                        tableState={getTableState}
                        hideTitleHeader
                      />
                    </div>
                  )}
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={() => {
                      print.printPage();
                    }}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    onClick={handleBackButton}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </Layout.Group>
  );
};

export default withAuth(UnlockClaimTransactions, 'cmdb_update');
