import { useEffect } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import * as XLSX from 'xlsx';
import { useRouter } from '@abyss/web/hooks/useRouter';

export const WrapperDataTable = ({ title, data, columns, fileName }) => {
  const { navigate } = useRouter();
  const handleModifySearch = () => {
    navigate('/cmdb/correction-management/inquiry-search');
  };

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleModifySearch,
    },
  ];

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    } else {
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    tableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };

  const downloadExcel = () => {
    let fullData = [];
    tableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };

  const tableState = useDataTable({
    showColumnSort: true,
    showTopPagination: true,
    initialColumns: columns,
    showDownloadButton: true,
    showPagination: true,
    initialData:
      data === 'Not available' ||
      data === 'Data not found' ||
      data === 'No records found'
        ? []
        : data,

    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    customHeaderButtons,
  });

  useEffect(() => {
    if (data.length) {
      tableState.setData(data || [], true);
    }
  }, [data]);

  return (
    <div
      style={{
        height: tableState?.data?.length > 6 ? '550px' : 'auto',
      }}
    >
      <DataTable title={title} tableState={tableState} hideTitleHeader />
    </div>
  );
};
