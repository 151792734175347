export const dateFormatter = (data: any) => {
  const date = new Date(data);

  // Extract the components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

  // Determine AM/PM
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

  // Format the date
  const formattedDate = `${month}/${day}/${year} ${formattedHours}:${minutes}:${seconds}.${milliseconds} ${ampm}`;
  const convertedTimestamp = formattedDate.replace(/\.\d{3}/, '');
  return convertedTimestamp;
}