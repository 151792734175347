import React from 'react';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { Button } from '@abyss/web/ui/Button';
import { currentDateFormat } from '@src/components/DateUtils';
import { Heading } from '@abyss/web/ui/Heading';
import GeneralInfoSection from './GeneralInfoSection';
import DefaultFundingAndBankingSection from './DefaultFundingAndBankingSection';
import RenewalCancellationSection from './RenewalCancellationSection';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import withAuth from '@src/auth/withAuth';
import { Flex } from '@abyss/web/ui/Flex';

const PolicyDetails = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const columnsData = location?.state?.searchScreen;
  const { readOnly } = useAuth();
  const handleButtonClick = () => {
    navigate('/fadb/inquiry/case-structure/case-structure-search', {
      state: {
        columns: location?.state?.columns,
        data: location?.state?.data,
        searchScreen: columnsData,
        include: location?.state?.include,
        policyNumber: location?.state?.policyNumber,
        status: location?.state?.status,
        suffixCode: location?.state?.suffixCode,
        accountCode: location?.state?.accountCode,
        policyTypeCode: location?.state?.policyTypeCode,
        name: location?.state?.name,
        suffixType: location?.state?.suffixType,
        caseInstallationSource: location?.state?.caseInstallationSource,
        originalEffectiveDateFrom: location?.state?.originalEffectiveDateFrom,
        originalEffectiveDateTo: location?.state?.originalEffectiveDateTo,
        cancellationDateFrom: location?.state?.cancellationDateFrom,
        cancellationDateTo: location?.state?.cancellationDateTo,
        changeDateFrom: location?.state?.changeDateFrom,
        changeDateTo: location?.state?.changeDateTo,
        authorOfChanges: location?.state?.authorOfChanges,
        legalEntity: location?.state?.legalEntity,
        glReinsurance: location?.state?.glReinsurance,
        outOfNetworkLegalEntity: location?.state?.outOfNetworkLegalEntity,
        fundingArrangment: location?.state?.fundingArrangment,
        legalEntityEffectiveDateFrom:
          location?.state?.legalEntityEffectiveDateFrom,
        legalEntityEffectiveDateTo: location?.state?.legalEntityEffectiveDateTo,
        fundingEffectiveDateFrom: location?.state?.fundingEffectiveDateFrom,
        fundingEffectiveDateTo: location?.state?.fundingEffectiveDateTo,
        response: location?.state?.response,
      },
    });
  };
  const date = new Date();
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '220px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Flex justify="space-between">
                    <span></span>
                    <Heading offset={4}>Policy Details</Heading>
                    <b>{currentDateFormat(date)}</b>
                  </Flex>

                  <GeneralInfoSection
                    data={location?.state?.policyData}
                    columnsData={columnsData}
                  />
                  <DefaultFundingAndBankingSection
                    data={location?.state?.policyData}
                  />
                  <RenewalCancellationSection
                    data={location?.state?.policyData}
                    columnsData={columnsData}
                    response={location?.state?.response}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    onClick={handleButtonClick}
                    isDisabled={readOnly}
                  >
                    Return To List
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(PolicyDetails, 'fadb_update_inquiry');
