import { access } from 'fs';

export const constantColumns = {
  ATS_CONTR: [
    {
      Header: 'CUST_CONTR_ID',
      accessor: 'CUST_CONTR_ID',
    },
    {
      Header: 'EFF_DT',
      accessor: 'EFFDT',
    },
    {
      Header: 'CHG_DT',
      accessor: 'CHGDT',
    },
    {
      Header: 'SHR_ARNG_CD',
      accessor: 'SHR_ARNG_CD',
    },
    {
      Header: 'TERM_DT',
      accessor: 'TERMDT',
    },
    {
      Header: 'LST_CHG_DTTM',
      accessor: 'LSTCHGDTTM',
      // width: '220px',
    },
    {
      Header: 'PRTN_PAR_ID',
      accessor: 'PRTN_PAR_ID',
    },
    {
      Header: 'LDPRTN_LGL_ENTY_CD',
      accessor: 'LDPRTN_LGL_ENTY_CD',
      width: 200,
    },
    {
      Header: 'ADMIN_SRC_SYS_CD',
      accessor: 'ADMIN_SRC_SYS_CD',
      width: 200,
    },
    {
      Header: 'CUST_ID',
      accessor: 'CUST_ID',
    },
  ],
  ATS_CUST: [
    {
      Header: 'PRTN_ID',
      accessor: 'PRTN_ID',
    },
    {
      Header: 'ADMIN_SRC_SYS_CD',
      accessor: 'ADMIN_SRC_SYS_CD',
    },
    {
      Header: 'EFF_DT',
      accessor: 'EFFDT',
    },
    {
      Header: 'CHG_DT',
      accessor: 'CHGDT',
    },
    {
      Header: 'TERM_DT',
      accessor: 'TERMDT',
    },
    {
      Header: 'ERISA_IND',
      accessor: 'ERISA_IND',
    },
    {
      Header: 'FINC_MKT_SEG_CD',
      accessor: 'FINC_MKT_SEG_CD',
    },
    {
      Header: 'MGRT_SRC_CD',
      accessor: 'MGRT_SRC_CD',
    },
    {
      Header: 'SALE_OFC_CD',
      accessor: 'SALE_OFC_CD',
    },
    {
      Header: 'CUST_ADDR_ZIP_CD',
      accessor: 'CUST_ADDR_ZIP_CD',
    },
    {
      Header: 'LST_CHG_DTTM',
      accessor: 'LSTCHGDTTM',
    },
    {
      Header: 'BIL_PLTFM_IND',
      accessor: 'BIL_PLTFM_IND',
    },
  ],
  ATS_LGL_ENTY: [
    {
      Header: 'LGL_ENTY_CD',
      accessor: 'LGL_ENTY_CD',
    },
    {
      Header: 'LGL_ENTY_TXT',
      accessor: 'LGL_ENTY_TXT',
    },
    {
      Header: 'OBLIG_ID',
      accessor: 'OBLIG_ID',
    },
  ],

  ATS_OBLIG: [
    {
      Header: 'OBLIG_ID',
      accessor: 'OBLIG_ID',
    },
    {
      Header: 'OBLIG_SHRT_NM',
      accessor: 'OBLIG_SHRT_NM',
    },
  ],

  ATS_PLN: [
    {
      Header: 'PRTN_PAR_ID',
      accessor: 'PRTN_PAR_ID',
    },
    {
      Header: 'PRTN_CHLD_ID',
      accessor: 'PRTN_CHLD_ID',
    },
    {
      Header: 'COV_TYP_CD',
      accessor: 'COV_TYP_CD',
    },
    {
      Header: 'EFF_DT',
      accessor: 'EFFDT',
    },
    {
      Header: 'CHG_DT',
      accessor: 'CHGDT',
    },
    {
      Header: 'TERM_DT',
      accessor: 'TERMDT',
    },
    {
      Header: 'OBLIG_ID',
      accessor: 'OBLIG_ID',
    },
    {
      Header: 'LGL_ENTY_CD',
      accessor: 'LGL_ENTY_CD',
    },
    {
      Header: 'FUND_TYP_CD',
      accessor: 'FUND_TYP_CD',
    },
    {
      Header: 'LST_CHG_DTTM',
      accessor: 'LSTCHGDTTM',
    },
    {
      Header: 'UNET_PRDT_CD',
      accessor: 'UNET_PRDT_CD',
    },
    {
      Header: 'PRDT_CLSS_ID',
      accessor: 'PRDT_CLSS_ID',
    },
    {
      Header: 'ADMIN_SRC_SYS_CD',
      accessor: 'ADMIN_SRC_SYS_CD',
    },
    {
      Header: 'CUST_CONTR_ID',
      accessor: 'CUST_CONTR_ID',
    },
    {
      Header: 'CUST_PLN_ID',
      accessor: 'CUST_PLN_ID',
    },
    {
      Header: 'FRANCH_CD',
      accessor: 'FRANCH_CD',
    },
    {
      Header: 'OON_LGL_ENT_CD',
      accessor: 'OON_LGL_ENT_CD',
    },
    {
      Header: 'UHC_ST_ISS_CD',
      accessor: 'UHC_ST_ISS_CD',
    },
  ],
  ATS_PVRC: [
    {
      Header: 'CUST_CONTR_ID',
      accessor: 'CUST_CONTR_ID',
    },
    {
      Header: 'PLN_VAR_CD_NBR',
      accessor: 'PLN_VAR_CD_NBR',
    },
    {
      Header: 'RPT_CD_NBR',
      accessor: 'RPT_CD_NBR',
    },
    {
      Header: 'EFF_DT',
      accessor: 'EFF_DT',
    },
    {
      Header: 'CHG_DT',
      accessor: 'CHG_DT',
    },
    {
      Header: 'CUST_PLN_ID',
      accessor: 'CUST_PLN_ID',
    },
    {
      Header: 'TERM_DT',
      accessor: 'TERM_DT',
    },
    {
      Header: 'PRTN_CHLD_DTTM',
      accessor: 'PRTN_CHLD_DTTM',
    },
    {
      Header: 'LST_CHG_DTTM',
      accessor: 'LST_CHG_DTTM',
    },
    {
      Header: 'ADMIN_SRC_SYS_ID',
      accessor: 'ADMIN_SRC_SYS_ID',
    },
    {
      Header: 'SUFX_CD',
      accessor: 'SUFX_CD',
    },
    {
      Header: 'HMO_ACCT_DIV',
      accessor: 'HMO_ACCT_DIV',
    },
    {
      Header: 'MKT_CD',
      accessor: 'MKT_CD',
    },
  ],

  ATS_SHRD_ARNG: [
    {
      Header: 'SHRD_ARNG_ID',
      accessor: 'SHRD_ARNG_ID',
    },
    {
      Header: 'SHRD_ARNG_SHRT_NM',
      accessor: 'SHRD_ARNG_SHRT_NM',
    },
    {
      Header: 'PTSP_CD',
      accessor: 'PTSP_CD',
    },
    {
      Header: 'BPO_CD',
      accessor: 'BPO_CD',
    },
    {
      Header: 'JNT_VNTR_CD',
      accessor: 'JNT_VNTR_CD',
    },
    {
      Header: 'INTRL_CD',
      accessor: 'INTRL_CD',
    },
  ],

  BUS_PROC_BYP_RULE: [
    {
      Header: 'POL_NBR',
      accessor: 'POL_NBR',
    },
    {
      Header: 'PLN_VAR_CD_NBR',
      accessor: 'PLN_VAR_CD_NBR',
    },
    {
      Header: 'RPT_CD_NBR',
      accessor: 'RPT_CD_NBR',
    },
    {
      Header: 'BYP_SRC_CD',
      accessor: 'BYP_SRC_CD',
    },
    {
      Header: 'BYP_LVL_CD',
      accessor: 'BYP_LVL_CD',
    },
    {
      Header: 'BYP_RSN_TXT',
      accessor: 'BYP_RSN_TXT',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'LSTUPDTDT',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'LST_UPDT_TM',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'LST_UPDT_ID',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'UPDT_IND',
    },
  ],

  COMPANY: [
    {
      Header: 'CO_CD',
      accessor: 'CO_CD',
    },
    {
      Header: 'FUND_ARNG_CD',
      accessor: 'FUND_ARNG_CD',
    },
    {
      Header: 'FUND_ARNG_GRP_CD',
      accessor: 'FUND_ARNG_GRP_CD',
    },
    {
      Header: 'LEG_ENTY_GRP_CD',
      accessor: 'LEG_ENTY_GRP_CD',
    },
    {
      Header: 'GL_REINS_CD',
      accessor: 'GL_REINS_CD',
    },
    {
      Header: 'CO_CD_TXT',
      accessor: 'CO_CD_TXT',
    },
    {
      Header: 'MBR_LEG_ENTY_CD',
      accessor: 'MBR_LEG_ENTY_CD',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'LSTUPDTDT',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'LST_UPDT_TM',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'LST_UPDT_ID',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'UPDT_IND',
    },
  ],

  GL_BUS_UNIT: [
    {
      Header: 'GL_BUSUNIT_NBR',
      accessor: 'GL_BUSUNIT_NBR',
    },
    {
      Header: 'GL_BUSUNIT_TXT',
      accessor: 'GL_BUSUNIT_TXT',
    },
    {
      Header: 'GL_BUSUNIT_LNG_TXT',
      accessor: 'GL_BUSUNIT_LNG_TXT',
    },
    {
      Header: 'EFF_STS_CD',
      accessor: 'EFF_STS_CD',
    },
  ],

  GL_FUNC: [
    {
      Header: 'GL_FUNC_NBR',
      accessor: 'GL_FUNC_NBR',
    },
    {
      Header: 'GL_FUNC_TXT',
      accessor: 'GL_FUNC_TXT',
    },
    {
      Header: 'GL_FUNC_LNG_TXT',
      accessor: 'GL_FUNC_LNG_TXT',
    },
    {
      Header: 'EFF_STS_CD',
      accessor: 'EFF_STS_CD',
    },
  ],

  GL_PRDCT: [
    {
      Header: 'GL_PRDCT_NBR',
      accessor: 'GL_PRDCT_NBR',
    },
    {
      Header: 'GL_PRDCT_TXT',
      accessor: 'GL_PRDCT_TXT',
    },
    {
      Header: 'GL_PRDCT_LNG_TXT',
      accessor: 'GL_PRDCT_LNG_TXT',
    },
    {
      Header: 'EFF_STS_CD',
      accessor: 'EFF_STS_CD',
    },
  ],

  FINANCIAL_MARKET_SEGMENT_INDICATOR: [
    {
      Header: 'FINL_MKT_SEG_IND',
      accessor: 'FINL_MKT_SEG_IND',
    },
    {
      Header: 'FINL_MKT_SEG_TXT',
      accessor: 'FINL_MKT_SEG_TXT',
    },
    {
      Header: 'FMSI_TYPE_CD',
      accessor: 'FMSI_TYPE_CD',
    },
    {
      Header: 'LST_CHANGE_ID',
      accessor: 'LST_CHANGE_ID',
    },
    {
      Header: 'LST_CHANGE_DATE',
      accessor: 'LST_CHANGE_DATE',
    },
  ],

  MBR_LEG_ENTY: [
    {
      Header: 'MBR_LEG_ENTY_CD',
      accessor: 'MBR_LEG_ENTY_CD',
    },
    {
      Header: 'GL_BUSUNIT_CD',
      accessor: 'GL_BUSUNIT_CD',
    },
    {
      Header: 'OBLIG_ID',
      accessor: 'OBLIG_ID',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'LSTUPDTDT',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'LST_UPDT_TM',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'LST_UPDT_ID',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'UPDT_IND',
    },
  ],

  SHARED_ARRANGEMENT: [
    {
      Header: 'SHR_ARNG_CD',
      accessor: 'SHR_ARNG_CD',
    },
    {
      Header: 'SHR_ARNG_DESC_TXT',
      accessor: 'SHR_ARNG_DESC_TXT',
    },
    {
      Header: 'SHR_ARNG_DFLT_IND',
      accessor: 'SHR_ARNG_DFLT_IND',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'LST_UPDT_DT',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'LST_UPDT_TM',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'LST_UPDT_ID',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'UPDT_IND',
    },
  ],

  Header: [
    {
      Header: 'POL_NBR',
      accessor: 'col1',
    },
    {
      Header: 'ORIGIN_CD',
      accessor: 'col2',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col3',
    },
    {
      Header: 'UPDT_DT',
      accessor: 'col4',
    },
    {
      Header: 'CUST_NBR',
      accessor: 'col5',
    },
    {
      Header: 'EXP_NBR',
      accessor: 'col6',
    },
    {
      Header: 'FRZN_MKT_SGMT',
      accessor: 'col7',
    },
    {
      Header: 'GEO_MKT',
      accessor: 'col8',
    },
    {
      Header: 'RETRO_IND',
      accessor: 'col9',
    },
    {
      Header: 'CANC_DT',
      accessor: 'col10',
    },
    {
      Header: 'ROW_END_DT',
      accessor: 'col11',
    },
    {
      Header: 'SHR_ARNG_CD',
      accessor: 'col12',
    },
    {
      Header: 'BUS_INS_TYP_CD',
      accessor: 'col13',
    },
  ],

  FINC_POL_MKT_SEG: [
    {
      Header: 'POL_NBR',
      accessor: 'col1',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col2',
    },
    {
      Header: 'TERM_DT',
      accessor: 'col3',
    },
    {
      Header: 'MKT_SEG_CD',
      accessor: 'col4',
    },
    {
      Header: 'POLHLDR_NM',
      accessor: 'col5',
    },
    {
      Header: 'ORIG_SRC_NM',
      accessor: 'col6',
    },
    {
      Header: 'UW_LOC_NM',
      accessor: 'col7',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'col8',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'col9',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'col10',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'col11',
    },
  ],
  MARKET: [
    {
      Header: 'MARKET_NBR',
      accessor: 'MARKET_NBR',
    },
    {
      Header: 'MARKET_NBR_PRFX',
      accessor: 'MARKET_NBR_PRFX',
    },
    {
      Header: 'MARKET_NBR_STATE',
      accessor: 'MARKET_NBR_STATE',
    },
    {
      Header: 'MARKET_NBR_SITE',
      accessor: 'MARKET_NBR_SITE',
    },
    {
      Header: 'SITE_NBR_1995',
      accessor: 'SITE_NBR_1995',
    },
    {
      Header: 'HCN_1995',
      accessor: 'HCN_1995',
    },
    {
      Header: 'MARKET_NAME',
      accessor: 'MARKET_NAME',
    },
    {
      Header: 'ORIGIN_CD',
      accessor: 'ORIGIN_CD',
    },
    {
      Header: 'HMO_ACCT_DIV',
      accessor: 'HMO_ACCT_DIV',
    },
    {
      Header: 'LEGAL_ENTITY',
      accessor: 'LEGAL_ENTITY',
    },
    {
      Header: 'LOCATION_CD',
      accessor: 'LOCATION_CD',
    },
    {
      Header: 'MAJOR_MARKET',
      accessor: 'MAJOR_MARKET',
    },
    {
      Header: 'HMO_PCT',
      accessor: 'HMO_PCT',
    },
    {
      Header: 'MAJ_MARKET_NM',
      accessor: 'MAJ_MARKET_NM',
    },
    {
      Header: 'SALE_OFC_CD',
      accessor: 'SALE_OFC_CD',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'LST_UPDT_DT',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'LST_UPDT_TM',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'LST_UPDT_ID',
    },
    {
      HEADER: 'UPDT_IND',
      accessor: 'UPDT_IND',
    },
  ],
  STATE: [
    {
      Header: 'ST_CD',
      accessor: 'ST_CD',
    },
    {
      Header: 'FL_LOC_NBR',
      accessor: 'FL_LOC_NBR',
    },
    {
      Header: 'EFF_DT',
      accessor: 'EFF_DT',
    },
    {
      Header: 'MED_SURCHRG_IND',
      accessor: 'MED_SURCHRG_IND',
    },
    {
      Header: 'ST_NM',
      accessor: 'ST_NM',
    },
    {
      Header: 'ST_ABBR_CD',
      accessor: 'ST_ABBR_CD',
    },
    {
      Header: 'UHIC_TAX_RT_AH',
      accessor: 'UHIC_TAX_RT_AH',
    },
    {
      Header: 'UHIC_TAX_RT_LF',
      accessor: 'UHIC_TAX_RT_LF',
    },
    {
      Header: 'UHIC_TAX_RT_MEDSUP',
      accessor: 'UHIC_TAX_RT_MEDSUP',
    },
    {
      Header: 'UHIC_TAX_RT4',
      accessor: 'UHIC_TAX_RT4',
    },
    {
      Header: 'UHIC_TAX_RT5',
      accessor: 'UHIC_TAX_RT5',
    },
    {
      Header: 'HP_TAX_RT5',
      accessor: 'HP_TAX_RT5',
    },
    {
      Header: 'HP_TAX_RT_AH',
      accessor: 'HP_TAX_RT_AH',
    },
    {
      Header: 'HP_TAX_RT_LF',
      accessor: 'HP_TAX_RT_LF',
    },
    {
      Header: 'HP_TAX_RT_RT3',
      accessor: 'HP_TAX_RT_RT3',
    },
    {
      Header: 'HP_TAX_RT_RT4',
      accessor: 'HP_TAX_RT_RT4',
    },
    {
      Header: 'HP_TAX_RT_RT5',
      accessor: 'HP_TAX_RT_RT5',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'LST_UPDT_DT',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'LST_UPDT_TM',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'LST_UPDT_ID',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'UPDT_IND',
    },
  ],
  PRODUCT: [
    {
      Header: 'UNET_PRDCT_CD',
      accessor: 'UNET_PRDCT_CD',
    },
    {
      Header: 'PRDCT_CLSS_ID',
      accessor: 'PRDCT_CLSS_ID',
    },
    {
      Header: 'COV_TYP_CD',
      accessor: 'COV_TYP_CD',
    },
    {
      Header: 'GL_PRDCT_CD',
      accessor: 'GL_PRDCT_CD',
    },
    {
      Header: 'NET_IND',
      accessor: 'NET_IND',
    },
    {
      Header: 'DUAL_CONTR_IND',
      accessor: 'DUAL_CONTR_IND',
    },
    {
      Header: 'GATE_IND',
      accessor: 'GATE_IND',
    },
    {
      Header: 'PRDCT_TXT',
      accessor: 'PRDCT_TXT',
    },
    {
      Header: 'MBR_MAJ_PRDCT_CD',
      accessor: 'MBR_MAJ_PRDCT_CD',
    },
    {
      Header: 'SEL_CHO_CD',
      accessor: 'SEL_CHO_CD',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'LSTUPDTDT',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'LST_UPDT_TM',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'LST_UPDT_ID',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'UPDT_IND',
    },
  ],
  HMOACCT: [
    {
      Header: 'HMO_ROW_NBR',
      accessor: 'HMO_ROW_NBR',
    },
    {
      Header: 'HMO_BEGIN_DT',
      accessor: 'HMOBEGINDT',
    },
    {
      Header: 'HMO_END_DT',
      accessor: 'HMOENDDT',
    },
    {
      Header: 'HMO_LEGAL_ENT_NBR',
      accessor: 'HMO_LEGAL_ENT_NBR',
    },
    {
      Header: 'HMO_LEGAL_ENT',
      accessor: 'HMO_LEGAL_ENT',
    },
    {
      Header: 'HMO_MKT_NAME',
      accessor: 'HMO_MKT_NAME',
    },
    {
      Header: 'HMO_MKT_NBR',
      accessor: 'HMO_MKT_NBR',
    },
    {
      Header: 'HMO_PROD_TYP',
      accessor: 'HMO_PROD_TYP',
    },
    {
      Header: 'HMO_ACCT_DIV',
      accessor: 'HMO_ACCT_DIV',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'LSTUPDTDT',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'LST_UPDT_TM',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'LST_UPDT_ID',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'UPDT_IND',
    },
    {
      Header: 'LGL_ENTY_GLBU_NBR',
      accessor: 'LGL_ENTY_GLBU_NBR',
    },
    {
      Header: 'GL_LOC_CD',
      accessor: 'GL_LOC_CD',
    },
    {
      Header: 'HMO_PCT',
      accessor: 'HMO_PCT',
    },
  ],
  RPT_POLICY: [
    {
      Header: 'RPT_GRP_POL_NBR',
      accessor: 'RPT_GRP_POL_NBR',
    },
    {
      Header: 'COSMOS_DIV',
      accessor: 'COSMOS_DIV',
    },
    {
      Header: 'POLHLDR_NM',
      accessor: 'POLHLDR_NM',
    },
    {
      Header: 'CONT_ISS_ST',
      accessor: 'CONT_ISS_ST',
    },
    {
      Header: 'ADMIN_SYS_CD',
      accessor: 'ADMIN_SYS_CD',
    },
    {
      Header: 'EFF_DT',
      accessor: 'EFF_DT',
    },
    {
      Header: 'CANC_DT',
      accessor: 'CANC_DT',
    },
  ],
  GL_ACCT: [
    { Header: 'GL_ACCT_CD', accessor: 'GL_ACCT_CD' },
    { Header: 'GL_ACCT_TXT', accessor: 'GL_ACCT_TXT' },
    { Header: 'GL_ACCT_LNG_TXT', accessor: 'GL_ACCT_LNG_TXT' },
    { Header: 'EFF_STS_CD', accessor: 'EFF_STS_CD' },
  ],
  LABEL: [
    { Header: 'POL_NBR', accessor: 'polNbr' },
    { Header: 'ORIGIN_CD', accessor: 'originCd' },
    { Header: 'EFF_DT', accessor: 'effDt' },
    { Header: 'UPDT_DT', accessor: 'updtDt' },
    { Header: 'CUST_NBR', accessor: 'custNbr' },
    { Header: 'EXP_NBR', accessor: 'expNbr' },
    { Header: 'FRZN_MKT_SGMT', accessor: 'frznMktSgmt' },
    { Header: 'GEO_MKT', accessor: 'geoMkt' },
    { Header: 'RETRO_IND', accessor: 'retroInd' },
    { Header: 'CANC_DT', accessor: 'cancDt' },
    { Header: 'ROW_END_DT', accessor: 'rowEndDt' },
    { Header: 'SHR_ARNG_CD', accessor: 'shrArngCd' },
    { Header: 'BUS_INS_TYP_CD', accessor: 'busInsTypCd' },
  ],
};
