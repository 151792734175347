import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { Modal } from '@abyss/web/ui/Modal';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Footer } from '@src/components/Footer';
import { Sidebar } from '@src/components/Sidebar';
import { DateInput } from '@abyss/web/ui/DateInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { PageHeader } from '@src/components';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { formateDate } from '@src/components/DateUtils';
import { Heading } from '@abyss/web/ui/Heading';
import { useDispatch } from 'react-redux';
import { referenceSuffixApi } from '@src/redux/api/FADB/suffix/suffixApi';
import {
  fetchSuffixTypeSuccess,
  fetchSuffixTypeFailure,
} from '../../../redux/action/FADB/suffix/suffixActions';
import { Alert } from '@abyss/web/ui/Alert';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';
import { set } from 'lodash';
const SuffixLookup = ({ data }) => {
  const { navigate } = useRouter();
  const [lookupflag, setLookupFlag] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { token, readOnly, msid } = useAuth();
  const { getLocation } = useRouter();
  const location = getLocation();
  const [revSuffixCodeRule, setrevSuffixCodeRule] = useState(false);
  const [suffix, setSuffix] = useState('');
  const [pageType, setPageType] = useState('');
  const [effectiveDateErr, setEffectiveDateErr] = useState('');
  const [attrEffectiveDateErr, setAttrEffectiveDateErr] = useState('');
  const [fundingEffectiveDateErr, setFundingEffectiveDateErr] = useState('');
  const [isHighLight, setIsHighLight] = useState(false);
  const [suffixErr, setSuffixErr] = useState('');
  const [caseInstallation, setCaseInstallation] = useState(
    location?.state?.data?.caseinstallationSource
  );
  const [policyNumber, setPolicyNumber] = useState(
    location?.state?.data?.policyNumber
  );
  const [selectedTypeOfEffDate, setSelectedTypeOfEffDate] = useState(
    location?.state?.selectedTypeOfEffDate
  );
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(
    location?.state?.selectedLegalEntity
  );
  const [selectedGLReinsurance, setSelectedGLReinsurance] = useState(
    location?.state?.selectedGLReinsurance
  );
  const [selectedFundingCode, setSelectedFundingCode] = useState(
    location?.state?.selectedFundingCode
  );
  const [selectedExtendedCoverage, setSelectedExtendedCoverage] = useState([]);
  const [selectedBankCode, setSelectedBankCode] = useState(
    location?.state?.selectedBankCode
  );
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertText1, setAlertText1] = useState('');
  const [showAlert1, setShowAlert1] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState([]);
  const [name, setName] = useState(location?.state?.data?.polName);
  const [defaultTypeOfEffectiveDate, setDefaultTypeOfEffectiveDate] = useState(
    location?.state?.data?.typeofeffectiveDate
  );
  const [defaultEffectiveDate, setDefaultEffectiveDate] = useState(
    location?.state?.data?.effectiveDate
  );
  const [defaultCancellationDate, setDefaultCancellationDate] = useState(
    location?.state?.data?.cancelationDate
  );
  const [defaultAttributeEffectiveDate, setDefaultAttributeEffectiveDate] =
    useState(location?.state?.data?.attrEffectiveDate);
  const [defaultLegalEntityCode, setDefaultLegalEntityCode] = useState(
    location?.state?.data?.deafultlegalEntityCode
  );
  const [defaultGLReinsurance, setDefaultGLReinsurance] = useState(
    location?.state?.data?.defaultglreinsurance
  );
  const [defaultFundingEffectiveDate, setDefaultFundingEffectiveDate] =
    useState(location?.state?.data?.fundingEffectiveDate);
  const [defaultFundingCode, setDefaultFundingCode] = useState(
    location?.state?.data?.fundingCode
  );
  const [defaultBankCode, setDefaultBankCode] = useState(
    location?.state?.data?.bankCode
  );
  const [suffixData, setSuffixData] = useState({});
  const [effModal, setEffModal] = useState(false);
  const [suffixType, setSuffixType] = useState('O ');
  const [effectiveDate, setEffectiveDate] = useState('');
  const [typeOfEffectiveDate, setTypeOfEffectiveDate] = useState('S');
  const [attributeEffectiveDate, setAttributeEffectiveDate] = useState('');
  const [legalEntity, setLegalEntity] = useState('');
  const [gLReinsurance, setGLReinsurance] = useState('');
  const [outOfNetworkLegalEntity, setOutOfNetworkLegalEntity] = useState('');
  const [extendedCoverage, setExtendedCoverage] = useState('0');
  const [cancellationDate, setCancellationDate] = useState('');
  const [suffixLastChanged, setSuffixLastChanged] = useState('');
  const [suffixLastChangedBy, setSuffixLastChangedBy] = useState('');
  const [selectedSuffixType, setSelectedSuffixType] = useState([]);
  const [dynamicData, setDynamicData] = useState([]);
  const [fundingEffectiveDate, setFundingEffectiveDate] = useState('');
  const [fundingCode, setFundingCode] = useState('');
  const [funddingCodeErr, setFundingCodeErr] = useState('');
  const [legalEntityErr, setLegalEntityErr] = useState('');
  const [bankCodeErr, setBankCodeErr] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [bankCode, setBankCode] = useState(
    location?.state?.data?.bankCode || 'F'
  );
  const [lastChangedBy, setLastChangedBy] = useState('');
  const [lastChanged, setLastChanged] = useState('');
  const dispatch = useDispatch();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    if (dynamicData?.extendCovg) {
      setSelectedExtendedCoverage(dynamicData?.extendCovg);
    }
  }, [dynamicData]);
  const inputRef = useRef(null);
  const focusTextInput = () => {
    // Check if the inputRef.current is not null
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  useEffect(() => {
    setSuffixType('O ');
    setTypeOfEffectiveDate('S');
  }, [selectedSuffixType, selectedTypeOfEffDate]);
  const handleFetchSuffixType = () => {
    referenceSuffixApi(config)
      .then((response) => {
        setSelectedSuffixType(response.data.sufxTyp);
        setSelectedLegalEntity(response.data.legalEntity);
        dispatch(fetchSuffixTypeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSuffixTypeFailure(error));
      });
  };
  const fecthDynamicData = async () => {
    await axios
      .get(`${BASE_URL}static/suffxDrpdwnData/suffixDropdwnData`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDynamicData(response.data);
      })
      .catch((error) => {
        // add-alert-popup
      });
  };
  const effModalClick = (e) => {
    setEffModal(false);
    setrevSuffixCodeRule(true);
    handlePageSubmit(e, pageType, true);
  };
  const clearErrorFields = () => {
    setSuffixErr('');
    setEffectiveDateErr('');
    setAttrEffectiveDateErr('');
    setFundingCodeErr('');
    setLegalEntityErr('');
    setBankCodeErr('');
    setFundingEffectiveDateErr('');
  };
  const handleSubmit = (e) => {
    setLookupFlag(true);
    setIsLoad(true);
    // setCompanyCode(location?.state?.data?.companyCode);
    e.preventDefault();
    clearErrorFields();
    if (suffix === '') {
      setSuffixErr('Suffix is required field');
      setIsLoad(false);
    } else if (suffix.length !== 2) {
      setSuffixErr('Please enter a 2 byte value for Suffix Code');
      setIsLoad(false);
    } else {
      cancel1();

      axios
        .get(
          `${BASE_URL}suffix/${location?.state?.policyEntId
          }/${suffix.toUpperCase()}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setSuffix(suffix.toUpperCase());
          setIsLoad(false);
          if (response?.data === 'Not available') {
            setSuffixType('O ');
            setIsHighLight(true);
            setEffectiveDate(location?.state?.data?.effectiveDate);
            setAttributeEffectiveDate(location?.state?.data?.attrEffectiveDate);
            setCancellationDate(location?.state?.data?.cancelationDate);
            setFundingEffectiveDate(
              location?.state?.data?.fundingEffectiveDate
            );
            setBankCode('F');
            setCompanyCode('');
          } else {
            setIsHighLight(false);
            setSuffixData(response?.data);
          }
        })
        .catch((error) => {
          // add-alert-popup
          setIsLoad(false);
        });
    }
  };
  const cancel = () => {
    clearErrorFields();
    setLookupFlag(false);
    setrevSuffixCodeRule(false);
    setSuffixData({});
    setSuffix('');
    setSuffixType('O ');
    setTypeOfEffectiveDate('S');
    setEffectiveDate('');
    setAttributeEffectiveDate('');
    setGLReinsurance('');
    setLegalEntity('');
    setOutOfNetworkLegalEntity('');
    setCancellationDate('');
    setSuffixLastChanged('');
    setSuffixLastChangedBy('');
    setFundingEffectiveDate('');
    setCompanyCode('');
    focusTextInput();
  };
  const cancel1 = () => {
    setrevSuffixCodeRule(false);
    clearErrorFields();
    setExtendedCoverage('0');
    setSuffixData({});
    setSuffixType('O ');
    setTypeOfEffectiveDate('S');
    setEffectiveDate('');
    setAttributeEffectiveDate('');
    setGLReinsurance('');
    setLegalEntity('');
    setOutOfNetworkLegalEntity('');
    setCancellationDate('');
    setSuffixLastChanged('');
    setSuffixLastChangedBy('');
    setFundingEffectiveDate('');
    setCompanyCode('');
    setLastChanged('');
    setLastChangedBy('');
    setFundingCode('');
  };

  const lookup = async (e) => {
    setLoad2(true);
    e.preventDefault();
    try {
      let legalEntity1: string;
      if (outOfNetworkLegalEntity.trim() !== '') {
        legalEntity1 = outOfNetworkLegalEntity.trim();
      } else {
        legalEntity1 = legalEntity;
      }
      const response = await axios.get(
        `${BASE_URL}policy/cocd-inq/${legalEntity1}/${suffixData?.[0]?.GL_REINS_CD === undefined
          ? ' '
          : suffixData?.[0]?.GL_REINS_CD
        }/${fundingCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setLoad2(false);
        if (
          response.data === 'Not available' ||
          response.data === 'Data Not Found'
        ) {
          setAlertText('Company Code not found');
          setShowAlert(true);
          setCompanyCode('');
        } else {
          setCompanyCode(response.data?.CO_CD);
        }
      }
    } catch (error) {
      // add-alert-popup
      setLoad2(false);
      return {};
    }
  };
  const noChange = () => {
    if (
      suffixType === suffixData?.[0]?.SUFX_TYP_CD &&
      effectiveDate === suffixData?.[0]?.SUFX_EFF_DT &&
      attributeEffectiveDate === suffixData?.[0]?.ATT_EFF_DT &&
      legalEntity === suffixData?.[0]?.LEG_ENTY_CD &&
      gLReinsurance === suffixData?.[0]?.GL_REINS_CD.trim() &&
      outOfNetworkLegalEntity === suffixData?.[0]?.LEG_ENTY_OFN_CD &&
      cancellationDate === suffixData?.[0]?.SUFX_CANC_DT &&
      extendedCoverage === suffixData?.[0]?.EXT_BEN_MO_NBR &&
      suffixLastChanged === suffixData?.[0]?.SUFX_CHG_DT &&
      suffixLastChangedBy === suffixData?.[0]?.PSH_USER_SHORT_NM &&
      lastChanged === suffixData?.[0]?.FNDNG_CHG_DT &&
      fundingEffectiveDate === suffixData?.[0]?.FNDNG_EFF_DT &&
      fundingCode === suffixData?.[0]?.FUND_ARNG_CD &&
      bankCode === suffixData?.[0]?.BNK_CD &&
      companyCode === suffixData?.[0]?.CO_CD
    ) {
      return true;
    }
    return false;
  };

  const checkMandatoryField = () => {
    let valid = true;
    let suffixcdInvalidFLag = false;
    if (
      effectiveDate === '' ||
      effectiveDate === undefined ||
      effectiveDate === null
    ) {
      setEffectiveDateErr('Suffix Effective Date cannot be blank');
      valid = false;
    }
    if (
      suffix !== '' &&
      suffix !== undefined &&
      suffix !== null &&
      suffix === 'ZY'
    ) {
      setSuffixErr('ZY is restricted suffix.');
      valid = false;
    }
    if (suffix === '' || suffix === undefined || suffix === null) {
      setSuffixErr('Suffix Code can not be blank');
      valid = false;
    }
    if (
      fundingCode === '' ||
      fundingCode === undefined ||
      fundingCode === null
    ) {
      setFundingCodeErr('Funding Code can not be blank');
      valid = false;
    }
    if (
      legalEntity === '' ||
      legalEntity === undefined ||
      legalEntity === null
    ) {
      setLegalEntityErr('Legal Entity can not be blank');
      valid = false;
    }
    if (bankCode === '' || bankCode === undefined || bankCode === null) {
      setBankCodeErr('Bank Code can not be blank');
      valid = false;
    } else {
      let a = suffix;
      if (
        caseInstallation === '1' &&
        a.charAt(1) !== 'W' &&
        a.charAt(1) !== 'M'
      ) {
        suffixcdInvalidFLag = true;
      }
      if (caseInstallation === '1' && a.length !== 2) {
        suffixcdInvalidFLag = true;
      }
      if (
        (caseInstallation === '2' || caseInstallation === '3') &&
        a.length === 2
      ) {
        if (a.charAt(1) === 'W' || a.charAt(1) === 'M') {
          suffixcdInvalidFLag = true;
        }
      }
      if (suffixcdInvalidFLag) {
        setAlertText('Suffix code is not valid');
        setShowAlert(true);
        setAlertType('error');
        return false;
        valid = false;
      }
    }
    if (
      effectiveDate === '' ||
      effectiveDate === undefined ||
      effectiveDate === null
    ) {
      setEffectiveDateErr('Suffix Effective Date cannot be blank');
      valid = false;
    }
    if (
      attributeEffectiveDate === '' ||
      attributeEffectiveDate === undefined ||
      attributeEffectiveDate === null
    ) {
      setAttrEffectiveDateErr('Attribute Effective Date can not be blank');
      valid = false;
    }
    if (
      fundingEffectiveDate === '' ||
      fundingEffectiveDate === undefined ||
      fundingEffectiveDate === null
    ) {
      setFundingEffectiveDateErr('Funding Effective Date can not be blank');
      valid = false;
    }

    if (valid) {
      if (suffix !== 'HW') {
        if (caseInstallation === '1' && fundingCode !== '1') {
          setFundingCodeErr(
            'Prime must have a Funding Code of Fully Insured, unless it is designated as a Prime ASO reserved suffix. The Financial tag combination does not exist'
          );
          valid = false;
        }
      }
    }
    return valid;
  };
  const convertDate = (dateStrr) => {
    if (!dateStrr.includes('-')) return dateStrr;
    const [year, month, day] = dateStrr.split('-');
    return `${month}/${day}/${year}`;
  };
  const handlePageSubmit = async (e, page, flagq) => {
    setLoad3(true);
    e.preventDefault();
    let flag = flagq;
    if (!lookupflag) {
      setAlertText1('Press Lookup First');
      setShowAlert1(true);
      setAlertType('error');
      setLoad3(false);
      window.scrollTo(0, 0);
      return;
    }
    if (noChange()) {
      setAlertText1('You have hit the submit button without changing the data');
      setShowAlert1(true);
      setAlertType('error');
      setLoad3(false);
      window.scrollTo(0, 0);
      return;
    }
    setPageType(page);
    let payload = {
      attEftDt: attributeEffectiveDate
        ? convertDate(attributeEffectiveDate)
        : '',
      bnkCd: bankCode,
      caseInstalation: caseInstallation,
      coCd: companyCode,
      effectiveDate: defaultEffectiveDate
        ? convertDate(defaultEffectiveDate)
        : '',
      extBenMoNbr: extendedCoverage.toString(),
      fndngEffDt: fundingEffectiveDate ? convertDate(fundingEffectiveDate) : '',
      fundArngCd: fundingCode,
      fundingandBankingLastChanged: lastChanged,
      glReinsCd: gLReinsurance,
      legEntyCd: legalEntity,
      polCanDate: defaultCancellationDate,
      polEntId: location?.state?.policyEntId,
      polNbr: policyNumber,
      reinstInd: 'N',
      revSuffixCodeRule: revSuffixCodeRule || flag,
      srvcIssDtCd: typeOfEffectiveDate,
      suffixLastChanged: suffixLastChanged,
      suffixlglEntyOfnRule: false,
      suffixlglEntyRule: false,
      suffixRule: false,
      sufxCancDt: cancellationDate ? convertDate(cancellationDate) : '',
      sufxCd: suffix,
      sufxEffDt: effectiveDate ? convertDate(effectiveDate) : '',
      sufxEntId: '0',
      sufxTypCd: suffixType,
      userShortNm: msid,
    };

    if (outOfNetworkLegalEntity.trim() !== '') {
      payload['legEntyOfnCd'] = outOfNetworkLegalEntity.trim();
    }
    clearErrorFields();
    if (checkMandatoryField()) {
      if (typeOfEffectiveDate === 'I' && !flag) {
        setEffModal(true);
        setLoad3(false);
        return;
      } else {
        flag = true;
      }
      if (flag) {
        try {
          const response = await axios.post(
            `${BASE_URL}suffix/update/suffix`,
            payload,
            config
          );
          if (response.status === 200) {
            setLoad3(false);
            if (response?.data?.success === 'success') {
              if (page === 'accountPage') {
                setShowAlert1(true);
                setAlertText1('Policy Data Updated Successfully');
                setAlertType('success');
                window.scrollTo(0, 0);
                setTimeout(() => {
                  navigate('/fadb/account/lookup', {
                    state: {
                      policyData: location?.state?.policyData,
                      suffixData: [
                        {
                          POL_ENT_ID: location?.state?.policyEntId,
                          SUFX_CD: suffix,
                          SUFX_CHG_DT: suffixLastChanged,
                          SUFX_EFF_DT: effectiveDate
                            ? convertDate(effectiveDate)
                            : '',
                          LEG_ENTY_CD: legalEntity,
                          LEG_ENTY_OFN_CD: outOfNetworkLegalEntity,
                          SUFX_TYP_CD: suffixType,
                          GL_REINS_CD: gLReinsurance,
                          PSH_USER_SHORT_NM: msid,
                          SUFX_CANC_DT: cancellationDate
                            ? convertDate(cancellationDate)
                            : '',
                          REINST_IND: 'N',
                          EXT_BEN_MO_NBR: suffixData?.[0]?.EXT_BEN_MO_NBR || 0,
                          SRVC_ISS_DT_CD: typeOfEffectiveDate,
                          ATT_EFF_DT: attributeEffectiveDate
                            ? convertDate(attributeEffectiveDate)
                            : '',
                          SUFX_ENT_ID: suffixData?.[0]?.SUFX_ENT_ID,
                          FNDNG_CHG_DT: lastChanged,
                          FNDNG_EFF_DT: fundingEffectiveDate
                            ? convertDate(fundingEffectiveDate)
                            : '',
                          FUND_ARNG_CD: fundingCode,
                          PFH_USER_SHORT_NM: msid,
                          BNK_CD: bankCode,
                          DT_FNDNG_CHG_DT: lastChanged,
                          DT_SUFX_CHG_DT: suffixLastChanged,
                        },
                      ],
                    },
                  });
                }, 3000);
              } else if (page === 'suffixPage') {
                cancel();
                setShowAlert1(true);
                setAlertText1('Policy Data Updated Successfully');
                setAlertType('success');
                window.scrollTo(0, 0);
              } else {
                navigate('/fadb/policy');
              }
            } else if (response?.data?.['Reserved suffix']) {
              setEffModal(true);
            } else if (response?.data === 'Not available') {
              setAlertText1('Suffix Code is not valid. Please check.');
              setShowAlert1(true);
              setAlertType('error');
              window.scrollTo(0, 0);
            } else {
              let value = Object.values(response?.data);
              setErrorModalText(value);
              setErrorModal(true);
            }
          }
        } catch (error) {
          // add-alert-popup
          setLoad3(false);
          return {};
        }
      }
    } else {
      setLoad3(false);
    }
  };
  useEffect(() => {
    if (token) {
      fecthDynamicData();
      handleFetchSuffixType();
    }
    focusTextInput();
  }, [token]);
  useEffect(() => {
    if (suffixData && suffixData?.length > 0) {
      setSuffixType(suffixData?.[0]?.SUFX_TYP_CD),
        // setTypeOfEffectiveDate(suffixData?.[0]?.SUFX_EFF_DT),
        setEffectiveDate(suffixData?.[0]?.SUFX_EFF_DT),
        setAttributeEffectiveDate(suffixData?.[0]?.ATT_EFF_DT),
        setLegalEntity(suffixData?.[0]?.LEG_ENTY_CD),
        setGLReinsurance(suffixData?.[0]?.GL_REINS_CD?.trim()),
        setOutOfNetworkLegalEntity(suffixData?.[0]?.LEG_ENTY_OFN_CD),
        setCancellationDate(suffixData?.[0]?.SUFX_CANC_DT),
        setExtendedCoverage(suffixData?.[0]?.EXT_BEN_MO_NBR.toString()),
        setSuffixLastChanged(suffixData?.[0]?.SUFX_CHG_DT),
        setSuffixLastChangedBy(suffixData?.[0]?.PSH_USER_SHORT_NM);
      setLastChangedBy(suffixData?.[0]?.PFH_USER_SHORT_NM),
        setLastChanged(suffixData?.[0]?.FNDNG_CHG_DT);
      setBankCode(suffixData?.[0]?.BNK_CD || 'F');
      setCompanyCode(suffixData?.[0]?.CO_CD || companyCode);
      setFundingCode(suffixData?.[0]?.FUND_ARNG_CD);
      setFundingEffectiveDate(suffixData?.[0]?.FNDNG_EFF_DT);
    }
  }, [suffixData]);
  return (
    <React.Fragment>
      <Modal
        isOpen={errorModal}
        onClose={() => setErrorModal(false)}
        title="Please check the below errors"
      >
        <div style={{ margin: '15px', color: 'red' }}>
          {errorModalText.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </Modal>
      <Modal
        isOpen={effModal}
        onClose={() => setEffModal(false)}
      // title=
      >
        <div style={{ padding: '15px' }}>
          The following business rule may be overridden.Do you wish to accept
          this business rule, Suffix type is not setup with valid reserve Suffix
          type.
        </div>
        <Button style={{ margin: '5px' }} onClick={(e) => effModalClick(e)}>
          Yes
        </Button>
        <Button style={{ margin: '5px' }} onClick={() => setEffModal(false)}>
          No
        </Button>
      </Modal>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid.Col span={12} css={{ paddingBottom: '2.5rem' }}>
                <Grid>
                  <React.Fragment>
                    <Alert
                      title={alertText1}
                      variant={alertType}
                      isVisible={showAlert1}
                      onClose={() => {
                        setShowAlert1(false);
                      }}
                    />
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>Policy</Heading>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Policy Number"
                        value={policyNumber}
                        isDisabled
                        onChange={(e) => {
                          return setPolicyNumber(e.target.value);
                        }}
                        css={{
                          'abyss-text-input:focus': {
                            boxShadow: '#ff4e00 0px 0px 0px 2px !important ',
                          },
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Name"
                        value={name}
                        isDisabled
                        onChange={(e) => {
                          return setName(e.target.value);
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Type Of Effective Date"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled
                        value={defaultTypeOfEffectiveDate}
                        onChange={setDefaultTypeOfEffectiveDate}
                        options={selectedTypeOfEffDate.map(
                          (typeofeffdt: any) => ({
                            value: typeofeffdt.value,
                            label: typeofeffdt.label,
                          })
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Effective Date"
                        value={formateDate(defaultEffectiveDate)}
                        isDisabled
                        onChange={setDefaultEffectiveDate}
                        subText={' '}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Cancellation Date"
                        value={formateDate(defaultCancellationDate)}
                        isDisabled
                        onChange={setDefaultCancellationDate}
                        subText={' '}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Default Attribute Effective Date"
                        value={formateDate(defaultAttributeEffectiveDate)}
                        isDisabled
                        onChange={setDefaultAttributeEffectiveDate}
                        subText={' '}
                      />
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Default Legal Entiy Code"
                        placeholder="Pick one"
                        isClearable
                        isDisabled
                        isSearchable
                        value={defaultLegalEntityCode}
                        onChange={setDefaultLegalEntityCode}
                        options={selectedLegalEntity?.map((entity) => ({
                          value: entity,
                          label: entity,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Default GL Reinsurance"
                        placeholder="Pick one"
                        isClearable
                        isDisabled
                        isSearchable
                        value={defaultGLReinsurance}
                        onChange={setDefaultGLReinsurance}
                        options={selectedGLReinsurance.map((reins: any) => ({
                          value: reins.value,
                          label: reins.label,
                        }))}
                      />
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Default Funding Effective Date"
                        value={formateDate(defaultFundingEffectiveDate)}
                        isDisabled
                        onChange={setDefaultFundingEffectiveDate}
                        subText={' '}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Default Funding Code"
                        placeholder="Pick one"
                        isClearable
                        isDisabled
                        isSearchable
                        value={defaultFundingCode}
                        onChange={setDefaultFundingCode}
                        options={selectedFundingCode.map((fndCode: any) => ({
                          value: fndCode.value,
                          label: fndCode.label,
                        }))}
                      />
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Default Bank Code"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled
                        value={defaultBankCode}
                        onChange={setDefaultBankCode}
                        options={selectedBankCode.map((bnkCd: any) => ({
                          value: bnkCd.value,
                          label: bnkCd.label,
                        }))}
                      />
                    </Grid.Col>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>Suffix</Heading>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Suffix"
                        value={suffix}
                        onChange={(e) => {
                          return setSuffix(e.target.value.toUpperCase());
                        }}
                        isDisabled={readOnly}
                        ref={inputRef}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleSubmit(event);
                          }
                        }}
                        errorMessage={suffixErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <Button
                        css={{
                          'abyss-button-root': { marginTop: '25px !important' },
                        }}
                        onClick={handleSubmit}
                      >
                        Look Up
                      </Button>
                    </Grid.Col>
                    {isLoad && <WrapperLoader />}
                  </React.Fragment>
                  <React.Fragment>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>General Information</Heading>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Suffix Type "
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={suffixType}
                        onChange={(value) => setSuffixType(value)}
                        options={selectedSuffixType?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Type Of Effective Date"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        value={typeOfEffectiveDate}
                        isDisabled={readOnly}
                        onChange={(value) => setTypeOfEffectiveDate(value)}
                        options={selectedTypeOfEffDate?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Effective Date"
                        errorMessage={effectiveDateErr}
                        value={effectiveDate ? formateDate(effectiveDate) : ''}
                        onChange={(value) => setEffectiveDate(value)}
                        subText={' '}
                        isDisabled={readOnly}
                        highlighted={isHighLight}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Attribute Effective Date"
                        errorMessage={attrEffectiveDateErr}
                        value={
                          attributeEffectiveDate
                            ? formateDate(attributeEffectiveDate)
                            : ''
                        }
                        onChange={(value) => setAttributeEffectiveDate(value)}
                        subText={' '}
                        isDisabled={readOnly}
                        highlighted={isHighLight}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Legal Entity"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={legalEntity}
                        errorMessage={legalEntityErr}
                        onChange={(value) => setLegalEntity(value)}
                        options={selectedLegalEntity?.map((item: any) => ({
                          value: item,
                          label: item,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="GL Reinsurance"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={gLReinsurance}
                        onChange={(value) => setGLReinsurance(value)}
                        options={selectedGLReinsurance?.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Out of Network Legal Entity"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        value={outOfNetworkLegalEntity}
                        onChange={(value) => setOutOfNetworkLegalEntity(value)}
                        options={selectedLegalEntity?.map((item: any) => ({
                          value: item,
                          label: item,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Cancellation Date"
                        value={
                          cancellationDate ? formateDate(cancellationDate) : ''
                        }
                        onChange={(value) => setCancellationDate(value)}
                        subText={' '}
                        isDisabled={readOnly}
                        highlighted={isHighLight}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Extended Coverage (Months)"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled
                        value={extendedCoverage}
                        onChange={(value) => setExtendedCoverage(value)}
                        options={selectedExtendedCoverage}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Last Changed"
                        value={suffixLastChanged}
                        onChange={(value) => setSuffixLastChanged(value)}
                        subText={' '}
                        isDisabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="By"
                        value={suffixLastChangedBy}
                        onChange={(value) => setSuffixLastChangedBy(value)}
                        isDisabled={true}
                      />
                    </Grid.Col>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>Funding and Banking</Heading>
                      <Alert
                        title={alertText}
                        variant="error"
                        isVisible={showAlert}
                        onClose={() => {
                          setShowAlert(false);
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Funding Effective Date"
                        errorMessage={fundingEffectiveDateErr}
                        value={
                          fundingEffectiveDate === ''
                            ? ''
                            : formateDate(fundingEffectiveDate)
                        }
                        onChange={(date) => setFundingEffectiveDate(date)}
                        subText={' '}
                        isDisabled={readOnly}
                        highlighted={isHighLight}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Funding Code"
                        errorMessage={funddingCodeErr}
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        value={fundingCode}
                        isDisabled={readOnly}
                        onChange={(value) => setFundingCode(value)}
                        options={selectedFundingCode?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Company Code"
                        value={companyCode}
                        isDisabled={true}
                        onChange={(e) => setCompanyCode(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Bank Code"
                        errorMessage={bankCodeErr}
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        value={bankCode}
                        isDisabled={readOnly}
                        onChange={(value) => setBankCode(value)}
                        options={selectedBankCode?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <Button
                        css={{
                          'abyss-button-root': { marginTop: '25px !important' },
                        }}
                        isDisabled={readOnly}
                        onClick={(e) => lookup(e)}
                      >
                        Look Up
                      </Button>
                    </Grid.Col>
                    {load2 && <WrapperLoader />}
                    <Grid.Col></Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Last Changed"
                        value={lastChanged}
                        onChange={(date) => setLastChanged(date)}
                        subText={' '}
                        isDisabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="By"
                        value={lastChangedBy}
                        isDisabled={true}
                      />
                    </Grid.Col>
                  </React.Fragment>
                </Grid>
                {load3 && <WrapperLoader />}
                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                          width: '230px',
                        },
                      }}
                      onClick={(e) => handlePageSubmit(e, 'accountPage', false)}
                      isDisabled={readOnly}
                      isLoading={load3}
                    >
                      Submit & Account Page
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                          width: '230px',
                        },
                      }}
                      isLoading={load3}
                      isDisabled={readOnly}
                      onClick={(e) => handlePageSubmit(e, 'suffixPage', false)}
                    >
                      Submit & Suffix Page
                    </Button>

                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                          width: '230px',
                        },
                      }}
                      isDisabled={readOnly}
                      isLoading={load3}
                      onClick={(e) => handlePageSubmit(e, 'policyPage', false)}
                    >
                      Submit & Policy Page
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={cancel}
                      isLoading={load3}
                    >
                      Cancel
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(SuffixLookup, "fadb_update");
