export const trimValues = (obj) => {
  const trimmedObj = {};
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObj[key] = obj[key].trim();
    } else {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj;
};
