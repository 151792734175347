import React from 'react';
import { PageFooter } from '@abyss/web/ui/PageFooter';
import styled from 'styled-components';
import { useIsAuthenticated } from '@azure/msal-react';

const StickyFooter = styled(PageFooter)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  boxsizing: 'border-box';
  z-index: 1000;
  background-color: #003c71;
  color: #fff;
  padding: 10px 20px;
  line-height: 2.5;
  // box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
  margin: 50px 0;
  & > * {
    margin: 0;
    // padding: 0 20px;
  }
`;

export const Footer = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      {isAuthenticated ? (
        <StickyFooter
          subFooterTitle={
            <>
              Optum, Inc. All rights reserved.
              <br />
              Your use of this product is governed by the terms of your
              company's agreement. You may not use or disclose this product or
              allow others to use it or disclose it, except as permitted by your
              agreement with Optum.
            </>
          }
        />
      ) : (
        ''
      )}
    </>
  );
};
