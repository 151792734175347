import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import { getEnvironment } from '@src/utils/environmentUtil';
import { Alert } from '@abyss/web/ui/Alert';
import { BASE_URL } from '@src/utils/endpoints';
import useAuth from '@src/utils/useAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { constantColumns } from './column-constants';
import { Modal } from '@abyss/web/ui/Modal';
import env from '../../../env.json';

const SharedArrangment = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, msid, userRolesGroup } = useAuth();
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [records, setRecords] = useState('0 records');
  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  const [row, setRow] = useState({});
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setShowModal(false);
    let payload1 = {
      shrArngCd: row['SHR_ARNG_CD'],
      shrArngDescTxt: row['SHR_ARNG_DESC_TXT'],
      shrArngDfltInd: row['SHR_ARNG_DFLT_IND'],
      lstUpdtDt: row['LST_UPDT_DT'],
      lstUpdtTm: row['LST_UPDT_TM'],
      lstUpdtId: row['LST_UPDT_ID'],
      updtInd: row['UPDT_IND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/shared-arrangement/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        let tableData = await getTableData1();
        setAlertText('You have successfully deleted the record.');
        setAlertType('success');
        setShowAlert(true);
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [shrArngCd, setshrArngCd] = useState('');
  const [shrArngCdErr, setshrArngCdErr] = useState('');
  const [shrArngTxt, setshrArngTxt] = useState('');
  const [shrArngDescTxt, setshrArngDescTxt] = useState('');
  const [shrArngDescTxtErr, setshrArngDescTxtErr] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setUpdateFlag(true);
    setHideAdd(true);
    setFlag(true);
    setUpdateRow(row);
    setshrArngCd(row?.['SHR_ARNG_CD']);
    setshrArngDescTxt(row?.['SHR_ARNG_DESC_TXT']);
    setshrArngTxt(row?.['SHR_ARNG_DFLT_IND']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'shared_arrangement.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'shared_arrangement.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions:
      hasRWaccess &&
      (hideUpdate ? [deleteLinkButton] : [updateLinkButton, deleteLinkButton]),
  });
  const addRow = () => {
    setFlag(true);
    setHideUpdate(true);
  };
  const handleClick = () => {
    navigate(-1);
  };
  const clearErrorFields = () => {
    setshrArngCdErr('');
    setshrArngDescTxtErr('');
  };
  const validateSave = () => {
    clearErrorFields();
    let valid = true;
    if (shrArngCd?.length !== 2) {
      setshrArngCdErr('Shared Arrangement Code must be 2 characters long.');
      valid = false;
    }
    if (shrArngDescTxt?.length === 0) {
      setshrArngDescTxtErr(
        'Shared Arrangement Description Text must be entered.'
      );
      valid = false;
    }
    return valid;
  };
  const alreadyExists = async () => {
    let tableData = await getTableData1();
    let exists = tableData.some((row) => row['SHR_ARNG_CD'] === shrArngCd);
    if (exists) {
      setshrArngCdErr('Shared Arrangement Code already exists.');
      setShowAlert(true);
      setAlertText(shrArngCd + ' already exists in table.');
      setAlertType('error');
    }
    return exists;
  };
  const save = async () => {
    setSaveModal(false);

    setHideUpdate(false);
    setFlag(false);
    const now = new Date();
    let payload = {
      shrArngCd: shrArngCd,
      shrArngDescTxt: shrArngDescTxt,
      shrArngDfltInd: shrArngCd === '00' ? 'Y' : 'N',
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: msid.substring(0, 7).toUpperCase(),
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'Y',
    };
    setshrArngCd('');
    setshrArngDescTxt('');
    setshrArngTxt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/shared-arrangement/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setAlertText('You have successfully added a record.');
        setAlertType('success');
        setShowAlert(true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };

  const update = async () => {
    clearErrorFields();
    if (shrArngDescTxt === '') {
      setshrArngDescTxtErr(
        'Shared Arrangement Description Text must be entered.'
      );
      return;
    }
    setUpdateFlag(false);
    setHideAdd(false);
    setFlag(false);
    setUpdateConfirmModal(false);
    const now = new Date();
    let payload = {
      newRecord: {
        shrArngCd: shrArngCd,
        shrArngDescTxt: shrArngDescTxt,
        shrArngDfltInd: shrArngTxt,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: msid.substring(0, 7).toUpperCase(),
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
      oldRecord: {
        shrArngCd: updateRow['SHR_ARNG_CD'],
        shrArngDescTxt: updateRow['SHR_ARNG_DESC_TXT'],
        shrArngDfltInd: updateRow['SHR_ARNG_DFLT_IND'],
        lstUpdtDt: updateRow['LST_UPDT_DT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: updateRow['UPDT_IND'],
      },
    };
    setshrArngCd('');
    setshrArngDescTxt('');
    setshrArngTxt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/shared-arrangement/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setAlertText('You have successfully updated the record.');
        setAlertType('success');
        setShowAlert(true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const close = () => {
    clearErrorFields();
    setFlag(false);
    setUpdateFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setshrArngCd('');
    setshrArngDescTxt('');
    setshrArngTxt('');
  };
  const close1 = () => {
    setFlag(false);
    setUpdateFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
  };

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  useEffect(() => {
    const hasTableCTSReadWriteAccess = env.Table_CTS_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    setHasRWaccess(hasTableCTSReadWriteAccess);
  }, [userRolesGroup]);

  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Delete Record"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Section>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>SHR_ARNG_CD: </b>
              </span>
              <span>
                <b>{row['SHR_ARNG_CD']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>SHR_ARNG_DESC_TXT: </b>
              </span>
              <span>
                <b>{row['SHR_ARNG_DESC_TXT']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>SHR_ARNG_DFLT_IND: </b>
              </span>
              <span>
                <b>{row['SHR_ARNG_DFLT_IND']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_DT: </b>
              </span>
              <span>
                <b>{row['LST_UPDT_DT']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_TM: </b>
              </span>
              <span>
                <b>{row['LST_UPDT_TM']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_ID: </b>
              </span>
              <span>
                <b>{row['LST_UPDT_ID']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>UPDT_IND: </b>
              </span>
              <span>
                <b>{row['UPDT_IND']}</b>
              </span>
            </div>
            <br />
            Are you sure you want to delete this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={deleteRows}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update"
          isOpen={updateModal}
          onClose={() => setUpdateModal(false)}
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>SHR_ARNG_CD: </b>
                {updateRow['SHR_ARNG_CD']}
              </span>
              <span>
                <b>SHR_ARNG_CD: </b>
                {shrArngCd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>SHR_ARNG_DESC_TXT: </b>
                {updateRow['SHR_ARNG_DESC_TXT']}
              </span>
              <span>
                <b>SHR_ARNG_DESC_TXT: </b>
                {shrArngDescTxt}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>SHR_ARNG_DFLT_IND: </b>
                {updateRow['SHR_ARNG_DFLT_IND']}
              </span>
              <span>
                <b>SHR_ARNG_DFLT_IND: </b>
                {shrArngTxt}
              </span>
            </div>
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                title={alertText}
                variant={alertType}
                isVisible={showAlert}
                onClose={() => {
                  setShowAlert(false);
                }}
              />
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                <Text fontWeight={300} css={{ fontSize: '14px' }}>
                  {records}
                </Text>
              </Grid.Col>
              <br />
              {hasRWaccess && (
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={flag || hideAdd ? true : false}
                >
                  + Add Row
                </Button>
              )}
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="SHR_ARNG_CD"
                        value={shrArngCd}
                        onChange={(e) => setshrArngCd(e.target.value)}
                        isDisabled={updateFlag}
                        maxLength={2}
                        errorMessage={shrArngCdErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="SHR_ARNG_DESC_TXT"
                        value={shrArngDescTxt}
                        onChange={(e) => setshrArngDescTxt(e.target.value)}
                        maxLength={25}
                        errorMessage={shrArngDescTxtErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="SHR_ARNG_DFLT_IND"
                        value={shrArngTxt}
                        isDisabled
                        onChange={(e) => setshrArngTxt(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button
                        onClick={() => {
                          if (updateFlag) {
                            setUpdateModal(true);
                          } else if (validateSave()) {
                            setSaveModal(true);
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={updateFlag ? close : close1}>
                        Cancel
                      </Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(SharedArrangment, 'table_cts_ro_rw');
