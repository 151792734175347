import React from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { formateDate } from '@src/utils/dateUtil';

export const SuffixSection = ({ sufData, bankCodeLabelSuffix, fundingCodeLabelSuffix, ReinsuranceLabel }) => {
  return (
    <React.Fragment>
      <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
        <Heading offset={5}>Suffix</Heading>
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput label="Suffix" value={sufData?.[0]?.SUFX_CD} isDisabled />
      </Grid.Col>

      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Suffix Type "
          isDisabled
          value={sufData?.[0]?.SUFX_TYP_CD}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Effective Date"
          isDisabled
          value={ sufData?.[0]?.SUFX_EFF_DT === "9999-12-31" ? "" : formateDate(sufData?.[0]?.SUFX_EFF_DT)}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Cancellation Date"
          isDisabled
          value={sufData?.[0]?.SUFX_CANC_DT === "9999-12-31" ? "" : formateDate(sufData?.[0]?.SUFX_CANC_DT)}
        />
      </Grid.Col>

      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Attribute Effective Date"
          isDisabled
          value={sufData?.[0]?.ATT_EFF_DT === "9999-12-31" ? "" : formateDate(sufData?.[0]?.ATT_EFF_DT)}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Legal Entiy"
          isDisabled
          value={sufData?.[0]?.LEG_ENTY_CD}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Reinsurance"
          isDisabled
          value={ReinsuranceLabel}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Funding Effective Date"
          isDisabled
          value={sufData?.[0]?.FNDNG_EFF_DT === "9999-12-31" ? "" : formateDate(sufData?.[0]?.FNDNG_EFF_DT)}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        {/* TODO: ADD Value */}
        <TextInput label="Funding Code" isDisabled value={fundingCodeLabelSuffix} />
      </Grid.Col>

      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput label="Bank Code" isDisabled value={bankCodeLabelSuffix} />
      </Grid.Col>
    </React.Fragment>
  );
};
