import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import * as XLSX from 'xlsx';
import { Text } from '@abyss/web/ui/Text';
import { Grid } from '@abyss/web/ui/Grid';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

export default function CorrectableTransactionTable({
  state,
  onTransformedData,
}) {
  const { getLocation } = useRouter();
  const location = getLocation();
  const [records, setRecords] = useState('0 records');

  const columns = React.useMemo(
    () => [
      {
        Header: 'Rsch Req',
        accessor: 'rschReqInd',
        style: { width: '10px !important' },
      },
      {
        Header: 'Charge Date',
        accessor: 'chrgDt',
      },
      {
        Header: 'Transaction ID',
        accessor: 'transId',
      },
      {
        Header: 'Subl Seq',
        accessor: 'sublSeqNbr',
      },
      {
        Header: 'Policy Number',
        accessor: 'polNbr',
      },
      {
        Header: 'Suffix Code',
        accessor: 'sufxCd',
      },
      {
        Header: 'Account Number',
        accessor: 'polAcctNbr',
      },
      {
        Header: 'HMO Account',
        accessor: 'hmoAcctDivNbr',
      },
      {
        Header: 'SD',
        accessor: 'srsDesgCd',
      },
      {
        Header: 'Check Number',
        accessor: 'chkNbr',
      },
      {
        Header: 'CPS#',
        accessor: 'clmPaySeqNbr',
      },
      {
        Header: 'Subl Type',
        accessor: 'sublTypCd',
      },
      {
        Header: 'Subl Amount',
        accessor: 'sublSrvcAmt',
      },
      {
        Header: 'Subscr SSN',
        accessor: 'subscrSsn',
      },
      {
        Header: 'Issue Date',
        accessor: 'chkIssDt',
      },
      {
        Header: 'Svc Date',
        accessor: 'transFstSvcDt',
      },
      {
        Header: 'Fund Arregement',
        accessor: 'fundArngCd',
      },
      {
        Header: 'Leg Entity',
        accessor: 'legEntyCd',
      },
      {
        Header: 'Case Fund',
        accessor: 'caseFundArngCd',
      },
      {
        Header: 'Trans Src',
        accessor: 'transSrcCd',
      },
      {
        Header: 'Actv Ret',
        accessor: 'actvRetCd',
      },
      {
        Header: 'TOPS Trans',
        accessor: 'transCd',
      },
      {
        Header: 'Fee Code',
        accessor: 'feeCd',
      },
      {
        Header: 'Fund Code',
        accessor: 'fundCd',
      },
      {
        Header: 'Product Code',
        accessor: 'prdctCd',
      },
      {
        Header: 'UNET Spcl',
        accessor: 'unetSpclCd',
      },
    ],
    []
  );

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedHours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    return `${month}-${day}-${year} ${formattedHours}_${minutes}_${seconds} `;
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    } else {
      if (Array.isArray(columns)) {
        columns.forEach((column) => {
          columnNames[column.Header] = null;
        });
      }
      return columnNames;
    }

    let columnNameMapping = {};
    columns.forEach((column) => {
      columnNameMapping[column.accessor] = column.Header;
    });
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    dataTablePropsPagination.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
      workbook,
      'CMDB-Apply Itemized Correction' + getFormattedDate() + '.xlsx'
    );
  };

  const downloadExcel = () => {
    let fullData = [];
    dataTablePropsPagination?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
      workbook,
      'CMDB-Apply Itemized Correction' + getFormattedDate() + '.xlsx'
    );
  };

  const dataTablePropsPagination = useDataTable({
    showPagination: true,
    initialData:
      location?.state?.response === 'No records found'
        ? []
        : location?.state?.response,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showGlobalFilter: false,
    showSelection: true,
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
  });

  useEffect(() => {
    if (dataTablePropsPagination.filter?.filteredRows?.length == 1) {
      setRecords(
        `${dataTablePropsPagination.filter?.filteredRows?.length} record`
      );
    } else {
      setRecords(
        `${dataTablePropsPagination.filter?.filteredRows?.length} records`
      );
    }
  }, [dataTablePropsPagination?.filter?.filteredRows]);

  useEffect(() => {
    let selectedRows = [];
    dataTablePropsPagination.reactTableProps?.selectedFlatRows.forEach(
      (obj) => {
        selectedRows.push({ ...obj.values, selected: true });
      }
    );
    if (selectedRows?.length > 0) {
      onTransformedData(selectedRows);
    }
  }, [dataTablePropsPagination.reactTableProps?.selectedFlatRows]);

  return (
    <React.Fragment>
      <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Text fontWeight={300} css={{ fontSize: '14px' }}>
            {records}
          </Text>
        </Grid.Col>
        <div
          style={{
            height:
              dataTablePropsPagination?.data?.length > 6 ? '550px' : 'auto',
          }}
        >
          <DataTable
            title="Correctable Transactions"
            hideTitleHeader
            tableState={dataTablePropsPagination}
          />
        </div>
      </Grid.Col>
    </React.Fragment>
  );
}
