import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

const AcisSourceSystemInfo = ({ policyDetails }) => {
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5} textAlign="center">
            Source System Information
          </Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>File ID:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.fileId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Trans. ID:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.trnId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Chg. Eff. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.chgEffDt === '9999-12-31'
              ? ''
              : policyDetails?.chgEffDt}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src Release Date Time:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.srcRlseDttm === '9999-12-31'
              ? ''
              : policyDetails?.srcRlseDttm}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Medical Com. Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.medCoCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Statue Issue Location Id:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.stIssLocId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Aud. Chg. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.audChgDt === '9999-12-31'
              ? ''
              : policyDetails?.audChgDt}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Aud. Chg. User Id:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.audChgUserId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Bus. Trans. Type Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.busTrnTypCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src. Suffix Canc Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.srcSufxCancDt === '9999-12-31'
              ? ''
              : policyDetails?.srcSufxCancDt}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src. Suffix Type Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcSufxTypCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Rpt Cd. Number:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.rptCdNbr}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>All Claim Cover Indicator:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.allClmCovInd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>AR Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.arCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Group ID:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.grpId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Ben Group Number:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.benGrpNbr}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Claim Seq. Number:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.clmSeqNbr}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>MBank Class Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.mbankClssCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>SL Suffix Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.slSufxCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Term Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.termCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Structure Term Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.structTermDt === '9999-12-31'
              ? ''
              : policyDetails?.structTermDt}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Sub Group Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.subGrpCd}</Text>
        </Grid.Col>

        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Tops Mail Distribution Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.topsMailCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Next Renewal Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.nxtRenDt === '9999-12-31'
              ? ''
              : policyDetails?.nxtRenDt}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Market Type Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.mktTypCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Network Org ID:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.ntwrkOrgId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>PVC Indicator:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.pvcInd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Product Type Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.prdctTypCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Product Short Name:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.prdctShrtNm}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Premium Acct. Number:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.rpremAcctNbr}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Premium Seq. Number:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.premSeqNbr}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src. Bank Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcBnkId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src Customer ID:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcCustId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src Legal Entity:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcLegEnty}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Customer Plan ID:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.custPlnId}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Customer Plan Eff. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.custPlnEffDt === '9999-12-31'
              ? ''
              : policyDetails?.custPlnEffDt}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Customer Plan Termination:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.custPlnTrmDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Src Funding Type Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.srcFndngCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.reinsSufxCancDt === '9999-12-31'
              ? ''
              : policyDetails?.reinsSufxCancDt}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Reins. Policy Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.reinsPolCancDt === '9999-12-31'
              ? ''
              : policyDetails?.reinsPolCancDt}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Plan Var Cd:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.planVarCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Reins. Acct. Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.reinsAcctCancDt === '9999-12-31'
              ? ''
              : policyDetails?.reinsAcctCancDt}
          </Text>
        </Grid.Col>

        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Reins. Suffix Canc. Date:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {policyDetails?.reinsSufxCancDt === '9999-12-31'
              ? ''
              : policyDetails?.reinsSufxCancDt}
          </Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default AcisSourceSystemInfo;
