import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { RouterProvider } from '@abyss/web/ui/RouterProvider';
import { BrowserRouter } from 'react-router-dom';
// import Account, PolicyLookup, Suffix  from '@src/pages';
import Account from '@src/pages/FADB/Account';
import PolicyLookup from '@src/pages/FADB/policy-screen';
import Label from '@src/pages/table-maintenance/cts/label';
import Suffix from '@src/pages/FADB/Suffix';
import Home from '@src/pages/Home';
import SuffixLookup from '@src/pages/FADB/policy-screen/suffix-lookup';
import AccountLookup from '@src/pages/FADB/AccountLookup/index';
import ATSPvrc from '@src/pages/table-maintenance/cts/ats-pvrc';
import CaseStructure from '@src/pages/FADB/Inquiry/CaseStructure/Index';
import PolicyDetails from '@src/pages/FADB/Inquiry/CaseStructure/PolicyDetails';
import PolicyLevelSearch from '@src/pages/FADB/Inquiry/CaseStructure/PolicyLevelSearch';
import SuffixLevelSearch from '@src/pages/FADB/Inquiry/CaseStructure/SuffixLevelSearch';
import FincPolMktSeg from '@src/pages/table-maintenance/cts/finc-pol-mkt-seg';
import SuffixDetails from '@src/pages/FADB/Inquiry/CaseStructure/SuffixDetails';
import AutomatedFeeds from '@src/pages/FADB/Inquiry/AutomatedFeeds';
import CorrectionManagement from '@src/pages/CMDB/CorrectionManagment/Index';
import ErrorDownloads from '@src/pages/CMDB/SuspectErrors/ErrorDownloads';
import Inquiry from '@src/pages/CMDB/SuspectErrors/Inquiry';
import AutomatedFeedsSearch from '@src/pages/FADB/Inquiry/AutomatedFeeds/AutomatedFeedsSearch';
import AutomatedFeedPolicyDetails from '@src/pages/FADB/Inquiry/AutomatedFeeds/AutomatedFeedPolicyDetails';
import UnlockClaimTransactions from '@src/pages/CMDB/CorrectionManagment/UnlockClaimTransactions';
import AggragateCorrection from '@src/pages/CMDB/CorrectionManagment/ManageRequest/Aggragate-Correction/AggragateCorrection';
import ItemizeCorrection from '@src/pages/CMDB/CorrectionManagment/ManageRequest/Itemized-Correction/ItemizeCorrection';
import Login from '@src/auth/Login';
import BulkCorrection from '@src/pages/CMDB/CorrectionManagment/BulkCorrection';
import PrelimFin from '@src/pages/CMDB/CorrectionManagment/PrelimFin';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, loginRequest } from '../auth/authConfig';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
const msalInstance = new PublicClientApplication(msalConfig);
import FinalizeCorrection from '@src/pages/CMDB/CorrectionManagment/FinalizeCorrection';
// import PolicyDetails from '@src/pages/FADB/Inquiry/AutomatedFeeds/PolicyDetails';
import PolicyStructure from '@src/pages/CMDB/CorrectionManagment/Policy-Structure/PolicyStructure';
import PolicyStructureSubmit from '@src/pages/CMDB/CorrectionManagment/Policy-Structure/PolicyStructureSubmit';
import FinancialStructure from '@src/pages/CMDB/CorrectionManagment/Financial-Structure/FinancialStructure';
import FinancialStructureSubmit from '@src/pages/CMDB/CorrectionManagment/Financial-Structure/FinancialStructureSubmit';
import Employee from '@src/pages/CMDB/CorrectionManagment/Employee/Employee';
import EmployeeSubmit from '@src/pages/CMDB/CorrectionManagment/Employee/EmployeeSubmit';
import Payment from '@src/pages/CMDB/CorrectionManagment/Payment/Payment';
import PaymentSubmit from '@src/pages/CMDB/CorrectionManagment/Payment/PaymentSubmit';
import SuspectError from '@src/pages/CMDB/CorrectionManagment/SuspectError/SuspectError';
import SuspectErrorSubmit from '@src/pages/CMDB/CorrectionManagment/SuspectError/SuspectErrorSubmit';
import InquirySearch from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/InquirySearch';
import InquirySearchResult from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/InquirySearchResult';
import InquiryDetailView from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/InquiryDetailView';
import RequestAvailableForCorrectionCustom from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/RequestAvailableForCorrectionCustom';
import RequestAvailableForCorrectionDetail from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/RequestAvailableForCorrectionDetail';
import RequestOnHoldAll from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/RequestOnHoldAll';
import RequestInProgressCustom from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/RequestInProgressCustom';
import LogsDailyAutoReview from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/LogsDailyAutoReview';
import ApproveFinancialSummary from '@src/pages/CMDB/CorrectionManagment/ApproveFinancialSummary';
import RequestsRequiringFinancialApproval from '@src/pages/CMDB/CorrectionManagment/RequestsRequiringFinancialApproval';
import Help from '@src/pages/CMDB/CorrectionManagment/Help';
import { Provider } from 'react-redux';
import store from '../redux/store/store';
import CorrectableTransaction from '@src/pages/CMDB/CorrectionManagment/ManageRequest/Itemized-Correction/CorrectableTransaction';
import CTS from '@src/pages/table-maintenance/cts/cts';
import UCAS from '@src/pages/table-maintenance/ucas/ucas';
import FADB from '@src/pages/table-maintenance/fadb/fadb';
import HealthPlanSite from '@src/pages/table-maintenance/fadb/health-plan-site';
import UcasSelectionTable from '@src/pages/table-maintenance/ucas/ucas-selection-table';
import CtsSelectionTable from '@src/pages/table-maintenance/cts/cts-selection-table';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import AuthenticatedRoute from '@src/auth/ProtectedRoutes';
import CtsSelectionTableLookup from '@src/pages/table-maintenance/cts/cts-selection-table-lookup';
import MarketLocation from '@src/pages/table-maintenance/cts/market-location';
import State from '@src/pages/table-maintenance/cts/state';
import ReportPolicy from '@src/pages/table-maintenance/cts/report-policy';
import UcasSRSTable from '@src/pages/table-maintenance/ucas/ucas-srs';
import UcasEscp from '@src/pages/table-maintenance/ucas/ucas-escp';
import UcasFMSITable from '@src/pages/table-maintenance/ucas/ucas-fmsi';
import UcasSuspErrorTable from '@src/pages/table-maintenance/ucas/ucas-susp-err';
import useAuth from '@src/utils/useAuth';
import InquiryDataSearch from '@src/pages/CMDB/SuspectErrors/inquirySearch';
import Bypass from '@src/pages/table-maintenance/cts/bus-proc-byp-rule';
import MbrLegEnty from '@src/pages/table-maintenance/cts/mbr-leg-enty';
import Company from '@src/pages/table-maintenance/cts/company';
import SharedArrangment from '@src/pages/table-maintenance/cts/shared-arrangment';
import FinancialMarket from '@src/pages/table-maintenance/cts/fin-mar-seg-ind';
import Product from '@src/pages/table-maintenance/cts/product';
import Hmoacct from '@src/pages/table-maintenance/cts/hmoacct';
import SecurityUser from '@src/pages/table-maintenance/fadb/security-user';
import UserGroupMember from '@src/pages/table-maintenance/fadb/user-group-member';
import RequestDetailView from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/RequestDetailView';
import RequestOnHoldDetail from '@src/pages/CMDB/CorrectionManagment/Inquiry-Excel-Download/RequestOnHoldDetail';
import InquiryPolicyDetails from '@src/pages/CMDB/SuspectErrors/policyDetails';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import SubTypeCodeDetails from '@src/pages/CMDB/SuspectErrors/SubTypeCodeDetails';
import { Footer } from '@src/components/Footer';
import FourOFour from '@src/pages/404';

export const Routes = () => {
  const { noAccess } = useAuth();
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <Grid css={{ margin: '0px !important' }}>
            <Grid.Col span={{ xs: 12 }} css={{ padding: '0px !important' }}>
              <PageHeader />
            </Grid.Col>
            <Grid.Col
              span={{ xs: 2 }}
              css={{
                padding: '0px !important',
                flexBasis: '100%',
                paddingLeft: '0rem',
              }}
            >
              <Sidebar />
            </Grid.Col>
          </Grid>
          <RouterProvider>
            <Router.Routes title="{{title}} | Abyss">
              <Router.Route path="/" element={<Login />} />
              <Router.Route path="/home" element={<Home />} />
              <Router.Route path="/fadb/policy" element={<PolicyLookup />} />
              <Router.Route path="/fadb/suffix" element={<Suffix />} />
              <Router.Route path="/fadb/account" element={<Account />} />
              <Router.Route
                path="/fadb/suffix/lookup"
                element={<SuffixLookup data={undefined} />}
              />
              <Router.Route
                path="/fadb/account/lookup"
                element={
                  <AccountLookup
                  // policyData={undefined}
                  // suffixData={undefined}
                  />
                }
              />
              <Router.Route
                path="/fadb/inquiry/case-structure"
                element={<CaseStructure />}
              />
              <Router.Route
                path="/fadb/inquiry/case-structure/case-structure-search"
                element={<PolicyLevelSearch />}
              />
              <Router.Route
                path="/fadb/inquiry/case-structure/case-structure-search/policy-details"
                element={<PolicyDetails />}
              />
              <Router.Route
                path="/fadb/inquiry/case-structure/case-structure-search/suffix-search"
                element={<SuffixLevelSearch />}
              />
              <Router.Route
                path="/fadb/inquiry/case-structure/case-structure-search/suffix-details"
                element={<SuffixDetails />}
              />
              <Router.Route
                path="/fadb/inquiry/automated-feeds"
                element={<AutomatedFeeds />}
              />
              <Router.Route
                path="/cmdb/correction-management"
                element={<CorrectionManagement />}
              />
              <Router.Route
                path="/cmdb/correction-management/apply-itemized/:id"
                element={<CorrectableTransaction />}
              />
              <Router.Route
                path="/cmdb/suspect-errors/errors-download"
                element={<ErrorDownloads />}
              />
              <Router.Route
                path="/cmdb/suspect-errors/inquiry"
                element={<Inquiry />}
              />
              <Router.Route
                path="/fadb/inquiry/automated-feeds/automated-feeds-search"
                element={<AutomatedFeedsSearch />}
              />
              <Router.Route
                path="/fadb/inquiry/automated-feeds/automated-feeds-search/policy-details"
                element={<AutomatedFeedPolicyDetails />}
              />
              <Router.Route
                path="/fadb/inquiry/automated-feeds/automated-feeds-search/policyNumber/:polycyNum"
                element={<AutomatedFeedPolicyDetails />}
              />
              <Router.Route
                path="/cmdb/correction-management/aggregate-correction"
                element={<AggragateCorrection />}
              />
              <Router.Route
                path="/cmdb/correction-management/unlock-claim-transactions"
                element={<UnlockClaimTransactions />}
              />
              <Router.Route
                path="/cmdb/correction-management/itemize-correction/:id"
                element={<ItemizeCorrection />}
              />
              <Router.Route
                path="/cmdb/correction-management/approve-financial-summary-all"
                element={<ApproveFinancialSummary />}
              />
              <Router.Route
                path="/cmdb/correction-management/finalize-correction"
                element={<FinalizeCorrection />}
              />
              <Router.Route
                path="/cmdb/correction-management/policy-structure"
                element={<PolicyStructure />}
              />
              <Router.Route
                path="/cmdb/correction-management/policy-structure/policy-structure-submit"
                element={<PolicyStructureSubmit />}
              />
              <Router.Route
                path="/cmdb/correction-management/financial-structure"
                element={<FinancialStructure />}
              />
              <Router.Route
                path="/cmdb/correction-management/financial-structure/financial-structure-submit"
                element={<FinancialStructureSubmit />}
              />
              <Router.Route
                path="/cmdb/correction-management/employee"
                element={<Employee />}
              />
              <Router.Route
                path="/cmdb/correction-management/employee/employee-submit"
                element={<EmployeeSubmit />}
              />
              <Router.Route
                path="/cmdb/correction-management/payment"
                element={<Payment />}
              />
              <Router.Route
                path="/cmdb/correction-management/payment/payment-submit"
                element={<PaymentSubmit />}
              />
              <Router.Route
                path="/cmdb/correction-management/suspect-error"
                element={<SuspectError />}
              />
              <Router.Route
                path="/cmdb/correction-management/suspect-error/suspect-error-submit"
                element={<SuspectErrorSubmit />}
              />
              <Router.Route
                path="/cmdb/correction-management/inquiry-search"
                element={<InquirySearch />}
              />
              <Router.Route
                path="/cmdb/correction-management/view-bulk-corrections"
                element={<BulkCorrection />}
              />
              <Router.Route
                path="/cmdb/correction-management/view-prelim-fin-sum"
                element={<PrelimFin />}
              />
              <Router.Route
                path="/cmdb/correction-management/inquiry-result"
                element={<InquirySearchResult />}
              />
              <Router.Route
                path="/cmdb/correction-management/view-correction-request-detail/:Id/"
                element={<RequestDetailView />}
              />
              {/* <Router.Route
                path="/cmdb/correction-management/view-correction-request-details/:Id/"
                element={<RequestDetailView />}
              />
              <Router.Route
                path="/cmdb/correction-management/view-correction-requests-detail/:Id/"
                element={<RequestAvailableForCorrectionDetail />}
              />
              <Router.Route
                path="/cmdb/correction-management/view-correction-request-on-hold-detail/:Id/"
                element={<RequestOnHoldDetail />}
              />
              <Router.Route
                path="/cmdb/correction-management/view-correction-request-in-progress-detail/:Id/"
                element={<RequestOnHoldDetail />}
              /> */}
              <Router.Route
                path="/cmdb/correction-management/finalize-corrections/:Id/"
                element={<FinalizeCorrection />}
              />
              <Router.Route
                path="/cmdb/correction-management/approve-financial-summary"
                element={<ApproveFinancialSummary />}
              />
              <Router.Route
                path="/cmdb/correction-management/requests-requiring-financial-approval"
                element={<RequestsRequiringFinancialApproval />}
              />
              <Router.Route
                path="/cmdb/correction-management/requests-available-correction"
                element={<RequestAvailableForCorrectionCustom />}
              />
              <Router.Route
                path="/cmdb/correction-management/requests-on-hold"
                element={<RequestOnHoldAll />}
              />
              <Router.Route
                path="/cmdb/correction-management/requests-in-progress"
                element={<RequestInProgressCustom />}
              />
              <Router.Route
                path="/cmdb/correction-management/daily-auto-review"
                element={<LogsDailyAutoReview />}
              />
              <Router.Route
                path="/cmdb/correction-management/help"
                element={<Help />}
              />
              <Router.Route path="/ftmf/cts" element={<CTS />} />
              <Router.Route path="/ftmf/fadb" element={<FADB />} />
              <Router.Route path="/ftmf/ucas" element={<UCAS />} />
              <Router.Route
                path="/ftmf/fadb/health-plan-site1"
                element={<HealthPlanSite />}
              />
              <Router.Route
                path="/ftmf/ucas/bank-account"
                element={<UcasSelectionTable />}
              />
              <Router.Route
                path="/ftmf/ucas/srs-desg/"
                element={<UcasSRSTable />}
              />
              <Router.Route
                path="/ftmf/ucas/fmsi-ahp-indicator"
                element={<UcasFMSITable />}
              />
              <Router.Route
                path="/ftmf/ucas/error-susp"
                element={<UcasSuspErrorTable />}
              />
              <Router.Route path="/ftmf/ucas/escp" element={<UcasEscp />} />
              <Router.Route
                path="/ftmf/cts/:name/:systemType/:databaseType/data"
                element={<CtsSelectionTable />}
              />
              <Router.Route
                path="/cmdb/suspect-errors/inquiry-search"
                element={<InquiryDataSearch />}
              />
              <Router.Route
                path="/cmdb/suspect-errors/inquiry-search/inquiry-search-subline"
                element={<InquiryPolicyDetails />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site-lookup"
                element={<CtsSelectionTableLookup />}
              />
              <Router.Route
                path="/ftmf/cts/finc-pol-mkt-seg/:systemType/:databaseType"
                element={<FincPolMktSeg />}
              />
              <Router.Route
                path="/ftmf/cts/label/:systemType/:databaseType"
                element={<Label />}
              />
              <Router.Route
                path="/ftmf/cts/:name/:systemType/:databaseType"
                element={<CtsSelectionTableLookup />}
              />
              <Router.Route
                path="/ftmf/cts/ats-pvrc/:systemType/:databaseType"
                element={<ATSPvrc />}
              />
              <Router.Route
                path="/ftmf/cts/market/:systemType/:databaseType"
                element={<MarketLocation />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site/mbr-leg-enty"
                element={<MbrLegEnty />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site/bypass"
                element={<Bypass />}
              />
              <Router.Route
                path="/ftmf/cts/company/:systemType/:databaseType"
                element={<Company />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site/state"
                element={<State />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site/rpt"
                element={<ReportPolicy />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site/shared-arrangment"
                element={<SharedArrangment />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site/fin-mar-seg-ind"
                element={<FinancialMarket />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site/product"
                element={<Product />}
              />
              <Router.Route
                path="/ftmf/cts/health-plan-site/hmoacct"
                element={<Hmoacct />}
              />
              <Router.Route
                path="/ftmf/fadb/sec-user"
                element={<SecurityUser />}
              />
              <Router.Route
                path="/ftmf/fadb/user-group-member"
                element={<UserGroupMember />}
              />
              <Router.Route
                path="/transac-info"
                element={<SubTypeCodeDetails />}
              />
              <Router.Route path="*" element={<FourOFour />} />
            </Router.Routes>
          </RouterProvider>
          <Footer />
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  );
};
