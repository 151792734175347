import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

const PolicyLevel = ({ policyDetails  }) => {
  return (
    <React.Fragment>
    <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
      <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
        <Heading offset={5}>Policy Level</Heading>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Number :</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 9 }}>
        <Text>{policyDetails?.polNbr}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Holder Name :</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 9 }}>
        <Text>{policyDetails?.polhldrNm}</Text>
      </Grid.Col>

      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Eff. Date:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.polEffDt === "9999-12-31" ? "" : policyDetails?.polEffDt}</Text>
      </Grid.Col>


      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Canc. Date:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.srcPolCancDt === "9999-12-31" ?  ""  :  policyDetails?.srcPolCancDt}</Text>
      </Grid.Col>

      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Funding Eff. Date:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.polFndEffDt === "9999-12-31" ? "" : policyDetails?.polFndEffDt}</Text>
      </Grid.Col>

      
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Attribute Date:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.polAttDt === "9999-12-31" ? "" : policyDetails?.polAttDt}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy funding Arng:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.polFundArng}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Bank Cd:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.polBnkCd}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Legal Entity:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.polLegEntyCd}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Legal Entity OFN:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.polLegOFNCd == "null" ? "" : policyDetails?.polLegOFNCd}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy GL Reinsurance Cd:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.polGlReinsCd}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Erisa Cd:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.erisaCd}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Renewal Cd. Type:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.renewalCdTyp}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Type:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.rrTypCd}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Renewal Month:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.renMoNbr}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Renewal Day:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.renDayNbr}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text css={{ fontWeight: 600 }}>Policy Type of Eff. Date:</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: 3 }}>
        <Text>{policyDetails?.srvcIssDtRulCd === "9999-12-31" ? "" : policyDetails?.srvcIssDtRulCd}</Text>
      </Grid.Col>
    </Grid>
  </React.Fragment>
  );
};

export default PolicyLevel;
