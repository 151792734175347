import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';
import { Link } from '@abyss/web/ui/Link';
import { values } from 'lodash';
import axios from 'axios';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Modal } from '@abyss/web/ui/Modal';
import { Layout } from '@abyss/web/ui/Layout';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { ToastProvider } from '@abyss/web/ui/Toast';
import { useToast } from '@abyss/web/hooks/useToast';
import { Alert } from '@abyss/web/ui/Alert';
import { BASE_URL } from '@src/utils/endpoints';
import useAuth from '@src/utils/useAuth';
import { Heading } from '@abyss/web/ui/Heading';
import * as XLSX from 'xlsx';
import withAuth from '@src/auth/withAuth';

const InquiryPolicyDetails = () => {
  const { navigate, getLocation } = useRouter();
  const location = getLocation();
  const [records, setRecords] = useState('0 records');
  const [selectedRecords, setSelectedRecords] = useState([]);
  let data = location?.state;
  const [searchCriteria, setSearchCritera] = useState(
    data?.clickCell?.searchDisplayValues
  );
  const [flag, setFlag] = useState(false);

  let searchData = data?.clickCell?.searchDisplayValues;
  const today = new Date();
  const day = today.toLocaleString('default', { weekday: 'long' });
  const month = today.toLocaleString('default', { month: 'long' });
  const date = today.getDate();
  const year = today.getFullYear();
  const currDate = `${day}, ${month} ${date}, ${year}`;

  const { loading, error, policyNumberData } = useSelector(
    (state) => state.inquiry
  );

  const [subTypeRowNo, setSubTypeRowNo] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [row, setRow] = useState({});
  const { toast } = useToast();
  const [showAlert, setShowAlert] = useState(false);
  const [showValidationCheck, setshowValidationCheck] = useState(false);
  const { readOnly, msid, token } = useAuth();
  // const transacInfo = policyNumberData?.pageItems[subTypeRowNo]?.ND_D_TRANS_ID;
  const sublineSeqNo =
    policyNumberData?.pageItems[subTypeRowNo]?.ND_D_SUBL_SEQ_NBR;
  const deleteRow = policyNumberData?.pageItems[subTypeRowNo];
  const [selecteddata, setSelectedData] = useState([]);

  const handleClick = () => {
    navigate('/cmdb/suspect-errors/inquiry', {
      state: { data: location?.state?.data },
    });
  };

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleClick,
    },
  ];

  const columns = React.useMemo(
    () => [
      { Header: 'POL NBR', accessor: 'POLICY_NBR' },
      { Header: 'SUF CD', accessor: 'SFFX_CODE' },
      { Header: 'ACCT CD', accessor: 'ACCT_CODE' },
      { Header: 'SD', accessor: 'SD' },
      { Header: 'CHK NBR', accessor: 'CHECK' },
      { Header: 'CPS#', accessor: 'CPS' },
      {
        Header: 'SUB TY CD',
        accessor: 'SUBLINE_TYPE_CODE',
        Cell: ({ value, row, column }) => {
          const rowValues = row.values;
          const handleClick = async (e) => {
            e.preventDefault();
            await setSubTypeRowNo(row.id);
            navigate('/transac-info', {
              state: {
                rowValues,
                transacInfo: policyNumberData?.pageItems[row.id]?.ND_D_TRANS_ID,
                sublineSeqNo,
                data,
              },
            });
          };
          return (
            <React.Fragment>
              <Link href="#" onClick={handleClick}>
                {value}
              </Link>
            </React.Fragment>
          );
        },
      },
      {
        Header: 'DOL AMT',
        accessor: 'DOLLAR_AMOUNT',
      },
      { Header: 'SBSCR ID', accessor: 'SUBSCR_ID' },
      { Header: 'CHARGE DATE', accessor: 'CHARGE_DATE' },
      { Header: 'ISSUE DATE', accessor: 'ISSUE_DATE' },
      { Header: 'SERVICE DATE', accessor: 'SERVICE_DATE' },

      {
        Header: 'FNDNG ARNG',
        accessor: 'FNDNG_ARNG',
        Cell: ({ value }) => {
          return value === '1'
            ? 'Fully Insured'
            : value === '2'
            ? 'MP-Minimum Premium'
            : value === '3'
            ? 'MMP-Member Minimum Premium'
            : value === '4'
            ? 'ASO'
            : value === '5'
            ? 'SC/SL 100'
            : value === '7'
            ? 'PRIME ASO'
            : '';
        },
      },
      { Header: 'LEGAL ENTITY', accessor: 'LEGAL_ENTITY' },
      { Header: 'CS FND ARG', accessor: 'CASE_FUND_ARNG' },
      { Header: 'CLM SRC CD', accessor: 'CLAIM_SOURCE_CODE' },
      { Header: 'WK STAT', accessor: 'WORKING_STATUS' },
      { Header: 'MD ELG', accessor: 'MED_ELIG' },
      { Header: 'SUS STAT', accessor: 'SUSPECT_STATUS' },
    ],

    []
  );
  const getHeadersForEmptyData = () => {
    let columnNames = {};
    columnNames = {
      'POL NBR': null,
      'SUF CD': null,
      'ACCT CD': null,
      SD: null,
      'CHK NBR': null,
      'CPS#': null,
      'SUB TY CD': null,
      'DOL AMT': null,
      'SBSCR ID': null,
      'CHARGE DATE': null,
      'ISSUE DATE': null,
      'SERVICE DATE': null,
      'FNDNG ARNG': null,
      'LEGAL ENTITY': null,
      'CS FND ARG': null,
      'CLM SRC CD': null,
      'WK STAT': null,
      'MD ELG': null,
      'SUS STAT': null,
    };
    return columnNames;
  };

  const fieldLabelMappings = {
    POLICY_NBR: 'POL NBR',
    SFFX_CODE: 'SUF CD',
    ACCT_CODE: 'ACCT CD',
    SD: 'SD',
    CHECK: 'CHK NBR',
    CPS: 'CPS#',
    SUBLINE_TYPE_CODE: 'SUB TY CD',
    DOLLAR_AMOUNT: 'DOL AMT',
    SUBSCR_ID: 'SBSCR ID',
    CHARGE_DATE: 'CHARGE DATE',
    ISSUE_DATE: 'ISSUE DATE',
    SERVICE_DATE: 'SERVICE DATE',
    FNDNG_ARNG: 'FNDNG ARNG',
    LEGAL_ENTITY: 'LEGAL ENTITY',
    CASE_FUND_ARNG: 'CS FND ARG',
    CLAIM_SOURCE_CODE: 'CLM SRC CD',
    WORKING_STATUS: 'WK STAT',
    MED_ELIG: 'MD ELG',
    SUSPECT_STATUS: 'SUS STAT',
  };

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    let hours = today.getHours() % 12 || 12;
    const formattedHours = String(hours).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${formattedHours}${minutes}${seconds}`;
  };

  const downloadExcel = () => {
    let fullData = [];
    let downloadData =
      dataTablePropsPagination.reactTableProps?.selectedFlatRows?.length > 0
        ? dataTablePropsPagination.reactTableProps?.selectedFlatRows
        : dataTablePropsPagination?.rows;
    downloadData?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = fieldLabelMappings[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
      workbook,
      'Suspect Errors(Case Structure)_' + getFormattedDate() + '.xlsx'
    );
  };

  const dataTablePropsPagination = useDataTable({
    showPagination: true,
    initialData:
      policyNumberData?.pageItems === 'Not available' ||
      policyNumberData?.pageItems === 'Data not found'
        ? []
        : policyNumberData?.pageItems,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showFilterDataset: true,
    customHeaderButtons,
    showSelection: true,
    downloadButtonConfig: {
      custom: {
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
  });

  const deletePolicydata = async (key) => {
    const deleteRow = policyNumberData?.pageItems[key];
    try {
      await axios.post(
        `${BASE_URL}cmdb-service/update-susp-err-subline/${msid}`,

        deleteRow,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error deleting policy:', error);
    }
  };

  const openModal = () => {
    if (Object.keys(dataTablePropsPagination.state.selectedRowIds).length > 0) {
      setShowModal(true);
      setIsOpen(true);
    } else {
      setshowValidationCheck(true);
    }
  };

  const handleDelete = async () => {
    const keys = Object.keys(dataTablePropsPagination.state.selectedRowIds);
    keys.forEach(async (key) => {
      await deletePolicydata(key);
      setIsOpen(false);
      setShowAlert(true);
    });
  };

  useEffect(() => {
    if (selecteddata?.length == 1) {
      setSelectedRecords(`${selecteddata?.length} record`);
    } else if (selecteddata?.length == 0) {
      setSelectedRecords([]);
    } else {
      setSelectedRecords(`${selecteddata?.length} records`);
    }
  }, [selecteddata]);

  useEffect(() => {
    let selectedRows = [];
    dataTablePropsPagination.reactTableProps?.selectedFlatRows.forEach(
      (obj) => {
        selectedRows.push(obj.values);
      }
    );
    if (selectedRows?.length > 0 && !flag) {
      setSelectedData(selectedRows);
    }
  }, [dataTablePropsPagination.reactTableProps?.selectedFlatRows]);

  const selectedData = async () => {
    setFlag(true);
    dataTablePropsPagination.setData(selecteddata, false);
  };

  const viewAllData = async () => {
    dataTablePropsPagination.setData(policyNumberData?.pageItems, false);
    setFlag(false);
    setSelectedData([]);
  };

  useEffect(() => {
    if (policyNumberData?.pageItems?.length == 1) {
      setRecords(`${policyNumberData?.pageItems?.length} record`);
    } else {
      setRecords(`${policyNumberData?.pageItems?.length} records`);
    }
  }, [policyNumberData?.pageItems]);

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Box color="$white">
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    padding: '0px !important',
                  }}
                >
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Heading
                      offset={5}
                      textAlign="center"
                      css={{ margin: '0 auto', flexGrow: 1 }}
                    >
                      Suspect Errors By Subline
                    </Heading>
                    {/* </Grid.Col> */}
                    {/* <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}> */}
                    <Text
                      css={{
                        marginLeft: 'auto',
                        textAlign: 'right',
                        alignSelf: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      {currDate}
                    </Text>
                    {/* </Grid.Col> */}
                  </Grid.Col>
                </Grid>

                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    padding: '0px !important',
                  }}
                >
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={700}>Search Criteria Used:</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    {searchCriteria?.map((item) => (
                      <Text fontWeight={600}>
                        {item}
                        <br />
                      </Text>
                    ))}
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    padding: '0px !important',
                    textAlign: 'end',
                  }}
                ></Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={300} css={{ fontSize: '14px' }}>
                    {records}
                  </Text>{' '}
                  {selectedRecords?.length > 0 && (
                    <>
                      <Link onClick={selectedData}>{`${selectedRecords}`}</Link>{' '}
                      selected{' '}
                    </>
                  )}{' '}
                  {flag ? (
                    <Link onClick={viewAllData}>View All Records</Link>
                  ) : (
                    ''
                  )}
                </Grid.Col>

                <Grid css={{ margin: '0px !important' }}>
                  {/* {loading && <WrapperLoader />} */}
                  {policyNumberData?.pageItems && (
                    <div
                      style={{
                        height:
                          dataTablePropsPagination?.data?.length > 6
                            ? '550px'
                            : 'auto',
                      }}
                    >
                      <DataTable
                        title=""
                        hideTitleHeader
                        tableState={dataTablePropsPagination}
                      />
                    </div>
                  )}
                </Grid>

                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'left',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          borderRadius: '0px',
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={openModal}
                    >
                      Delete
                    </Button>
                    {showModal ? (
                      <React.Fragment>
                        <Modal
                          title="Confirm Delete Record"
                          isOpen={isOpen}
                          onClose={() => setIsOpen(false)}
                          titleAlign="center"
                        >
                          <Modal.Section titleAlign="center">
                            <Layout.Group alignLayout="center">
                              Are you sure you want to delete this record?
                            </Layout.Group>
                          </Modal.Section>
                          <Modal.Section>
                            <Layout.Group alignLayout="center">
                              <Button onClick={handleDelete}>OK</Button>
                              <Button onClick={() => setIsOpen(false)}>
                                Cancel
                              </Button>
                            </Layout.Group>
                          </Modal.Section>
                        </Modal>
                      </React.Fragment>
                    ) : null}
                    {showAlert ? (
                      <Alert
                        title="Error occured due to deletion of record"
                        variant="error"
                        onClose={() => setShowAlert(false)}
                      ></Alert>
                    ) : null}
                    {showValidationCheck ? (
                      <Alert
                        title="Please select a record to delete"
                        variant="error"
                        onClose={() => setshowValidationCheck(false)}
                      ></Alert>
                    ) : null}
                  </Grid.Col>
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(InquiryPolicyDetails, 'cmdb_update_inquiry');
