import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { getEnvironment } from '@src/utils/environmentUtil';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { constantColumns } from './column-constants';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { Navigate } from 'react-router-dom';
import { BASE_URL } from '@src/utils/endpoints';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import withAuth from '@src/auth/withAuth';

const Label = () => {
  const [policyNumber, setPolicyNumber] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token } = useAuth();
  const data = [];
  const [count, setCount] = useState(0);
  const [records, setRecords] = useState('0 records');

  //get column definition

  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );

  const fileName = location?.state?.systemSelectedValue;

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName.toLowerCase()}.xlsx`);
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName.toLowerCase()}.xlsx`);
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: data,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
  });
  const setData = async (tableData) => {
    let columnKeys = [];
    let columns = {};
    if (tableData?.length > 0) {
      columnKeys = Object.keys(tableData?.[0]);
      columns = columnKeys.map((key, index) => ({
        Header: key,
        accessor: key,
      }));
    }
    if (Array.isArray(tableData)) {
      getTableState.setColumns(columns, true);
      getTableState.setData(tableData, true);
      setCount(tableData.length);
    }
  };
  const onLookup = async () => {
    setErrorMsg('');
    if (policyNumber === '') {
      setErrorMsg('Policy Number is required field.');
      return;
    }
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
      policyNumber,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/getbypolicy/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{ marginTop: '135px', marginLeft: '12px' }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Grid css={{ marginBottom: '10px' }}>
                    <Grid.Col span={{ xs: 6 }} css={{ display: 'flex' }}>
                      <TextInput
                        label={'Policy Number'}
                        css={{ width: '100px' }}
                        value={policyNumber}
                        onChange={(e) => setPolicyNumber(e.target.value)}
                        errorMessage={errorMsg}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            onLookup(event);
                          }
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col>
                      <Button onClick={onLookup} css={{ marginTop: '25px' }}>
                        Look up
                      </Button>
                    </Grid.Col>
                  </Grid>
                  <Text
                    css={{
                      fontWeight: 600,
                      fontSize: '18px',
                    }}
                  >
                    Database:
                  </Text>
                  <span>
                    {' '}
                    {getEnvironment()} - {location?.state?.database}
                  </span>

                  <Text
                    css={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: '20px',
                    }}
                  >
                    Table:
                  </Text>
                  <span>{location?.state?.systemSelectedValue}</span>
                </Grid.Col>
                <br />
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={300} css={{ fontSize: '14px' }}>
                    {records}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(Label, 'table_cts_ro_rw');
