import React, { useState, useEffect } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { usePrint } from '@abyss/web/hooks/usePrint';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Layout } from '@abyss/web/ui/Layout';
import withAuth from '@src/auth/withAuth';
import { WrapperDataTable } from '@src/common/WrapperDataTable/WrapperDataTable';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';
import useAuth from '@src/utils/useAuth';
import { currentDateFormat } from '@src/components/DateUtils';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';

const RequestsRequiringFinancialApproval = () => {
  const { navigate } = useRouter();
  const print = usePrint();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { readOnly, token } = useAuth();
  const date = new Date();

  useEffect(() => {
    const payload = {
      screenType: 'F',
      pageNumber: 1,
      recordsPerPage: 10,
    };

    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}cmdb/inquiry/search`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data === 'No Records Found') setTableData([]);
        else setTableData(response.data);
        setIsLoading(false);
      } catch (error) {
        // add-alert-popup
      }
    };
    if (token) fetchData();
  }, [token]);

  const path = '/cmdb/correction-management';
  const LinkCellRenderer = ({ value }) => {
    return (
      <Link
        href={`/cmdb/correction-management/view-correction-request-detail/${value}`}
      >
        {value}
      </Link>
    );
  };

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    let hours = today.getHours() % 12 || 12;
    const formattedHours = String(hours).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${formattedHours}${minutes}${seconds}`;
  };

  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'Rqst Id',
        accessor: 'RQST_ID',
        Cell: ({ value }) => <LinkCellRenderer value={value} />,
      },
      {
        Header: 'Rqst Date		',
        accessor: 'RQST_DT',
        width: 200,
      },
      {
        Header: 'Pol Nbr	',
        accessor: 'POL_NBR',
      },
      {
        Header: 'Sufx Code	',
        accessor: 'SUFX_CD',
      },
      {
        Header: 'Status',
        accessor: 'STS_TXT',
      },
      {
        Header: 'Message		',
        accessor: 'MESSAGE_TXT',
      },
      {
        Header: '# Records Extracted',
        accessor: 'RQST_TRAN_CNT',
      },
      {
        Header: 'Rqst User Nm',
        accessor: 'RQST_USER_SHORT_NM',
      },
      {
        Header: 'Request Type',
        accessor: 'RQST_TXT',
      },
      {
        Header: 'Event Type',
        accessor: 'EVENT_TXT',
      },
      {
        Header: 'Last Uptd User Nm',
        accessor: 'LAST_USER_SHORT_NM',
      },
      {
        Header: 'Last Uptd Date',
        accessor: 'LAST_UPDT_DT',
        width: 200,
      },
      {
        Header: 'Auto Gen',
        accessor: 'SYS_AUTO_PATH_IND',
      },
    ],

    []
  );

  const tableConfig = {
    showPagination: true,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showFilterDataset: true,
  };

  const handleClick = () => {
    navigate(`${path}/inquiry-result`);
  };
  const handleBackButton = () => {
    navigate(path);
  };
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
            marginLeft: '100px',
          }}
        >
          <Card
            css={{
              marginTop: '70px',
              marginLeft: '100px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    View Correction Request
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '10px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Request Requiring Financial Approval (All)
                  </Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Flex justify="space-between">
                    <Text css={{ fontWeight: 'bold' }}>
                      Click on Request Id to view detail
                    </Text>
                    <Label size="md" id="first name">
                      {currentDateFormat(date)}
                    </Label>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  borderTop: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  style={{ marginTop: '-15px' }}
                >
                  <span
                    style={{
                      position: 'relative',
                      top: '35px',
                      marginLeft: '16px',
                    }}
                  >
                    {tableData?.length || 0} Records
                  </span>
                  {isLoading && <WrapperLoader />}
                  {!isLoading && (
                    <WrapperDataTable
                      title="View Financial Approval Request"
                      data={tableData}
                      columns={columns}
                      fileName={
                        'CMDB-Requests Requiring Financial Approval (All)_' +
                        getFormattedDate() +
                        '.xlsx'
                      }
                    />
                  )}
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '180px',
                      },
                    }}
                  >
                    Modify Search
                  </Button>
                  <Button
                    onClick={handleBackButton}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                        width: '180px',
                      },
                    }}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(RequestsRequiringFinancialApproval, 'cmdb_update');
