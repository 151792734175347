import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Modal } from '@abyss/web/ui/Modal';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { getEnvironment } from '@src/utils/environmentUtil';
import withAuth from '@src/auth/withAuth';
import { Alert } from '@abyss/web/ui/Alert';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { BASE_URL } from '@src/utils/endpoints';
import { constantColumns } from './column-constants';
import { Flex } from '@abyss/web/ui/Flex';
import env from '../../../env.json';

const Company = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, msid, userRolesGroup } = useAuth();
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [row, setRow] = useState({});
  const [records, setRecords] = useState('0 records');

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [jsonData, setJsonData] = useState(location?.state?.data);
  const [isCOCDVisible, setIsCOCDVisible] = useState(false);

  useEffect(() => {
    if (isAlertVisible) {
      const timer = setTimeout(() => {
        setIsAlertVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isAlertVisible]);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const deleteRows = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setShowModal(false);
    let payload1 = {
      coCd: row['CO_CD'],
      fundArngCd: row['FUND_ARNG_CD'],
      fundArngGrpCd: row['FUND_ARNG_GRP_CD'],
      legEntyGrpCd: row['LEG_ENTY_GRP_CD'],
      glReinsCd: row['GL_REINS_CD'],
      coCdTxt: row['CO_CD_TXT'],
      mbrLegEntyCd: row['MBR_LEG_ENTY_CD'],
      lstUpdtDt: row['LSTUPDTDT'],
      lstUpdtTm: row['LST_UPDT_TM'],
      lstUpdtId: row['LST_UPDT_ID'],
      updtInd: row['UPDT_IND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/company/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 5000);
        setAlertMsg('You have successfully deleted the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };

  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };

  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateRow, setUpdateRow] = useState({});
  const [cocd, setcocd] = useState('');
  const [fundArngcd, setfundArngcd] = useState('');
  const [fundArngGpcd, setfundArngGpcd] = useState('');
  const [legEntyGrpCd, setlegEntyGrpCd] = useState('');
  const [glReinsCd, setglReinsCd] = useState('');
  const [cocdtxt, setcocdtxt] = useState('');
  const [mbrLegEnty, setmbrLegEnt] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate || hideAdd ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setUpdateFlag(true);
    setHideAdd(true);
    setFlag(true);
    setUpdateRow(row);
    setcocd(row?.['CO_CD']);
    setfundArngcd(row?.['FUND_ARNG_CD']);
    setfundArngGpcd(row?.['FUND_ARNG_GRP_CD']);
    setlegEntyGrpCd(row?.['LEG_ENTY_GRP_CD']);
    setglReinsCd(row?.['GL_REINS_CD']);
    setcocdtxt(row['CO_CD_TXT']);
    setmbrLegEnt(row['MBR_LEG_ENTY_CD']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };

  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'company.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'company.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions:
      hasRWaccess &&
      (hideUpdate || hideAdd
        ? [deleteLinkButton]
        : [updateLinkButton, deleteLinkButton]),
  });

  const validationCheck = () => {
    let existingCO_CD_Check = location?.state?.data;
    let existsCO_CD = existingCO_CD_Check.some((row) => row['CO_CD'] === cocd);

    if (cocd === '') {
      setIsAlertVisible(true);
    } else if (existsCO_CD === true) {
      setIsCOCDVisible(true);
    } else {
      setSaveModal(true);
    }
  };
  const addRow = () => {
    setHideUpdate(true);
    setFlag(true);
    setUpdateFlag(false);
  };
  const handleClick = () => {
    navigate(-1);
  };
  const save = async () => {
    setFlag(false);
    setHideUpdate(false);
    const now = new Date();
    let payload = {
      coCd: cocd,
      fundArngCd: fundArngcd,
      fundArngGrpCd: fundArngGpcd,
      legEntyGrpCd: legEntyGrpCd,
      glReinsCd: glReinsCd,
      coCdTxt: cocdtxt,
      mbrLegEntyCd: mbrLegEnty,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: msid.toUpperCase(),
      lstUpdtTm: now.toLocaleTimeString('en-US', { hour12: false }),
      updtInd: 'Y',
    };
    setcocd(cocd);
    setfundArngcd(fundArngcd);
    setfundArngGpcd(fundArngGpcd);
    setlegEntyGrpCd(legEntyGrpCd);
    setglReinsCd(glReinsCd);
    setcocdtxt(cocdtxt);
    setmbrLegEnt(mbrLegEnty);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/company/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setSaveModal(false);
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 5000);
        setAlertMsg('You have successfully added record to table');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setJsonData(tableData);
      }
    } catch (error) {
      // add-alert-popup
      setSaveModal(false);
      return {};
    }
  };
  const validateUpdate = () => {
    let valid = true;
    return valid;
  };
  const update = async () => {
    if (!validateUpdate()) return;
    setHideAdd(false);
    setFlag(false);
    const now = new Date();
    let payload = {
      newRecord: {
        coCd: cocd,
        fundArngCd: fundArngcd,
        fundArngGrpCd: fundArngGpcd,
        legEntyGrpCd: legEntyGrpCd,
        glReinsCd: glReinsCd,
        coCdTxt: cocdtxt,
        mbrLegEntyCd: mbrLegEnty,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: msid,
        lstUpdtTm: now.toLocaleTimeString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
      oldRecord: {
        coCd: updateRow['CO_CD'],
        fundArngCd: updateRow['FUND_ARNG_CD'],
        fundArngGrpCd: updateRow['FUND_ARNG_GRP_CD'],
        legEntyGrpCd: updateRow['LEG_ENTY_GRP_CD'],
        glReinsCd: updateRow['GL_REINS_CD'],
        coCdTxt: updateRow['CO_CD_TX'],
        mbrLegEntyCd: updateRow['MBR_LEG_ENTY_CD'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: updateRow['UPDT_IND'],
      },
    };
    setUpdateConfirmModal(false);
    setcocd('');
    setfundArngcd('');
    setfundArngGpcd('');
    setlegEntyGrpCd('');
    setglReinsCd('');
    setcocdtxt('');
    setmbrLegEnt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/company/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 5000);
        setAlertMsg('You have successfully updated the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const close = () => {
    setUpdateFlag(false);
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setcocd('');
    setfundArngcd('');
    setfundArngGpcd('');
    setlegEntyGrpCd('');
    setglReinsCd('');
    setcocdtxt('');
    setmbrLegEnt('');
  };
  const close1 = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
  };

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);

  useEffect(() => {
    const hasTableCTSReadWriteAccess = env.Table_CTS_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    setHasRWaccess(hasTableCTSReadWriteAccess);
  }, [userRolesGroup]);

  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Delete Record"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Section>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>CO_CD: </b>
              </span>
              <span>
                <b>{row['CO_CD']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>FUND_ARNG_CD: </b>
              </span>
              <span>
                <b>{row['FUND_ARNG_CD']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>FUND_ARNG_GRP_CD: </b>
              </span>
              <span>
                <b>{row['FUND_ARNG_GRP_CD']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>LEG_ENTY_GRP_CD: </b>
              </span>
              <span>
                <b>{row['LEG_ENTY_GRP_CD']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>GL_REINS_CD: </b>
              </span>
              <span>
                <b>{row['GL_REINS_CD']}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>CO_CD_TXT: </b>
              </span>
              <span>
                <b>{row['CO_CD_TXT']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>MBR_LEG_ENTY_CD: </b>
              </span>
              <span>
                <b>{row['MBR_LEG_ENTY_CD']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_DT: </b>
              </span>
              <span>
                <b>{row['LSTUPDTDT']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_TM: </b>
              </span>
              <span>
                <b>{row['LST_UPDT_TM']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>LST_UPDT_ID: </b>
              </span>
              <span>
                <b>{row['LST_UPDT_ID']}</b>
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '50%' }}>
                <b>UPDT_IND: </b>
              </span>
              <span>
                <b>{row['UPDT_IND']}</b>
              </span>
            </div>
            <br />
            Are you sure you want to delete this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={deleteRows}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
          size="md"
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateConfirmModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal isOpen={updateModal} onClose={() => setUpdateModal(false)}>
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>CO_CD:</b>
                {updateRow['CO_CD']}
              </span>
              <span>
                <b>CO_CD:</b>
                {cocd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>FUND_ARNG_CD:</b>
                {updateRow['FUND_ARNG_CD']}
              </span>
              <span>
                <b>FUND_ARNG_CD:</b>
                {fundArngcd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>FUND_ARNG_GRP_CD:</b>
                {updateRow['FUND_ARNG_GRP_CD']}
              </span>
              <span>
                <b>FUND_ARNG_GRP_CD:</b>
                {fundArngGpcd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>LEG_ENTY_GRP_CD:</b>
                {updateRow['LEG_ENTY_GRP_CD']}
              </span>
              <span>
                <b>LEG_ENTY_GRP_CD:</b>
                {legEntyGrpCd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>GL_REINS_CD:</b>
                {updateRow['GL_REINS_CD']}
              </span>
              <span>
                <b>GL_REINS_CD:</b>
                {glReinsCd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>CO_CD_TXT:</b>
                {updateRow['CO_CD_TXT']}
              </span>
              <span>
                <b>CO_CD_TXT:</b>
                {cocdtxt}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>MBR_LEG_ENTY_CD:</b>
                {updateRow['MBR_LEG_ENTY_CD']}
              </span>
              <span>
                <b>MBR_LEG_ENTY_CD:</b>
                {mbrLegEnty}
              </span>
            </div>
            <br />
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
          size="md"
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={11} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '220px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '90px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                title={alertMsg}
                variant="success"
                isVisible={isVisible}
                onClose={() => {
                  setIsVisible(false);
                }}
              />
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>{' '}
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                <Text fontWeight={300} css={{ fontSize: '14px' }}>
                  {records}
                </Text>
              </Grid.Col>
              {hasRWaccess && (
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={flag || hideAdd ? true : false}
                  css={{ marginLeft: '-5px' }}
                >
                  + Add Row
                </Button>
              )}
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Alert
                      title="Please enter valid CO_CD."
                      variant="info"
                      isVisible={isAlertVisible}
                      onClose={() => {
                        setIsAlertVisible(false);
                      }}
                    />
                    <Alert
                      title="CO_CD already exists in table."
                      variant="info"
                      isVisible={isCOCDVisible}
                      onClose={() => {
                        setIsCOCDVisible(false);
                      }}
                    />
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="CO_CD"
                        maxLength={2}
                        value={cocd}
                        isDisabled={updateFlag}
                        onChange={(e) => setcocd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="FUND_ARNG_CD"
                        value={fundArngcd}
                        maxLength={1}
                        onChange={(e) => setfundArngcd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="FUND_ARNG_GRP_CD"
                        value={fundArngGpcd}
                        maxLength={2}
                        onChange={(e) => setfundArngGpcd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="LEG_ENTY_GRP_CD"
                        maxLength={5}
                        value={legEntyGrpCd}
                        onChange={(e) => setlegEntyGrpCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="GL_REINS_CD"
                        value={glReinsCd}
                        maxLength={2}
                        onChange={(e) => setglReinsCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="CO_CD_TXT"
                        value={cocdtxt}
                        maxLength={30}
                        onChange={(e) => setcocdtxt(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="MBR_LEG_ENTY_CD"
                        value={mbrLegEnty}
                        maxLength={3}
                        onChange={(e) => setmbrLegEnt(e.target.value)}
                      />
                    </Grid.Col>
                  </Grid>
                  <Flex direction="row" style={{ margin: '20px' }}>
                    <Button
                      onClick={
                        updateFlag
                          ? () => setUpdateModal(true)
                          : () => validationCheck()
                      }
                    >
                      Save
                    </Button>

                    <Button
                      onClick={updateFlag ? close : close1}
                      style={{ marginLeft: '20px' }}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '-70px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(Company, 'table_cts_ro_rw');
