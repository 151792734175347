import React, { useEffect, useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { getEnvironment } from '@src/utils/environmentUtil';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import useAuth from '@src/utils/useAuth';
import { Modal } from '@abyss/web/ui/Modal';
import { Alert } from '@abyss/web/ui/Alert';
import { TextInput } from '@abyss/web/ui/TextInput';
import env from '../../../env.json';

const SecurityUser = () => {
  const { getLocation } = useRouter();
  const location = getLocation();
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { token, userName, userRolesGroup } = useAuth();
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const [row, setRow] = useState({});
  const [records, setRecords] = useState('0 records');
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  const deleteRows = async (payload) => {
    setShowModal(false);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/sec/delete/secUser/${location?.state?.database}`,
        row,
        config
      );
      if (response.status === 200) {
        setIsVisible(true);
        setAlertMsg('You have successfully deleted the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setJsonData(tableData);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [userShortNm, setuserShortNm] = useState('');
  const [fstNm, setFstNm] = useState('');
  const [lstNm, setLstNm] = useState('');
  const [depNm, setDepNm] = useState('');
  const [userNtId, setUserNtId] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [userNameErr, setUserNameErr] = useState('');
  const [fstNmErr, setFstNmErr] = useState('');
  const [lstNmErr, setLstNmErr] = useState('');
  const [depNmErr, setDepNmErr] = useState('');
  const [userNtIdErr, setUserNtIdErr] = useState('');
  const [jsonData, setJsonData] = useState(location?.state?.data);
  const getTableData1 = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}fadb/sec/get/secUser/${location?.state?.database}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    clearFields();
    setHideAdd(true);
    setFlag(true);
    setUpdateFlag(true);
    setUpdateRow(row);
    setuserShortNm(row?.['userShortNm']);
    setFstNm(row?.['fstNm']);
    setLstNm(row?.['lstNm']);
    setDepNm(row?.['depNm']);
    setUserNtId(row?.['userNtId']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    columnNames = {
      USER_SHORT_NM: null,
      FST_NM: null,
      LST_NM: null,
      DEF_NM: null,
      USER_NT_ID: null,
    };
    return columnNames;
  };

  const columnNameMapping = {
    userShortNm: 'USER_SHORT_NM',
    fstNm: 'FST_NM',
    lstNm: 'LST_NM',
    depNm: 'DEF_NM',
    userNtId: 'USER_NT_ID',
  };

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'sec_user.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'sec_user.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions:
      hasRWaccess &&
      (hideUpdate ? [deleteLinkButton] : [updateLinkButton, deleteLinkButton]),
  });
  const addRow = () => {
    clearFields();
    setFlag(true);
    setHideUpdate(true);
  };
  const handleClick = () => {
    window.location.href = '/ftmf/fadb';
  };
  const validateSave = () => {
    let valid = true;
    if (userShortNm) {
      setuserShortNm(userShortNm.toLocaleUpperCase().trim());
    }
    if (userShortNm === '') {
      setUserNameErr('Please enter valid User Short Name.');
      valid = false;
    }
    if (fstNm) {
      setFstNm(fstNm.toLocaleUpperCase().trim());
    }

    if (fstNm === '') {
      setFstNmErr('Please enter First Name.');
      valid = false;
    }
    if (lstNm) {
      setLstNm(lstNm.toLocaleUpperCase().trim());
    }
    if (lstNm === '') {
      setLstNmErr('Please enter Last Name.');
      valid = false;
    }
    if (depNm) {
      setDepNm(depNm.toLocaleUpperCase().trim());
    }
    if (depNm === '') {
      setDepNmErr('Please enter Dept Name.');
      valid = false;
    }
    if (userNtId) {
      setUserNtId(userNtId.trim());
    }
    if (userNtId === '') {
      setUserNtIdErr('Please enter NT ID.');
      valid = false;
    }

    if (
      jsonData.some((item) => item.userShortNm.trim() === userShortNm.trim())
    ) {
      setErrorAlert(true);
      setErrorMessage(userShortNm + ' already exists on SEC_USER Table.');

      valid = false;
    }

    return valid;
  };
  const validate = () => {
    let valid = true;

    if (fstNm) {
      setFstNm(fstNm.toLocaleUpperCase().trim());
    }
    if (fstNm === '') {
      setFstNmErr('Please enter First Name.');
      valid = false;
    }
    if (lstNm) {
      setLstNm(lstNm.toLocaleUpperCase().trim());
    }
    if (lstNm === '') {
      setLstNmErr('Please enter Last Name.');
      valid = false;
    }
    if (depNm) {
      setDepNm(depNm.toLocaleUpperCase().trim());
    }
    if (depNm === '') {
      setDepNmErr('Please enter Dept Name.');
      valid = false;
    }
    if (userNtId) {
      setUserNtId(userNtId.trim());
    }
    if (userNtId === '') {
      setUserNtIdErr('Please enter NT ID.');
      valid = false;
    }

    const isMatch = jsonData.some(
      (item) =>
        fstNm.trim() === item.fstNm.trim() &&
        lstNm.trim() === item.lstNm.trim() &&
        depNm.trim() === item.depNm.trim() &&
        userNtId.trim() === item.userNtId.trim()
    );
    if (isMatch) {
      setErrorAlert(true);
      setErrorMessage('Nothing changed for update.');
      setFlag(false);
      setHideUpdate(false);
      setHideAdd(false);
      valid = false;
    }
    return valid;
  };
  const clearFields = () => {
    setErrorAlert(false);
    setFstNmErr('');
    setLstNmErr('');
    setDepNmErr('');
    setUserNameErr('');
    setDepNmErr('');
    setUserNtIdErr('');
  };
  const save = async () => {
    clearFields();
    setSaveModal(false);
    setHideUpdate(false);
    if (validateSave()) {
      setFlag(false);
      let payload = {
        userShortNm: userShortNm,
        fstNm: fstNm,
        lstNm: lstNm,
        depNm: depNm,
        userNtId: userNtId,
      };
      setuserShortNm('');
      setFstNm('');
      setLstNm('');
      setDepNm('');
      setUserNtId('');
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${BASE_URL}fadb/sec/add/secUser/${location?.state?.database}`,
          payload,
          config
        );
        if (response.status === 200) {
          setIsVisible(true);
          setAlertMsg('You have successfully added record to table');
          let tableData = await getTableData1();
          getTableState.setData(tableData || [], true);
          setJsonData(tableData);
        }
      } catch (error) {
        // add-alert-popup
        return {};
      }
    }
  };
  const update = async () => {
    setHideAdd(false);
    setUpdateModal(false);
    setUpdateConfirmModal(false);
    setUpdateFlag(false);
    clearFields();
    if (validate()) {
      setFlag(false);
      let payload = {
        newRecord: {
          userShortNm: userShortNm,
          fstNm: fstNm,
          lstNm: lstNm,
          depNm: depNm,
          lstUpdtId: userName,
          userNtId: userNtId,
        },
        oldRecord: {
          userShortNm: updateRow['userShortNm'],
          fstNm: updateRow['fstNm'],
          lstNm: updateRow['lstNm'],
          depNm: updateRow['depNm'],
          userNtId: updateRow['userNtId'],
        },
      };
      setuserShortNm('');
      setUpdateConfirmModal(false);
      setFstNm('');
      setLstNm('');
      setDepNm('');
      setUserNtId('');
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${BASE_URL}fadb/sec/update/secUser/${location?.state?.database}`,
          payload,
          config
        );
        if (response.status === 200) {
          setIsVisible(true);
          setAlertMsg('You have successfully updated the record');
          let tableData = await getTableData1();
          getTableState.setData(tableData || [], true);
          setJsonData(tableData);
        }
      } catch (error) {
        // add-alert-popup
        return {};
      }
    }
  };
  const close = () => {
    setHideUpdate(false);
    setHideAdd(false);
    setFlag(false);
    // setUpdateFlag(!updateFlag);
    setuserShortNm('');
    setFstNm('');
    setLstNm('');
    setDepNm('');
    setUserNtId('');
  };
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  useEffect(() => {
    if (getTableState.filter?.filteredRows?.length == 1) {
      setRecords(`${getTableState.filter?.filteredRows?.length} record`);
    } else {
      setRecords(`${getTableState.filter?.filteredRows?.length} records`);
    }
  }, [getTableState?.filter?.filteredRows]);

  useEffect(() => {
    const hasTableFADBReadWriteAccess = env.Table_FADB_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    setHasRWaccess(hasTableFADBReadWriteAccess);
  }, [userRolesGroup]);

  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update"
          isOpen={updateModal}
          onClose={() => setUpdateModal(false)}
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>USER_SHORT_NM:</b>
                {updateRow?.['userShortNm']}
              </span>
              <span>
                <b>USER_SHORT_NM:</b>
                {userShortNm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>FST_NM:</b>
                {updateRow?.['fstNm']}
              </span>
              <span>
                <b>FST_NM:</b>
                {fstNm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>LST_NM:</b>
                {updateRow?.['lstNm']}
              </span>
              <span>
                <b>LST_NM:</b>
                {lstNm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>DEP_NM:</b>
                {updateRow?.['depNm']}
              </span>
              <span>
                <b>DEP_NM:</b>
                {depNm}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>USER_NT_ID:</b>
                {updateRow?.['userNtId']}
              </span>
              <span>
                <b>USER_NT_ID:</b>
                {userNtId}
              </span>
            </div>
            <br />
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Delete Record"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Section>
            <b>
              USER_SHORT_NM: {row?.['userShortNm']}
              <br />
              FST_NM: {row?.['fstNm']}
              <br />
              LST_NM: {row?.['lstNm']}
              <br />
              DEP_NM: {row?.['depNm']}
              <br />
              USER_NT_ID: {row?.['userNtId']}
              <br />
            </b>
            Are you sure you want to delete this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={deleteRows}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                title={alertMsg}
                variant="success"
                isVisible={isVisible}
                onClose={() => {
                  setIsVisible(false);
                }}
              />

              <Alert
                title={errorMessage}
                variant="error"
                isVisible={errorAlert}
                onClose={() => {
                  setErrorAlert(false);
                }}
              />
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                <Text fontWeight={300} css={{ fontSize: '14px' }}>
                  {records}
                </Text>
              </Grid.Col>
              <br />
              {hasRWaccess && (
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={flag || hideAdd ? true : false}
                >
                  + Add Row
                </Button>
              )}
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                    span={{ xs: 8 }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="USER_SHORT_NM"
                        value={userShortNm}
                        isDisabled={updateFlag}
                        onChange={(e) => setuserShortNm(e.target.value)}
                        maxLength={10}
                        errorMessage={userNameErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="FST_NM"
                        value={fstNm}
                        onChange={(e) => setFstNm(e.target.value)}
                        maxLength={10}
                        errorMessage={fstNmErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="LST_NM"
                        maxLength={20}
                        value={lstNm}
                        onChange={(e) => setLstNm(e.target.value)}
                        errorMessage={lstNmErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="DEP_NM"
                        value={depNm}
                        onChange={(e) => setDepNm(e.target.value)}
                        maxLength={20}
                        errorMessage={depNmErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="USER_NT_ID"
                        value={userNtId}
                        maxLength={8}
                        onChange={(e) => setUserNtId(e.target.value)}
                        errorMessage={userNtIdErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button
                        onClick={() => {
                          clearFields();
                          if (updateFlag) {
                            if (validate()) {
                              setUpdateModal(true);
                            }
                          } else if (validateSave()) {
                            setSaveModal(true);
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(SecurityUser, 'table_fadb_ro_rw');
