import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { Heading } from '@abyss/web/ui/Heading';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';

const CTS = () => {
  const [databaseDropdown, setDatabaseDropdown] = useState([]);
  const { navigate } = useRouter();
  const tables = [
    {
      id: 2,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS Contract \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS_CONTR',
      value: 'ATS_CONTR',
    },
    {
      id: 3,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS Customer \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS_CUST',
      value: 'ATS_CUST',
    },
    {
      id: 4,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS Legal Entity \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS_LGL_ENTY',
      value: 'ATS_LGL_ENTY',
    },
    {
      id: 5,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS Oblig \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS_OBLIG',
      value: 'ATS_OBLIG',
    },
    {
      id: 6,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS Plan \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS_PLN',
      value: 'ATS_PLN',
    },
    {
      id: 7,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS Plan Variation Rpt Code \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS_PVRC',
      value: 'ATS_PVRC',
    },
    {
      id: 8,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS Shared Arrangement \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ATS_SHRD_ARNG',
      value: 'ATS_SHRD_ARNG',
    },
    {
      id: 9,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Bypass \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 BUS_PROC_BYP_RULE',
      value: 'BUS_PROC_BYP_RULE',
    },
    // { id: 10, label: 'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Business Segment \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 BUSEG', value: 'BUSEG' },
    {
      id: 11,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Company Code \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 COMPANY',
      value: 'COMPANY',
    },
    {
      id: 12,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL Accounts \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL_ACCT',
      value: 'GL_ACCT',
    },
    {
      id: 13,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL Business Unit \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL_BUS_UNIT',
      value: 'GL_BUS_UNIT',
    },
    // { id: 14, label: 'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL Customer Derivation \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL_CUST_DERV', value: 'GL_CUST_DERV' },
    // { id: 15, label: 'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL Financial Arrangement \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL_FIN_ARRANGE', value: 'GL_FIN_ARRANGE' },
    {
      id: 16,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL Function \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL_FUNC',
      value: 'GL_FUNC',
    },
    {
      id: 17,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL Product \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 GL_PRDCT',
      value: 'GL_PRDCT',
    },
    {
      id: 18,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 HMO Acct \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 HMOACCT',
      value: 'HMOACCT',
    },
    {
      id: 19,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Label (inq. only) \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 LABEL',
      value: 'LABEL',
    },
    {
      id: 20,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Market Location \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 MARKET',
      value: 'MARKET',
    },
    {
      id: 21,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Market Segmentation \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 FINC_POL_MKT_SEG',
      value: 'FINC_POL_MKT_SEG',
    },
    {
      id: 22,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Financial Market Segment Indicator \xa0  FINANCIAL_MARKET_SEGMENT_INDICATOR',
      value: 'FINANCIAL_MARKET_SEGMENT_INDICATOR',
    },
    {
      id: 23,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Member Legal Entity \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 MBR_LEG_ENTY',
      value: 'MBR_LEG_ENTY',
    },
    // { id: 23, label: 'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 NHA Policy \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 NHA_POLICY', value: 'NHA_POLICY' },
    {
      id: 24,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Product \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 PRODUCT',
      value: 'PRODUCT',
    },
    {
      id: 25,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Report Policy (inq. only) \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 RPT_POLICY',
      value: 'RPT_POLICY',
    },
    {
      id: 26,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Shared Arrangement \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 SHARED_ARRANGEMENT',
      value: 'SHARED_ARRANGEMENT',
    },
    {
      id: 27,
      label:
        'CTS \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 State Code \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 STATE',
      value: 'STATE',
    },
  ];
  const { token } = useAuth();
  const [database, setSelectedDatabase] = useState('');
  const [transformedData, setTransformedData] = useState([]);
  useEffect(() => {
    if (databaseDropdown?.length > 0) {
      const transformedData1 = databaseDropdown.map((item) => ({
        value: item['NAME'],
        label: item['NAME'],
      }));
      setTransformedData(transformedData1);
      setSelectedDatabase(transformedData1[0].label);
    }
  }, [databaseDropdown]);
  const handleDynamicDropdown = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/home/`,
        { queryString: '5065' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setDatabaseDropdown(response.data);
      }
    } catch (error) {
      // add-alert-popup
    }
  };
  const getTableData1 = async () => {
    let payload = [database, systemSelectedValue];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const getTableData2 = async () => {
    let payload = [database, systemSelectedValue, ''];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/getbypolicy/${systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);
  const [systemSelectedValue, setSystemSelectedValue] = useState(
    tables[0].value
  );
  const handleSelect = async () => {
    if (systemSelectedValue === 'MBR_LEG_ENTY') {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/cts/health-plan-site/mbr-leg-enty', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    } else if (systemSelectedValue === 'FINANCIAL_MARKET_SEGMENT_INDICATOR') {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/cts/health-plan-site/fin-mar-seg-ind', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    } else if (systemSelectedValue === 'BUS_PROC_BYP_RULE') {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/cts/health-plan-site/bypass', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    } else if (systemSelectedValue === 'COMPANY') {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate(
        `/ftmf/cts/company/systemType-${systemSelectedValue}/database-${database}`,
        {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,
            columns: columns,
            data: tableData,
          },
        }
      );
    } else if (systemSelectedValue === 'SHARED_ARRANGEMENT') {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/cts/health-plan-site/shared-arrangment', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    } else if (systemSelectedValue === 'HMOACCT') {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/cts/health-plan-site/hmoacct', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    } else if (systemSelectedValue === 'ATS_PVRC') {
      getTableData2();
      navigate(
        `/ftmf/cts/ats-pvrc/systemType-${systemSelectedValue}/database-${database}`,
        {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,
          },
        }
      );
    } else if (
      systemSelectedValue === 'ATS_CONTR' ||
      systemSelectedValue === 'ATS_CUST' ||
      systemSelectedValue === 'ATS_PLN'
    ) {
      getTableData2();
      navigate(
        `/ftmf/cts/${systemSelectedValue
          .toLowerCase()
          .replace(
            /_/g,
            '-'
          )}/systemType-${systemSelectedValue}/database-${database}`,
        {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,
          },
        }
      );
    } else if (systemSelectedValue === 'PRODUCT') {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/cts/health-plan-site/product', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    } else if (
      systemSelectedValue === 'ATS_LGL_ENTY' ||
      systemSelectedValue === 'ATS_OBLIG' ||
      systemSelectedValue === 'ATS_SHRD_ARNG' ||
      systemSelectedValue === 'GL_ACCT' ||
      systemSelectedValue === 'GL_BUS_UNIT' ||
      systemSelectedValue === 'GL_FUNC' ||
      systemSelectedValue === 'GL_PRDCT'
    ) {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate(
        `/ftmf/cts/${systemSelectedValue
          .toLowerCase()
          .replace(
            /_/g,
            '-'
          )}/systemType-${systemSelectedValue}/database-${database}/data`,
        {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,
            columns: columns,
            data: tableData,
          },
        }
      );
    } else if (systemSelectedValue === 'FINC_POL_MKT_SEG') {
      navigate(
        `/ftmf/cts/finc-pol-mkt-seg/systemType-${systemSelectedValue}/database-${database}`,
        {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,
          },
        }
      );
    } else if (systemSelectedValue === 'LABEL') {
      navigate(
        `/ftmf/cts/label/systemType-${systemSelectedValue}/database-${database}`,
        {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,
          },
        }
      );
    } else if (
      systemSelectedValue !== 'RPT_POLICY' &&
      systemSelectedValue !== 'MARKET' &&
      systemSelectedValue !== 'STATE'
    ) {
      getTableData2();
      navigate('/ftmf/cts/health-plan-site-lookup', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
        },
      });
    } else if (systemSelectedValue === 'MARKET') {
      navigate(
        `/ftmf/cts/market/systemType-${systemSelectedValue}/database-${database}`,
        {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,
          },
        }
      );
    } else if (systemSelectedValue === 'STATE') {
      navigate('/ftmf/cts/health-plan-site/state', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
        },
      });
    } else if (systemSelectedValue === 'RPT_POLICY') {
      navigate('/ftmf/cts/health-plan-site/rpt', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
        },
      });
    }
  };
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '40px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white" css={{ padding: '2rem' }}>
              <Grid
                css={{
                  marginTop: '30px',
                  marginLeft: '20px',
                }}
              >
                <Heading offset={5} css={{ marginBottom: '10px' }}>
                  Financial Tables Table Selection Screen
                </Heading>
                <SelectInput
                  label={
                    'System\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Screen\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Table'
                  }
                  placeholder="Pick one"
                  isClearable
                  isSearchable
                  value={systemSelectedValue}
                  onChange={(e) => setSystemSelectedValue(e)}
                  // onChange={setDefaultTypeOfEffectiveDate}
                  options={tables}
                />
                <SelectInput
                  label="Database"
                  placeholder="Pick one"
                  isClearable
                  isSearchable
                  onChange={(e) => setSelectedDatabase(e)}
                  value={database}
                  options={transformedData}
                />
                <Button
                  css={{
                    'abyss-button-root': { marginTop: '25px !important' },
                  }}
                  onClick={handleSelect}
                >
                  Select
                </Button>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
export default withAuth(CTS, 'table_cts_ro_rw');
