import React, { useState } from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { formateDate } from '@src/utils/dateUtil';
import { Text } from '@abyss/web/ui/Text';

export const PolicySection = ({
  polData,
  bankCodeLabel,
  fundingCodeLabel,
  effDateLabel,
}) => {
  // Policy Section State
  const [policyNumber, setPolicyNumber] = useState(
    polData ? polData[0]?.POL_NBR : polData?.[0]?.POL_NBR
  );
  const [name, setName] = useState(
    polData ? polData[0]?.POLHLDR_NM : polData?.[0]?.POLHLDR_NM
  );
  const [defaultTypeOfEffectiveDate, setDefaultTypeOfEffectiveDate] =
    useState('');
  const [defaultEffectiveDate, setDefaultEffectiveDate] = useState(
    polData ? polData[0]?.POL_EFF_DT : polData?.[0]?.POL_EFF_DT
  );
  const [defaultCancellationDate, setDefaultCancellationDate] = useState(
    polData ? polData[0]?.POL_CANC_DT : polData?.[0]?.POL_CANC_DT
  );
  const [defaultAttributeEffectiveDate, setDefaultAttributeEffectiveDate] =
    useState(
      polData ? polData[0]?.POL_ATT_EFF_DT : polData?.[0]?.POL_ATT_EFF_DT
    );
  const [defaultLegalEntityCode, setDefaultLegalEntityCode] = useState(
    polData ? polData[0]?.LEG_ENTY_CD : polData?.[0]?.LEG_ENTY_CD
  );
  const [defaultGLReinsurance, setDefaultGLReinsurance] = useState(
    polData?.polData?.[0]?.GL_REINS_CD === undefined
      ? 'None'
      : polData?.[0]?.GL_REINS_CD
  );
  const [defaultFundingEffectiveDate, setDefaultFundingEffectiveDate] =
    useState(
      polData ? polData[0]?.POL_FNDNG_EFF_DT : polData?.[0]?.POL_FNDNG_EFF_DT
    );
  {
    /* TODO: ADD Value */
  }
  const [defaultFundingCode, setDefaultFundingCode] = useState('');
  const [defaultBankCode, setDefaultBankCode] = useState('');

  const today = new Date();
  const day = today.toLocaleString('default', { weekday: 'long' });
  const date = today.getDate();
  const month = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  const currentDate = `${day}, ${month} ${date}, ${year}`;

  return (
    <React.Fragment>
      <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }} css={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  >
        <Heading offset={5}>Policy</Heading>
                   <Text
                    css={{
                      marginLeft: 'auto',
                      textAlign: 'right',
                      alignSelf: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {/* {currentDate} */}
                  </Text>
      </Grid.Col>

      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Policy Number"
          isDisabled
          value={policyNumber}
          onChange={(e) => {
            return setPolicyNumber(e.target.value);
          }}
          css={{
            'abyss-text-input:focus': {
              boxShadow: '#ff4e00 0px 0px 0px 2px !important ',
            },
          }}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Name"
          isDisabled
          value={name}
          onChange={(e) => {
            return setName(e.target.value);
          }}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Type Of Effective Date"
          isDisabled
          value={effDateLabel}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Effective Date"
          isDisabled
          value={defaultEffectiveDate === "9999-12-31" ? "" : formateDate(defaultEffectiveDate)}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Cancellation Date"
          isDisabled
          value={defaultCancellationDate==="9999-12-31" ? "" : formateDate(defaultCancellationDate)}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Default Attribute Effective Date"
          isDisabled
          value={defaultAttributeEffectiveDate === "9999-12-31" ? "" : formateDate(defaultAttributeEffectiveDate)}
        />
      </Grid.Col>

      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Default Legal Entiy Code"
          isDisabled
          value={defaultLegalEntityCode}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Default GL Reinsurance"
          isDisabled
          value={defaultGLReinsurance}
        />
      </Grid.Col>

      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Default Funding Effective Date"
          isDisabled
          value={defaultFundingEffectiveDate === "9999-12-31" ? "" : formateDate(defaultFundingEffectiveDate)}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput
          label="Default Funding Code"
          isDisabled
          value={fundingCodeLabel}
        />
      </Grid.Col>

      <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
        <TextInput label="Default Bank Code" isDisabled value={bankCodeLabel} />
      </Grid.Col>
    </React.Fragment>
  );
};
