import React from 'react';
import { usePrint } from '@abyss/web/hooks/usePrint';

import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import withAuth from '@src/auth/withAuth';

const FinancialStructureSubmit = () => {
  const { readOnly } = useAuth();
  const { getLocation } = useRouter();
  const location = getLocation();
  const { navigate } = useRouter();
  const print = usePrint();
  const requestDetails = location?.state?.requestDetails;
  const resData = location?.state?.data;

  const handleBack = () => {
    navigate('/cmdb/correction-management');
  };

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <style>{`
            @media print{
              .printing {
              margin-top:150px;
              min-width: 800px;}
            }`}</style>
            <Box color="$white" className="printing">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>Correction Request Processor</Heading>
                </Grid.Col>
              </Grid>
              <Grid.Col span={{ xs: 3 }}>
                <Text css={{ fontWeight: 600 }}>Request ID : </Text>
                <Text>{resData}</Text>
              </Grid.Col>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Heading offset={5}>Request Detail</Heading>
                    <b>
                      {new Date().toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </b>
                  </div>
                </Grid.Col>
              </Grid>
              <Grid
                css={{ margin: '0px !important', marginTop: '20px !important' }}
              >
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Book Month From :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>
                    {requestDetails?.fromBookMonth
                      ? requestDetails.fromBookYear +
                      requestDetails?.fromBookMonth
                      : '(blank)'}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Book Month To :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>
                    {requestDetails?.toBookMonth
                      ? requestDetails.toBookYear + requestDetails?.toBookMonth
                      : '(blank)'}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Policy Number :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>
                    {requestDetails?.policyNumber
                      ? requestDetails?.policyNumber
                      : '(blank)'}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Suffix Code :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>
                    {requestDetails?.suffixCode
                      ? requestDetails?.suffixCode.replaceAll("  ","__")
                      : '(blank)'}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>
                    Funding Arrg Group Code :
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>
                    {requestDetails?.funGrpCd ? requestDetails?.funGrpCd : '(blank)'}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Funding Arrg Code :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>
                    {requestDetails?.funCd ? requestDetails?.funCd : '(blank)'}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>
                    Legal Entity Group Code :
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 9 }}>
                  <Text>
                    {requestDetails?.legEntCd ? requestDetails?.legEntCd : '(blank)'}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Request Type :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>Financial</Text>
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={() => {
                      print.printPage();
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleBack}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
export default withAuth(FinancialStructureSubmit, "cmdb_update");
