import React, { useState, useEffect } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@abyss/web/ui/Button';
import { PolicySection } from './PolicySection';
import { SuffixSection } from './SuffixSection';
import { AccountSection } from './AccountSection';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { DateInput } from '@abyss/web/ui/DateInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { formateDate } from '@src/utils/dateUtil';
import { Alert } from '@abyss/web/ui/Alert';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';
import { set } from 'lodash';

const AccountLookup = ({ policyData, suffixData }) => {
  const { getLocation } = useRouter();
  let location = getLocation();
  const { navigate } = useRouter();
  const { readOnly, token, msid } = useAuth();
  const [dropdownData, setDropDownData] = useState('');
  const [generalData, setGeneralData] = useState('');
  const [policyNumber, setPolicyNumber] = useState();
  const [suffixCode, setSuffixCode] = useState();
  const [accountCode, setAccountCode] = useState('');
  const [accountEffectiveDate, setAccountEffectiveDate] = useState('');
  const [attributeEffectiveDate, setAttributeEffectiveDate] = useState('');
  const [cancellationDate, setCancellationDate] = useState('');
  const [subscriberStatusValidation, setSubscriberStatusValidation] =
    useState('');
  const [hmoAccountDivision, setHmoAccoutnDivision] = useState('');
  const [checkReview, setCheckReview] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertSuccText, setAlerSucctText] = useState('');
  const [showSuccAlert, setShowSuccAlert] = useState(false);
  const [isExistAccountCode, setIsExistAccountCode] = useState(false);
  const [lookupPress, setLookupPress] = useState(false);
  const [clearAccCode, setClearAccCode] = useState(false);
  const [isAccLoad, setIsAccLoad] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [isAccountButtonDisabled, setIsAccountButtonDisabled] = useState(false);
  const [isSuffixButtonDisabled, setIsSuffixButtonDisabled] = useState(false);
  const [isPolicyButtonDisabled, setIsPolicyButtonDisabled] = useState(false);
  const [isDataEntryButtonDisabled, setIsDataEntryButtonDisabled] =
    useState(false);

  const handleGeneralData = (data) => {
    const defaultSubSts = dropdownData?.subsStatusVal?.filter((item) => {
      return item.label == 'No';
    });
    setGeneralData('');
    setAccountEffectiveDate('');
    setAttributeEffectiveDate('');
    setCancellationDate('');
    setSubscriberStatusValidation(defaultSubSts[0].value);
    setHmoAccoutnDivision('');
    setCheckReview(false);
    setIsExistAccountCode(false);
    setIsHighlight(false);
    if (data[0]?.POL_ACCT_NBR !== undefined) {
      setIsExistAccountCode(true);
      setGeneralData(data);
      setAccountCode(data[0]?.POL_ACCT_NBR);
    } else {
      setIsHighlight(true);
      setAccountEffectiveDate(
        formateDate(location?.state?.suffixData?.[0]?.SUFX_EFF_DT)
      );
      setAttributeEffectiveDate(
        formateDate(location?.state?.suffixData?.[0]?.ATT_EFF_DT)
      );
    }
  };
  const setAcctCode = (value) => {
    setAccountCode(value);
  };

  const setLoadStatus = (value) => {
    setIsAccLoad(value);
  };

  useEffect(() => { }, [location]);
  const fecthDropdownData = async () => {
    await axios
      // .get(`${BASE_URL}fadb-service/acc-inq-dropdown-data/`, {
      .get(`${BASE_URL}static/accDrpdwnData/accountDrpdwnData `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDropDownData(response.data);
        const defaultSubSts = response.data?.subsStatusVal?.filter((item) => {
          return item.label == 'No';
        });
        setSubscriberStatusValidation(defaultSubSts[0]?.value);
      })
      .catch((error) => {
        // add-alert-popup
      });
  };
  useEffect(() => {
    if (policyData) {
      setPolicyNumber(policyData?.[0]?.POL_NBR);
    } else {
      setPolicyNumber(location?.state?.policyData?.[0]?.POL_NBR);
    }
    setSuffixCode(suffixData?.[0]?.SUFX_CD);
  }, [suffixData, policyData]);

  useEffect(() => {
    if (token) fecthDropdownData();
  }, [token]);

  useEffect(() => {
    if (generalData) {
      setAccountEffectiveDate(formateDate(generalData[0]?.ACCT_EFF_DT));
      setAttributeEffectiveDate(formateDate(generalData[0]?.ATT_EFF_DT));
      setCancellationDate(formateDate(generalData[0]?.ACCT_CANC_DT));
      setSubscriberStatusValidation(generalData[0]?.SBSCR_STS_VLD_IND);
      setHmoAccoutnDivision(generalData[0]?.HMO_ACCTDIV_ADM_CD);
    }
  }, [generalData]);

  const handleSubmit = async (redirectPage) => {
    const pageHeight = document.documentElement.scrollHeight;
    const halfPageHeight = pageHeight / 2;

    if (!lookupPress) {
      setShowAlert(true);
      window.scrollTo(0, halfPageHeight);
      setAlertText('Press the lookup button first ');

      return;
    }
    redirectPage === 'accountPage' && setIsAccountButtonDisabled(true);
    redirectPage === 'suffixPage' && setIsSuffixButtonDisabled(true);
    redirectPage === 'policyPage' && setIsPolicyButtonDisabled(true);
    redirectPage === 'dataEntry' && setIsDataEntryButtonDisabled(true);
    try {
      const data = {
        polacctEntId: isExistAccountCode ? generalData[0]?.POLACCT_ENT_ID : '',
        polAcctNbr: accountCode,
        acctEffDt: formateDate(accountEffectiveDate),
        attEffDt: formateDate(attributeEffectiveDate),
        acctCancDt: cancellationDate
          ? formateDate(cancellationDate)
          : '12/31/9999',
        hmoAcctdivAdmCd: hmoAccountDivision,
        sbscrStsVldInd: subscriberStatusValidation,
        userShortNm: msid,
        sufxEntId: isExistAccountCode
          ? generalData[0]?.SUFX_ENT_ID
          : location?.state?.suffixData?.[0]?.SUFX_ENT_ID,
        suffxTypCd: location?.state?.suffixData?.[0]?.SUFX_TYP_CD,
        sufxEffDt: location?.state?.suffixData?.[0]?.SUFX_EFF_DT,
        sufxAttEffDt: location?.state?.suffixData?.[0]?.ATT_EFF_DT,
        sufxCancDt: location?.state?.suffixData?.[0]?.SUFX_CANC_DT,
        fndngEffDt: location?.state?.suffixData?.[0]?.SUFX_EFF_DT,
        legEntyCd: location?.state?.suffixData?.[0]?.LEG_ENTY_CD,
        polEntId: isExistAccountCode
          ? generalData[0]?.POL_ENT_ID
          : location?.state?.policyData?.[0]?.POL_ENT_ID,
      };
      const apiURL = isExistAccountCode
        ? `${BASE_URL}account/update`
        : `${BASE_URL}account/save`;
      if (redirectPage === 'accountPage') {
        const response = await axios
          .post(apiURL, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIsAccountButtonDisabled(false);
            let result = '';
            for (const item of response.data) {
              if (item.hasOwnProperty('fail')) {
                result = item['fail'];
                setShowAlert(true);
                window.scrollTo(0, halfPageHeight);
                setAlertText(result.replace(/<br\/?>/g, ' '));
                break;
              } else if (item.hasOwnProperty('success')) {
                result = item['success'];
                const defaultSubSts = dropdownData?.subsStatusVal?.filter(
                  (item) => {
                    return item.label == 'No';
                  }
                );
                setSubscriberStatusValidation(defaultSubSts[0].value);
                setShowSuccAlert(true);
                setAlerSucctText(result);
                onCancel();
                setAccountCode('');
                setLookupPress(false);
                setClearAccCode(true);
                break;
              }
            }
          });
      } else if (redirectPage === 'suffixPage') {
        let stateData = {
          policyNumber: location?.state?.policyData?.[0]['POL_NBR'],
          POL_CHG_DT: location?.state?.policyData?.[0]['POL_CHG_DT'],
          RR_TYP_CD: location?.state?.policyData?.[0]['RR_TYP_CD'],
          BNK_CD: location?.state?.policyData?.[0]['BNK_CD'],
          ADMIN_SYS_SRC_CD:
            location?.state?.policyData?.[0]['ADMIN_SYS_SRC_CD'],
          POL_FNDNG_EFF_DT:
            location?.state?.policyData?.[0]['POL_FNDNG_EFF_DT'],
          USER_SHORT_NM: msid,
          GL_REINS_CD: location?.state?.policyData?.[0]?.GL_REINS_CD?.trim(),
          FUND_ARNG_CD: location?.state?.policyData?.[0]['FUND_ARNG_CD'],
          LEG_ENTY_CD: location?.state?.policyData?.[0]['LEG_ENTY_CD'],
          LEG_ENTY_OFN_CD: '',
          POL_EFF_DT: location?.state?.policyData?.[0]['POL_EFF_DT'],
          POLHLDR_NM: location?.state?.policyData?.[0]['POLHLDR_NM'],
          POL_CANC_DT: location?.state?.policyData?.[0]['POL_CHG_DT'],
          ERISA_CD: location?.state?.policyData?.[0]['ERISA_CD'],
          REN_MO_NBR: location?.state?.policyData?.[0]['REN_MO_NBR'],
          REN_DAY_NBR: location?.state?.policyData?.[0]['REN_DAY_NBR'],
          SRVC_ISS_DT_RUL_CD:
            location?.state?.policyData?.[0]['SRVC_ISS_DT_RUL_CD'],
          POL_ENT_ID: location?.state?.policyData?.[0]['POL_ENT_ID'],
          POL_ATT_EFF_DT: location?.state?.policyData?.[0]['POL_ATT_EFF_DT'],
          SHR_ARNG_CD: location?.state?.policyData?.[0]['SHR_ARNG_CD'],
          CO_CD: location?.state?.policyData?.[0]['CO_CD'],
          disabledFlag: false,
          disabledDropdown: true,
          actPolNbr: location?.state?.policyData?.[0]['POL_NBR'],
          policyNumber: location?.state?.policyData?.[0]['POL_NBR'],
          polName: location?.state?.policyData?.[0]['POLHLDR_NM'],
          typeofeffectiveDate:
            location?.state?.policyData?.[0]['SRVC_ISS_DT_RUL_CD'],
          effectiveDate: location?.state?.policyData?.[0]['POL_EFF_DT'],
          caseinstallationSource:
            location?.state?.policyData?.[0]['ADMIN_SYS_SRC_CD'],
          policytype: location?.state?.policyData?.[0]['RR_TYP_CD'],
          erisacode: location?.state?.policyData?.[0]['ERISA_CD'],
          attrEffectiveDate: location?.state?.policyData?.[0]['POL_ATT_EFF_DT'],
          deafultlegalEntityCode:
            location?.state?.policyData?.[0]['LEG_ENTY_CD'],
          defaultglreinsurance:
            location?.state?.policyData?.[0]?.GL_REINS_CD?.trim(),
          sharedArrangement: location?.state?.policyData?.[0]['SHR_ARNG_CD'],
          fundingEffectiveDate:
            location?.state?.policyData?.[0]['POL_FNDNG_EFF_DT'],
          fundingCode: location?.state?.policyData?.[0]['FUND_ARNG_CD'],
          bankCode: location?.state?.policyData?.[0]['BNK_CD'],
          companyCode: location?.state?.policyData?.[0]['CO_CD'],
          typeofRenewal: location?.state?.policyData?.[0]['RR_TYP_CD'],
          firstRenewalMonth: location?.state?.policyData?.[0]['REN_MO_NBR'],
          firstRenewalDay: location?.state?.policyData?.[0]['REN_DAY_NBR'],
          lastChange: location?.state?.policyData?.[0]['POL_CHG_DT'],
          lastChangeBy: msid,
          typeftdtRule: false,
          userSortName: msid,
          cancelationDate: location?.state?.policyData?.[0]['POL_CANC_DT'],
        };

        const response = await axios
          .post(apiURL, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIsSuffixButtonDisabled(false);
            let result = '';
            for (const item of response.data) {
              if (item.hasOwnProperty('fail')) {
                result = item['fail'];
                setShowAlert(true);
                window.scrollTo(0, halfPageHeight);

                setAlertText(result.replace(/<br\/?>/g, ' '));
                break;
              } else if (item.hasOwnProperty('success')) {
                const defaultSubSts = dropdownData?.subsStatusVal?.filter(
                  (item) => {
                    return item.label == 'No';
                  }
                );
                setSubscriberStatusValidation(defaultSubSts[0].value);
                navigate('/fadb/suffix/lookup', {
                  state: {
                    data: stateData,
                    policyNumber: policyNumber,
                    policyEntId: location?.state?.policyData?.[0]['POL_ENT_ID'],
                    selectedTypeOfEffDate: dropdownData?.typEffDate,
                    selectedGLReinsurance: dropdownData?.glReins,
                    selectedFundingCode: dropdownData?.fndCd,
                    selectedBankCode: dropdownData?.bnkCd,
                  },
                });
                break;
              }
            }
          });
      } else if (redirectPage === 'policyPage') {
        const response = await axios
          .post(apiURL, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIsPolicyButtonDisabled(false);
            let result = '';
            for (const item of response.data) {
              if (item.hasOwnProperty('fail')) {
                result = item['fail'];
                setShowAlert(true);
                window.scrollTo(0, halfPageHeight);

                setAlertText(result.replace(/<br\/?>/g, ' '));
                break;
              } else if (item.hasOwnProperty('success')) {
                navigate('/fadb/policy');
                const defaultSubSts = dropdownData?.subsStatusVal?.filter(
                  (item) => {
                    return item.label == 'No';
                  }
                );
                setSubscriberStatusValidation(defaultSubSts[0].value);
                break;
              }
            }
          });
      } else if (redirectPage === 'dataEntry') {
        const response = await axios
          .post(apiURL, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIsDataEntryButtonDisabled(false);
            let result = '';
            for (const item of response.data) {
              if (item.hasOwnProperty('fail')) {
                result = item['fail'];
                setShowAlert(true);
                setAlertText(result.replace(/<br\/?>/g, ' '));
                break;
              } else if (item.hasOwnProperty('success')) {
                result = item['success'];
                setShowSuccAlert(true);
                setAlerSucctText(result);
                onCancel();
                setAccountEffectiveDate(
                  formateDate(location?.state?.suffixData?.[0]?.SUFX_EFF_DT)
                );
                setAttributeEffectiveDate(
                  formateDate(location?.state?.suffixData?.[0]?.ATT_EFF_DT)
                );
                setAccountCode('');
                setLookupPress(false);
                setClearAccCode(true);
                const defaultSubSts = dropdownData?.subsStatusVal?.filter(
                  (item) => {
                    return item.label == 'No';
                  }
                );
                setSubscriberStatusValidation(defaultSubSts[0].value);
                break;
              }
            }
          });
      }
    } catch (error) {
      // add-alert-popup
    }
  };
  function onCancel() {
    const defaultSubSts = dropdownData?.subsStatusVal?.filter((item) => {
      return item.label == 'No';
    });
    setGeneralData('');
    setClearAccCode(true);
    setAccountEffectiveDate('');
    setAttributeEffectiveDate('');
    setCancellationDate('');
    setSubscriberStatusValidation(defaultSubSts[0].value);
    setHmoAccoutnDivision('');
    setCheckReview(false);
    setIsExistAccountCode(false);
    setIsHighlight(false);
    setLookupPress(false);
  }

  // Bank Code Label Policy
  const bankCode = location?.state?.policyData[0].BNK_CD;
  const dropDownBankCode = dropdownData.bnkCd;
  const bankCodeLabel = dropDownBankCode?.find(
    (item) => item.value === bankCode
  )?.label;

  //Bank Code Label Suffix
  const bankCodeSuffix = location?.state?.suffixData[0].BNK_CD;
  const dropDownBankCodeSuffix = dropdownData.bnkCd;
  const bankCodeLabelSuffix = dropDownBankCodeSuffix?.find(
    (item) => item.value === bankCodeSuffix
  )?.label;

  const ReInsurance = location?.state?.suffixData[0].GL_REINS_CD;
  const dropDownReinsurance = dropdownData.glReins;
  const ReinsuranceLabel = dropDownReinsurance?.find(
    (item) => item.value === ReInsurance.trim()
  )?.label;

  // Funding code Label policy
  const fundingCode = location?.state?.policyData[0].FUND_ARNG_CD;
  const dropDownFundingCode = dropdownData.fndCd;
  const fundingCodeLabel = dropDownFundingCode?.find(
    (item) => item.value === fundingCode
  )?.label;

  //Funding Code Label Suffix
  const fundingCodeSuffix = location?.state?.suffixData[0].FUND_ARNG_CD;
  const dropDownFundingCodeSuffix = dropdownData.fndCd;
  const fundingCodeLabelSuffix = dropDownFundingCodeSuffix?.find(
    (item) => item.value === fundingCodeSuffix
  )?.label;

  // Type of Effective date label
  const typeOfEffDate = location?.state?.policyData[0]?.SRVC_ISS_DT_RUL_CD;
  const dropDownEffDate = dropdownData.typEffDate;
  const effDateLabel = dropDownEffDate?.find(
    (item) => item.value === typeOfEffDate
  )?.label;

  useEffect(() => {
    if (showSuccAlert) {
      const timer = setTimeout(() => {
        setShowSuccAlert(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showSuccAlert]);

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
                <Grid>
                  <PolicySection
                    polData={location?.state?.policyData}
                    bankCodeLabel={bankCodeLabel}
                    fundingCodeLabel={fundingCodeLabel}
                    effDateLabel={effDateLabel}
                  />
                  <SuffixSection
                    sufData={location?.state?.suffixData}
                    bankCodeLabelSuffix={bankCodeLabelSuffix}
                    fundingCodeLabelSuffix={fundingCodeLabelSuffix}
                    ReinsuranceLabel={ReinsuranceLabel}
                  />
                  <AccountSection
                    handleGeneralData={handleGeneralData}
                    setLoadingStatus={setLoadStatus}
                    getAcctCode={setAcctCode}
                    setLookupPress={setLookupPress}
                    polData={location?.state?.policyData}
                    sufData={location?.state?.suffixData}
                    clearAccCode={clearAccCode}
                    setClearAccCode={setClearAccCode}
                  />
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                  }}
                >
                  <Alert
                    variant="error"
                    isVisible={showAlert}
                    onClose={() => {
                      setShowAlert(false);
                    }}
                  >
                    {alertText}
                  </Alert>
                </Grid>
                {showSuccAlert && (
                  <Grid
                    css={{
                      margin: '0px !important',
                      marginTop: '20px !important',
                    }}
                  >
                    <Alert
                      variant="success"
                      isVisible={showSuccAlert}
                      onClose={() => {
                        setShowSuccAlert(false);
                      }}
                    >
                      {alertSuccText}
                    </Alert>
                  </Grid>
                )}
                {isAccLoad && <WrapperLoader />}
                {!isAccLoad && (
                  <Grid>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>General Information</Heading>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Account Effective Date"
                        value={accountEffectiveDate}
                        onChange={(date) => setAccountEffectiveDate(date)}
                        subText={' '}
                        highlighted={isHighlight ? true : false}
                        isDisabled={readOnly}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        highlighted={isHighlight ? true : false}
                        label="Attribute Effective Date"
                        value={attributeEffectiveDate}
                        onChange={(date) => setAttributeEffectiveDate(date)}
                        subText={' '}
                        isDisabled={readOnly}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Cancellation Date"
                        value={cancellationDate}
                        onChange={(date) => setCancellationDate(date)}
                        subText={' '}
                        isDisabled={readOnly}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="HMO Account Division"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={hmoAccountDivision}
                        onChange={(value) => setHmoAccoutnDivision(value)}
                        options={dropdownData.healthPlanSite?.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Subscriber Status Validation"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={subscriberStatusValidation}
                        onChange={(value) =>
                          setSubscriberStatusValidation(value)
                        }
                        options={dropdownData.subsStatusVal?.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <Checkbox
                        label="Check here to review Subscriber status"
                        isChecked={checkReview}
                        onChange={(e) => setCheckReview(e.target.checked)}
                        isDisabled={readOnly}
                        css={{
                          'abyss-checkbox-root': { paddingTop: '2.1rem' },
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Last Changed"
                        value={generalData[0]?.ACCT_CHG_DT}
                        isDisabled
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="By"
                        isDisabled
                        value={generalData[0]?.USER_SHORT_NM}
                      />
                    </Grid.Col>
                  </Grid>
                )}
                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.3rem',
                          padding: '0.5rem',
                          fontSize: '15px',
                        },
                      }}
                      onClick={() => handleSubmit('accountPage')}
                      isLoading={isAccountButtonDisabled}
                      ariaLoadingLabel="Loading"
                      isDisabled={readOnly}
                    >
                      Submit & Account Page
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.3rem',
                          padding: '0.5rem',
                          fontSize: '15px',
                        },
                      }}
                      isLoading={isSuffixButtonDisabled}
                      ariaLoadingLabel="Loading"
                      isDisabled={readOnly}
                      onClick={() => handleSubmit('suffixPage')}
                    >
                      Submit & Suffix Page
                    </Button>

                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.3rem',
                          padding: '0.5rem',
                          fontSize: '15px',
                        },
                      }}
                      isLoading={isPolicyButtonDisabled}
                      ariaLoadingLabel="Loading"
                      isDisabled={readOnly}
                      onClick={() => handleSubmit('policyPage')}
                    >
                      Submit & Policy Page
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.3rem',
                          padding: '0.5rem',
                          fontSize: '15px',
                        },
                      }}
                      isLoading={isDataEntryButtonDisabled}
                      ariaLoadingLabel="Loading"
                      isDisabled={readOnly}
                      onClick={() => handleSubmit('dataEntry')}
                    >
                      Submit & Data Entry
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.3rem',
                          fontSize: '15px',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(AccountLookup, "fadb_update");
