import React, { useEffect, useState } from 'react';
import { usePrint } from '@abyss/web/hooks/usePrint';

import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { useRouter } from '@abyss/web/hooks/useRouter';
import useAuth from '@src/utils/useAuth';
import withAuth from '@src/auth/withAuth';
import { BASE_URL } from '@src/utils/endpoints';
import axios from 'axios';
import { Text } from '@abyss/web/ui/Text';

const LogsDailyAutoReview = () => {
  const { readOnly, token } = useAuth();
  const { navigate } = useRouter();
  const print = usePrint();
  const path = '/cmdb/correction-management';
  const [dailyLogDetails, setDailyLogDetails] = useState({ fileText: '' });
  const today = new Date();
  const day = today.toLocaleString('default', { weekday: 'long' });
  const month = today.toLocaleString('default', { month: 'long' });
  const date = today.getDate();
  const year = today.getFullYear();
  const currDate = `${day}, ${month} ${date}, ${year}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}crtmgt/view-correction-request-log/getAll`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response && response.data) {
          setDailyLogDetails(response.data);
        }
      } catch (error) {
        // add-alert-popup
      }
    };
    if (token) fetchData();
  }, [token]);

  const handleBackButton = () => {
    navigate(path);
  };
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <style>{`
            @media print{
              .printing {
              margin-top:150px;
              min-width: 800px;
              margin-left:20px}
            }`}</style>
            <Box color="$white" className="printing">
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  padding: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 8, md: 8, lg: 8, xl: 8 }}>
                  <Heading offset={5}>View Correction Request Log</Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <Text fontWeight={'bold'}>{currDate}</Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  borderTop: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {dailyLogDetails.fileText}{' '}
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={() => {
                      print.printPage();
                    }}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Print
                  </Button>
                  <Button
                    onClick={handleBackButton}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(LogsDailyAutoReview, "cmdb_update");
