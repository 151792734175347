import React, { useState, useEffect } from 'react';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Alert } from '@abyss/web/ui/Alert';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { constantColumns } from './column-constants';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Navigate } from 'react-router-dom';
import { DateInput } from '@abyss/web/ui/DateInput';
import { getEnvironment } from '@src/utils/environmentUtil';
import env from '../../../env.json';
import withAuth from '@src/auth/withAuth';

const State = () => {
  const [saveModal, setSaveModal] = useState(false);
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [stCd, setStCd] = useState([]);
  const [stCdErr, setStCdErr] = useState('');
  const [glLocNbr, setGlLocNbr] = useState([]);
  const [glLocNbrErr, setGlLocNbrErr] = useState('');
  const [medSurchrgInd, setMedSurchrgInd] = useState([]);
  const [stAbbrCd, setAbbrCd] = useState([]);
  const [stCdSelected, setStCdSelected] = useState([]);
  const [glLocNbrSelected, setGlLocNbrSelected] = useState([]);
  const [medSurchrgIndSelected, setMedSurchrgIndSelected] = useState([]);
  const [stAbbrCdSelected, setAbbrCdSelected] = useState([]);
  const [payloadText, setPayloadText] = useState('');
  const [stCdForm, setStCdForm] = useState('');
  const [glLocNbrForm, setGlLocNbrForm] = useState('');
  const [effDt, setEffDt] = useState(null);
  const [effDtErr, setEffDtErr] = useState('');
  const [medSurchrgIndForm, setMedSurchrgIndForm] = useState('');
  const [medSurchrgIndErr, setMedSurchrgIndErr] = useState('');
  const [stNm, setStNm] = useState('');
  const [stNmErr, setStNmErr] = useState('');
  const [stAbbrCdForm, setAbbrCdForm] = useState('');
  const [abbrCdErr, setAbbrCdErr] = useState('');
  const [uhicRtAH, setUhicRtAH] = useState('');
  const [uhicRtAhErr, setUhicRtAhErr] = useState('');
  const [uhicRtLf, setUhicRtLf] = useState('');
  const [uhicRtLfErr, setUhicRtLfErr] = useState('');
  const [uhicRtMeds, setUhicMeds] = useState('');
  const [uhicRtMedsErr, setUhicRtMedsErr] = useState('');
  const [uhicRt4, setUhicRt4] = useState('');
  const [uhicRt4Err, setUhicRt4Err] = useState('');
  const [uhicRt5, setUhicRt5] = useState('');
  const [uhicRt5Err, setUhicRt5Err] = useState('');
  const [hpTaxRtAh, setHpTaxRtAh] = useState('');
  const [hpTaxRtAhErr, setHpTaxRtAhErr] = useState('');
  const [hpTaxRtLf, setHpTaxRtLf] = useState('');
  const [hpTaxRtLfErr, setHpTaxRtLfErr] = useState('');
  const [hpTaxRt3, setHpTaxRt3] = useState('');
  const [hpTaxRt3Err, setHpTaxRt3Err] = useState('');
  const [hpTaxRt4, setHpTaxRt4] = useState('');
  const [hpTaxRt4Err, setHpTaxRt4Err] = useState('');
  const [hpTaxRt5, setHpTaxRt5] = useState('');
  const [hpTaxRt5Err, setHpTaxRt5Err] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateRow, setUpdateRow] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [text, setText] = useState('');
  const [json, setJson] = useState({});
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, msid, userRolesGroup } = useAuth();
  const [hasRWaccess, setHasRWaccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const data = [];
  const [row, setRow] = useState({});
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  useEffect(() => {
    if (Object.keys(json).length > 0) {
      let glLocNbr1 = json?.['getGlLocNbrList'].map((item) => ({
        value: item,
        label: item,
      }));
      setGlLocNbr(glLocNbr1);
      let medSurchrgInd1 = json?.['getMedSurchrgIndList'].map((item) => ({
        value: item,
        label: item,
      }));
      setMedSurchrgInd(medSurchrgInd1);
      let stAbbrCd1 = json?.['getStAbbrCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setAbbrCd(stAbbrCd1);
      let stCdList1 = json?.['stCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setStCd(stCdList1);
    }
  }, [json]);
  const handleDynamicDropdown = async () => {
    try {
      let payload = {
        database: location?.state?.database,
      };
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/state/getDropDowns`,
        payload,
        config
      );
      if (response.status === 200) {
        setJson(response.data);
      }
    } catch (error) {
      // add-alert-popup
    }
  };
  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);
  useEffect(() => {
    let text1 =
      (stCdSelected?.length > 0
        ? 'ST_CD - ' + stCdSelected.toString() + '\n'
        : '') +
      (glLocNbrSelected?.length > 0
        ? 'GL_LOC_NBR - ' + glLocNbrSelected.toString() + '\n'
        : '') +
      (medSurchrgIndSelected?.length > 0
        ? 'MED_SURCHRG_IND - ' + medSurchrgIndSelected.toString() + '\n'
        : '') +
      (stAbbrCdSelected?.length > 0
        ? 'ST_ABBR_CD - ' + stAbbrCdSelected.toString() + '\n'
        : '');
    let payload =
      (stCdSelected?.length > 0
        ? ' ST_CD IN (' + stCdSelected.toString() + ') AND'
        : '') +
      (glLocNbrSelected?.length > 0
        ? ' GL_LOC_NBR IN (' + glLocNbrSelected.toString() + ') AND'
        : '') +
      (medSurchrgIndSelected?.length > 0
        ? ' MED_SURCHRG_IND IN (' +
          medSurchrgIndSelected.map((item) => `'${item}'`).join(',') +
          ') AND'
        : '') +
      (stAbbrCdSelected?.length > 0
        ? ' ST_ABBR_CD IN (' +
          stAbbrCdSelected.map((item) => `'${item}'`).join(',') +
          ') AND'
        : '');
    setPayloadText(payload);
    setText(text1);
  }, [stAbbrCdSelected, medSurchrgIndSelected, stCdSelected, glLocNbrSelected]);
  //get column definition
  const addRow = () => {
    setFlag(true);
    setHideUpdate(true);
  };

  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );

  const updateRows = (row) => {
    setUpdateFlag(true);
    setFlag(true);
    setHideAdd(true);
    setUpdateRow(row);
    setStCdForm(row['ST_CD']);
    setGlLocNbrForm(row['GL_LOC_NBR']);
    setEffDt(row['EFFDT']);
    setMedSurchrgIndForm(row['MED_SURCHRG_IND']);
    setStNm(row['ST_NM']);
    setAbbrCdForm(row['ST_ABBR_CD']);
    setUhicRtAH(row['UHIC_TAX_RT_AH']);
    setUhicRtLf(row['"UHIC_TAX_RT_LF']);
    setUhicMeds(row['UHIC_TAX_RT_MEDSUP']);
    setUhicRt4(row['UHIC_TAX_RT4']);
    setUhicRt5(row['UHIC_TAX_RT5']);
    setHpTaxRtAh(row['HP_TAX_RT_AH']);
    setHpTaxRtLf(row['HP_TAX_RT_LF']);
    setHpTaxRt3(row['HP_TAX_RT_RT3']);
    setHpTaxRt4(row['HP_TAX_RT_RT4']);
    setHpTaxRt5(row['HP_TAX_RT_RT5']);
  };
  const saveValidate = () => {
    if (validateSave()) {
      setSaveModal(true);
    }
  };
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setShowModal(false);
    let payload1 = {
      stCd: row['ST_CD'],
      glLocNbr: row['GL_LOC_NBR'],
      effDt: row['EFFDT'],
      medSurchrgInd: row['MED_SURCHRG_IND'],
      stNm: row['ST_NM'],
      stAbbrCd: row['ST_ABBR_CD'],
      uhicTaxRtAh: row['UHIC_TAX_RT_AH'],
      uhicTaxRtLf: row['UHIC_TAX_RT_LF'],
      uhicTaxRtMedsup: row['UHIC_TAX_RT_MEDSUP'],
      uhicTaxRt4: row['UHIC_TAX_RT4'],
      uhicTaxRt5: row['UHIC_TAX_RT5'],
      hpTaxRtAh: row['HP_TAX_RT_AH'],
      hpTaxRtLf: row['HP_TAX_RT_LF'],
      hpTaxRtRt3: row['HP_TAX_RT_RT3'],
      hpTaxRtRt4: row['HP_TAX_RT_RT4'],
      hpTaxRtRt5: row['HP_TAX_RT_RT5'],
      lstUpdtDt: row['LSTUPDTDT'],
      lstUpdtTm: row['LST_UPDT_TM'],
      lstUpdtId: row['LST_UPDT_ID'],
      updtInd: row['UPDT_IND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/state/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        setAlertText('You have successfully deleted the record.');
        setAlertType('success');
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
        getTableData1();
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };

  const getHeadersForEmptyData = () => {
    let columnNames = {};
    if (Array.isArray(columns)) {
      columns.forEach((column) => {
        columnNames[column.Header] = null;
      });
    }
    return columnNames;
  };

  let columnNameMapping = {};
  columns.forEach((column) => {
    columnNameMapping[column.accessor] = column.Header;
  });

  const downloadFilterExcel = () => {
    let filteredData = [];
    getTableState.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'state.xlsx');
  };

  const downloadExcel = () => {
    let fullData = [];
    getTableState?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'state.xlsx');
  };

  const getTableState = useDataTable({
    showPagination: true,
    initialData: data,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: [
        {
          // optional custom action; can also be an array of objects
          title: 'Download filtered dataset (XLSX)', // option item title
          icon: <IconSymbol icon="filter_alt" />, // option item icon
          onClick: downloadFilterExcel,
        },
        {
          // optional custom action; can also be an array of objects
          title: 'Download full dataset (XLSX)', // option item title
          icon: <IconSymbol icon="download_done" />, // option item icon
          onClick: downloadExcel,
        },
      ],
    },
    showFilterDataset: true,
    individualActions:
      hasRWaccess &&
      (hideUpdate ? [deleteLinkButton] : [updateLinkButton, deleteLinkButton]),
  });
  const clear = () => {
    setAbbrCdSelected([]);
    setGlLocNbrSelected([]);
    setStCdSelected([]);
    setMedSurchrgIndSelected([]);
    setPayloadText('');
    setText('');
  };
  const setData = async (tableData) => {
    let columnKeys = [];
    let columns = {};
    if (tableData?.length > 0) {
      columnKeys = Object.keys(tableData?.[0]);
      columns = columnKeys.map((key, index) => ({
        Header: key,
        accessor: key,
      }));
    }
    if (Array.isArray(tableData)) {
      getTableState.setColumns(columns, true);
      getTableState.setData(tableData, true);
    }
  };
  const onLookup = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload = {
      selectedValues: payloadText.substring(0, payloadText.length - 4),
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/state/getStateList/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = {
      selectedValues: payloadText.substring(0, payloadText.length - 4),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/state/getStateList/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
        handleDynamicDropdown();
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const handleClick = () => {
    navigate(-1);
  };
  const update = async () => {
    const now = new Date();
    setUpdateModal(false);
    setHideAdd(false);
    setFlag(false);
    let payload = {
      oldRecord: {
        stCd: updateRow['ST_CD'],
        glLocNbr: updateRow['GL_LOC_NBR'],
        effDt: updateRow['EFFDT'],
        medSurchrgInd: updateRow['MED_SURCHRG_IND'],
        stNm: updateRow['ST_NM'],
        stAbbrCd: updateRow['ST_ABBR_CD'],
        uhicTaxRtAh: updateRow['UHIC_TAX_RT_AH'],
        uhicTaxRtLf: updateRow['UHIC_TAX_RT_LF'],
        uhicTaxRtMedsup: updateRow['UHIC_TAX_RT_MEDSUP'],
        uhicTaxRt4: updateRow['UHIC_TAX_RT4'],
        uhicTaxRt5: updateRow['UHIC_TAX_RT5'],
        hpTaxRtAh: updateRow['HP_TAX_RT_AH'],
        hpTaxRtLf: updateRow['HP_TAX_RT_LF'],
        hpTaxRtRt3: updateRow['HP_TAX_RT_RT3'],
        hpTaxRtRt4: updateRow['HP_TAX_RT_RT4'],
        hpTaxRtRt5: updateRow['HP_TAX_RT_RT5'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: updateRow['UPDT_IND'],
      },
      newRecord: {
        stCd: stCdForm,
        glLocNbr: glLocNbrForm,
        effDt: effDt,
        medSurchrgInd: medSurchrgIndForm,
        stNm: stNm,
        stAbbrCd: stAbbrCdForm,
        uhicTaxRtAh: uhicRtAH,
        uhicTaxRtLf: uhicRtLf,
        uhicTaxRtMedsup: uhicRtMeds,
        uhicTaxRt4: uhicRt4,
        uhicTaxRt5: uhicRt5,
        hpTaxRtAh: hpTaxRtAh,
        hpTaxRtLf: hpTaxRtLf,
        hpTaxRtRt3: hpTaxRt3,
        hpTaxRtRt4: hpTaxRt4,
        hpTaxRtRt5: hpTaxRt5,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: msid.substr(0, 7).toUpperCase(),
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
    };
    setStCdForm('');
    setGlLocNbrForm('');
    setEffDt(null);
    setMedSurchrgIndForm('');
    setStNm('');
    setAbbrCdForm('');
    setUhicRtAH('');
    setUhicRtLf('');
    setUhicMeds('');
    setUhicRt4('');
    setUhicRt5('');
    setHpTaxRtAh('');
    setHpTaxRtLf('');
    setHpTaxRt3('');
    setHpTaxRt4('');
    setHpTaxRt5('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/state/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setAlertText('You have successfully updated the record.');
        setAlertType('success');
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
        getTableData1();
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const validateUpdate = () => {
    console.log(uhicRt4, updateRow['UHIC_TAX_RT4']);
    clearErrors();
    let valid = true;
    if (stCdForm?.trim() === '') {
      setStCdErr('Please enter valid ST_CD.');
      valid = false;
    }
    if (glLocNbrForm?.trim() === '') {
      setGlLocNbrErr('Please enter valid GL_LOC_NBR.');
      valid = false;
    }
    if (effDt === null || effDt?.length !== 10) {
      setEffDtErr('Please enter valid EFF_DT.');
      valid = false;
    }
    if (medSurchrgIndForm?.trim() === '') {
      setMedSurchrgIndErr('Please enter valid MED_SURCHRG_IND.');
      valid = false;
    }
    if (stNm?.trim() === '') {
      setStNmErr('Please enter valid ST_NM.');
      valid = false;
    }
    if (stAbbrCdForm?.trim() === '') {
      setAbbrCdErr('Please enter valid ST_ABBR_CD.');
      valid = false;
    }
    if (
      uhicRtAH?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRtAH) ||
      isNaN(uhicRtAH) ||
      +uhicRtAH >= 100
    ) {
      setUhicRtAhErr('UHIC_TAX_RT_AH must be less than or equal to 99.9999.');
      valid = false;
    } else setUhicRtAH((+uhicRtAH).toFixed(4));
    if (
      uhicRtMeds?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRtMeds) ||
      isNaN(uhicRtMeds) ||
      +uhicRtMeds >= 100
    ) {
      setUhicRtMedsErr(
        'UHIC_TAX_RT_MEDSUP must be less than or equal to 99.9999.'
      );
      valid = false;
    } else setUhicMeds((+uhicRtMeds).toFixed(4));
    if (
      uhicRt4?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRt4) ||
      isNaN(uhicRt4) ||
      +uhicRt4 >= 100
    ) {
      setUhicRt4Err('UHIC_TAX_RT4 must be less than or equal to 99.9999.');
      valid = false;
    } else setUhicRt4((+uhicRt4).toFixed(4));
    if (
      uhicRt5?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRt5) ||
      isNaN(uhicRt5) ||
      +uhicRt5 >= 100
    ) {
      setUhicRt5Err('UHIC_TAX_RT5 must be less than or equal to 99.9999.');
      valid = false;
    } else setUhicRt5((+uhicRt5).toFixed(4));
    if (
      uhicRtLf?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRtLf) ||
      isNaN(uhicRtLf) ||
      +uhicRtLf >= 100
    ) {
      setUhicRtLfErr('UHIC_TAX_RT_LF must be less than or equal to 99.9999.');
      valid = false;
    } else setUhicRtLf((+uhicRtLf).toFixed(4));
    if (
      hpTaxRtAh?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRtAh) ||
      isNaN(hpTaxRtAh) ||
      +hpTaxRtAh >= 100
    ) {
      setHpTaxRtAhErr('HP_TAX_RT_AH must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRtAh((+hpTaxRtAh).toFixed(4));
    if (
      hpTaxRtLf?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRtLf) ||
      isNaN(hpTaxRtLf) ||
      +hpTaxRtLf >= 100
    ) {
      setHpTaxRtLfErr('HP_TAX_RT_LF must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRtLf((+hpTaxRtLf).toFixed(4));
    if (
      hpTaxRt3?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRt3) ||
      isNaN(hpTaxRt3) ||
      +hpTaxRt3 >= 100
    ) {
      setHpTaxRt3Err('HP_TAX_RT_RT3 must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRt3((+hpTaxRt3).toFixed(4));
    if (
      hpTaxRt4?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRt4) ||
      isNaN(hpTaxRt4) ||
      +hpTaxRt4 >= 100
    ) {
      setHpTaxRt4Err('HP_TAX_RT_RT4 must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRt4((+hpTaxRt4).toFixed(4));
    if (
      hpTaxRt5?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRt5) ||
      isNaN(hpTaxRt5) ||
      +hpTaxRt5 >= 100
    ) {
      setHpTaxRt5Err('HP_TAX_RT_RT5 must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRt5((+hpTaxRt5).toFixed(4));
    if (!valid) {
      return valid;
    }
    if (
      stCdForm !== updateRow['ST_CD'] ||
      glLocNbrForm !== updateRow['GL_LOC_NBR'] ||
      effDt !== updateRow['EFFDT'] ||
      medSurchrgIndForm !== updateRow['MED_SURCHRG_IND'] ||
      stNm !== updateRow['ST_NM'] ||
      stAbbrCdForm !== updateRow['ST_ABBR_CD'] ||
      uhicRtAH !== updateRow['UHIC_TAX_RT_AH'] ||
      uhicRtLf !== updateRow['UHIC_TAX_RT_LF'] ||
      uhicRtMeds !== updateRow['UHIC_TAX_RT_MEDSUP'] ||
      uhicRt4 !== updateRow['UHIC_TAX_RT4'] ||
      uhicRt5 !== updateRow['UHIC_TAX_RT5'] ||
      hpTaxRtAh !== updateRow['HP_TAX_RT_AH'] ||
      hpTaxRtLf !== updateRow['HP_TAX_RT_LF'] ||
      hpTaxRt3 !== updateRow['HP_TAX_RT_RT3'] ||
      hpTaxRt4 !== updateRow['HP_TAX_RT_RT4'] ||
      hpTaxRt5 !== updateRow['HP_TAX_RT_RT5']
    ) {
      return true;
    } else {
      setAlertText('Nothing changed for update.');
      setAlertType('error');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return false;
    }
  };
  const clearErrors = () => {
    setStCdErr('');
    setGlLocNbrErr('');
    setEffDtErr('');
    setMedSurchrgIndErr('');
    setStNmErr('');
    setAbbrCdErr('');
    setUhicRtAhErr('');
    setUhicRtLfErr('');
    setUhicRtMedsErr('');
    setUhicRt4Err('');
    setUhicRt5Err('');
    setHpTaxRtAhErr('');
    setHpTaxRtLfErr('');
    setHpTaxRt3Err('');
    setHpTaxRt4Err('');
    setHpTaxRt5Err('');
  };

  const validateSave = () => {
    clearErrors();
    let valid = true;
    if (stCdForm?.trim() === '') {
      setStCdErr('Please enter valid ST_CD.');
      valid = false;
    }
    if (glLocNbrForm?.trim() === '') {
      setGlLocNbrErr('Please enter valid GL_LOC_NBR.');
      valid = false;
    }
    if (effDt === null || effDt?.length !== 10) {
      setEffDtErr('Please enter valid EFF_DT.');
      valid = false;
    }
    if (medSurchrgIndForm?.trim() === '') {
      setMedSurchrgIndErr('Please enter valid MED_SURCHRG_IND.');
      valid = false;
    }
    if (stNm?.trim() === '') {
      setStNmErr('Please enter valid ST_NM.');
      valid = false;
    }
    if (stAbbrCdForm?.trim() === '') {
      setAbbrCdErr('Please enter valid ST_ABBR_CD.');
      valid = false;
    }
    if (
      uhicRtAH?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRtAH) ||
      isNaN(uhicRtAH) ||
      +uhicRtAH >= 100
    ) {
      setUhicRtAhErr('UHIC_TAX_RT_AH must be less than or equal to 99.9999.');
      valid = false;
    } else setUhicRtAH((+uhicRtAH).toFixed(4));
    if (
      uhicRtMeds?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRtMeds) ||
      isNaN(uhicRtMeds) ||
      +uhicRtMeds >= 100
    ) {
      setUhicRtMedsErr(
        'UHIC_TAX_RT_MEDSUP must be less than or equal to 99.9999.'
      );
      valid = false;
    } else setUhicMeds((+uhicRtMeds).toFixed(4));
    if (
      uhicRt4?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRt4) ||
      isNaN(uhicRt4) ||
      +uhicRt4 >= 100
    ) {
      setUhicRt4Err('UHIC_TAX_RT4 must be less than or equal to 99.9999.');
      valid = false;
    } else setUhicRt4((+uhicRt4).toFixed(4));
    if (
      uhicRt5?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRt5) ||
      isNaN(uhicRt5) ||
      +uhicRt5 >= 100
    ) {
      setUhicRt5Err('UHIC_TAX_RT5 must be less than or equal to 99.9999.');
      valid = false;
    } else setUhicRt5((+uhicRt5).toFixed(4));
    if (
      uhicRtLf?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(uhicRtLf) ||
      isNaN(uhicRtLf) ||
      +uhicRtLf >= 100
    ) {
      setUhicRtLfErr('UHIC_TAX_RT_LF must be less than or equal to 99.9999.');
      valid = false;
    } else setUhicRtLf((+uhicRtLf).toFixed(4));
    if (
      hpTaxRtAh?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRtAh) ||
      isNaN(hpTaxRtAh) ||
      +hpTaxRtAh >= 100
    ) {
      setHpTaxRtAhErr('HP_TAX_RT_AH must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRtAh((+hpTaxRtAh).toFixed(4));
    if (
      hpTaxRtLf?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRtLf) ||
      isNaN(hpTaxRtLf) ||
      +hpTaxRtLf >= 100
    ) {
      setHpTaxRtLfErr('HP_TAX_RT_LF must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRtLf((+hpTaxRtLf).toFixed(4));
    if (
      hpTaxRt3?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRt3) ||
      isNaN(hpTaxRt3) ||
      +hpTaxRt3 >= 100
    ) {
      setHpTaxRt3Err('HP_TAX_RT_RT3 must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRt3((+hpTaxRt3).toFixed(4));
    if (
      hpTaxRt4?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRt4) ||
      isNaN(hpTaxRt4) ||
      +hpTaxRt4 >= 100
    ) {
      setHpTaxRt4Err('HP_TAX_RT_RT4 must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRt4((+hpTaxRt4).toFixed(4));
    if (
      hpTaxRt5?.toString()?.trim() === '' ||
      !/^[0-9.]+$/.test(hpTaxRt5) ||
      isNaN(hpTaxRt5) ||
      +hpTaxRt5 >= 100
    ) {
      setHpTaxRt5Err('HP_TAX_RT_RT5 must be less than or equal to 99.9999.');
      valid = false;
    } else setHpTaxRt5((+hpTaxRt5).toFixed(4));
    // already exists in table
    getTableState?.data?.forEach((row) => {
      if (row.ST_CD === stCdForm && row.GL_LOC_NBR === glLocNbrForm) {
        setAlertText(
          'ST_CD and GL_LOC_NBR combination already exists in the table.'
        );
        setAlertType('error');
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
        valid = false;
      }
    });

    return valid;
  };
  const updateValidate = () => {
    if (validateUpdate()) {
      setUpdateModal(true);
    }
  };
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  const save = async () => {
    setHideUpdate(false);
    setSaveModal(false);
    const now = new Date();
    setFlag(false);
    let payload = {
      stCd: stCdForm,
      glLocNbr: glLocNbrForm,
      effDt: effDt,
      medSurchrgInd: medSurchrgIndForm,
      stNm: stNm,
      stAbbrCd: stAbbrCdForm,
      uhicTaxRtAh: uhicRtAH,
      uhicTaxRtLf: uhicRtLf,
      uhicTaxRtMedsup: uhicRtMeds,
      uhicTaxRt4: uhicRt4,
      uhicTaxRt5: uhicRt5,
      hpTaxRtAh: hpTaxRtAh,
      hpTaxRtLf: hpTaxRtLf,
      hpTaxRtRt3: hpTaxRt3,
      hpTaxRtRt4: hpTaxRt4,
      hpTaxRtRt5: hpTaxRt5,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: msid.substr(0, 7).toUpperCase(),
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'Y',
    };
    setStCdForm('');
    setGlLocNbrForm('');
    setEffDt(null);
    setMedSurchrgIndForm('');
    setStNm('');
    setAbbrCdForm('');
    setUhicRtAH('');
    setUhicRtLf('');
    setUhicMeds('');
    setUhicRt4('');
    setUhicRt5('');
    setHpTaxRtAh('');
    setHpTaxRtLf('');
    setHpTaxRt3('');
    setHpTaxRt4('');
    setHpTaxRt5('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/state/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        setAlertText('You have successfully added the record.');
        setAlertType('success');
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
        getTableData1();
      }
    } catch (error) {
      // add-alert-popup
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setStCdForm('');
    setGlLocNbrForm('');
    setEffDt(null);
    setMedSurchrgIndForm('');
    setStNm('');
    setAbbrCdForm('');
    setUhicRtAH('');
    setUhicRtLf('');
    setUhicMeds('');
    setUhicRt4('');
    setUhicRt5('');
    setHpTaxRtAh('');
    setHpTaxRtLf('');
    setHpTaxRt3('');
    setHpTaxRt4('');
    setHpTaxRt5('');
  };

  useEffect(() => {
    const hasTableCTSReadWriteAccess = env.Table_CTS_Read_Write_Access.some(
      (role) => {
        return userRolesGroup?.includes(role);
      }
    );
    setHasRWaccess(hasTableCTSReadWriteAccess);
  }, [userRolesGroup]);

  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update"
          isOpen={updateModal}
          onClose={() => setUpdateModal(false)}
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>ST_CD:</b> {updateRow['ST_CD']}
              </span>
              <span>
                <b>ST_CD:</b> {stCdForm}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>GL_LOC_NBR:</b> {updateRow['GL_LOC_NBR']}
              </span>
              <span>
                <b>GL_LOC_NBR:</b> {glLocNbrForm}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>EFF_DT:</b> {updateRow['EFFDT']}
              </span>
              <span>
                <b>EFF_DT:</b> {effDt}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>MED_SURCHRG_IND:</b> {updateRow['MED_SURCHRG_IND']}
              </span>
              <span>
                <b>MED_SURCHRG_IND:</b> {medSurchrgIndForm}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>ST_NM:</b> {updateRow['ST_NM']}
              </span>
              <span>
                <b>ST_NM:</b> {stNm}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>ST_ABBR_CD:</b> {updateRow['ST_ABBR_CD']}
              </span>
              <span>
                <b>ST_ABBR_CD:</b> {stAbbrCdForm}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>UHIC_TAX_RT_AH:</b> {updateRow['UHIC_TAX_RT_AH']}
              </span>
              <span>
                <b>UHIC_TAX_RT_AH:</b> {uhicRtAH}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>UHIC_TAX_RT_LF:</b> {updateRow['UHIC_TAX_RT_LF']}
              </span>
              <span>
                <b>UHIC_TAX_RT_LF:</b> {uhicRtLf}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>UHIC_TAX_RT_MEDSUP:</b> {updateRow['UHIC_TAX_RT_MEDSUP']}
              </span>
              <span>
                <b>UHIC_TAX_RT_MEDSUP:</b> {uhicRtMeds}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>UHIC_TAX_RT4:</b> {updateRow['UHIC_TAX_RT4']}
              </span>
              <span>
                <b>UHIC_TAX_RT4:</b> {uhicRt4}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>UHIC_TAX_RT5:</b> {updateRow['UHIC_TAX_RT5']}
              </span>
              <span>
                <b>UHIC_TAX_RT5:</b> {uhicRt5}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HP_TAX_RT_AH:</b> {updateRow['HP_TAX_RT_AH']}
              </span>
              <span>
                <b>HP_TAX_RT_AH:</b> {hpTaxRtAh}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HP_TAX_RT_LF:</b> {updateRow['HP_TAX_RT_LF']}
              </span>
              <span>
                <b>HP_TAX_RT_LF:</b> {hpTaxRtLf}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HP_TAX_RT_RT3:</b> {updateRow['HP_TAX_RT_RT3']}
              </span>
              <span>
                <b>HP_TAX_RT_RT3:</b> {hpTaxRt3}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HP_TAX_RT_RT4:</b> {updateRow['HP_TAX_RT_RT4']}
              </span>
              <span>
                <b>HP_TAX_RT_RT4:</b> {hpTaxRt4}
              </span>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HP_TAX_RT_RT5:</b> {updateRow['HP_TAX_RT_RT5']}
              </span>
              <span>
                <b>HP_TAX_RT_RT5:</b> {hpTaxRt5}
              </span>
            </div>
            <br />
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '0px',
                  marginBottom: '14px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                title={alertText}
                variant={alertType}
                isVisible={showAlert}
                onClose={() => {
                  setShowAlert(false);
                }}
              />
              <Grid>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="ST_CD"
                    options={stCd}
                    placeholder="Select"
                    onChange={(e) => setStCdSelected(e)}
                    value={stCdSelected}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="GL_LOC_NBR"
                    options={glLocNbr}
                    placeholder="Select"
                    onChange={(e) => setGlLocNbrSelected(e)}
                    value={glLocNbrSelected}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="MED_SURCHRG_IND"
                    options={medSurchrgInd}
                    placeholder="Select"
                    onChange={(e) => setMedSurchrgIndSelected(e)}
                    value={medSurchrgIndSelected}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="ST_ABBR_CD"
                    options={stAbbrCd}
                    placeholder="Select"
                    onChange={(e) => setAbbrCdSelected(e)}
                    value={stAbbrCdSelected}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <Button css={{ marginTop: '25px' }} onClick={onLookup}>
                    Lookup
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 10 }}>
                  <TextInputArea value={text} rows={5} />
                </Grid.Col>
                <Grid.Col span={{ xs: 2 }}>
                  <Button css={{ marginTop: '15px' }} onClick={clear}>
                    Clear
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid css={{ marginTop: '15px', marginLeft: '0px' }}>
                <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                  Database:
                </Text>
                <span>
                  {' '}
                  {getEnvironment()} - {location?.state?.database}
                </span>
                <Text
                  css={{
                    fontWeight: 600,
                    fontSize: '18px',
                    marginLeft: '20px',
                  }}
                >
                  Table:
                </Text>
                <span>{location?.state?.systemSelectedValue}</span>
                <br />
                <span style={{ marginLeft: '12px' }}>
                  {location?.state?.data?.length} Records
                </span>
                {hasRWaccess && (
                  <Button
                    variant="tertiary"
                    onClick={addRow}
                    isDisabled={flag || hideAdd ? true : false}
                    css={{ marginTop: '-10px' }}
                  >
                    + Add Row
                  </Button>
                )}
                {flag && (
                  <>
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                      }}
                    >
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ST_CD"
                          errorMessage={stCdErr}
                          maxLength={2}
                          isDisabled={updateFlag}
                          value={stCdForm}
                          onChange={(e) => setStCdForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="GL_LOC_NBR"
                          maxLength={4}
                          isDisabled={updateFlag}
                          errorMessage={glLocNbrErr}
                          value={glLocNbrForm}
                          onChange={(e) => setGlLocNbrForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <DateInput
                          label="EFF_DT"
                          value={effDt}
                          errorMessage={effDtErr}
                          onChange={(e) => setEffDt(e)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          maxLength={1}
                          label="MED_SURCHRG_IND"
                          errorMessage={medSurchrgIndErr}
                          value={medSurchrgIndForm}
                          onChange={(e) => setMedSurchrgIndForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          errorMessage={stNmErr}
                          label="ST_NM"
                          maxLength={20}
                          value={stNm}
                          onChange={(e) => setStNm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ST_ABBR_CD"
                          errorMessage={abbrCdErr}
                          value={stAbbrCdForm}
                          maxLength={2}
                          onChange={(e) => setAbbrCdForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT_AH"
                          errorMessage={uhicRtAhErr}
                          maxLength={7}
                          value={uhicRtAH}
                          onChange={(e) => setUhicRtAH(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT_LF"
                          errorMessage={uhicRtLfErr}
                          value={uhicRtLf}
                          maxLength={7}
                          onChange={(e) => setUhicRtLf(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT_MEDSUP"
                          errorMessage={uhicRtMedsErr}
                          maxLength={7}
                          value={uhicRtMeds}
                          onChange={(e) => setUhicMeds(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          maxLength={7}
                          label="UHIC_TAX_RT4"
                          errorMessage={uhicRt4Err}
                          value={uhicRt4}
                          onChange={(e) => setUhicRt4(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT5"
                          errorMessage={uhicRt5Err}
                          value={uhicRt5}
                          onChange={(e) => setUhicRt5(e.target.value)}
                          maxLength={7}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_AH"
                          errorMessage={hpTaxRtAhErr}
                          maxLength={7}
                          value={hpTaxRtAh}
                          onChange={(e) => setHpTaxRtAh(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_LF"
                          maxLength={7}
                          value={hpTaxRtLf}
                          errorMessage={hpTaxRtLfErr}
                          onChange={(e) => setHpTaxRtLf(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_RT3"
                          errorMessage={hpTaxRt3Err}
                          maxLength={7}
                          value={hpTaxRt3}
                          onChange={(e) => setHpTaxRt3(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_RT4"
                          maxLength={7}
                          errorMessage={hpTaxRt4Err}
                          value={hpTaxRt4}
                          onChange={(e) => setHpTaxRt4(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          maxLength={7}
                          label="HP_TAX_RT_RT5"
                          value={hpTaxRt5}
                          errorMessage={hpTaxRt5Err}
                          onChange={(e) => setHpTaxRt5(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                        <Button
                          onClick={updateFlag ? updateValidate : saveValidate}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                        <Button onClick={close}>Cancel</Button>
                      </Grid.Col>
                    </Grid>
                  </>
                )}
              </Grid>
              <span style={{ marginLeft: '0px' }}>
                {location?.state?.data?.length || 0} Records
              </span>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <div
                    style={{
                      height:
                        getTableState?.data?.length > 6 ? '550px' : 'auto',
                    }}
                  >
                    <DataTable
                      title="Unlock Claim Transactions"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </div>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(State, 'table_cts_ro_rw');
